import React from 'react';
import {SelectField} from 'lib/react-md'
import {ErrorMessage} from 'formik';
import _ from 'lib/lodash'

export default ({
	                className,
	                field: {name, value = "", onBlur}, // {name, value, onChange, onBlur}
	                form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                label,
	                menuItems = [],
	                onChanged = _.noop,
	                ...otherProps
                }) => {

	const onChange = (value, e) => {
		setFieldValue(name, value);
		onChanged({[name]: value});
	};

	return (
		<div className={className}>
			{<label>{label ? label : undefined}</label>}
			<div id="listbox">
				<SelectField
					{...otherProps}
					id={name}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={() => setFieldTouched(name, true)}
					menuItems={menuItems}
				/>
			</div>
			<div id="errorText"><ErrorMessage name={name}/></div>
		</div>
	);
}