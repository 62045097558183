import React from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl} from 'components/hocs'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g; // string template variables are stored in form {{var}}

class ViewComponent extends Wrappers.EditComponentWrapper {
	state = {
		...this.state, // important
		Component: () => <div>Custom Component not available</div>
	};

	componentDidMount() {
		const {item, actions} = this.props;
		const {properties = {}} = item;
		const {component = ""} = properties;

		if (component === "") return null;

		try {
			const Component = React.lazy(() => import(/* webpackChunkName: "helvetia" */ `custom-nodes/${component}`));
			this.setState({Component});
		} catch (e) {
			actions.raiseCustomError(new Error("Custom Component could be loaded."));
		}
	}

	render() {
		const {item, actions} = this.props;
		const {properties = {}} = item;
		const {data = ""} = properties;
		let dataObj = {};

		const {Component} = this.state;

		const bundleProps = actions.getBundleProperties();
		const stickerProps = actions.getStickerProperties();
		const obj = {bundleProps, stickerProps, ...properties};

		try {
			const interpolatedText = _.template(data)(obj); // use {{obj.<variableName>}} to access its values
			dataObj = JSON.parse(interpolatedText || "{}");
		} catch (e) {
			actions.raiseCustomError(new Error("Custom Component data object not valid."));
		}

		return (
			<React.Suspense fallback={<div>Loading custom component...</div>}>
				<Wrappers.ViewComponentWrapper>
					<Component {...this.props} dataObj={dataObj} updateProperty={this.updateProperty}/>
				</Wrappers.ViewComponentWrapper>
			</React.Suspense>
		);
	}
}


export default {
	ViewComponent: withIntl(ViewComponent),
	EditComponent: () => null,
}