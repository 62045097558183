import React, {Component} from 'react';
import {ListItem} from 'lib/react-md'
import _ from 'lib/lodash'

import css from './ContainerComponentWrapper.module.scss'

class ContainerComponentWrapper extends Component {
	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['ListItemContent', 'item']);
	}

	render() {
		const {
			item, actions, languages, allowEdit, allowSubnodeAdd, EditComponent, HeaderComponent,
			ListItemContent, className = css.card, visible
		} = this.props;
		const {target = "slave"} = this.props;

		return (
			<div className={css.container}>
				<ListItem
					key={`container-component-${item.$id}-${item.$subnodeCount}`} // make sure HeaderComponent gets updated when $subnodeCount changes
					primaryText=""
					className={className}
					component={HeaderComponent}
					nestedItems={ListItemContent}
					animateNestedItems={false}
					visible={visible}
					onClick={_.noop}
					tileProps={{item, actions, allowEdit, allowSubnodeAdd, EditComponent, target, languages}}
				/>
			</div>
		)
	}
}

export default ContainerComponentWrapper