import React from 'react'

import FormContainer from 'components/forms/FormContainer';
import FormEvent from './FormEvent'

const PageEvent = ({substep, cancel, onSubmit, actions, item, images, onClearForm}) => {
	const FormComponent = FormEvent({substep, cancel, actions, item, images, onClearForm});

	return (
		<FormContainer
			onSubmit={onSubmit}
			FormComponent={FormComponent}/>
	);
};

export default PageEvent;