import axios from 'axios'
import {auth} from './firebase';
import _ from 'lib/lodash'

const APIVERSION = process.env.REACT_APP_FIREBASE_APIVERSION;
const APISUBVERSION = process.env.REACT_APP_FIREBASE_APISUBVERSION;
const LOGINURL = process.env.REACT_APP_FIREBASE_LOGINURL;

const storeTokenClaims = (userClaims = {}, tokenClaims = {}) => {
	try {
		const claims = Buffer.from(tokenClaims, 'base64').toString('utf8');
		const decoded = JSON.parse(claims);

		const {profileId = ""} = decoded;
		const {tagId = ""} = userClaims;

		const claimsId = `stx-${APIVERSION}-${APISUBVERSION}-userclaims-${profileId}${tagId && '-'}${tagId}`;

		localStorage.setItem(claimsId, JSON.stringify(tokenClaims)); // needs to be provided as polyfill if unavailable

		return tokenClaims;
	} catch (e) {
	}
};

// check if valid token for given profile and tag are available
export const getStoredTokenClaims = (userClaims = {}) => {

	const {profileId = "", tagId = ""} = userClaims;
	const claimsId = `stx-${APIVERSION}-${APISUBVERSION}-userclaims-${profileId}${tagId && '-'}${tagId}`;

	if (!localStorage.getItem(claimsId)) return null; // needs to be provided as polyfill if unavailable
	try {
		const claims = Buffer.from(localStorage.getItem(claimsId), 'base64').toString('utf8');
		const decoded = JSON.parse(claims);

		const uidMatch = _.get(decoded, 'profileId') === profileId && (!!tagId ? _.get(decoded, 'tag.id') === tagId : true);
		const expMatch = new Date(decoded.exp) > new Date();
		return uidMatch && expMatch ? decoded : null;
	} catch (e) {
		return null;
	}
};

/**
 * login with callable function: generates additional overhead, so no performance improvement

export const signIn = async matchParams => {
 	const {portalId = "", tagId = "", params = {}} = matchParams;

	try {
		const login = functions.httpsCallable('login'); // shape: { token: ..., claims: { portal: ..., [sticker: ...] } }
		const {data: {token, claims}} = await login({portalId, tagId, params});

		await auth.signInWithCustomToken(token);
		await storeTokenClaims(matchParams, claims); // store token locally, if possible

	} catch(error) {
		if (!!error.code) {
			if (error.code === "auth/invalid-custom-token") throw error; // Firebase or custom api error
			// Firebase or custom api error, e.g. TAG_NOT_FOUND
			const e = new Error(error.code);
			e.code = error.message;
			e.details = error.details;
			throw e;
		} else {
			const e = new Error();
			e.code = "unknown";
			throw e;
		}
	}
};
*/

export const signIn = async matchParams => {
	const {portalId = "", tagId = "", params = {}} = matchParams;

	return axios.get(LOGINURL, {params: {portalId, tagId, params}})
		.then(response => response.data) // shape: { token: ..., claims: { portal: ..., [sticker: ...] } }
		.then(token => auth.signInWithCustomToken(token.token).then(() => token.claims))
		.then(tokenClaims => storeTokenClaims(matchParams, tokenClaims)) // store token locally, if possible
		.catch(error => {
			if (!!error.code) {
				// Firebase auth error uses code and message props, e.g. auth/invalid-custom-token
				const e = new Error(error.message);
				e.code = error.code; // Firebase or custom api error
				throw e;
			} else {
				// Firebase or custom api error, e.g. TAG_NOT_FOUND
				const res = _.get(error, 'response.data');
				const e = new Error(res.code);
				e.code = res.code;
				e.details = res.message;
				throw e;
			}
		})
};

//export const signedIn = () => auth.currentUser;

//export const signOut = () => signedIn() ? auth.signOut() : Promise.resolve();

export const module = auth;