import React, {PureComponent} from 'react';
import {getUserPage, RichText} from 'lib/cms'
import _ from 'lib/lodash'
import {Skeleton} from 'components/utils'

import css from './CustomMYSTXUserSlave.scss'
import {Container, Header, Picture, Content} from './CustomMYSTXUserSlave.styles'

const C = "profiles.my-stx.CustomMYSTXUserSlave";

const Loaders = ({name}) => (
	<>
		<Skeleton height="200px" text={{section: C, id: "PAGE_LOADING", params: {name}}}/>
		<div style={{width: "80%", padding: "24px", margin: "auto"}}>
			<Skeleton height="40px"/>
			<Skeleton height="120px"/>
			<Skeleton height="80px"/>
		</div>
	</>
);

class CustomHVCHButtonAssistanceSlave extends PureComponent {
	state = {
		doc: {}
	};

	componentDidMount() {
		const {intl, actions} = this.props;
		const stickerProps = actions.getStickerProperties();
		const {prismicDocumentUid} = stickerProps;

		getUserPage({uid: prismicDocumentUid, lang: intl.locale}).then(res => this.setState({doc: res.data || {}}));
	}

	render() {
		const {actions} = this.props;

		const stickerProps = actions.getStickerProperties();
		const {name} = stickerProps;

		const {doc} = this.state;

		if (_.isEmpty(doc)) return <Loaders name={name}/>;

		const {data} = doc;

		return (
			<div className={css.viewContainer}>
				<Container>
					<Picture>
						<img src={data.picture.url} alt={data.picture.alt}/>
					</Picture>
					<Header>
						<RichText render={data.name}/>
					</Header>
					<Content>
						<RichText render={data.content}/>
					</Content>
				</Container>
			</div>
		);
	}
}

export default CustomHVCHButtonAssistanceSlave;