import React, {Component} from 'react';
import GridGallery from 'react-grid-gallery';
import _ from 'lib/lodash'

import css from './ResponsiveDocumentGallery.module.scss'

const DEFAULT_THUMBNAIL_WIDTH = 180;
const DEFAULT_THUMBNAIL_HEIGHT = 270;

class ResponsiveDocumentGallery extends Component {
	static defaultProps = {
		showCustomControlsInView: false,
		showCustomControlsInLightbox: false,
		enableFullscreen: true
	};

	state = {
		currentIndex: 0,
		width: 0,
		height: 0
	};

	constructor(props) {
		super(props);
		this.gridGallery = React.createRef();
	}

	updateWindowDimensions = () => {
		this.setState({width: window.innerWidth, height: window.innerHeight});
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	renderLightbox = (items, customControls, showCustomControlsInView, showCustomControlsinLightbox) => () => {
		const noItems = _.size(items);
		const {currentIndex} = this.state;

		const thumbnailActions = item => (
			<div style={{textAlign: "right"}}>
				{customControls(item)()}
			</div>
		);

		const images = _.map(items, item => ({
			src: item.original,
			thumbnail: item.thumbnail,
			caption: item.description,
			thumbnailWidth: item.thumbnailWidth || DEFAULT_THUMBNAIL_WIDTH,
			thumbnailHeight: item.thumbnailHeight || DEFAULT_THUMBNAIL_HEIGHT,
			thumbnailCaption: showCustomControlsInView ? thumbnailActions(item) : null
		}));

		const theme = {
			container: {
				background: 'rgba(255, 255, 255, 0.9)',
			},
			arrow: {
				backgroundColor: 'rgba(255, 255, 255, 0.8)',
				fill: '#222',
				opacity: 0.6,
				transition: 'opacity 200ms',

				':hover': {
					opacity: 1,
				},
			},
			arrow__size__medium: {
				borderRadius: 40,
				height: 40,
				marginTop: -20,

				'@media (min-width: 768px)': {
					height: 70,
					padding: 15,
				},
			},
			arrow__direction__left: {marginLeft: 10},
			arrow__direction__right: {marginRight: 10},
			close: {
				fill: '#D40000',
				opacity: 0.6,
				transition: 'all 200ms',
				':hover': {
					opacity: 1,
				},
			},
			footer: {
				color: 'black',
			},
			footerCount: {
				color: 'rgba(0, 0, 0, 0.6)',
			},
			thumbnail: {},
			thumbnail__active: {
				boxShadow: '0 0 0 2px #00D8FF',
			}
		};

		const customControlsContainer = (
			<div key="custom-controls-container">
				{customControls instanceof Function && customControls(null, () => this.gridGallery.current.state.currentImage)()}
			</div>
		);

		// bug in packaget: backdropClosesModal needs to be enabled to suppress onClick and onTouch warnings!
		return (
			<div className={css.lightboxImageContainer}>
				<GridGallery
					{...this.props}
					ref={this.gridGallery}
					backdropClosesModal={true}
					currentImage={currentIndex}
					customControls={showCustomControlsinLightbox ? [customControlsContainer] : null}
					enableImageSelection={false}
					enableKeyboardInput
					enableLightbox={false}
					imageCountSeparator={" von "}
					images={images}
					isOpen={false}
					lightboxWidth={1024}
					margin={4}
					preloadNextImage
					rowHeight={DEFAULT_THUMBNAIL_HEIGHT}
					showCloseButton
					showImageCount={true}
					showLightboxThumbnails={noItems > 1}
					theme={theme}
				/>
			</div>
		);
	};

	render() {
		const {
			items = [],
			customControls = () => _.noop, // customControls receives the current item as "this"
			showCustomControlsInView,
			showCustomControlsInLightbox
		} = this.props;

		const noImages = _.size(items);

		if (noImages === 0) return null;

		return (
			<div className={css.container}>
				{this.renderLightbox(items, customControls, showCustomControlsInView, showCustomControlsInLightbox)()}
			</div>
		);
	}
}

export default ResponsiveDocumentGallery;
