import React from 'react';
import ImagesUploader from './ImagesUploader'

export default ({
	                className,
	                field: {name, onChange, value, onBlur}, // {name, value, onChange, onBlur}
	                form: {touched, errors}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                label,
	                ...otherProps
                }) => (
	<div className={className}>
		{<label>{label ? label : undefined}</label>}
		<ImagesUploader {...otherProps}/>
	</div>
);