import React from 'react'
import {Field, Form} from 'formik';
import {StyledFormListbox} from '../../../../controls';
import {StyledFormDatetimePicker} from '../../../../../../../helvetia-at/stickers/pilot-19/controls';
import * as Yup from 'yup';
import styled from 'styled-components/macro';

const C = "profiles.folder.PageService";

const PageWrapper = styled.div`
	padding: 16px; 
`;

export const ServiceForm = ({initialValues}) => ({T, formProps: {submitForm, setFieldValue}}) => {
	const items = [{
		label: T(C, "resource.label.1"),
		value: "Auto im Fuhrpark A",
	}, {
		label: T(C, "resource.label.2"),
		value: "Parkplatz am Gästeparkplatz",
	}, {
		label: T(C, "resource.label.3"),
		value: "Waschturm W1",
	}];

	return (
		<PageWrapper>
			<Form>
				<Field
					type="text"
					name="details"
					label={T(C, "resource.details.label")}
					placeholder={T(C, "placeholder")}
					component={StyledFormListbox}
					menuItems={items}
					fullWidth
					sameWidth
					stripActiveItem
				/>
				<Field
					type="datetime-local"
					name="datetime"
					label={T(C, "resource.datetime.label.from")}
					placeholder={T(C, "placeholder")}
					component={StyledFormDatetimePicker}
					min={new Date()}
				/>
				<Field
					type="datetime-local"
					name="datetimeTo"
					label={T(C, "resource.datetime.label.to")}
					placeholder={T(C, "placeholder")}
					component={StyledFormDatetimePicker}
					min={new Date()}
				/>
			</Form>
		</PageWrapper>
	);
};

export const validationSchema = Yup.object().shape({
	details: Yup.string().required("Bitte wählen Sie eine Ressource aus."),
});

