import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer, StickerPickerContainer} from 'components/containers'
import {TextFieldEdit, RadioGroup} from 'components/controls'

import css from './TextMaster.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent} = this.props;
		const {properties = {}, filters = {}, style = {}} = item;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					{filters.language && <Flag className={css.flag} code={filters.language}/>}
					<div className={css.label} style={style}>{properties.label}</div>
					<div className={css.icon}>
						<EditComponent/>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {T} = this.props;
		const {item} = this.state;
		const {properties = {}, filters = {}, style = {}} = item;
		const {label = "", purpose = ""} = properties;
		const {fontSize = "", marginTop = "", marginBottom = ""} = style;
		const {language, stickers} = filters;

		const styles = {
			title: {fontSize: fontSize || "24px", fontWeight: 500},
			block: {fontSize: fontSize || "16px", fontWeight: 400}
		};

		const titles = {
			title: "title",
			block: "block"
		};

		return (
			<div className={css.editContainer}>
				<RadioGroup
					id={`textmaster-radiogroup-purpose-${item.$id}`}
					label={T("label", "appearance")}
					controls={[
						{label: T("label", titles.title), value: "title"},
						{label: T("label", titles.block), value: "block"},
					]}
					defaultValue={purpose}
					onChange={purpose => {
						this.updateProperty({purpose});
						this.updateStyle(styles[purpose]);
					}}
				/>
				<TextFieldEdit
					id="title"
					label={_.get(titles, purpose)}
					value={{label}}
					rows={1}
					maxRows={3}
					onSubmit={this.updateProperty}
				/>
				<TextFieldEdit
					id="fontSize"
					label="fontSize"
					value={{fontSize}}
					onSubmit={this.updateStyle}
				/>
				<TextFieldEdit
					id="marginTop"
					label="marginTop"
					value={{marginTop}}
					onSubmit={this.updateStyle}
				/>
				<TextFieldEdit
					id="marginBottom"
					label="marginBottom"
					value={{marginBottom}}
					onSubmit={this.updateStyle}
				/>
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
				<StickerPickerContainer
					defaultValue={{stickers}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}