import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import _ from 'lib/lodash'

import {layoutActions} from 'components/handlers/actions'

const withDrawer = (WrappedComponent, options) => {

	class DrawerContainer extends Component {
		initDrawer = ({container, navItems, position, memoized = false}) => {
			this.props.layoutActions.addDrawer(options.name, {container, navItems, position, memoized});
		};

		updateDrawer = ({container, navItems, position}) => {
			this.props.layoutActions.updateDrawer(options.name, {container, navItems, position});
		};

		openDrawer = () => this.props.layoutActions.openDrawer(options.name);

		closeDrawer = this.props.layoutActions.closeDrawer;

		removeDrawer = () => this.props.layoutActions.removeDrawer(options.name);

		shouldComponentUpdate(nextProps) {
			return _.anyDiff(nextProps, this.props, ['layoutHandler']);
		}

		componentWillUnmount() {
			this.props.layoutActions.removeDrawer(options.name)
		};

		render() {
			const {layoutHandler, layoutActions, ...otherProps} = this.props;

			return (
				<WrappedComponent
					initDrawer={this.initDrawer}
					updateDrawer={this.updateDrawer}
					openDrawer={this.openDrawer}
					closeDrawer={this.closeDrawer}
					removeDrawer={this.removeDrawer}
					{...otherProps}
				/>
			);
		}
	}

	const mapStateToProps = ({layoutHandler}) => ({layoutHandler});
	const mapDispatchToProps = dispatch => ({layoutActions: bindActionCreators(layoutActions, dispatch)});

	return connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);
};

export default withDrawer;