import React, {Component} from 'react'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import {List, TextField, ListItem, Subheader, Button, FontIcon} from 'lib/react-md'
import _ from 'lib/lodash'

import css from './OptionList.module.scss'

const DragHandle = SortableHandle(() => <FontIcon>unfold_more</FontIcon>);

class OptionList extends Component {
	state = {
		items: this.props.items,
		newItem: ""
	};

	onChange = value => this.setState({newItem: value});

	add = () => {
		let {items = [], newItem} = this.state;

		// remove invalid characters from newItem, remove multiple white spaces and leading and trailing ones
		const illegalChars = /[\u0021-\u002f\u003a-\u0040\u005b-\u005e\u0060\u007b-\u007e]/g;
		newItem = newItem.replace(illegalChars, ' ').replace(/\s{2,}/g, ' ').trim();

		// change only if value not empty and no duplicate
		if (newItem && _.isEmpty(items.filter(i => i.label === newItem))) {
			this.setState({items: [...items, {label: newItem, value: newItem}], newItem: ""});
		} else {
			this.setState({newItem: ""}); // reset field
		}
	};

	remove = label => this.setState({items: _.filter(this.state.items, i => i.label !== label)});

	onSortEnd = ({oldIndex, newIndex}) => this.setState({items: arrayMove(this.state.items, oldIndex, newIndex)});

	// eslint-disable-next-line
	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(this.state.items, prevState.items)) {
			this.props.onChange(this.state.items);
		}
	}

	render() {
		const {items, newItem} = this.state;
		const {titleNew = "", titleList = ""} = this.props;

		const SortableItem = SortableElement(({item}) => (
			<ListItem
				className="md-list-unstyled"
				key={item.label}
				leftIcon={<DragHandle/>}
				rightIcon={<Button icon onClick={() => this.remove(item.label)}>delete</Button>}
				primaryText={item.label}
				disabled
			/>
		));

		const SortableList = SortableContainer(({items}) => (
			<List className="md-list-unstyled">
				{_.map(items, (item, index) => <SortableItem key={`item-${item.value}`} index={index} item={item}/>)}
			</List>
		));

		return (
			<div className="md-list-unstyled">
				<div className={css.optionList}>
					<Subheader primaryText={titleNew}/>
					<ListItem
						rightIcon={<Button icon onClick={this.add}>add</Button>}
						primaryText={
							<TextField
								id="option-list-newitem"
								className="md-cell"
								value={newItem}
								onChange={this.onChange}
							/>
						}
						disabled
					/>
					{_.isSet(items) && <Subheader primaryText={titleList}/>}
					<SortableList
						helperClass={css.sortableHocHelper} /* required to make it work in drawer */
						items={items}
						lockToContainerEdges
						lockAxis="y"
						useDragHandle
						onSortEnd={this.onSortEnd}
					/>
				</div>
			</div>
		);
	}
}

export default OptionList;