import React, {Component} from 'react';
import ImageGallery from 'react-image-gallery'
import GridGallery from 'react-grid-gallery';
import _ from 'lib/lodash'

import css from './ResponsiveImageGallery.module.scss'

const DEFAULT_THUMBNAIL_WIDTH = 270;
const DEFAULT_THUMBNAIL_HEIGHT = 180;

class ResponsiveImageGallery extends Component {
	static defaultProps = {
		showCustomControlsInView: false,
		showCustomControlsInLightbox: false,
		enableFullscreen: true
	};

	state = {
		currentIndex: 0,
		width: 0,
		height: 0
	};

	constructor(props) {
		super(props);
		this.gridGallery = React.createRef();
	}

	updateWindowDimensions = () => {
		this.setState({width: window.innerWidth, height: window.innerHeight});
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	renderLightbox = (items, customControls, showCustomControlsInView, showCustomControlsinLightbox) => () => {
		const noItems = _.size(items);
		const {currentIndex} = this.state;

		const thumbnailActions = item => (
			<div style={{textAlign: "right"}}>
				{customControls(item)()}
			</div>
		);

		const images = _.map(items, item => ({
			src: item.original,
			thumbnail: item.thumbnail,
			caption: item.description,
			thumbnailWidth: item.thumbnailWidth || DEFAULT_THUMBNAIL_WIDTH,
			thumbnailHeight: item.thumbnailHeight || DEFAULT_THUMBNAIL_HEIGHT,
			thumbnailCaption: showCustomControlsInView ? thumbnailActions(item) : null
		}));

		const theme = {
			container: {
				background: 'rgba(255, 255, 255, 0.9)',
			},
			arrow: {
				backgroundColor: 'rgba(255, 255, 255, 0.8)',
				fill: '#222',
				opacity: 0.6,
				transition: 'opacity 200ms',

				':hover': {
					opacity: 1,
				},
			},
			arrow__size__medium: {
				borderRadius: 40,
				height: 40,
				marginTop: -20,

				'@media (min-width: 768px)': {
					height: 70,
					padding: 15,
				},
			},
			arrow__direction__left: {marginLeft: 10},
			arrow__direction__right: {marginRight: 10},
			close: {
				fill: '#D40000',
				opacity: 0.6,
				transition: 'all 200ms',
				':hover': {
					opacity: 1,
				},
			},
			footer: {
				color: 'black',
			},
			footerCount: {
				color: 'rgba(0, 0, 0, 0.6)',
			},
			thumbnail: {},
			thumbnail__active: {
				boxShadow: '0 0 0 2px #00D8FF',
			}
		};

		const customControlsContainer = (
			<div key="custom-controls-container">
				{customControls instanceof Function && customControls(null, () => this.gridGallery.current.state.currentImage)()}
			</div>
		);

		// bug in packaget: backdropClosesModal needs to be enabled to suppress onClick and onTouch warnings!
		return (
			<div className={css.lightboxImageContainer}>
				<GridGallery
					ref={this.gridGallery}
					currentImage={currentIndex}
					images={images}
					enableImageSelection={false}
					rowHeight={180}
					margin={4}
					enableLightbox
					backdropClosesModal={true}
					preloadNextImage
					enableKeyboardInput
					imageCountSeparator={" von "}
					isOpen={false}
					showCloseButton
					showImageCount={true}
					lightboxWidth={1024}
					showLightboxThumbnails={noItems > 1}
					theme={theme}
					customControls={showCustomControlsinLightbox ? [customControlsContainer] : null}
				/>
			</div>
		);
	};

	renderGallery = (items, customControls, enableFullscreen) => () => {
		const {currentIndex} = this.state;

		const images = _.map(items, item => ({
			...item,
			originalClass: css.imageGalleryOriginalClass,
			thumbnailClass: css.imageGalleryThumbnailClass
		}));

		const renderCustomControls = customControls => () => (
			<div className={css.imageGalleryCustomControls}>
				{customControls instanceof Function && customControls(currentIndex)()}
			</div>
		);

		const onSlide = currentIndex => this.setState({currentIndex});

		return (
			<div className={css.imageGalleryContainer}>
				<ImageGallery
					startIndex={currentIndex}
					items={images}
					lazyLoad
					showPlayButton={false}
					showIndex
					onSlide={onSlide}
					showFullscreenButton={enableFullscreen}
					renderCustomControls={renderCustomControls(customControls)}
				/>
			</div>
		)
	};

	render() {
		let {
			items = [],
			customControls = () => _.noop, // customControls receives the current item as "this"
			showCustomControlsInView,
			showCustomControlsInLightbox,
			enableFullscreen,
			enforceGallery = false,
			enforceLightbox = false,
		} = this.props;
		const {width, height} = this.state;

		const noImages = _.size(items);
		const autoMode = !enforceGallery && !enforceLightbox;

		const renderGallery = () => this.renderGallery(items, customControls, enableFullscreen)();
		const renderLightbox = () => this.renderLightbox(items, customControls, showCustomControlsInView, showCustomControlsInLightbox)();

		if (noImages === 0) return null;

		return (
			<div className={css.container}>
				{enforceGallery && renderGallery()}
				{enforceLightbox && renderLightbox()}
				{autoMode && width < 480 && height > 550 && noImages > 1 && renderGallery()}
				{autoMode && !(width < 480 && height > 550 && noImages > 1) && renderLightbox()}
			</div>
		);
	}
}

export default ResponsiveImageGallery;
