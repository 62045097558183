import React, {PureComponent} from 'react';
import {SelectionControlGroup} from 'lib/react-md'
import icons from 'lib/icons'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'

import css from './RadioGroupEdit.module.scss'

class RadioGroupEdit extends PureComponent {
	static defaultProps = {
		id: "",
		label: "",
		value: {value: ""},
		onSubmit: _.noop
	};

	state = {
		key: "",
		val: ""
	};

	componentDidMount() {
		const {value} = this.props;

		this.setState({
			key: Object.keys(value)[0],
			val: value[Object.keys(value)[0]]
		});
	}

	componentDidUpdate(prevProps) {
		const {value} = this.props;

		if (!_.isEqual(value, prevProps.value)) {
			this.setState({
				key: Object.keys(value)[0],
				val: value[Object.keys(value)[0]]
			});
		}
	}

	onChange = val => {
		const {key} = this.state;

		this.props.onSubmit({[key]: val});
	};

	render() {
		const {intl, T, FormattedMessage, id, label, value, menuItemLabels, menuItemValues, onSubmit, ...otherProps} = this.props;
		const {val} = this.state;

		const labels = T("menuItems", menuItemLabels).split(";");
		const values = T("menuItems", menuItemValues).split(";");

		const controls = _.map(labels, (label, idx) => ({label, value: values[idx]}));

		return (
			<div className={css.container}>
				<div className="md-text-field-container md-full-width md-text-field-container--multiline">
					<div className="md-text-field-multiline-container">
						<div className={css.selectionControlGroup}>
							<SelectionControlGroup
								{...otherProps}
								id={id}
								name={id}
								type="radio"
								label={T("label", label)}
								controls={controls}
								value={val}
								checkedRadioIcon={icons.check}
								uncheckedRadioIcon={icons.uncheck}
								onChange={this.onChange}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withIntl(RadioGroupEdit);