import React, {PureComponent} from 'react';
import {Button, Subheader} from 'lib/react-md'
import _ from 'lib/lodash'

import {DatetimePicker, LocationPicker} from 'components/controls';

import css from './CustomFormSlave.module.scss';

class CustomFormSlave extends PureComponent {
	state = {
		data: {},
		newDatetime: _.get(this.props, 'item.properties.datetime', new Date()),
		newLocation: _.get(this.props, 'item.properties.location'),
	};

	t = require(`./translations/${this.props.intl.locale}.json`);

	componentDidMount() {
		const {dataObj, intl: {locale}} = this.props;

		this.setState({data: _.get(dataObj, locale, dataObj)})
	}

	renderReservation = ({properties, data, newDatetime}) => {
		const {datetime = new Date()} = properties;

		const updateDatetime = () => {
			const {actions, updateProperty} = this.props;

			updateProperty({datetime: newDatetime}, true);
			actions.showCustomInfo(this.t.submissionText);
		};

		return (
			<>
				<div>
					{this.t.title} {datetime instanceof Date ? datetime.toLocaleDateString() : datetime.toDate().toLocaleDateString()}.
				</div>
				<br/>
				<DatetimePicker
					id="date"
					containerClassName={css.datetimePickerContainer}
					time={false}
					label=""
					min={new Date()}
					value={{newDatetime}}
					onSubmit={value => this.setState(value)}
				/>
				<Button
					className={css.button}
					flat
					secondary
					swapTheming
					primary
					onClick={updateDatetime}
				>
					{this.t.labelReservation}
				</Button>
			</>
		);
	};

	renderLocation = ({properties, data, newLocation}) => {
		const updateLocation = () => {
			const {actions, updateProperty} = this.props;
			const locationLabel = newLocation.label.split(',')[0];

			updateProperty({location: newLocation, locationLabel}, true);
			actions.showCustomInfo(`${this.t.locationText} ${locationLabel}.`);
		};

		return (
			<>
				<div>
					{this.t.locationTitle}
				</div>
				<LocationPicker
					id="location-picker"
					label=""
					markerLabel=""
					value={{newLocation}}
					onSubmit={value => this.setState(value)}
				/>
				{!!newLocation && !!newLocation.label && (
					<Button
						className={css.button}
						flat
						secondary
						swapTheming
						primary
						onClick={updateLocation}
					>
						{this.t.labelLocation}
					</Button>
				)}
			</>
		);
	};

	render() {
		const {item} = this.props;
		const {properties = {}} = item;

		const {data, newDatetime, newLocation} = this.state;

		return (
			<div className={css.container}>
				<Subheader primaryText={this.t.subheader_reservation}/>
				<li>
					{this.renderReservation({properties, data, newDatetime})}
				</li>
				<Subheader primaryText={this.t.subheader_retrieval}/>
				<li>
					{this.renderLocation({properties, data, newLocation})}
				</li>
				<Subheader primaryText={this.t.subheader_disponent}/>

			</div>
		);
	}
}

export default CustomFormSlave