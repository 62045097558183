import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

const withDialog = Component => {
	return class ComponentWithVisibility extends PureComponent {
		state = {
			visible: false
		};

		show = (e, callback = _.noop) => this.setState({visible: true}, callback);
		hide = (e, callback = _.noop) => this.setState({visible: false}, callback);

		// make sure show, hide and visible can be overwritten by props
		render() {
			return <Component show={this.show} hide={this.hide} {...this.state} {...this.props}/>;
		}
	}
};

export default withDialog;
