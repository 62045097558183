import React from 'react'
import {SelectionControlGroup} from 'lib/react-md'
import icons from 'lib/icons'

const RadioGroup = ({id, label, controls, defaultValue, onChange}) => (
	<div className="md-text-field-container md-full-width md-text-field-container--multiline">
		<label htmlFor={id} className="md-floating-label md-floating-label--floating md-text--secondary">{label}</label>
		<div className="md-text-field-multiline-container md-text-field--floating-margin">
			<SelectionControlGroup
				id={id}
				name={id}
				type="radio"
				inline
				controls={controls}
				defaultValue={defaultValue}
				checkedRadioIcon={icons.check}
				uncheckedRadioIcon={icons.uncheck}
				onChange={onChange}
			/>
		</div>
	</div>
);

export default RadioGroup;