import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {
	StyledButton, StyledFormTextField, StyledFormRadioGroup, StyledImagesUploader, StyledErrors, StyledFormHeader
} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Person.PageParty";
const CC = "profiles.folder-f.PageClaim.PageCollision";

const PagePartyForm = ({actions, item, images, onChanged}) => ({T, formProps: {values}}) => {
	const controlsPartyHurt = [
		{label: T(C, "partyHurt.options.yes"), value: "yes"},
		{label: T(C, "partyHurt.options.no"), value: "no"}
	];

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					name="errors"
					showOnlyFirst
					component={StyledErrors}
				/>
				<Field
					type="text"
					name="partyHurt"
					label={T(C, "partyHurt.label")}
					component={StyledFormRadioGroup}
					controls={controlsPartyHurt}
					inline
					onChanged={value => onChanged({...values, ...value})}
				/>
				<StyledFormHeader>{T(C, "contact.label")}</StyledFormHeader>
				<Field
					name="images_license"
					label={T(C, "images_license.label")}
					actions={actions}
					item={item}
					images={images}
					tag="license"
					component={StyledImagesUploader}
				/>
				<Field
					type="text"
					name="partyName"
					label={T(C, "partyName.label")}
					placeholder=""
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyAddress"
					label={T(C, "partyAddress.label")}
					placeholder=""
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyTelno"
					label={T(C, "partyTelno.label")}
					placeholder=""
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyEmail"
					label={T(C, "partyEmail.label")}
					placeholder=""
					component={StyledFormTextField}
				/>
			</Form>
		</ThemedPageWrapper>
	);
};

const validationSchema = ({T}) => Yup.object().shape({
	partyHurt: Yup.string().required(T(CC, "yup.partyHurt")),
	partyName: Yup.string(),
	partyAddress: Yup.string(),
	partyTelno: Yup.string(),
	partyEmail: Yup.string().trim().email(T(CC, "yup.partyEmail")),
});

const PageParty = ({wizardProps: {T, actions}, item, images}) => {
	const [formState, dispatch] = useFormState();
	const {partyHurt, partyCause, partyName, partyAddress, partyTelno, partyEmail} = formState;
	const initialValues = {partyHurt, partyCause, partyName, partyAddress, partyTelno, partyEmail};

	const onChanged = values => dispatch({type: "FORM_CHANGE_VALUES", ...values});
	const FormComponent = PagePartyForm({actions, item, images, onChanged});

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_PARTY", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema({T})}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageParty;