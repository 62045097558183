import React from 'react';
import LocalImageUploader from './LocalImageUploader'
import {ErrorMessage} from 'formik';

export default ({
	                className,
	                field: {name, onChange, value, onBlur}, // {name, value, onChange, onBlur}
	                form: {touched, errors}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                label,
	                ...otherProps
                }) => (
	<div className={className}>
		{<label>{label ? label : undefined}</label>}
		<LocalImageUploader value={value} {...otherProps}/>
		<div id="errorText"><ErrorMessage name={name}/></div>
	</div>
);