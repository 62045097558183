import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {PannellumImage360Viewer} from 'components/viewers'

import css from './Image360Slave.module.scss'

class ViewComponent extends PureComponent {
	// eslint-disable-next-line
	onClickHotspot = (e, clickHandlerArgs) => {
		//this.props.actions.dispatch({type: events.EVENT_IMAGE360_HOTSPOT_CLICK, params: clickHandlerArgs});
	};

	render() {
		const {item} = this.props;
		const {properties = {}, storage = {}} = item;
		const {label, hotspots = []} = properties;
		const {fileUrl, thumbUrl} = storage;

		const hotSpots = _.map(hotspots, hs => ({
			...hs,
			clickHandlerArgs: hs,
			clickHandlerFunc: this.onClickHotspot
		}));

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<div className={css.viewImage}>
						<PannellumImage360Viewer
							id={item.$id}
							imgPath={fileUrl}
							thumbPath={thumbUrl}
							showZoomCtrl={false}
							showFullscreenCtrl
							autoLoad={false}
							orientationOnByDefault
							hotSpots={hotSpots}
							customStrings={{
								loadButtonLabel: label
							}}
						/>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		return null;
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}