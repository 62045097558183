const initialState = {
	/* Component */
	component: "",
	data: "",
	/* Form */
	step: 0,
	substep: 0,
	/* PageMain */
	owner: "",
	service: "",
	/* PageClaim */
	id: "",
	claimNo: 0,
	claimId: "",
	/* PageContact */
	contactName: "",
	contactEmail: "",
	/* PageService */
	/* PageDatetimeLocation */
	datetime: "",
	datetimeTo: "",
	location: "",
	/* PageComments */
	details: "",
	comments: "",
	/* Text fields */
	serviceText: "",
	isMassage: false,
	isClaim: false,
};

const reducer = (state, {type, ...otherProps}) => {
	const {
		/* Component */
		component,
		data,
		/* PageMain */
		owner,
		service,
		/* PageClaim */
		id,
		claimNo,
		claimId,
		/* PageContact */
		contactName,
		contactEmail,
		/* PageDatetimeLocation */
		datetime,
		datetimeTo,
		location,
		/* PageComments */
		details,
		comments,
	} = otherProps;

	let newState = {};

	switch (type) {
		case "FORM_INIT_CLAIM":
			newState = {...state, component, data, id, claimNo, claimId};
			break;

		/* pages */

		case "FORM_SUBMIT_STEP_MAIN":
			newState = {...state, owner, service, step: 1};
			break;

		case "FORM_SUBMIT_STEP_SERVICE":
			newState = {...state, details, comments, location, datetime, datetimeTo, step: 2};
			break;

		case "FORM_SUBMIT_STEP_CONTACT":
			newState = {...state, contactName, contactEmail, step: 0};
			break;

		case "FORM_GOTO_PREVIOUS_STEP":
			newState = {...state, step: Math.max(state.step - 1, 0)};
			break;

		case "FORM_CHANGE_VALUES":
			newState = {...state, ...otherProps};
			break;

		case "RESET_FORM":
			newState = {...initialState, claimId: state.claimId}; // keep feedback request
			break;

		default:
			return state;
	}

	return {...newState, ...getTextFields(newState)};
};

export {
	reducer,
	initialState,
}

const getTextFields = ({service}) => {
	const textFields = {};

	switch (service) {
		case "claim":
			textFields.isClaim = true;
			textFields.isMassage = false;
			textFields.isResource = false;
			textFields.serviceText = "Schadenfall";
			break;
		case "massage":
			textFields.isClaim = false;
			textFields.isMassage = true;
			textFields.isResource = false;
			textFields.serviceText = "Dienstleistungsbuchung";
			break;
		case "resource":
			textFields.isClaim = false;
			textFields.isMassage = false;
			textFields.isResource = true;
			textFields.serviceText = "Ressourcenreservierung";
			break;
		default:
			textFields.isClaim = false;
			textFields.isMassage = false;
			textFields.isResource = false;
			textFields.serviceText = "(nicht definiert)";
			break;
	}

	return textFields;
};