import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer} from 'components/containers'
import {TextFieldEdit} from 'components/controls'
import {ThumbnailLoader} from 'components/utils'

import css from './ImageSlave.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent, allowEdit} = this.props;
		const {properties = {}, filters = {}} = item;
		const {label, description} = properties;

		const editAllowed = allowEdit(item);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<div className={css.viewHeader}>
						{filters.language && <Flag className={css.flag} code={filters.language}/>}
						<div className={css.content}>
							<div className={css.label}>{label}</div>
						</div>
						<div className={css.icon}>
							{editAllowed && <EditComponent/>}
						</div>
					</div>
					<div className={css.viewImage}>
						<ThumbnailLoader item={item} height={180}/>
					</div>
					<div className={css.viewFooter}>
						<div className={css.description}>{description}</div>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}


class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties, filters} = item;
		const {label, description} = properties;
		const {language} = filters;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="title"
					label="title"
					rows={1}
					maxRows={2}
					value={{label}}
					onSubmit={this.updateProperty}
				/>
				<TextFieldEdit
					id="description"
					label="description"
					value={{description}}
					rows={1}
					maxRows={3}
					onSubmit={this.updateProperty}
				/>
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}