import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormDatetimePicker, StyledFormLocationPicker} from '../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Common.PageDatetimeLocation";
const CC = "profiles.folder-f.PageClaim.PageCollision";

const PageDatetimeLocationForm = ({T}) => (
	<ThemedPageWrapper>
		<Form>
			<Field
				type="datetime-local"
				name="datetime"
				label={T(C, "datetime")}
				placeholder={T(C, "datetime.placeholder")}
				component={StyledFormDatetimePicker}
				max={new Date()}
			/>
			<Field
				type="text"
				name="location"
				label={T(C, "location")}
				placeholder={T(C, "location.placeholder")}
				component={StyledFormLocationPicker}
			/>
		</Form>
	</ThemedPageWrapper>
);

const validationSchema = ({T}) => Yup.object().shape({
	datetime: Yup.date().required(T(CC, "yup.datetime")),
	location: Yup.object().shape({
		label: Yup.string().required(T(CC, "yup.location"))
	})
});

const PageDatetimeLocation = ({wizardProps: {T, actions}, cancel, ...otherProps}) => {
	const [formState, dispatch] = useFormState();
	const {datetime, location} = formState;
	const initialValues = {datetime: datetime || new Date(), location};

	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COMMON_DATETIME_LOCATION", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema({T})}
			onSubmit={onSubmit}
			FormComponent={PageDatetimeLocationForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={cancel}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageDatetimeLocation;