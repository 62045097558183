export default ({stickerId, order = 1000, tag = ""}) => ({
	type: "audio",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	storage: {
		fileLoc: "",
		fileUrl: "",
		status: "",
		metadata: {},
		totalSize: 0
	},
	properties: {
		tag,
		label: "",
		description: "",
		author: "",
		likes: 0,
		dislikes: 0,
		ranking: 0
	}
})