import React from 'react'

import css from './CookieInfo.module.scss'

const CookieInfo = () => (
	<div className={css.container}>
		<p className={css.textblock}>Wir verwenden Cookies, um Ihnen den bestmöglichen Service über diesen Smart&nbsp;Sticker bieten zu können.
			Wenn Sie fortfahren, stimmen Sie der Verwendung von Cookies zu.</p>
		<p className={css.textblock}>Weitere Informationen dazu finden Sie <a href="https://www.sensotix.com/disclaimer#cookies">hier</a>.</p>
	</div>
);

export default CookieInfo