import React from 'react';

import css from './OnboardingPage.module.scss'

import OnboardingActions from './OnboardingActions'

const OnboardingPage = ({isLoading, imgPath, title, main, sub, leftAction = {}, rightAction = {}}) => (
	<div className={css.container}>
		<div className={css.body}>
			<div className={css.image}>
				{imgPath && <img src={require(`components/${imgPath}`)} alt="Onboarding"/>}
			</div>
			<div className={css.title}>
				{title}
			</div>
			<div className={css.main}>
				{main}
			</div>
			<div className={css.sub}>
				{sub}
			</div>
		</div>
		<div className={css.footer}>
			<OnboardingActions leftAction={leftAction} rightAction={rightAction} isLoading={isLoading}/>
		</div>
	</div>
);

export default OnboardingPage