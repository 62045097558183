import React from 'react';
import {SelectionControlGroup} from 'lib/react-md'
import icons from 'lib/icons'

import * as Wrappers from 'components/wrappers'
import {TextFieldEdit} from 'components/controls'

import css from './FeedbackSlave.module.scss'

class ViewComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.props;
		const {$id, properties = {}} = item;
		const {inputType = "", value = "", options = []} = properties;

		const renderChoices = () => {
			switch (inputType) {
				case "text":
					return <TextFieldEdit
						id={`feedback-text-input-${$id}`}
						name={`feedback-text-input-${$id}`}
						label="feedback.text.placeholder"
						value={{value}}
						onSubmit={value => this.updateProperty(value, true)}
					/>;

				case "radio":
					return <SelectionControlGroup
						id={`feedback-radio-input-${$id}`}
						name={`feedback-radio-input-${$id}`}
						type="radio"
						value={value}
						controls={options}
						checkedRadioIcon={icons.check}
						uncheckedRadioIcon={icons.uncheck}
						onChange={value => this.updateProperty({value}, true)}
					/>;

				case "checkbox":
					return <SelectionControlGroup
						id={`feedback-checkbox-input-${$id}`}
						name={`feedback-checkbox-input-${$id}`}
						type="checkbox"
						value={value}
						controls={options}
						checkedCheckboxIcon={icons.check}
						uncheckedCheckboxIcon={icons.uncheckbox}
						onChange={value => this.updateProperty({value: value.replace(/(^[,\s]+)|([,\s]+$)/g, '')}, true)} /* remove leading comma */
					/>;

				default:
					return null
			}
		};

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<div className={css.label}>{properties.label}</div>
					<div>{renderChoices()}</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: () => null
}