import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormTextField, StyledFormRadioGroup} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.helvetia-at.PageClaim.PageCollision.Car.PageComments";

const PageCommentsForm = () => ({formProps: {values}}) => {
	const controlsPartyCause = [
		{label: "beim Ein- oder Ausparken", value: "parking"},
		{label: "beim Überholmanöver", value: "overtaking"},
		{label: "wegen Vorfahrtmissachtung", value: "giveway"},
		{label: "aus anderen Gründen", value: "other"},
	];

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					type="text"
					name="partyCause"
					label="Schadenhergang: Kollision"
					component={StyledFormRadioGroup}
					controls={controlsPartyCause}
					inline
				/>
				{values.partyCause === "other" && (
					<Field
						type="text"
						name="partyReason"
						label="Grund"
						placeholder="Bitte ausfüllen..."
						rows={3}
						component={StyledFormTextField}
					/>
				)}
				<Field
					type="text"
					name="comments"
					label="Ergänzungen/Bemerkungen"
					component={StyledFormTextField}
					rows={3}
				/>
				<Field
					type="text"
					name="damages"
					label="Wo befinden sich die Schäden am eigenen Fahrzeug?"
					component={StyledFormTextField}
					rows={3}
				/>
			</Form>
		</ThemedPageWrapper>
	);
}

const validationSchema = Yup.object().shape({
	partyCause: Yup.string().required("Bitte geben Sie einen Grund an."),
	partyReason: Yup.string().when("partyCause", {
		is: "other",
		then: Yup.string().required("Bitte geben Sie einen Grund an.")
	})
});

const PageComments = ({wizardProps: {T, actions}, ...otherProps}) => {
	const [formState, dispatch] = useFormState();
	const {partyCause, partyReason, damages, comments} = formState;
	const initialValues = {partyCause, partyReason, damages, comments};

	const FormComponent = PageCommentsForm();

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_COMMENTS", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageComments;