import ErrorRoute from './ErrorRoute'
import NotFoundRoute from './NotFoundRoute'
import PortalRoute from './PortalRoute'
import StickerRoute from './StickerRoute'
import HVCHFlyerRoute from './HVCHFlyerRoute'

export {
	ErrorRoute,
	NotFoundRoute,
	PortalRoute,
	StickerRoute,
	HVCHFlyerRoute
}