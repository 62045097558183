import React from 'react'
import SwipeableViews from 'react-swipeable-views';
import {WizardStep as BaseWizardStep} from 'react-wizard-primitive';

import styled from 'styled-components/macro'

const PagesWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
`;

const Pages = ({children, activeStepIndex, moveToStep, ...wizardProps}) => (
	<PagesWrapper>
		<SwipeableViews
			enableMouseEvents
			index={activeStepIndex}
		>
			{children.map((child, key) => (
				<BaseWizardStep key={key}>
					{wizardStepProps => React.cloneElement(child, {...wizardStepProps})}
				</BaseWizardStep>
			))}
		</SwipeableViews>
	</PagesWrapper>
);

export default Pages;