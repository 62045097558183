import React from 'react';

import * as Wrappers from 'components/wrappers'
import {Button} from 'lib/react-md'
import {getFloorDate} from 'lib/utils'
import _ from 'lib/lodash'

import {
	CheckboxGroupEdit,
	RadioGroupEdit,
	TextFieldEdit,
	SelectFieldEdit,
	DatetimePicker,
	LocationPicker
} from 'components/controls'
import {withIntl} from 'components/hocs'

import css from './CustomHVCHClaimSlave.module.scss'

const C = "profiles.helvetia-ch.CustomHVCHClaimSlave";

class ViewComponent extends Wrappers.EditComponentWrapper {
	state = {
		...this.state, // contains item
		policeInvolvedToggle: _.get(this.props, "item.properties.policeInvolved", "0") === "1"
	};

	componentDidMount() {
		const {item, actions} = this.props;

		const {properties = {}} = item;
		const {owner, polno, driverName, driverZip, driverTelno, driverEmail} = properties;

		const stickerProps = actions.getStickerProperties();
		const driver = actions.getDriver();

		// set default values
		if (!owner) this.updateProperty({owner: stickerProps.owner || ""}, true);
		if (!polno) this.updateProperty({polno: stickerProps.polno || ""}, true);
		if (!driverName) this.updateProperty({driverName: driver.driverName || ""}, true);
		if (!driverZip) this.updateProperty({driverZip: driver.driverZip || ""}, true);
		if (!driverTelno) this.updateProperty({driverTelno: driver.driverTelno || ""}, true);
		if (!driverEmail) this.updateProperty({driverEmail: driver.driverEmail || ""}, true);
	}

	renderDriver = ({driverName, driverZip, driverTelno, driverEmail}) => {
		// driver is not updated immediately in localStorage, since empty field would immediately trigger Driver form!
		return (
			<>
				<TextFieldEdit
					id="driver.name"
					label="claim.driverName"
					floatingLabel={false}
					className={css.textFieldEdit}
					value={{driverName}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					id="driver.zip"
					label="claim.driverZip"
					floatingLabel={false}
					className={css.textFieldEdit}
					type="tel"
					value={{driverZip}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					id="driver.telno"
					label="claim.driverTelno"
					floatingLabel={false}
					className={css.textFieldEdit}
					type="tel"
					value={{driverTelno}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					id="driver.email"
					label="claim.driverEmail"
					floatingLabel={false}
					className={css.textFieldEdit}
					type="tel"
					value={{driverEmail}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
			</>
		);
	};

	renderClaimCategory = ({category, subcategory, partyStatus, partyName, partyAddress, partyZip, partyTelno, partyEmail, policeInvolved}) => {
		const {T, actions} = this.props;
		const {onUpload = _.noop} = actions;
		const subcategoryItems = subcategory.split(',');
		const {policeInvolvedToggle} = this.state;

		const renderPoliceInvolved = () => (
			<>
				<RadioGroupEdit
					id="policeInvolved"
					className={css.checkboxGroupEdit}
					label="claim.policeInvolved"
					menuItemLabels="claim.policeInvolved.labels"
					menuItemValues="claim.policeInvolved.values"
					value={{policeInvolved}}
					onSubmit={value => {
						this.updateProperty(value, true);
						this.setState({policeInvolvedToggle: value.policeInvolved === "1"})
					}}
					inline
				/>
				{policeInvolvedToggle && (
					<div className="infoBoxPrimary">
						{T("label", "claim.policeInvolved.button.title")}
						<div className={css.tertiaryButton}>
							<Button
								flat
								primary
								onClick={onUpload}
								data-gtm-action={`button:${C}`}
								data-gtm-label="Foto hochladen (Polizeirapport)"
							>
								{T("label", "claim.policeInvolved.button.label")}
							</Button>
						</div>
					</div>
				)}
			</>
		);

		// Parkschaden
		const render1Details = () => (
			<>
				<div className="infoBoxPrimary" style={{marginTop: "16px"}}>
					{T("label", "claim.category.1")}
					<div className={css.tertiaryButton}>
						<Button
							flat
							primary
							onClick={onUpload}
							data-gtm-action={`button:${C}`}
							data-gtm-label="Foto hochladen (Parkschaden)"
						>
							{T("profiles.helvetia-ch.CustomHVCHClaimCollectionSlave", "upload")}
						</Button>
					</div>
				</div>
				{renderPoliceInvolved()}
			</>
		);

		const renderPartyStatus = () => (
			<RadioGroupEdit
				id="partyStatus"
				className={css.checkboxGroupEdit}
				label="claim.category.2.party.status"
				menuItemLabels="claim.category.2.party.status.labels"
				menuItemValues="claim.category.2.party.status.values"
				value={{partyStatus}}
				onSubmit={value => this.updateProperty(value, true)}
			/>
		);

		const renderContactInfo = () => (
			<>
				<TextFieldEdit
					id="party.name"
					label="claim.category.2.party.name"
					floatingLabel={false}
					className={css.textFieldEdit}
					value={{partyName}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<TextFieldEdit
					id="party.address"
					label="claim.category.2.party.address"
					floatingLabel={false}
					className={css.textFieldEdit}
					value={{partyAddress}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<TextFieldEdit
					id="party.zip"
					label="claim.category.2.party.zip"
					floatingLabel={false}
					className={css.textFieldEdit}
					type="tel"
					value={{partyZip}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<TextFieldEdit
					id="party.telno"
					label="claim.category.2.party.telno"
					floatingLabel={false}
					className={css.textFieldEdit}
					type="tel"
					value={{partyTelno}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<TextFieldEdit
					id="party.email"
					label="claim.category.2.party.email"
					floatingLabel={false}
					className={css.textFieldEdit}
					type="email"
					value={{partyEmail}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
			</>
		);

		// Kollision
		const render2Details = () => (
			<>
				<CheckboxGroupEdit
					id="subcategory"
					className={css.checkboxGroupEdit}
					label="claim.category.2"
					menuItemLabels="claim.category.2.labels"
					menuItemValues="claim.category.2.values"
					value={{subcategory}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				{(subcategoryItems.includes("2") || subcategoryItems.includes("4") || subcategoryItems.includes("5")) && renderPartyStatus()}
				{(subcategoryItems.includes("2") || subcategoryItems.includes("4") || subcategoryItems.includes("5")) && partyStatus === "1" && renderContactInfo()}
				{subcategoryItems.includes("3") && <div className="infoBoxPrimary">{T("label", "claim.category.2.3")}</div>}
				{renderPoliceInvolved()}
			</>
		);

		// Glasbruch
		const render3Details = () => (
			<CheckboxGroupEdit
				id="subcategory"
				label="claim.category.3"
				menuItemLabels="claim.category.3.labels"
				menuItemValues="claim.category.3.values"
				value={{subcategory}}
				onSubmit={value => this.updateProperty(value, true)}
			/>
		);

		// Übrige
		const render5Details = () => (
			<>
				<TextFieldEdit
					id="subcategory"
					label="claim.category.5"
					floatingLabel={false}
					className={css.textFieldEdit}
					rows={1}
					maxRows={5}
					value={{subcategory}}
					onSubmit={value => this.updateProperty(value, true)}
					inputClassName={css.selectFieldEditInput}
				/>
				{renderPoliceInvolved()}
			</>
		);

		return (
			<>
				<SelectFieldEdit
					id="category"
					label="claim.category"
					floatingLabel={false}
					menuItemLabels="claim.category.labels"
					menuItemValues="claim.category.values"
					defaultValue=""
					value={{category}}
					onSubmit={value => this.updateProperty({
						...value,
						subcategory: "",
						partyStatus: "",
						partyName: "",
						partyAddress: "",
						partyZip: "",
						partyTelno: "",
						partyEmail: ""
					}, true)}
					inputClassName={css.selectFieldEditInput}
					transitionName=""
					transitionTime={0}
				/>
				{category === "1" && render1Details()}
				{category === "2" && render2Details()}
				{category === "3" && render3Details()}
				{category === "5" && render5Details()}
			</>
		);
	};

	render() {
		const {T, actions} = this.props;
		const {item} = this.props;
		const {properties = {}} = item;
		const {
			location = "", datetime, category = "0", subcategory = "", owner = "", polno = "", plate = "",
			partyStatus = "", partyName = "", partyAddress = "", partyZip = "", partyTelno = "", partyEmail = "",
			comments = "", driverName, driverZip, driverTelno, driverEmail,
			policeInvolved = "0"
		} = properties;
		//const {name: driverName, telno: driverTelno} = driver;

		const {onClear = _.noop} = actions;

		return (
			<div className={css.viewContainer}>
				<div className={css.tertiaryButton}>
					<Button
						flat
						primary
						onClick={onClear}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Formular leeren"
					>
						{T("label", "claim.button.clear")}
					</Button>
				</div>
				<br/>
				{this.renderClaimCategory({
					category,
					subcategory,
					partyStatus,
					partyName,
					partyAddress,
					partyZip,
					partyTelno,
					partyEmail,
					policeInvolved
				})}
				<div className={css.spacingSmall}/>
				<LocationPicker
					suggestionClassName={css.locationPickerSuggestion}
					suggestionButtonClassName={css.locationPickerSuggestionButton}
					id="location-picker"
					label="claim.location"
					markerLabel="claim.marker.label"
					value={{location}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacingSmall}/>
				<DatetimePicker
					containerClassName={css.datetimePickerContainer}
					id="date"
					label="claim.datetime"
					value={{datetime}}
					step={30}
					max={getFloorDate(30)}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					className={css.textFieldEdit}
					id="owner"
					label="claim.owner"
					floatingLabel={false}
					value={{owner}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					className={css.textFieldEdit}
					id="polno"
					label="claim.polno"
					floatingLabel={false}
					value={{polno}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					className={css.textFieldEdit}
					id="plate"
					label="claim.plate"
					floatingLabel={false}
					placeholder="claim.plate.placeholder"
					value={{plate}}
					onSubmit={({plate = ""}) => this.updateProperty({plate: plate.toUpperCase()}, true)}
				/>
				<div className={css.spacing}/>
				<TextFieldEdit
					className={css.textFieldEdit}
					id="comments"
					label="claim.comments"
					floatingLabel={false}
					rows={1}
					maxRows={10}
					value={{comments}}
					onSubmit={value => this.updateProperty(value, true)}
				/>
				<div className={css.spacing}/>
				{this.renderDriver({
					driverName,
					driverZip,
					driverTelno,
					driverEmail
				})}
			</div>
		);
	}
}

export default {
	ViewComponent: withIntl(ViewComponent),
	EditComponent: () => null
}