import React from 'react'

import DefaultMaster from 'components/templates/sticker/master/DefaultMaster'
import {withIntlCookieInfo} from 'components/hocs'

import MasterMenu from './MasterMenu'
import ActionsMenu from './ActionsMenu'
import '../../../../theme-aida.scss'
import Slave from '../slave/Slave';

const Master = props => (
	<DefaultMaster
		bundle={props.bundle}
		sticker={props.sticker}
		ActionsMenu={ActionsMenu}
		StickerMenu={() => <MasterMenu {...props}/>}
		SlaveComponent={Slave}
		theme="theme-aida"
		{...props}
	/>
);

export default withIntlCookieInfo(Master);