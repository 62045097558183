import React, {Component} from 'react';
import PropTypes from 'prop-types'
import _ from 'lib/lodash'

import {withDraggableList} from 'components/hocs'
import {master as NodeComponents} from 'components/nodes/nodes'
import MasterSubnodeContainer from './MasterSubnodeContainer'

const DraggableSubnodesList = withDraggableList({ListItemComponent: MasterSubnodeContainer});

class MasterNodeContainer extends Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		subnodes: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		const {type} = this.props.item;

		this.ContainerComponent = _.get(NodeComponents, type, NodeComponents['text-collection']).ContainerComponent;
		this.HeaderComponent = _.get(NodeComponents, type, NodeComponents['text-collection']).HeaderComponent;
		this.ViewComponent = _.get(NodeComponents, type, NodeComponents['text-collection']).ViewComponent;
		this.EditComponent = _.get(NodeComponents, type, NodeComponents['text-collection']).EditComponent;
	}

	getSubitems = (item, subnodes) => _.get(subnodes, `[${item.targets.master}][${item.$id}].items`, []);

	shouldComponentUpdate(nextProps) {
		const subitems = this.getSubitems(this.props.item, this.props.subnodes);
		const nextSubitems = this.getSubitems(nextProps.item, nextProps.subnodes);

		return _.anyDiff(nextProps, this.props, ['item']) || !_.isEqual(nextSubitems, subitems);
	}

	render() {
		const {ContainerComponent, HeaderComponent, ViewComponent, EditComponent} = this;
		const {keyPrefix, item, subnodes, actions, isUploading} = this.props;
		const {$id, expanded, targets = {}} = item;
		const subitems = this.getSubitems(item, subnodes);
		const EditComponentInstance = EditComponent({keyPrefix, item, actions});

		// define rules for adding, editing and dragging
		const allowEdit = true;
		const allowSubnodeAdd = (targets.slave !== "private" || targets.master === "public") && !isUploading;

		// show items only if master can add items or if any elements exist at all to show
		const showNestedItems = !!_.size(subitems) || allowSubnodeAdd;

		const ListItemContent = [
			<ViewComponent
				key={`${keyPrefix}-${$id}`}
				item={item}
				subitems={subitems}
				actions={actions}
				allowAdd={allowSubnodeAdd}
			>
				<DraggableSubnodesList
					items={subitems}
					actions={actions}
					keyPrefix={keyPrefix}
					onDragEnd={actions.updateNodesOrder}
				/>
			</ViewComponent>
		];

		return (
			<ContainerComponent
				key={$id}
				item={item}
				subitems={subitems}
				actions={actions}
				allowEdit={allowEdit}
				allowSubnodeAdd={allowSubnodeAdd}
				HeaderComponent={HeaderComponent}
				ListItemContent={showNestedItems ? ListItemContent : null}
				EditComponent={EditComponentInstance}
				visible={expanded}
			/>
		);
	}
}

export default MasterNodeContainer