import React from 'react';
import styled from 'styled-components/macro';
import {FormImagesUploader} from 'components/forms'

const FormImagesUploaderWrapper = ({className, ...otherProps}) => (
	<FormImagesUploader className={className} {...otherProps}/>
);

export default styled(FormImagesUploaderWrapper)`
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	> div {
		margin-top: 8px;
	}
`;