import React, {PureComponent} from 'react';
import {Subheader, SelectionControlGroup} from 'lib/react-md'
import icons from 'lib/icons'

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer, StickerPickerContainer} from 'components/containers'
import {TextFieldEdit, OptionList} from 'components/controls'

import css from './FeedbackMaster.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent} = this.props;
		const {properties = {}, filters = {}} = item;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					{filters.language && <Flag className={css.flag} code={filters.language}/>}
					<div className={css.label}>{properties.label}</div>
					<div className={css.icon}>
						<EditComponent/>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {T} = this.props;
		const {item} = this.state;
		const {properties = {}, filters = {}} = item;
		const {label = "", inputType = "text", options = []} = properties;
		const {language, stickers} = filters;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="title"
					label="title"
					value={{label}}
					onSubmit={this.updateProperty}
				/>
				<div className="md-list-unstyled">
					<Subheader primaryText={T("label", "feedback.subheader")}/>
					<SelectionControlGroup
						id={`feedback-radio-input-${item.$id}`}
						name={`feedback-radio-input-${item.$id}`}
						type="radio"
						//inline
						controls={[
							{label: T("label", "feedback.radio"), value: 'radio'},
							{label: T("label", "feedback.checkbox"), value: 'checkbox'},
							{label: T("label", "feedback.text"), value: 'text'}
						]}
						value={inputType}
						checkedRadioIcon={icons.check}
						uncheckedRadioIcon={icons.uncheck}
						onChange={inputType => this.updateProperty({inputType})}
					/>
				</div>
				{['radio', 'checkbox'].includes(inputType) && (
					<OptionList
						items={options}
						titleNew={T("label", "feedback.OptionList.titleNew")}
						titleList={T("label", "feedback.OptionList.titleList")}
						onChange={options => this.updateProperty({options})}
					/>
				)}
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
				<StickerPickerContainer
					defaultValue={{stickers}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}