import React, {PureComponent} from 'react';
//import {Button} from 'lib/react-md'
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl} from 'components/hocs'
import {DocumentFactory} from 'components/nodes/factories'
import {AddItemDropzone, FooterInfo} from 'components/utils'
import {ResponsiveDocumentGallery} from 'components/controls'

import css from './DocumentCollectionSlave.module.scss'

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	onDrop = acceptedFiles => {
		const {item, actions} = this.props;

		_.forEach(acceptedFiles, file => actions.postSubnodeAndFile(item, "slave", DocumentFactory, file));
	};

	render() {
		const {T, item, subitems, allowAdd, actions, /*allowEdit*/} = this.props;
		const {constraints = {}, $subnodeCount} = item;
		const {count = 0, maxDownloads = 0, mimeTypes} = constraints;

		const accept = mimeTypes || ["image/*"];
		const uploadLimitReached = !!count && $subnodeCount >= count;

		const images = subitems;
		const items = _.map(images, subnode => ({
			node: subnode,
			original: _.get(subnode, 'storage.fileUrl'),
			//description: _.get(subnode, 'properties.label'),
			thumbnail: _.get(subnode, 'storage.thumbUrl'),
			thumbnailWidth: _.get(subnode, 'storage.metadata.width'),
			thumbnailHeight: _.get(subnode, 'storage.metadata.height'),
			downloads: _.get(subnode, 'stats.downloads', 0),
		}));

		// eslint-disable-next-line
		const voteDocument = (idx, getCurrentIndex, vote = 0) => () => {
			const index = idx !== null ? idx : getCurrentIndex(); //null-checking mandatory since idx=0 is a valid index!
			const currentNode = _.get(images, index, {});

			if (!_.isEmpty(currentNode)) {
				//actions.deleteDocument(currentNode);
				//actions.showCustomInfo({id: "node.deleted"});
			}
		};

		/*const deleteDocument = (idx, getCurrentIndex) => () => {
			const index = idx !== null ? idx : getCurrentIndex(); //null-checking mandatory since idx=0 is a valid index!

			const currentNode = _.get(images, index, {});

			if (!_.isEmpty(currentNode)) {
				actions.deleteDocument(currentNode);
				actions.showCustomInfo({id: "node.deleted"});
			}
		};*/

		const customControls = (idx, getCurrentIndex) => () => (<></>);

		const onClickThumbnail = idx => {
			const item = items[idx];
			const {node} = item;
			const {stats = {}} = node;
			const downloads = _.get(stats, 'downloads', 0) + 1;

			if (!maxDownloads || (downloads <= maxDownloads)) {
				actions.updateNodeStats(node, {downloads});
				window.open(item.original, "_blank");
				if (maxDownloads && (downloads < maxDownloads)) {
					actions.showCustomInfo(T("validation", "document.downloadLimitNotReached", {size: maxDownloads - downloads}));
				}
			} else {
				actions.raiseCustomError(new Error(T("error", "downloadLimitReached")));
			}
		};

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContent}>
					<ResponsiveDocumentGallery
						items={items}
						customControls={customControls}
						showCustomControlsInLightbox
						showCustomControlsInView
						onClickThumbnail={onClickThumbnail}
					/>
				</div>
				<div className={css.viewFooter}>
					{allowAdd && !uploadLimitReached && <AddItemDropzone accept={accept} onDrop={this.onDrop}/>}
					{allowAdd && uploadLimitReached && <FooterInfo T={T} type="error" info="uploadLimitReached"/>}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent: withIntl(ViewComponent)
}