import React from 'react'
import _ from 'lib/lodash'

import {OptionList} from 'components/controls'
import {withIntl} from 'components/hocs'

const C = "StickerPickerContainer";

const onChangeFilter = onChange => items => onChange({stickers: _.map(items, "label")});

const StickerPickerContainer = ({T, defaultValue: {stickers}, onChange}) => (
	<div className="md-text-field-container md-full-width md-text-field-container--multiline">
		<label htmlFor={C} className="md-floating-label md-floating-label--floating md-text--secondary">{T(C, "title")}</label>
		<div className="md-text-field-multiline-container md-text-field--floating-margin">
			<OptionList
				id={C}
				items={_.map(stickers, sticker => ({label: sticker, value: sticker}))}
				titleNew={T("label", "filters.stickers.OptionList.titleNew")}
				titleList={T("label", "filters.stickers.OptionList.titleList")}
				onChange={onChangeFilter(onChange)}
			/>
		</div>
	</div>
);

export default withIntl(StickerPickerContainer);