class AudioRecorder {
  constructor() {
    this.mediaRecorder = null;
    this.blobUrl = "";
    this.timeout = null;
  }

  async record(onStart, onStop, onError, maxDurationSec = 30) {
    try {
      // Request permissions to record audio
      const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
      const recorder = new MediaRecorder(stream);

      // Set record to <audio> when recording will be finished
      recorder.addEventListener('dataavailable', e => {
        this.blobUrl = URL.createObjectURL(e.data);
        onStop(e.data, this.blobUrl);
      });
      recorder.addEventListener('error', onError);

      this.mediaRecorder = recorder;

      recorder.start();
      onStart(recorder);

      const cancelTimeout = () => {
        if (!!this.timeout) {
          this.stop();
          clearTimeout(this.timeout);
          this.timeout = null;
        }
      };

      if (!!maxDurationSec) {
        this.timeout = setTimeout(cancelTimeout, (maxDurationSec + 1) * 1000);
      }
    } catch (e) {
      onError(e);
    }
  }

  stop() {
    if (!!this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
      this.mediaRecorder = null;
    }
  }
}

export default AudioRecorder;