import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {UserInfoConsumer} from 'components/contexts'

import Claim from './Claim';
import Driver from './Driver';
import css from './CustomHVCHClaimCollectionSlave.module.scss'
import {ThemeProvider} from "styled-components";
import theme from '../../../theme';

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {subitems, actions} = this.props;

		const claim = _.get(_.filter(subitems, {type: "custom-hvch-claim"}), '[0]');
		const images = _.filter(subitems, {type: "image"});
		const audios = _.filter(subitems, {type: "audio"});

		const stickerProps = actions.getStickerProperties();
		const bundleProps = actions.getBundleProperties();

		if (!claim) return;

		return (
			<ThemeProvider theme={theme}>
				<div className={css.container}>
					<Wrappers.ViewComponentWrapper>
						<UserInfoConsumer>
							{({userInfo, setUserInfo}) => {
								const getDriver = () => driver;
								const setDriver = driver => setUserInfo({driver});

								const props = {
									...this.props,
									item,
									claim,
									images,
									audios,
									stickerProps,
									bundleProps,
									getDriver,
									setDriver
								};

								const {driver = {}} = userInfo;
								const {driverName, driverZip, driverTelno} = driver;
								const driverInfoComplete = !!driverName && !!driverZip && !!driverTelno;

								return driverInfoComplete ? <Claim {...props}/> : <Driver driver={driver} {...props}/>
							}}
						</UserInfoConsumer>
					</Wrappers.ViewComponentWrapper>
				</div>
			</ThemeProvider>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent
}