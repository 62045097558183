export function disableConsole() {
	const console = (window.console = window.console || {});

	const methods = [
		'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
		'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
		'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
		'timeStamp', 'trace', 'warn'
	];

	let length = methods.length;
	while (length--) console[methods[length]] = () => {
	};
}