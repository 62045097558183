import {PureComponent} from 'react';
import {connect} from 'react-redux'

import {authActions} from 'components/handlers/actions'

/*
	HVCH Smart Sticker Flyer
	Portal: hvch.stx.world
	Webcode: ABXTRWQZS (as printed on flyer)
	redirect to: 	https://www.helvetia.ch/smartsticker;
*/

class HVCHFlyerRoute extends PureComponent {
	componentDidMount() {
		// loading screen
		this.props.showAuthLoader({
			title: "HVCHFlyerRoute.title",
			main: "HVCHFlyerRoute.main",
			sub: "HVCHFlyerRoute.sub",
			imgPath: 'loading/loading-payloads.min.svg',
			value: 50
		});

		window.location.replace("https://www.helvetia.ch/smartsticker");
	}

	render() {
		return null;
	}
}

export default connect(null, authActions)(HVCHFlyerRoute);