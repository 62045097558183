import React from 'react';
import {Toolbar} from 'lib/react-md'
import StickerMenu from '../../../../common/StickerMenu';

const C = "profiles.folder.PageMainToolbar.A";

const PageMainToolbar = ({T, languages, bundle, sticker}) => () => (
	<Toolbar
		nav={<StickerMenu languages={languages} bundle={bundle} sticker={sticker}/>}
		zDepth={0}
		title={T(C, "title")}
	/>
);

export default PageMainToolbar;