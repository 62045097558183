import React, {PureComponent} from 'react';

import {Tab, Tabs, TabsContainer, Toolbar} from 'lib/react-md'
import {SlaveNodesList} from 'components/containers'

import StickerMenu from '../../common/StickerMenu';
import StickerFooter from '../../common/StickerFooter'
import SlaveHeader from './SlaveHeader'

import css from './Slave.module.scss';
import "../../../../styles.scss" // profile-wide styles

const C = "profiles.helvetia-ch.Slave";

class Slave extends PureComponent {
	state = {
		activeTabIndex: 0,
	};

	changeTabIndex = activeTabIndex => this.setState({activeTabIndex});

	render() {
		const {T, locale, bundle, sticker, languages} = this.props;
		const {activeTabIndex} = this.state;

		const Logo = (
			<div className={css.logo}>
				<img src={require('../../../../img/helvetia-logo-color-pos-170px.svg')} alt="logo"/>
				{/*<Link
					title={<img src={require('../../img/helvetia-logo-color-pos-170px.svg')} alt="logo"/>}
					href={T(C, "link.aboutus.href")}
				/>*/}
			</div>
		);

		const Header = (
			<Toolbar
				className={css.toolbar}
				title=""
				nav={<StickerMenu languages={languages} bundle={bundle} sticker={sticker}/>}
				actions={Logo}
				zDepth={0}
			/>
		);

		return (
			<TabsContainer
				className={css.container}
				colored
				toolbar={Header}
				swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
				activeTabIndex={activeTabIndex}
				onTabChange={this.changeTabIndex}
				swipeableViewsClassName={css.swipeableViewsContainer}
			>
				<Tabs tabId={`tabs-${locale}`} mobile>
					<Tab key={`tab-overview-${locale}`} label={T(C, "tabOverview")} className={css.tab}>
						<main>
							<SlaveHeader T={T} sticker={sticker} changeTabIndex={this.changeTabIndex}/>
							<div className={css.body}>
								<SlaveNodesList
									keyPrefix={`slave-${locale}`}
									subnodeFilters={{
										language: locale,
										stickerId: sticker.id
									}}
								/>
							</div>
							<StickerFooter/>
						</main>
					</Tab>
					<Tab key={`tab-claims-${locale}`} label={T(C, "tabClaims")} className={css.tab}>
						<main>
							<SlaveNodesList
								keyPrefix={`slave-${locale}-claims`}
								nodeFilters={{section: "claims"}}
								subnodeFilters={{
									language: locale,
									stickerId: sticker.id
								}}
								containerActions={{changeTabIndex: this.changeTabIndex}}
							/>
						</main>
					</Tab>
				</Tabs>
			</TabsContainer>
		);
	}
}

export default Slave