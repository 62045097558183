import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormTextField, StyledFormRadioGroup} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Car.PageComments";
const CC = "profiles.folder-f.PageClaim.PageCollision";

const PageCommentsForm = ({T, formProps: {values}}) => {
	const controlsPartyCause = [
		{label: T(C, "partyCause.options.parking"), value: "parking"},
		{label: T(C, "partyCause.options.overtaking"), value: "overtaking"},
		{label: T(C, "partyCause.options.giveway"), value: "giveway"},
		{label: T(C, "partyCause.options.other"), value: "other"},
	];

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					type="text"
					name="partyCause"
					label={T(C, "partyCause.label")}
					component={StyledFormRadioGroup}
					controls={controlsPartyCause}
					inline
				/>
				{values.partyCause === "other" && (
					<Field
						type="text"
						name="partyReason"
						label={T(C, "partyReason.label")}
						placeholder={T(C, "partyReason.placeholder")}
						rows={3}
						component={StyledFormTextField}
					/>
				)}
				<Field
					type="text"
					name="comments"
					label={T(C, "comments.label")}
					component={StyledFormTextField}
					rows={3}
				/>
				<Field
					type="text"
					name="damages"
					label={T(C, "damages.label")}
					component={StyledFormTextField}
					rows={3}
				/>
			</Form>
		</ThemedPageWrapper>
	);
}

const validationSchema = ({T}) => Yup.object().shape({
	partyCause: Yup.string().required(T(CC, "yup.partyCause")),
	partyReason: Yup.string().when("partyCause", {
		is: "other",
		then: Yup.string().required(T(CC, "yup.partyReason"))
	})
});

const PageComments = ({wizardProps: {T, actions}, ...otherProps}) => {
	const [formState, dispatch] = useFormState();
	const {partyCause, partyReason, damages, comments} = formState;
	const initialValues = {partyCause, partyReason, damages, comments};

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_COMMENTS", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema({T})}
			onSubmit={onSubmit}
			FormComponent={PageCommentsForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageComments;