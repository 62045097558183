export default ({targets, order = 1000}) => ({
	type: "document-collection",
	targets,
	order,
	constraints: {
		count: 0,
		size: 0,
		maxDownloads: 0,
		mimeTypes: ["application/pdf"],
	}
})