/* http://jquense.github.io/react-widgets/api/DateTimePicker/ */

import React, {Component} from 'react';
import 'react-widgets/dist/css/react-widgets.css';

import {withIntl} from 'components/hocs'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import css from './DatetimePicker.module.scss'

class DatetimePicker extends Component {
	state = {
		open: false
	};

	constructor(props) {
		super(props);

		this.dateTimePicker = React.createRef();
	}

	componentDidMount() {
		document.getElementById(this.dateTimePicker.current.inner.inputId).readOnly = true;
	}

	onChange = key => value => {
		if (!(value instanceof Date)) return;

		this.props.onSubmit({[key]: value});
	};

	onClick = () => this.setState(state => ({open: !state.open ? "date" : false}));

	onToggle = open => this.setState({open});

	render() {
		const {
			intl, T, FormatMessage, id, label, value, onSubmit, defaultValue = new Date(),
			containerClassName, readOnly = false, ...otherProps
		} = this.props;
		const {open} = this.state;

		const key = Object.keys(value)[0];
		const val = value[key];

		var datetime = defaultValue;
		if (val instanceof Date) {
			datetime = val;
		} else {
			try {
				datetime = val.toDate();
			} catch (e) {
			}
		}

		return (
			<div className={containerClassName}>
				<div className={css.container}>
					<div className="md-text-field-container md-full-width md-text-field-container--input">
						{label && (
							<label
								htmlFor={id}
								className="md-floating-label md-floating-label--floating md-text--secondary"
							>
								{T("label", label)}
							</label>
						)}
						<div className={!!label ? css.containerLabel : css.containerNoLabel}>
							<DateTimePicker
								ref={this.dateTimePicker}
								containerClassName={css.dateTimePicker}
								name={id}
								value={datetime}
								defaultValue={defaultValue}
								onChange={this.onChange(key)}
								inputProps={{
									onClick: this.onClick
								}}
								onToggle={this.onToggle}
								culture={intl.locale}
								timeFormat="HH:mm"
								open={open}
								readOnly={readOnly}
								{...otherProps}
							/>
						</div>
					</div>
				</div>
				</div>
		);
	}
}

export default withIntl(DatetimePicker)