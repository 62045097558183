import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lib/lodash'

import {TextFieldEdit, Wizard} from 'components/controls'
import {StickerFooter} from '../../../../common';
import PageMainToolbar from './PageMainToolbar'
import {StyledButton} from '../../../../controls';

import css from './PageMain.module.scss';
import {useFormState} from 'components/hooks'

const C = "profiles.demo-sc.Slave";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px;
`;

const HeaderWrapper = styled.div`
	flex: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const MainWrapper = styled.div`
	flex: 1;
	padding-top: 16px;
	
  button {
  	height: 60px;
	  width: 100%;
	  font-size: x-large !important;
  }
`;

const FooterWrapper = styled.div`
	flex: none;
`;

const PageMain = ({T, languages, bundle, sticker, nextStep}) => {
	const [, dispatch] = useFormState();
	const {owner = ""} = _.get(sticker, 'properties', {});

	const onSubmit = service => () => {
		dispatch({type: "FORM_SUBMIT_STEP_MAIN", owner, service});
		nextStep();
	};

	return (
		<Wizard HeaderComponent={PageMainToolbar({languages, bundle, sticker})}>
			<Container>
				<HeaderWrapper>
					<TextFieldEdit
						id="owner"
						className={css.textfield}
						label="overview.owner"
						value={{owner}}
						rows={owner.length > 16 ? 2 : 1}
						readOnly
					/>
					<div>{T(C, "massage.label")}</div>
				</HeaderWrapper>
				<MainWrapper>
					<StyledButton
						styled="primary"
						flat
						primary
						onClick={onSubmit("claim")}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Schadenfall melden"
					>
						{T(C, "button.claim")}
					</StyledButton>
					<div style={{height: "8px"}}/>
					<StyledButton
						styled="tertiary"
						flat
						primary
						onClick={onSubmit("massage")}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Massage buchen"
					>
						{T(C, "button.massage")}
					</StyledButton>
					<div style={{height: "8px"}}/>
					<StyledButton
						styled="secondary"
						flat
						primary
						onClick={onSubmit("resource")}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Ressource reservieren"
					>
						{T(C, "button.resource")}
					</StyledButton>
				</MainWrapper>

				<FooterWrapper>
					<StickerFooter/>
				</FooterWrapper>
			</Container>
		</Wizard>
	);
};

export default PageMain;