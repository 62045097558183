import React, {PureComponent} from 'react'
import {injectIntl, FormattedMessage} from 'react-intl'

const withIntl = WrappedComponent => {

	class IntlContainer extends PureComponent {
		T = (section, id, params) => this.props.intl.formatMessage({id: `${section}.${id}`}, params);

		render() {
			const {intl, ...otherProps} = this.props;

			return (
				<WrappedComponent
					intl={intl}
					FormattedMessage={FormattedMessage}
					T={this.T}
					{...otherProps}
				/>
			);
		}
	}

	return injectIntl(IntlContainer);
};

export default withIntl;