import React from 'react'
import {connect} from 'react-redux'
import _ from 'lib/lodash'

const Flag = ({flags, className, code, flag, height = "18", width = "24"}) => (
	<span className={className}>
		<img src={`/flags/${flag || _.get(flags, code, 'at')}.svg`} height={height} width={width} alt={code}/>
	</span>
);

// TODO: Improve with reselect
const mapStateToProps = ({authHandler}) => ({
	flags: _.transform(authHandler.languages, (res, {code, flag}) => res[code] = flag, {})
});

export default connect(mapStateToProps)(Flag);
