import {addLocaleData} from 'react-intl'
import dateFnsLocalizer from 'react-widgets-date-fns'

import {LS} from 'lib/constants'
import {getLocalePreference} from 'lib/utils';
import {gtm} from 'components/events'

const availableLocales = ['de', 'en', 'fr', 'it'];
const defaultLocale = getLocalePreference(availableLocales) || 'en';

const loadLocaleComponents = {
	'de': () => Promise.all([
		import(`date-fns/locale/de`),
		import('react-intl/locale-data/de'),
		import(`translations/de.json`),
		import(`translations/de-faq.json`),
	]),
	'en': () => Promise.all([
		import('date-fns/locale/en-GB'),
		import('react-intl/locale-data/en'),
		import('translations/en.json'),
		import('translations/en-faq.json'),
	]),
	'fr': () => Promise.all([
		import('date-fns/locale/fr'),
		import('react-intl/locale-data/fr'),
		import('translations/fr.json'),
		import('translations/fr-faq.json'),
	]),
	'it': () => Promise.all([
		import('date-fns/locale/it'),
		import('react-intl/locale-data/it'),
		import('translations/it.json'),
		import('translations/it-faq.json'),
	]),
};

/* action types */
export const types = {
	LOCALE_IS_LOADING: 'LOCALE/IS_LOADING',
	LOCALE_CHANGE_LOCALE: 'LOCALE/CHANGE_LOCALE',
	LOCALE_ADD_MESSAGES: 'LOCALE/ADD_MESSAGES',

	INTL_UPDATE: '@@intl/UPDATE',
};

/* initial state */
const initialState = {
	localeLS: LS.INIT,
	locale: defaultLocale,
	formats: {},
	messages: {},
	flags: {}
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.LOCALE_IS_LOADING:
			return {...state, localeLS: LS.REQUESTED};

		case types.LOCALE_CHANGE_LOCALE:
			return {
				...state,
				localeLS: LS.LOADED, locale: action.locale, formats: action.formats, messages: action.messages, flags: action.flags
			};

		case types.LOCALE_ADD_MESSAGES:
			return {...state, messages: {...state.messages, ...action.messages}};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	setDefaultLocale: () => actions.changeLocale(defaultLocale, true),

	changeLocale: (newLocale, init = false) => dispatch => {
		const locale = newLocale || defaultLocale;

		dispatch({type: types.LOCALE_IS_LOADING});

		loadLocaleComponents[locale]().then(([date_fns_data, react_intl_data, translations, translations_faq]) => {
			const formats = {};

			// store new list of translations and messages for current locale in intl object
			const messages = {
				...translations.default,
				...translations_faq.default
			};

			dateFnsLocalizer({locales: {[locale]: date_fns_data.default}});

			addLocaleData([...react_intl_data.default]);

			dispatch({type: types.INTL_UPDATE, payload: {locale, formats, messages}});
			dispatch({type: types.LOCALE_CHANGE_LOCALE, locale, formats, messages});

			if (!init) dispatch({type: gtm.GTM_EVENT_LOCALE_CHANGE, eventAction: locale}); // trigger only if not initial load
		});
	},

	addMessages: messages => ({type: types.LOCALE_ADD_MESSAGES, messages})
};