import React, {PureComponent} from 'react'
import {TextField} from 'lib/react-md'
import _ from 'lib/lodash'

import {withDialog} from 'components/hocs'
import {ConfirmationDialog, StarRatingPicker} from 'components/controls'

import css from './FeedbackForm.module.scss'

const C = "profiles.helvetia-ch.CustomHVCHClaimCollectionSlave.feedbackForm";

const FeedbackDialog = withDialog(({T, id, visible, show, hide, title, children, allRated, ...otherProps}) => {
	const actionTitle = T(C, allRated ? "okButton.label" : "disabledButton.label");

	return (
		<ConfirmationDialog
			id="feedaback-dialog"
			dialogClassName={css.feedbackDialog}
			visible={visible}
			title={title}
			actions={[{secondary: false, disabled: !allRated, children: actionTitle, onClick: hide}]}
			{...otherProps}
		>
			{children}
		</ConfirmationDialog>
	);
});

class FeedbackForm extends PureComponent {
	state = {
		rating1: "",
		rating2: "",
		rating3: ""
	};

	onChangeRatingItem1 = rating => this.setState({rating1: rating.label});
	onChangeRatingItem2 = rating => this.setState({rating2: rating.label});
	onChangeRatingItem3 = value => this.setState({rating3: value});

	renderStarIcon = (index, value, name, id) => <i key={`icon_${id}`} className={css.starIcon}>&#9733;</i>;

	getAndResetRatings = () => {
		const ret = [this.state.rating1, this.state.rating2, this.state.rating3];

		this.setState({rating1: "", rating2: "", rating3: ""});

		return ret;
	};

	componentDidMount() {
		const {onEntry = () => null} = this.props;

		onEntry();
	}

	render() {
		const {T, visible, onHide, ...otherProps} = this.props;
		const {rating1, rating2} = this.state;

		const allRated = visible && _.isSet(rating1) && _.isSet(rating2);

		return (
			<FeedbackDialog
				T={T}
				title={T(C, "title")}
				visible={visible}
				hide={onHide}
				allRated={allRated}
				{...otherProps}
			>
				<div className={css.container}>
					<p>{T(C, "subtitle")}</p>
					<div hidden={true} className={css.text}>{T(C, "text")}</div>
					<StarRatingPicker
						item={T(C, "item1.label")}
						leftLabel={T(C, "item1.lower")}
						rightLabel={T(C, "item1.upper")}
						labels={["1", "2", "2.5", "3", "4"]}
						starCount={5}
						starColor="#E2001A"
						emptyStarColor="#a9a9a9"
						containerClassName={css.starRatingPickerContainer}
						itemClassName={css.starRatingPickerItem}
						itemTitleClassName={css.starRatingPickerItemTitle}
						ratingClassName={css.starRatingRating}
						renderStarIcon={this.renderStarIcon}
						onChange={this.onChangeRatingItem1}
					/>
					<StarRatingPicker
						item={T(C, "item2.label")}
						leftLabel={T(C, "item2.lower")}
						rightLabel={T(C, "item2.upper")}
						labels={["1", "2", "2.5", "3", "4"]}
						starCount={5}
						starColor="#E2001A"
						emptyStarColor="#a9a9a9"
						containerClassName={css.starRatingPickerContainer}
						itemClassName={css.starRatingPickerItem}
						itemTitleClassName={css.starRatingPickerItemTitle}
						ratingClassName={css.starRatingRating}
						renderStarIcon={this.renderStarIcon}
						onChange={this.onChangeRatingItem2}
					/>
					<div className={css.starRatingPickerItem}>
						<div className={css.starRatingPickerItemTitle}>{T(C, "item3.label")}</div>
						<TextField
							id="comments"
							className={css.containerEdit}
							rows={1}
							maxRows={10}
							onChange={this.onChangeRatingItem3}
						/>
					</div>

				</div>
			</FeedbackDialog>
		);
	}
}

export default FeedbackForm // withIntl() does not work, otherwise .state is not accessible anymore