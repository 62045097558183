import React from 'react';
import styled from 'styled-components/macro';
import {Toolbar} from 'lib/react-md';


const ToolbarWrapper = ({className, styled, children, ...otherProps}) =>
	<Toolbar className={className} {...otherProps}>{children}</Toolbar>;

export default styled(ToolbarWrapper)`
	background: ${({theme}) => theme.primaryColor};
	
	h2 {
		color: ${({theme}) => theme.primaryTextColor};
		margin-left: 16px;
	}
	
	@media (max-height: 400px) {
		&& {
			display: none; 
		}
	}
	
	&& i {
		color: ${({theme}) => theme.primaryTextColor};
	}
`;