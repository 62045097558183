import React from 'react';
import styled from 'styled-components/macro';
import {FormListbox} from 'components/forms'

const FormListboxWrapper = ({className, styled, children, ...otherProps}) =>
	<FormListbox className={className} {...otherProps}>{children}</FormListbox>;

export default styled(FormListboxWrapper)`
	margin: 0 0 16px 0;
	
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	#listbox {
		display: block;
		
		ul li > div {
			padding-left: 16px;
		}
	}
	
  #errorText {
    font-size: 16px;
  	color: ${({theme}) => theme.alertTextColor};
  }
`;