import React from 'react';

import {Button} from 'lib/react-md'
import {withIntl} from 'components/hocs'
import {SensotixLink} from 'components/utils'

import css from './SlaveFooter.module.scss'

const C = "profiles.default.demo-event-gold-premium.SlaveFooter";

const SlaveFooter = ({T}) => {
	const onEmailClick = () => window.location.href = "mailto:office@sensotix.com";
	const onPhoneClick = () => window.location.href = "tel:+436505907369‬";

	return (
		<div className={css.container}>

			<div className={css.contactContainer}>
				<div className={css.title}>{T(C, "contact.title")}</div>
				<div className={css.text}>{T(C, "contact.text")}</div>
				<div>
					<Button icon onClick={onEmailClick}>email</Button>
					<Button icon onClick={onPhoneClick}>phone</Button>
				</div>
			</div>
			<div className={css.footer}>
				<div>No App. Just Tap!</div>
				<div><SensotixLink title="Smart Stickers by Sensotix."/></div>
			</div>
		</div>
	);
};

export default withIntl(SlaveFooter);