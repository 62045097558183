import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer} from 'components/containers'
import {TextFieldEdit} from 'components/controls'
import {VideoViewer} from 'components/viewers'
import {gtm} from 'components/events'

import css from './VideoSlave.module.scss'

class ViewComponent extends PureComponent {
	onVideoReady = eventData => ({id}) => this.props.actions.dispatch({type: gtm.GTM_EVENT_VIDEO_READY, eventData: {...eventData, id}});

	render() {
		const {item, EditComponent, allowEdit} = this.props;
		const {filters = {}, storage = {}, properties = {}} = item;
		const {fileUrl, thumbUrl} = storage;
		const {videojs = {}} = properties;
		const {autoplay = false, muted = false, preload = "none", loop = false, stopOnScroll = true} = videojs;

		const editAllowed = allowEdit(item);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<div className={css.viewWrapper}>
						<div className={css.viewHeader}>
							{filters.language && <Flag className={css.flag} code={filters.language}/>}
							<div className={css.content}/>
							<div className={css.icon}>
								{editAllowed && <EditComponent/>}
							</div>
						</div>
						<div className={css.viewImage}>
							<VideoViewer
								id={item.$id}
								imgPath={fileUrl}
								thumbPath={thumbUrl}
								showZoomCtrl={false}
								showFullscreenCtrl
								autoplay={autoplay}
								muted={muted}
								preload={preload}
								stopOnScroll={stopOnScroll}
								onReady={this.onVideoReady({item})}
								loop={loop}
							/>
						</div>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}


class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties, filters} = item;
		const {label, description} = properties;
		const {language} = filters;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="title"
					label="title"
					rows={1}
					maxRows={2}
					value={{label}}
					onSubmit={this.updateProperty}
				/>
				<TextFieldEdit
					id="description"
					label="description"
					value={{description}}
					rows={1}
					maxRows={3}
					onSubmit={this.updateProperty}
				/>
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}