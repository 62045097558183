import React, {PureComponent} from 'react';
import {Button} from 'lib/react-md'

import * as Wrappers from 'components/wrappers'
import {PostFactory} from 'components/nodes/factories'

import css from './PostCollectionSlave.module.scss'

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	addPost = () => this.props.actions.postSubnode(this.props.item, "slave", PostFactory);

	render() {
		const {children, allowAdd} = this.props;

		const AddItem = () => (
			<div className="styles-dropzone">
				<Button icon onClick={this.addPost}>add</Button>
			</div>
		);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.content}>
					{children}
				</div>
				<div className={css.footer}>
					{allowAdd && <AddItem/>}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ContainerComponent: Wrappers.ContainerComponentWrapper,
	HeaderComponent,
	ViewComponent
}