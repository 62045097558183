import React, {Component} from 'react';
import {connect} from 'react-redux'
import _ from 'lib/lodash'
import {FontIcon} from 'lib/react-md'

import {withIntl} from 'components/hocs'
import {scannerActions} from 'components/handlers/actions'

import css from './QRScanner.module.scss'

const C = "QRScanner";

class QRScanner extends Component {
	state = {
		QrReader: null,
		facingMode: "environment"
	};

	shouldComponentUpdate(nextProps, nextState) {
		return _.anyDiff(nextProps, this.props, ['data', 'error']) || _.anyDiff(nextState, this.state, ['QrReader', 'facingMode']);
	}

	componentDidMount() {
		// dynamically import react-qr-reader library, otherwise it increases bundle size by 137KB
		import(/* webpackChunkName: "react-qr-reader" */ "modern-react-qr-reader").then(module => this.setState({QrReader: module.default || module}));
	}

	componentWillUnmount() {
		this.props.resetScannerHandler();
	}

	onLoad = () => this.props.handleQRLoad();

	onScan = data => data && data !== this.props.data && this.props.handleQRData(data);

	onError = error => this.props.handleQRError(error);

	switchFacingMode = () => this.setState(state => ({facingMode: state.facingMode === "environment" ? "user" : "environment"}));

	render() {
		const {error, T} = this.props;
		const {QrReader, facingMode} = this.state;

		if (!QrReader) return null;
		if (!!error && error.code === "NotAllowedError") return <div>{T("error", `${C}.NotAllowedError`)}</div>;
		if (!!error && error.code === "NoVideoInputDevicesError") return <div>{T("error", `${C}.NoVideoInputDevicesError`)}</div>;

		return (
			<div className={css.container}>
				<QrReader
					delay={300}
					onLoad={this.onLoad}
					onError={this.onError}
					onScan={this.onScan}
					facingMode={facingMode}
					showViewFinder={false}
				/>
				<FontIcon onClick={this.switchFacingMode}>switch_camera</FontIcon>
			</div>
		);
	}
}

const mapStateToProps = ({scannerHandler}) => scannerHandler;

export default withIntl(connect(mapStateToProps, scannerActions)(QRScanner));