import React, {useState, useEffect} from 'react'
import StarRatingComponent from 'react-star-rating-component';
import PropTypes from 'prop-types'

const StarRatingPicker = ({
	                          item, starCount, itemClassName, itemTitleClassName, containerClassName, ratingClassName,
	                          leftLabel, rightLabel, values, onChange, labels, ...otherProps
                          }) => {
	const [rating, setRating] = useState({label: "", value: 0});

	useEffect(() => {
		onChange(rating)
	}, [rating, onChange]);

	const onChangeRating = rating => setRating({label: labels[rating - 1], value: rating});
	const onSetMinimum = () => setRating({label: labels[0], value: 1});
	const onSetMaximum = () => setRating({label: labels[labels.length - 1], value: starCount});

	return (
		<div className={containerClassName}>
			<div className={itemClassName} key="row">
				<span className={itemTitleClassName}>{item}</span>
				<div className={ratingClassName}>
					<span onClick={onSetMinimum}>{leftLabel}</span>
					<StarRatingComponent
						name="rating"
						starCount={starCount}
						value={rating.value}
						onStarClick={onChangeRating}
						{...otherProps}
					/>
					<span onClick={onSetMaximum}>{rightLabel}</span>
				</div>
			</div>
		</div>
	)
};

StarRatingPicker.propTypes = {
	item: PropTypes.string.isRequired,
	starCount: PropTypes.number,
	onChange: PropTypes.func.isRequired
};

StarRatingPicker.defaultProps = {
	starCount: 5,
	labels: ["1", "2", "3", "4", "5"]
};

export default StarRatingPicker