import React, {PureComponent} from 'react'
import {Checkbox} from 'lib/react-md'
import {FontIcon, DialogContainer} from 'lib/react-md'

import {withDialog} from 'components/hocs'

import css from './ConsentForm.module.scss'

const C = "profiles.aida.ConsentForm";

const InfoDialog = withDialog(({id, visible, show, hide, title, children, label}) => (
	<>
		<FontIcon onClick={show} data-gtm-action={`icon:${C}`} data-gtm-label={label}>info</FontIcon>
		<DialogContainer
			id={id}
			dialogClassName={css.dialog}
			title={title}
			onHide={hide}
			visible={visible}
			disableScrollLocking
			portal
			actions={[{secondary: false, children: "Schließen", onClick: hide}]}
		>
			{children}
		</DialogContainer>
	</>
));

class ConsentForm extends PureComponent {
	state = {
		supplement2: false
	};

	onChangeSupplement2 = value => this.setState({supplement2: value});

	render() {
		const infoTextSupplement1 = (
			<p>Ich erteile hiermit ausdrücklich meine Einwilligung, dass die Firma AIDA Cruises – German Branch of Costa Crociere S.p.A.
				meine im Rahmen meiner Teilnahme am Gewinnspiel von mir bekanntgegebenen <strong>personenbezogenen
					Daten für Zwecke der Durchführung des Gewinnspiels speichert und verarbeitet</strong>. Die Datenschutzerklärung,
				die jederzeit unter <a
					href="https://www.aida-cruises.at/datenschutz.18597.html">https://www.aida-cruises.at/datenschutz.18597.html</a>abrufbar
				ist und alle Informationen über die mir zustehenden Rechte enthält habe ich zur Kenntnis genommen.
				Diese Einwilligung ist freiwillig und kann von mir jederzeit mit Wirkung für die Zukunft widerrufen werden.</p>
		);

		const infoTextSupplement2 = (
			<p>Ich erteile hiermit ausdrücklich meine Einwilligung, dass die Firma AIDA Cruises – German Branch of Costa Crociere S.p.A.
				meine im Rahmen meiner Teilnahme am Gewinnspiel von mir bekanntgegebenen <strong>personenbezogenen
					Daten auch für Werbezwecke speichert und verarbeitet</strong> und ich stimme hiermit dem Erhalt von Telefonanrufen,
				SMS-Nachrichten und E-Mail-Zusendungen durch die Firma AIDA Cruises – German Branch of Costa Crociere S.p.A. ausdrücklich
				bis auf jederzeitigen Widerruf zu. Diese Einwilligung ist freiwillig.
				Wird sie nicht erteilt, entstehen mir keine Nachteile bzw. kann ich dennoch am Gewinnspiel teilnehmen.
				Diese Einwilligung ist freiwillig und kann von mir jederzeit mit Wirkung für die Zukunft widerrufen werden.</p>
		);

		return (
			<div className={css.container}>
				<div className={css.row}>
					<Checkbox
						id="checkbox-supplement1"
						name="checkbox-supplement1"
						label="Für das Gewinnspiel"
						value=""
						defaultChecked
						disabled
					/>
					<InfoDialog id="info-dialog-supplement1" title={null} label="Für das Gewinnspiel">{infoTextSupplement1}</InfoDialog>
				</div>
				<div className={css.row}>
					<Checkbox
						id="checkbox-supplement2"
						name="checkbox-supplement2"
						label="Für weitere Spezialangebote"
						value=""
						onChange={this.onChangeSupplement2}
					/>
					<InfoDialog id="info-dialog-supplement2" title={null} label="Für weitere Spezialangebote">{infoTextSupplement2}</InfoDialog>
				</div>
			</div>
		);
	}
}

export default ConsentForm