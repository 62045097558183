import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {ListItem} from 'lib/react-md'
import _ from 'lib/lodash'

import {Flag} from 'components/utils'
import {localeActions} from 'components/handlers/actions'

class LocaleContainer extends PureComponent {
	changeLocale = locale => () => {
		// store selected language in localStorage
		localStorage.setItem('stx-locale', locale);

		this.props.changeLocale(locale);
	};

	render() {
		const {locale, languages} = this.props;

		return _.transform(languages, (res, {code, label, flag}) => {
			if (code !== locale) {
				res.push(
					<ListItem
						key={code}
						primaryText={label}
						leftIcon={<Flag code={code} flag={flag}/>}
						onClick={this.changeLocale(code)}
					/>
				);
			}
		}, []);
	}
}

const mapStateToProps = ({localeHandler}) => localeHandler;

export default connect(mapStateToProps, localeActions)(LocaleContainer);