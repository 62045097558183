import React from 'react';
import styled from 'styled-components/macro';
import FormTextField from 'components/forms/FormTextField'

const FormTextFieldWrapper = ({className, styled, children, ...otherProps}) =>
	<FormTextField className={className} {...otherProps}>{children}</FormTextField>;

export default styled(FormTextFieldWrapper)`
	margin: 0 0 16px 0;
	
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	input, textarea {
		-webkit-appearance: none;
		margin-top: 8px;
    padding: 8px;
    border: 1px solid ${({styled, theme}) => theme.primaryColor}15;
    background: ${({styled, theme}) => theme.primaryColor}15;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    
    :hover, :active {
		   border: 1px solid ${({styled, theme}) => theme.primaryColor};
    }
    
    ::placeholder {
    	color: ${({styled, theme}) => theme.textColor};
    	font-weight: normal;
    }
  }
  
  hr {
  	display: none;
  }
  
  div.md-text--error {
  	font-size: 16px;
  	color: ${({styled, theme}) => theme.alertTextColor};
  }
`;