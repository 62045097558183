import React from 'react'

import {withIntlCookieInfo} from 'components/hocs'
import DefaultPortal from 'components/templates/portal/DefaultPortal'
import PortalMenu from './PortalMenu';

import '../../theme-aida.scss'

const C = "profiles.aida.portals.default";

const Portal = ({T, languages, profileId}) => (
	<DefaultPortal
		profileId={profileId}
		length={8}
		options={{blocks: [2, 2, 2, 2], delimiter: ' ', uppercase: true}}
		logoPath="profiles/aida/img/AIDA-Logo.svg"
		logoWidth={160}
		helpImagePath="profiles/aida/img/help.svg"
		title={T(C, "title")}
		textCamera={T(C, "textCamera")}
		textNoCamera={T(C, "textNoCamera")}
		Menu={<PortalMenu languages={languages}/>}
		theme="theme-aida"
	/>
);

export default withIntlCookieInfo(Portal);