import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Button} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'
import {SensotixLink} from 'components/utils';

import css from './NotFoundRoute.module.scss'

const C = "NotFoundRoute";

class NotFoundRoute extends Component {
	componentDidMount() {
		this.props.dispatch({type: "HIDE_LOADING"});
	}

	openContactEmail = () => {
		const {history} = this.props;
		const location = JSON.stringify(history.location);
		const referrer = window.document.referrer;

		window.location.href = `mailto:office@sensotix.com?Subject=Smart Sticker Portal NotFoundError&body=Location: ${location}%0D%0AReferrer: ${referrer}`;
	};

	render() {
		const {T, history} = this.props;
		const {code = "", message = ""} = _.get(history.location, 'state', {});

		return (
			<div className={css.container}>
				<Button className={css.backButton} icon onClick={() => history.goBack()}>arrow_back</Button>
				<h1>{T(C, "header")}</h1>
				<p>{T(C, "title")}</p>
				<h6>{code || message ? `[${code || message}]` : ""}</h6>
				<Button flat onClick={this.openContactEmail}>{T(C, "contact")}</Button>
				<footer>
					© {(new Date()).getFullYear()} <SensotixLink title="Sensotix GmbH"
					                                             data-gtm-action={`${C}:SensotixGmbH`}/> ・ <SensotixLink
					title="www.sensotix.com" data-gtm-action={`${C}:www.sensotix.com`}/>
				</footer>
			</div>
		)
	}
}

export default connect()(withIntl(NotFoundRoute));