import React, {Component} from 'react';
import {SelectField} from 'lib/react-md'
import _ from 'lib/lodash'

import {Flag} from 'components/utils'
import {withIntl} from 'components/hocs'

import css from './LanguagePicker.module.scss'

const C = "LanguagePicker";

class LanguagePicker extends Component {
	onChange = value => this.props.onChange({language: value});

	render() {
		const {T, languages, defaultValue} = this.props;

		const menuItems = [
			{label: T(C, "all"), code: ""},
			..._.map(languages, ({code, label}) => (
				{label: <div className={css.label}><Flag code={code}/><span/>{label}</div>, code}
			))
		];

		return (
			<SelectField
				id="select-field-language"
				label={T(C, "select")}
				menuItems={menuItems}
				position={SelectField.Positions.BELOW}
				simplifiedMenu={true}
				value={defaultValue.language}
				itemLabel="label"
				itemValue="code"
				onChange={this.onChange}
				fullWidth
			/>
		);
	}
}

export default withIntl(LanguagePicker)
