import React from 'react';
import styled from 'styled-components/macro';
import {Button} from 'lib/react-md'

import LoadingDots from './LoadingDots';

const StyledLoadingDots = styled(LoadingDots)`
	div {
		background-color: white;
	}
`;

const ButtonWrapper = ({className, styled, children, fullWidth, isLoading = false, ...otherProps}) => isLoading
	? <Button className={className} raised disabled iconEl={<StyledLoadingDots/>}>{""}</Button>
	: <Button className={className} flat {...otherProps}>{children}</Button>;

export default styled(ButtonWrapper)`
	&& { // increase specificity
		display: flex;
		flex-direction: row;
		justify-content: center;
	
		background: ${({styled, theme}) => styled === "primary" && theme.primaryColor};
		background: ${({styled, theme}) => styled === "secondary" && theme.secondaryColor};
		background: ${({styled, theme}) => styled === "tertiary" && theme.tertiaryColor};
		background: ${({styled, theme}) => styled === "alert" && theme.alertTextColor};
		width: ${({fullWidth}) => fullWidth ? "100%" : "auto"};

		color: white;		
		height: 50px;
	}
`;