import {getLocalePreference} from 'lib/utils';

// detect browser locale and make selection
const availableLocales = ['de', 'en', 'fr', 'it'];

export const loadLocaleComponents = {
	'de': () => Promise.all([
		import('date-fns/locale/de'),
		import('react-intl/locale-data/de'),
		import('translations/de.json'),
		import('translations/de-faq.json')
	]),
	'en': () => Promise.all([
		import('date-fns/locale/en-GB'),
		import('react-intl/locale-data/en'),
		import('translations/en.json'),
		import('translations/en-faq.json')
	]),
	'fr': () => Promise.all([
		import('date-fns/locale/fr'),
		import('react-intl/locale-data/fr'),
		import('translations/fr.json'),
		import('translations/fr-faq.json')
	]),
	'it': () => Promise.all([
		import('date-fns/locale/fr'),
		import('react-intl/locale-data/it'),
		import('translations/it.json'),
		import('translations/it-faq.json')
	]),
};

export const getDefaultLocale = () => getLocalePreference(availableLocales) || 'en'; // set English as default language if no preference found