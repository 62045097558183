import React from 'react';
import styled from 'styled-components/macro'
import {Toolbar} from 'lib/react-md'

const ImageWrapper = styled.div`
 	padding: 8px;
  text-align: right;

  img {
    width: 90%;
  }
`;

const PageMainToolbar = ({languages, bundle, sticker}) => () => (
	<Toolbar
		actions={(
			<ImageWrapper>
				<img src={require('../../../../../../img/helvetia-logo-color-pos-170px.svg')} alt="logo"/>
			</ImageWrapper>
		)}
		zDepth={0}
	/>
);

export default PageMainToolbar;