import React from 'react';
import styled from 'styled-components/macro';
import {FormLocalImageUploader} from 'components/forms'

const FormLocalImageUploaderWrapper = ({className, ...otherProps}) => (
	<FormLocalImageUploader className={className} {...otherProps}/>
);

export default styled(FormLocalImageUploaderWrapper)`
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	> div {
		margin-top: 8px;
	}
	
	#errorText {
    font-size: 16px;
  	color: ${({theme}) => theme.alertTextColor};
  }
`;