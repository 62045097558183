import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {
	StyledButton, StyledFormTextField, StyledFormRadioGroup, StyledImagesUploader, StyledErrors, StyledFormHeader
} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.helvetia-at.PageClaim.PageCollision.Person.PageParty";

const PagePartyForm = ({actions, item, images, onChanged}) => ({formProps: {values}}) => {
	const controlsPartyHurt = [
		{label: "ja", value: "yes"},
		{label: "nein", value: "no"}
	];

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					name="errors"
					showOnlyFirst
					component={StyledErrors}
				/>
				<Field
					type="text"
					name="partyHurt"
					label="Ist die andere Person verletzt?"
					component={StyledFormRadioGroup}
					controls={controlsPartyHurt}
					inline
					onChanged={value => onChanged({...values, ...value})}
				/>
				<StyledFormHeader>Kontaktdaten der anderen Person:</StyledFormHeader>
				<Field
					name="images_license"
					label="Foto des Ausweises:"
					actions={actions}
					item={item}
					images={images}
					tag="license"
					component={StyledImagesUploader}
				/>
				<Field
					type="text"
					name="partyName"
					label="Name, Vorname"
					placeholder=""
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyAddress"
					label="Adresse"
					placeholder=""
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyTelno"
					label="Telefonnummer"
					placeholder=""
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyEmail"
					label="Emailadresse"
					placeholder=""
					component={StyledFormTextField}
				/>
			</Form>
		</ThemedPageWrapper>
	);
};

const validationSchema = Yup.object().shape({
	partyHurt: Yup.string().required("Bitte geben Sie an, ob die andere Person verletzt ist."),
	partyName: Yup.string(),
	partyAddress: Yup.string(),
	partyTelno: Yup.string(),
	partyEmail: Yup.string().trim().email("Bitte geben Sie eine gültige Emailadresse an."),
});

const PageParty = ({wizardProps: {T, actions}, item, images}) => {
	const [formState, dispatch] = useFormState();
	const {partyHurt, partyCause, partyName, partyAddress, partyTelno, partyEmail} = formState;
	const initialValues = {partyHurt, partyCause, partyName, partyAddress, partyTelno, partyEmail};

	const onChanged = values => dispatch({type: "FORM_CHANGE_VALUES", ...values});
	const FormComponent = PagePartyForm({actions, item, images, onChanged});

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_PARTY", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageParty;