export default ({stickerId, order = 1000}) => ({
	type: "custom-aida-callback-form",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		name: "",
		telno: "",
		datetime: ""
	}
})