import React from "react";
import {Link} from "../../../components/utils";

import css from './SensotixAd.module.scss';

const C = "profiles.helvetia-ch.SensotixAd";

const SensotixAd = ({T}) => (
	<div className={css.container}>
		<h3 className={css.title}>{T(C, `title`)}</h3>
		<h5 className={css.body}>{T(C, `body`)}</h5>
		<Link className={css.link} title={T(C, `link`)} href={T(C, `href`)} data-gtm-action={`${C}:SensotixAd`}/>
	</div>
);

export default SensotixAd