import React from 'react'

import DefaultMaster from 'components/templates/sticker/master/DefaultMaster'
import {withIntlCookieInfo} from 'components/hocs'

import StickerMenu from '../../common/StickerMenu'
import ActionsMenu from 'profiles/default/stickers/default/roles/master/ActionsMenu'
import '../../../../theme-helvetia.scss'

import Slave from '../slave/Slave';

/*const styles = {
	"--primary-background-color": "var(--helvetia-purple)",
	"--primary-text-color": "white",
	"--secondary-background-color": "var(--helvetia-blue)",
	"--secondary-text-color": "white",
	"--default-main-color": "black",
	"--default-sub-color": "white",
};*/

const Master = props => (
	<DefaultMaster
		bundle={props.bundle}
		sticker={props.sticker}
		ActionsMenu={ActionsMenu}
		StickerMenu={() => <StickerMenu {...props}/>}
		theme="theme-helvetia"
		SlaveComponent={Slave}
		/*styles={styles}*/
		{...props}
	/>
);

export default withIntlCookieInfo(Master);