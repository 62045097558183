import React from 'react';
import styled from 'styled-components/macro'
import {Toolbar} from 'lib/react-md'
import StickerMenu from '../../../../common/StickerMenu';

const ImageWrapper = styled.div`
 	padding: 8px;
  text-align: right;

  img {
    height: 40px;
  }
`;

const PageMainToolbar = ({languages, bundle, sticker}) => () => (
	<Toolbar
		nav={<StickerMenu languages={languages} bundle={bundle} sticker={sticker}/>}
		actions={(
			<ImageWrapper>
				<img src={require('../../../../../../img/concierge-svgrepo-com.svg')} alt="logo"/>
			</ImageWrapper>
		)}
		zDepth={0}
		title="DEMO"
	/>
);

export default PageMainToolbar;