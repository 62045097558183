import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {
	StyledButton, StyledFormCheckboxGroup, StyledErrors
} from '../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageGlass.PageDetails";
const CC = "profiles.folder-f.PageClaim.PageGlass";

const PagePartyForm = ({T}) => {
	const controlsDamages = [
		{label: T(C, "damages.options.front"), value: "front"},
		{label: T(C, "damages.options.rearside"), value: "rearside"},
		{label: T(C, "damages.options.other"), value: "other"}
	];

	const controlsDetails = [
		{label: T(C, "details.options.view"), value: "view"},
		{label: T(C, "details.options.small"), value: "small"},
		{label: T(C, "details.options.large"), value: "large"},
		{label: T(C, "details.options.crack"), value: "crack"},
	];

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					name="errors"
					showOnlyFirst
					component={StyledErrors}
				/>
				<Field
					type="text"
					name="damages"
					label={T(C, "damages.label")}
					component={StyledFormCheckboxGroup}
					controls={controlsDamages}
				/>
				<Field
					type="text"
					name="details"
					label={T(C, "details.label")}
					component={StyledFormCheckboxGroup}
					controls={controlsDetails}
				/>
			</Form>
		</ThemedPageWrapper>
	);
};

const validationSchema = ({T}) => Yup.object().shape({
	damages: Yup.string().required(T(CC, "yup.damages")),
	details: Yup.string().required(T(CC, "yup.details")),
});

const PageSpecifics = ({wizardProps: {T, actions}, item, images}) => {
	const [formState, dispatch] = useFormState();
	const {damages, details} = formState;
	const initialValues = {damages, details};

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_GLASS_DETAILS", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema({T})}
			onSubmit={onSubmit}
			FormComponent={PagePartyForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageSpecifics;