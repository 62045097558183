import React, {PureComponent} from 'react'
import {Toolbar, Button, FontIcon} from 'lib/react-md'

import {withIntlDrawer} from 'components/hocs'

import css from './PortalMenu.module.scss'

const C = "profiles.aida.PortalMenu";

class PortalMenu extends PureComponent {
	componentDidMount() {
		const {T, initDrawer, closeDrawer} = this.props;

		const titleImage = require(`../../img/AIDA-Logo.svg`);

		const container = (
			<div className={css.container}>
				<Toolbar
					colored
					fixed
					title=""
					prominentTitle
					zDepth={0}
					nav={<Button icon onClick={closeDrawer}>close</Button>}
					style={{
						background: `url(${titleImage}) no-repeat center 70%`,
						backgroundSize: '60%'
					}}
				/>
				<section>
					<p className={css.text}>{T(C, "welcomeMessage")}</p>
				</section>
			</div>
		);

		initDrawer({container, position: "left", memoized: true});
	}

	render() {
		const {openDrawer} = this.props;

		return (
			<Button
				icon
				onClick={openDrawer}
				iconEl={<FontIcon data-gtm-action={`button:${C}`} data-gtm-label="Portal Menü">menu</FontIcon>}
			/>
		);
	}
}

export default withIntlDrawer(PortalMenu, {name: "PortalMenu"});