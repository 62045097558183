/* imports */
import {LS} from 'lib/constants'
//import _ from 'lib/lodash'

/* action types */
export const types = {
	PORTAL_ROUTE_UPDATE_LOADING: 'PORTAL_ROUTE/UPDATE_LOADING',
	PORTAL_ROUTE_HIDE_LOADING: 'PORTAL_ROUTE/HIDE_LOADING',

	PORTAL_ROUTE_COMPONENT_LOAD_REQUEST: 'PORTAL_ROUTE/COMPONENT_LOAD_REQUEST',
	PORTAL_ROUTE_COMPONENT_LOAD_SUCCESS: 'PORTAL_ROUTE/COMPONENT_LOAD_SUCCESS',
	PORTAL_ROUTE_COMPONENT_LOAD_ERROR: 'PORTAL_ROUTE/COMPONENT_LOAD_ERROR',

	PORTAL_ROUTE_TRANSLATIONS_LOAD_REQUEST: 'PORTAL_ROUTE/TRANSLATIONS_LOAD_REQUEST',
	PORTAL_ROUTE_TRANSLATIONS_LOAD_SUCCESS: 'PORTAL_ROUTE/TRANSLATIONS_LOAD_SUCCESS',

	PORTAL_ROUTE_RESET_STATE: 'PORTAL_ROUTE/RESET_STATE',
};

/* initial state */
const initialState = {
	PortalComponent: null,
	componentLS: LS.INIT,
	translationsLS: LS.INIT,
	statusLS: LS.INIT,
	error: null,
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.PORTAL_ROUTE_COMPONENT_LOAD_REQUEST:
			return {...state, componentLS: LS.REQUESTED};

		case types.PORTAL_ROUTE_COMPONENT_LOAD_SUCCESS:
			return {...state, componentLS: LS.LOADED, PortalComponent: action.Component};

		case types.PORTAL_ROUTE_COMPONENT_LOAD_ERROR:
			return {...state, componentLS: LS.ERROR, PortalComponent: null, error: action.error};

		case types.PORTAL_ROUTE_TRANSLATIONS_LOAD_REQUEST:
			return {...state, translationsLS: LS.REQUESTED};

		case types.PORTAL_ROUTE_TRANSLATIONS_LOAD_SUCCESS:
			return {...state, translationsLS: LS.LOADED};

		case types.PORTAL_ROUTE_LOADING_FINISHED:
			return {...state, statusLS: LS.LOADED};

		case types.PORTAL_ROUTE_RESET_STATE:
			return initialState;

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	// loadPortalComponents: component => dispatch => {
	// 	const [_profiles, _customer, _branch, _name] = component.split('/');
	//
	// 	// Promise.all(_.compact([
	// 	// 	_customer === "default" && import(/* webpackChunkName: "default" */ `../../../profiles/default/${_branch}/${_name}`),
	// 	// 	_customer === "aida" && import(/* webpackChunkName: "aida" */ `../../../profiles/aida/${_branch}/${_name}`),
	// 	// 	_customer === "helvetia-ch" && import(/* webpackChunkName: "helvetia-ch" */ `../../../profiles/helvetia-ch/${_branch}/${_name}`),
	// 	// ]))
	//
	// 	import(/* webpackChunkName: "[request]" */ `profiles/${_customer}/${_branch}/${_name}`)
	// 	.then(result => {
	// 		console.log(result);
	//
	//
	// 		dispatch({type: types.PORTAL_ROUTE_COMPONENT_LOAD_SUCCESS, Component: result.default});
	// 		dispatch({type: types.PORTAL_ROUTE_UPDATE_LOADING, addRel: 0.3, main: "portal.main.components"});
	// 	}).catch(error => {
	// 		dispatch({type: types.PORTAL_ROUTE_COMPONENT_LOAD_ERROR, error});
	// 	});
	// },

	loadPortalComponent: component => dispatch => {
		dispatch({
			type: types.PORTAL_ROUTE_COMPONENT_LOAD_REQUEST,
			component,
			onSuccessActions: [
				{type: types.PORTAL_ROUTE_COMPONENT_LOAD_SUCCESS},
				{type: types.PORTAL_ROUTE_UPDATE_LOADING, addRel: 0.3, main: "portal.main.components"}
			],
			onErrorActions: [{type: types.PORTAL_ROUTE_COMPONENT_LOAD_ERROR}]
		});
	},

	loadTranslations: component => dispatch => {
		dispatch({
			type: types.PORTAL_ROUTE_TRANSLATIONS_LOAD_REQUEST,
			profile: component.split('/')[1],
			onSuccessActions: [
				{type: types.PORTAL_ROUTE_TRANSLATIONS_LOAD_SUCCESS},
				{type: types.PORTAL_ROUTE_UPDATE_LOADING, addRel: 1, main: "portal.main.translations"}
			]
		});
	},

	portalRouteLoadingFinished: () => ({type: types.PORTAL_ROUTE_HIDE_LOADING}),

	resetPortalRouteHandler: () => ({type: types.PORTAL_ROUTE_RESET_STATE}),
};