import React, {PureComponent} from 'react'
import {ExpansionList, Toolbar, Button, Subheader, List, ExpansionPanel, Divider/*, Autocomplete*/} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntlDrawer} from 'components/hocs'

import css from './FaqsContainer.module.scss'

const Panel = ({label, children}) => (
	<ExpansionList>
		<ExpansionPanel
			label={label}
			footer={null}
			zDepth={0}
			animateContent={false}
		>
			{children}
		</ExpansionPanel>
	</ExpansionList>
);

const C = "FaqsContainer";

class FaqsContainer extends PureComponent {
	static defaultProps = {
		position: "right"
	};

	componentDidMount() {
		const {T, intl, position, initDrawer, closeDrawer} = this.props;
		const title = T(C, "title");
		const actionButton = <Button icon onClick={closeDrawer}>close</Button>;

		const Div = idx => (
			<div key={`${C}_divider_${idx}`}>
				<Divider/>
			</div>
		);
		const Section = (primaryText, idx) => (
			<div key={`${C}_section_${idx}`}>
				<Subheader primaryText={primaryText} className={css.subheader}/>
			</div>
		);
		const Item = (label, children, idxSection, idx) => (
			<div key={`${C}_section_${idxSection}_${idx}`}>
				<Panel label={label} className={css.panel}>{children}</Panel>
			</div>
		);

		// render FAQ sections with headers and list of questions
		const renderSections = _.transform(_.get(intl.messages, `${C}.sections`), (res, section, idxSection) => {
			if (_.isSet(res)) res.push(Div(idxSection)); // add divider
			res.push(Section(section.h, idxSection)); // add subheader
			_.forEach(section.qa, ({q, a}, idxItem) => res.push(Item(q, a, idxSection, idxItem))) // add questions and answers
		}, []);

		const container = (
			<div className={css.container}>
				<Toolbar
					colored
					fixed
					title={title}
					zDepth={0}
					actions={actionButton}
				/>
				{/*<Autocomplete
					id="search-pastries"
					block
					data={[{key: "abc", name: "abc"}]}
					dataLabel="name"
					dataValue="key"
					value=""
					placeholder="Search pastries"
					toolbar
					autoFocus
				/>*/}
				<section>
					<List>
						{renderSections}
					</List>
				</section>
			</div>
		);

		initDrawer({container, position, memoized: true})
	}

	render() {
		return null;
	}
}

export default withIntlDrawer(FaqsContainer, {name: "FAQ"});