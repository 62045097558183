import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';
import _ from 'lib/lodash'

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormTextField} from '../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../PageClaim/ThemedPageWrapper'

const C = "profiles.helvetia-at.PageDriver.PageDriverInfo";

const PageDriverInfoForm = () => (
	<ThemedPageWrapper>
		<Form>
			<Field
				type="text"
				name="driverPlate"
				label="Kennzeichen"
				placeholder="Bitte ausfüllen..."
				component={StyledFormTextField}
			/>
			<Field
				type="text"
				name="driverName"
				label="Name, Vorname"
				placeholder="Bitte ausfüllen..."
				component={StyledFormTextField}
			/>
			<Field
				type="text"
				name="driverTelno"
				label="Telefonnummer"
				placeholder="Bitte ausfüllen..."
				component={StyledFormTextField}
			/>
			<Field
				type="text"
				name="driverEmail"
				label="Emailadresse"
				placeholder="Bitte ausfüllen..."
				component={StyledFormTextField}
			/>
		</Form>
	</ThemedPageWrapper>
);

const validationSchema = Yup.object().shape({
	driverPlate: Yup.string().required("Bitte geben Sie das Kennzeichen an."),
	driverName: Yup.string().required("Bitte geben Sie den Namen an."),
	driverTelno: Yup.string().when("driverEmail", {
		is: driverEmail => !driverEmail,
		then: Yup.string().trim().required("Bitte geben Sie eine gültige Telefonnummer und/oder Emailadresse an."),
		otherwise: Yup.string()
	}),
	driverEmail: Yup.string().when("driverTelno", {
		is: driverTelno => !driverTelno,
		then: Yup.string().trim().email("Bitte geben Sie eine gültige Telefonnummer und/oder Emailadresse an."),
		otherwise: Yup.string().trim().email("Bitte geben Sie eine gültige Emailadresse an.")
	}),
}, ["driverTelno", "driverEmail"]);

const PageDriverInfo = ({wizardProps: {T, actions}, nextStep, userInfo, setUserInfo, cancel, ...otherProps}) => {
	const [, dispatch] = useFormState();
	const {driverPlate = "", driverName = "", driverTelno = "", driverEmail = ""} = _.get(userInfo, 'driver', {});
	const initialValues = {driverPlate, driverName, driverTelno, driverEmail};

	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_DRIVER_INFO", ...values});
		setUserInfo(_.merge({}, userInfo, {driver: values}));
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={PageDriverInfoForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={cancel}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageDriverInfo;