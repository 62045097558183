import React, {PureComponent} from 'react';
import {Tab, Tabs, TabsContainer, Toolbar} from 'lib/react-md'

import SlaveMenu from './SlaveMenu';
import SlaveHeader from './SlaveHeader'
import SlaveMain from './SlaveMain'
import StickerFooter from '../../common/StickerFooter'
import SlavePanorama from './SlavePanorama';
import css from './Slave.module.scss';

class Slave extends PureComponent {
	state = {
		activeTabIndex: 0,
	};

	changeTabIndex = activeTabIndex => {
		this.setState({activeTabIndex}, () => {
			if (activeTabIndex === 0) {
				//document.getElementById("bodyContent").scrollTop = 270; // Fokussiere auf Wettbewerb
				window.setTimeout(() => document.getElementById("contentStart").scrollIntoView(true), 150);
			}
		});
	};

	render() {
		const {locale, languages, bundle, sticker} = this.props;
		const {activeTabIndex} = this.state;

		const Header = (
			<Toolbar
				className={css.toolbar}
				title=""
				nav={<SlaveMenu languages={languages} bundle={bundle} sticker={sticker}/>}
				actions={<div className={css.logo}><img src={require('../../../../img/AIDA-Logo.svg')} alt="logo"/></div>}
				zDepth={0}
			/>
		);

		return (
			<TabsContainer
				id="tabsContainer"
				className={css.container}
				colored
				toolbar={Header}
				swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
				activeTabIndex={activeTabIndex}
				onTabChange={this.changeTabIndex}
				swipeableViewsClassName={css.swipeableViewsContainer}
			>
				<Tabs tabId={`tabs-${locale}`} mobile>
					<Tab
						key={`tab-overview-${locale}`}
						label="Zum Gewinnspiel"
						className={css.tab}
					>
						<main>
							<SlaveHeader/>
							<SlaveMain sticker={sticker} locale={locale}/>
							<StickerFooter/>
						</main>
					</Tab>
					<Tab
						key={`tab-panorama-${locale}`}
						label="Zum 360° Erlebnis"
						className={css.tab}
					>
						{activeTabIndex === 1 && (
							<main>
								<SlavePanorama/>
							</main>
						)}
					</Tab>
				</Tabs>
			</TabsContainer>
		);
	}
}

export default Slave