import React, {PureComponent} from 'react';
import _ from 'lib/lodash'
import {isIOS} from 'lib/utils';

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer} from 'components/containers'
import {TextFieldEdit} from 'components/controls'
import {PannellumVideo360Viewer} from 'components/viewers'

import css from './Video360Slave.module.scss'

class ViewComponent extends PureComponent {
	// eslint-disable-next-line
	onClickHotspot = (e, clickHandlerArgs) => {
		//this.props.actions.dispatch({type: events.EVENT_IMAGE360_HOTSPOT_CLICK, params: clickHandlerArgs});
	};

	render() {
		const {item, EditComponent, allowEdit} = this.props;
		const {properties = {}, filters = {}, storage = {}} = item;
		const {label, hotspots = []} = properties;
		const {fileUrl, thumbUrl} = storage;

		const editAllowed = allowEdit(item);

		const hotSpots = _.map(hotspots, hs => ({
			...hs,
			clickHandlerArgs: hs,
			clickHandlerFunc: this.onClickHotspot
		}));

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<div className={css.viewWrapper}>
						<div className={css.viewHeader}>
							{filters.language && <Flag className={css.flag} code={filters.language}/>}
							<div className={css.content}/>
							<div className={css.icon}>
								{editAllowed && <EditComponent/>}
							</div>
						</div>
						<div className={css.viewImage}>
							<PannellumVideo360Viewer
								id={item.$id}
								imgPath={fileUrl}
								thumbPath={thumbUrl}
								showZoomCtrl={false}
								showFullscreenCtrl={!isIOS}
								autoLoad={false}
								orientationOnByDefault
								hotSpots={hotSpots}
								customStrings={{
									loadButtonLabel: label
								}}
							/>
						</div>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}


class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties, filters} = item;
		const {label, description} = properties;
		const {language} = filters;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="title"
					label="title"
					rows={1}
					maxRows={2}
					value={{label}}
					onSubmit={this.updateProperty}
				/>
				<TextFieldEdit
					id="description"
					label="description"
					value={{description}}
					rows={1}
					maxRows={3}
					onSubmit={this.updateProperty}
				/>
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}