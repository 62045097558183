import React, {createContext, useReducer, useRef} from 'react';
import useDeepCompareEffect from './useDeepCompareEffect';
import _ from 'lib/lodash'

const APIVERSION = process.env.REACT_APP_FIREBASE_APIVERSION;
const APISUBVERSION = process.env.REACT_APP_FIREBASE_APISUBVERSION;

const setLocalStorageItem = (storageItem, data) => {
	try {
		const encodedData = Buffer.from(JSON.stringify(data), 'utf8').toString('base64');
		localStorage.setItem(storageItem, encodedData);
		return true;
	} catch (e) {
		console.error('FormStateContext: Could not set localStorage item.');
		return false;
	}
};

const readLocalStorageItem = (storageItem) => {
	try {
		const encodedUserInfo = Buffer.from(localStorage.getItem(storageItem), 'base64').toString('utf8');
		return JSON.parse(encodedUserInfo);
	} catch (e) {
		return null;
	}
};

export const FormStateProvider = ({name, profileId, bundleId, stickerId, tagId, reducer, initialState, children}) => {
	const profileSuffix = !!profileId ? "-" + profileId : "";
	const bundleSuffix = !!bundleId ? "-" + bundleId : "";
	const stickerSuffix = !!stickerId ? "-" + stickerId : "";
	const tagSuffix = !!tagId ? "-" + tagId : "";

	const storageItem = `stx-${APIVERSION}-${APISUBVERSION}-${name}${profileSuffix}${bundleSuffix}${stickerSuffix}${tagSuffix}`;
	const isLoading = useRef(true);
	let savedState;

	if (!!isLoading.current) {
		savedState = readLocalStorageItem(storageItem);
		isLoading.current = false;
	}

	const [state, dispatch] = useReducer(reducer, _.merge({}, initialState, savedState));
	const value = [state, dispatch];

	useDeepCompareEffect(
		() => {
			setLocalStorageItem(storageItem, state);
		},
		[state, storageItem]
	);

	return (
		<FormStateContext.Provider value={value}>
			{children({formState: state, dispatch})}
		</FormStateContext.Provider>
	)
};

export const FormStateContext = createContext();