export default {
	textColor: "#141412", // Rangoon Green
	hintTextColor: "rgba(0, 0, 0, 0.60)",
	disabledTextColor: "rgba(0, 0, 0, 0.38)",
	disabledColor: "#ccc",
	tertiaryColor: "#00a9ce", // Cardinal
	tertiaryTextColor: "#8b9196", // Oslo Gray
	primaryTextColor: "white",
	secondaryTextColor: "white",
	primaryBackgroundColor: "#f3f1ed", // Pampas
	secondaryBackgroundColor: "#e8e9ea", // Iron
	neutralBackgroundColor: "white",
	alertTextColor: "#c31622", // Cardinal

	/* react-md */
	primaryColor: "#1e22aa", // Wisteria
	secondaryColor: "#00a9ce" // Fountain Blue
}