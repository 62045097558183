import React from 'react'
import styled, {css} from 'styled-components/macro'

const LabelWrapper = styled.div`
	display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
	background: lightgray;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  margin: 0 0.25em;
  transform: scale(0.75);
  transition: transform 0.2s cubic-bezier(.25, .8, .25, 1), background 0.2s cubic-bezier(.25, .8, .25, 1);
  cursor: pointer;

	${({active}) => active && css`
    background: red;
    transform: scale(1);
    transition-delay: 0.2s;
  `}
`;

const Label = ({className, pageProps, activeStepIndex, moveToStep}) => (
	<LabelWrapper className={className}>
		{Array.from({length: pageProps.length}, (_, i) => <Dot active={i === activeStepIndex} onClick={() => moveToStep(i)} key={i}/>)}
	</LabelWrapper>
);

export default Label;