import React, {Component} from 'react';
import _ from 'lib/lodash'

import {master as NodeComponents} from 'components/nodes/subnodes'

class MasterSubnodeContainer extends Component {
	constructor(props) {
		super(props);
		const {type} = this.props.item;

		this.ViewComponent = _.get(NodeComponents, type, NodeComponents['title']).ViewComponent;
		this.EditComponent = _.get(NodeComponents, type, NodeComponents['title']).EditComponent;
	}

	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['item']);
	}

	render() {
		const {ViewComponent, EditComponent} = this;

		const {keyPrefix, item, actions} = this.props;
		const {$id} = item;
		const EditComponentInstance = EditComponent({keyPrefix, item, actions});

		return (
			<ViewComponent
				key={$id}
				item={item}
				actions={actions}
				EditComponent={EditComponentInstance}
			/>
		);
	}
}

export default MasterSubnodeContainer