import React, {Component} from 'react';
import _ from 'lib/lodash'

export const BasisContext = React.createContext();

export class BasisProvider extends Component {
	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['bundle', 'sticker', 'children']);
	}

	render() {
		const {bundle, sticker, children} = this.props;

		return (
			<BasisContext.Provider value={{
				bundle,
				sticker,
			}}>
				{children}
			</BasisContext.Provider>
		)
	}
}