import React from 'react';
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.at.js';
import {Button, TextField} from 'lib/react-md'
import {api} from 'services'
import {getCeilDate} from 'lib/utils'
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl} from 'components/hocs'
import {DatetimePicker, ConfirmationDialog} from 'components/controls'

import ConsentForm from './ConsentForm'
import css from './CustomAIDACallbackFormSlave.module.scss'

const CUSTOM_INFO_TYPE_ASAP = "customInfoTypeASAP";
const CUSTOM_INFO_TYPE_DATETIME = "customInfoTypeDATETIME";
const C = "profiles.aida.CustomAIDACallbackFormSlave";

class ViewComponent extends Wrappers.EditComponentWrapper {
	state = {
		...this.state, // important to get parent's state
		confirmVisible: false,
		customInfoType: "",
		name: _.get(this.props, "item.properties.name", ""),
		telno: _.get(this.props, "item.properties.telno", ""),
	};

	constructor(props) {
		super(props);
		this.cleaveTelno = React.createRef();
		this.consentForm = React.createRef();
	}

	componentDidUpdate(prevProps) {
		const prevTelno = _.get(prevProps, "item.properties.telno", "");
		const telno = _.get(this.props, "item.properties.telno", "");

		if (telno !== prevTelno) this.setState({telno});

		const prevName = _.get(prevProps, "item.properties.name", "");
		const name = _.get(this.props, "item.properties.name", "");

		if (name !== prevName) this.setState({name});
	}

	showConfirmationDialog = () => this.setState({confirmVisible: true});

	hideConfirmationDialog = (result, callback) => () => {
		const {actions} = this.props;

		this.setState({confirmVisible: false}, () => {
			callback && callback();

			const labelCancel = "Abbrechen";
			const labelOK = `Einverstanden:${!!_.get(this.consentForm.current, 'state.supplement2') ? "Für weitere Spezialangebote" : "Nur für Gewinnspiel"}`;

			actions.gtmClickEvent({
				eventAction: `button:${C}:confirmationDialog:${result ? labelOK : labelCancel}`,
				eventLabel: result ? labelOK : labelCancel
			});
		});
	};

	validateForm = () => {
		const {actions} = this.props;
		const {telno, name} = this.state;

		const message = (() => {
			if (name.length > 50) return "Bitte kürzen Sie Ihren Namen auf 50 Zeichen ab.";

			if (!telno) return "Bitte geben Sie Ihre Telefonnummer an.";
			if (telno.length > 30) return "Bitte geben Sie eine gültige Telefonnummer an.";
		})();

		if (message) {
			actions.showCustomInfo(message);
			actions.gtmValidationError({eventAction: `${C}:validateForm`, eventLabel: message});
		}

		return !message;
	};

	clearForm = () => {
		const {name, telno} = this.state;

		const changedProps = {
			telno: "",
			telno_confirmed: telno,
			name: "",
			name_confirmed: name
		};

		this.updateProperty(changedProps, true);
		this.cleaveTelno.current.setRawValue(""); // important since element does not automatically re-render
	};

	onButtonClick = customInfoType => () => {
		if (!this.validateForm()) return;

		this.setState({customInfoType}, this.showConfirmationDialog);
	};

	onPostEmail = () => {
		const {T, item, actions} = this.props;
		const {name, telno, customInfoType} = this.state;
		const bundleProps = actions.getBundleProperties();
		const {email = {}} = bundleProps; // from: {name, address}, to, cc, subject, templateId

		const id = actions.getId();
		const {properties = {}} = item;
		const {datetime} = properties;
		const supplement1 = "Der Nutzer hat seine Einwilligung zur Speicherung personenbezogener Daten für das Gewinnspiel erteilt.";
		const supplement2 = _.get(this.consentForm.current, 'state.supplement2') ? "Der Nutzer hat seine Einwilligung zur Speicherung personenbezogener Daten für weiterführende Werbezwecke erteilt." : "";

		const callback = customInfoType === CUSTOM_INFO_TYPE_ASAP
			? T("label", "custom-aida-callback-form.button.asSoonAsPossible")
			: !!datetime
				? `${datetime.toDate().toLocaleDateString("de-DE")} ${datetime.toDate().toLocaleTimeString("de-DE")}`
				: T("label", "custom-aida-callback-form.button.asSoonAsPossible");

		const params = {
			dynamic_template_data: {
				id,
				name,
				telno,
				callback,
				supplement1,
				supplement2
			},
			...email
		};

		api.auth().post("postEmail", params)
			.then(() => {
				this.clearForm();
				actions.showCustomInfo(T(C, customInfoType));
				actions.gtmCustomEvent({eventAction: "email:sent"});
			})
			.catch(actions.raiseCustomError)
	};

	onChangeName = name => this.setState({name});
	onBlurName = () => this.updateProperty({name: this.state.name}, true);

	onChangeTelnoCleave = e => this.setState({telno: e.target.rawValue});
	onBlurTelnoCleave = () => this.updateProperty({telno: this.state.telno}, true);

	render() {
		const {T, item} = this.props;
		const {properties = {}} = item;
		const {datetime} = properties;
		const {confirmVisible, name, telno} = this.state;

		const actions = [
			{
				secondary: false,
				children: "Abbrechen",
				onClick: this.hideConfirmationDialog(false)
			},
			{
				secondary: false,
				children: 'Einverstanden',
				onClick: this.hideConfirmationDialog(true, this.onPostEmail)
			},
		];

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<div className={css.title}>Wie lautet Ihr Name?</div>
					<TextField
						id="name"
						className={css.textField}
						value={name}
						placeholder="Name"
						onChange={this.onChangeName}
						onBlur={this.onBlurName}
					/>
					<br/>
					<div className={css.title}>Wie lautet Ihre Telefonnummer?</div>
					<Cleave
						ref={this.cleaveTelno}
						className={css.textField}
						type="tel"
						value={telno}
						placeholder="Telefonnummer"
						options={{phone: true, phoneRegionCode: "AT"}}
						onChange={this.onChangeTelnoCleave}
						onBlur={this.onBlurTelnoCleave}
					/>
					<br/>
					<div className={css.title}>Wann dürfen wir Sie zurückrufen?</div>
					<Button
						flat
						secondary
						swapTheming
						className={css.button}
						onClick={this.onButtonClick(CUSTOM_INFO_TYPE_ASAP)}
						data-gtm-action={`button:${C}`}
						data-gtm-label="So bald wie möglich"
					>
						{T("label", "custom-aida-callback-form.button.asSoonAsPossible")}
					</Button>
					<div style={{height: "16px"}}/>
					<DatetimePicker
						id="date"
						className={css.dateTimePicker}
						label=""
						value={{datetime}}
						step={30}
						min={getCeilDate(30)}
						onSubmit={value => this.updateProperty(value, true)}
					/>
					<Button
						flat
						secondary
						swapTheming
						className={css.button}
						onClick={this.onButtonClick(CUSTOM_INFO_TYPE_DATETIME)}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Zum angegebenen Zeitpunkt"
					>
						{T("label", "custom-aida-callback-form.button.atSpecificDateTime")}
					</Button>
					<ConfirmationDialog
						id="data-protection-confirmation"
						className={css.confirmationDialog}
						visible={confirmVisible}
						title="Einwilligung"
						actions={actions}
					>
						Ich möchte unter <strong>{telno}</strong> für folgende Verarbeitungen personenbezogener Daten gemäß EU-DSGVO
						kontaktiert werden:
						<ConsentForm ref={this.consentForm}/>
					</ConfirmationDialog>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ViewComponent: withIntl(ViewComponent),
	EditComponent: () => null
}