import React from 'react'
import styled from 'styled-components/macro'

import {FontIcon} from 'lib/react-md'

export const StepperWrapper = styled.ul`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 0;
	padding: 0;
	
	list-style-type: none;
	text-indent: 0;
`;

export const StepWrapper = styled.li`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	i {
		color: inherit; 
		font-size: xx-large;
	}
`;

const IconStepper = ({
	                     className,
	                     pageProps,
	                     activeStepIndex,
	                     maxActivatedStepIndex,
	                     moveToStep,
	                     StepWrapperComponent = StepWrapper,
	                     restrictTouchToVisited = true,
	                     disableOnClick = false,
	                     ...wizardProps
                     }) => (
	<StepperWrapper className={className}>
		{pageProps.map(({title, icon}, idx) => {
			const active = idx === activeStepIndex;
			const visited = idx <= Math.max(maxActivatedStepIndex, activeStepIndex);
			const onClick = idx => () => (restrictTouchToVisited ? visited : true) ? moveToStep(idx) : null;

			return (
				<StepWrapperComponent
					key={idx}
					active={active}
					visited={visited}
					onClick={!disableOnClick ? onClick(idx) : () => null}
					icon={icon}
				>
					<>
					{icon && <FontIcon>{icon}</FontIcon>}
					</>
				</StepWrapperComponent>
			);
		})}
	</StepperWrapper>
);

export default IconStepper;