import React from 'react';

import {withIntlCookieInfo} from 'components/hocs'
import DefaultPortal from 'components/templates/portal/DefaultPortal'
import PortalMenu from './PortalMenu'

import '../../theme-helvetia.scss'

const C = "profiles.demo-sc.portals.default";

const Portal = ({T, languages, profileId}) => (
	<DefaultPortal
		profileId={profileId}
		length={9}
		options={{blocks: [3, 3, 3], delimiter: ' ', uppercase: true}}
		logoPath="profiles/demo-smartconcierge19/img/concierge-svgrepo-com.svg"
		logoWidth={70}
		helpImagePath="profiles/helvetia-ch/img/help.svg"
		title={T(C, "title")}
		textCamera={T(C, "textCamera")}
		textNoCamera={T(C, "textNoCamera")}
		Menu={<PortalMenu languages={languages}/>}
		theme="theme-helvetia"
	/>
);

export default withIntlCookieInfo(Portal, {C: "CookieInfo.Portal", profileId: true});