import React from 'react'

import DefaultMaster from 'components/templates/sticker/master/DefaultMaster'
import {withIntlCookieInfo} from 'components/hocs'

import ActionsMenu from 'profiles/default/stickers/default/roles/master/ActionsMenu'
import '../../../../theme-folder.scss'

import Slave from '../slave-a/Slave';

const Master = props => (
	<DefaultMaster
		bundle={props.bundle}
		sticker={props.sticker}
		ActionsMenu={ActionsMenu}
		StickerMenu={() => null}
		theme="theme-folder"
		SlaveComponent={Slave}
		/*styles={styles}*/
		{...props}
	/>
);

export default withIntlCookieInfo(Master);