import {loadingActions} from 'components/handlers/actions'

export const loadingMiddleware = store => next => action => {
	next(action);

	if (!action.type.endsWith('_LOADING')) return;

	const {showLoading, hideLoading, updateLoading} = loadingActions;

	// TODO: Loading progress bar showing theme background color, which cannot be found

	if (action.type.endsWith('SHOW_LOADING')) return store.dispatch(showLoading(action.loader));

	if (action.type.endsWith('HIDE_LOADING')) return store.dispatch(hideLoading());

	if (action.type.endsWith('UPDATE_LOADING')) return store.dispatch(updateLoading(action));
};