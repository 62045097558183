import {useState, useEffect} from 'react'

let images = {};

const useImage = (id, url, status, crossOrigin) => {
	const cachedImage = images[id];
	const isLoaded = !!cachedImage;

	const [loadState, setLoadState] = useState({
		image: isLoaded ? cachedImage : undefined,
		status: isLoaded ? 'loaded' : 'loading'
	});

	useEffect(() => {
			if (status === "THUMBNAIL_ERROR") {
				setLoadState({image: undefined, status: 'failed'});
			} else if (!isLoaded && !!url) {
				const img = new Image();

				const onLoad = () => {
					images[id] = img;
					setLoadState({image: img, status: 'loaded'});
				};
				const onError = () => setLoadState({image: undefined, status: 'failed'});

				img.addEventListener('load', onLoad);
				img.addEventListener('error', onError);
				img.src = url;
				img.crossOrigin = crossOrigin || undefined;

				return () => {
					img.removeEventListener('load', onLoad);
					img.removeEventListener('error', onError);
				};
			}
		},
		[id, isLoaded, url, status, crossOrigin]
	);

	return [loadState.image, loadState.status];
};

export default useImage;