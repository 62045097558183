import React, {PureComponent} from 'react';
import {SelectField} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'

import css from './SelectFieldEdit.module.scss'

class SelectFieldEdit extends PureComponent {
	static defaultProps = {
		id: "",
		label: "",
		value: {value: ""},
		onSubmit: _.noop
	};

	onChange = key => value => this.props.onSubmit({[key]: value});

	render() {
		const {T, FormattedMessage, intl, label, floatingLabel = true, value, menuItemLabels, menuItemValues, ...otherProps} = this.props;

		const key = Object.keys(value)[0];
		const val = value[key];

		const labels = T("menuItems", menuItemLabels).split(";");
		const values = T("menuItems", menuItemValues).split(";");
		//const secondaryText = T("menuItems", menuItemSecondaryText).split(";");

		const menuItems = _.map(labels, (label, idx) => ({
			label,
			value: values[idx], props: () => {
			}
		}));

		//const menuItems = _.map(labels, (label, idx) => ({label, value: values[idx], props: () => secondaryText[idx] && ({secondaryText: secondaryText[idx]})}));
		//const menuItems = _.map(labels, (label, idx) => ({label, value: values[idx], props: () => ({threeLines: true})}));

		const placeholder = _.get(menuItems, "[0].label", "");

		return (
			<div className={css.container}>
				{!floatingLabel && <label>{T("label", label)}</label>}
				<SelectField
					label={floatingLabel ? T("label", label) : undefined}
					placeholder={floatingLabel ? undefined : placeholder}
					itemLabel="label"
					itemValue="value"
					itemProps="props"
					menuItems={menuItems}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={true}
					value={val}
					onChange={this.onChange(key)}
					fullWidth
					{...otherProps}
				/>
			</div>
		);
	}
}

export default withIntl(SelectFieldEdit);