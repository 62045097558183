import React, {Component} from 'react';
import PropTypes from 'prop-types'
import _ from 'lib/lodash'

import {withDraggableList} from 'components/hocs'
import {slave as NodeComponents} from 'components/nodes/nodes'
import SlaveSubnodeContainer from './SlaveSubnodeContainer'

const DraggableSubnodesList = withDraggableList({ListItemComponent: SlaveSubnodeContainer});

class SlaveNodeContainer extends Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		subnodes: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
	};

	state = {
		item: {},
		subitems: []
	};

	static filterSubitems = (subitems, subnodeFilters) => {
		return _.filter(subitems, subitem => {
			const language = _.get(subitem.filters, 'language', "");
			const stickers = _.get(subitem.filters, 'stickers', []); // check if stickerId in array of ids
			const exclude = _.get(subitem.filters, 'exclude', false);

			return _.isEmpty(subnodeFilters) || (
				(!language || language === subnodeFilters.language) &&
				(_.isEmpty(stickers) || _.includes(stickers, subnodeFilters.stickerId)) &&
				!exclude
			);
		});
	};

	static getDerivedStateFromProps(nextProps) {
		const {item, subnodeFilters = {}} = nextProps;

		// first, look for slave target, then proceed to master. important for feedback-collection (master: public, slave: private)
		const target = _.get(item, 'targets.slave') || _.get(item, 'targets.master');
		const subitems = _.get(nextProps, `subnodes.[${target}][${item.$id}].items`, []);
		const filteredSubitems = SlaveNodeContainer.filterSubitems(subitems, subnodeFilters);

		// update subnodeCount with filtered elements
		return ({item: {...nextProps.item, $subnodeCount: _.size(filteredSubitems)}, subitems: filteredSubitems});
	}

	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.state, ['item', 'subitems']);
	}

	render() {
		const {ContainerComponent, HeaderComponent, ViewComponent} = _.get(NodeComponents, this.state.item.type, NodeComponents['text-collection']);
		//const {ViewComponent, EditComponent} = _.get(NodeComponents, this.props.item.type, {ViewComponent: null, EditComponent: null});
		if (!ContainerComponent || !HeaderComponent || !ViewComponent) return <div/>;

		const {keyPrefix, actions, nodeFilters = {}, subnodeFilters = {}, isUploading, ...otherProps} = this.props;
		const {item, subitems} = this.state;
		const {$id, targets = {}, filters = {}} = item;
		const masterTarget = _.get(targets, 'master');
		const target = _.get(targets, 'slave');

		// check if section filters are set
		if (_.isSet(filters) && !!filters.section && !_.includes(nodeFilters, filters.section)) return <div/>;
		if (_.isSet(nodeFilters) && !_.includes(nodeFilters, filters.section)) return <div/>;

		// define rules for adding, editing and dragging
		const allowEdit = false;
		const allowSubnodeAdd = ((masterTarget === "private" && target === "private") || target === "shared") && !isUploading;
		const allowSubnodeEdit = subnode => target === "private" || (target === "shared" && subnode.stickerId === subnodeFilters.stickerId);
		const allowSubnodeDrag = masterTarget === "private" && target === "private";

		const ListItemContent = [
			<ViewComponent
				key={`${keyPrefix}-${$id}`}
				item={item}
				subitems={subitems}
				actions={actions}
				allowAdd={allowSubnodeAdd}
				allowEdit={allowSubnodeEdit}
				{...otherProps}
			>
				<DraggableSubnodesList
					items={subitems}
					actions={actions}
					keyPrefix={keyPrefix}
					onDragEnd={actions.updateNodesOrder}
					allowEdit={allowSubnodeEdit}
					disabled={!allowSubnodeDrag}
				/>
			</ViewComponent>
		];

		return (
			<ContainerComponent
				key={$id}
				item={item}
				subitems={subitems}
				actions={actions}
				allowEdit={allowEdit}
				HeaderComponent={HeaderComponent}
				ListItemContent={ListItemContent}
				EditComponent={null}
				visible
			/>
		);
	}
}

export default SlaveNodeContainer