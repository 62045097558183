/* imports */
import {CS} from 'lib/constants' // claims status flag
import {auth, api} from 'services'
import _ from 'lib/lodash'

import {gtm} from 'components/events'

/* action types */
export const types = {
	AUTH_INIT_OBSERVER: 'AUTH/INIT_OBSERVER',

	AUTH_USER_CLAIMS_RECEIVED: 'AUTH/USER_CLAIMS_RECEIVED',
	AUTH_USER_CLAIMS_VALID: 'AUTH/USER_CLAIMS_VALID',
	AUTH_USER_CLAIMS_INVALID: 'AUTH/USER_CLAIMS_INVALID',
	AUTH_USER_CLAIMS_ERROR: 'AUTH/USER_CLAIMS_ERROR',

	AUTH_SHOW_LOADING: 'AUTH/SHOW_LOADING',
	AUTH_UPDATE_LOADING: 'AUTH/UPDATE_LOADING',

	AUTH_FLAGS_LOADED: 'AUTH/FLAGS_LOADED',

	AUTH_RESET_STATE: 'AUTH/RESET_STATE',
};

/* initial state */
const initialState = {
	authObserver: null,
	userCS: CS.INIT,
	userClaims: {},
	customClaims: {},
	idToken: "",
	authError: null,
	profileId: "",
	languages: null,
	portal: null,
	bundle: null,
	sticker: null,
	tag: null
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.AUTH_INIT_OBSERVER:
			return {...state, authObserver: action.authObserver};

		case types.AUTH_USER_CLAIMS_RECEIVED:
			return {
				...state,
				userClaims: action.userClaims,
				customClaims: action.customClaims,
				idToken: action.idToken,
				userCS: CS.RECEIVED
			};

		case types.AUTH_USER_CLAIMS_VALID:
			return {
				...state,
				userCS: CS.VALID,
				profileId: action.profileId,
				languages: action.languages,
				portal: action.portal,
				bundle: action.bundle,
				sticker: action.sticker,
				tag: action.tag
			};

		case types.AUTH_USER_CLAIMS_INVALID:
			return {...state, userClaims: {}, customClaims: {}, userCS: CS.INVALID};

		case types.AUTH_USER_CLAIMS_ERROR:
			return {...state, userClaims: {}, customClaims: {}, userCS: CS.ERROR, authError: action.error};

		case types.AUTH_RESET_STATE:
			return {...initialState, userCS: CS.INVALID};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	initAuthObserver: () => dispatch => {
		let noErrors = 0;

		const authObserver = auth.module.onIdTokenChanged(user => {
			if (user) { // User is signed in or token was refreshed.
				user.getIdTokenResult().then(
					token => {
						const idToken = _.get(token, 'token', "");
						const userClaims = _.get(token, 'claims', {});
						const customClaims = auth.getStoredTokenClaims(userClaims);

						if (!!customClaims) {
							// initialize API
							api.initAuth(idToken);
							dispatch({type: types.AUTH_USER_CLAIMS_RECEIVED, userClaims, customClaims, idToken});
						} else {
							noErrors++;
							if (noErrors === 5) {
								dispatch({type: types.AUTH_USER_CLAIMS_ERROR, error: new Error('Login cancelled after 5 failed attempts.')});
							} else if (noErrors < 5) {
								dispatch({type: types.AUTH_USER_CLAIMS_INVALID});
							}
						}
					},
					error => dispatch({type: types.AUTH_USER_CLAIMS_ERROR, error})
				);
			} else {
				dispatch({type: types.AUTH_USER_CLAIMS_INVALID});
			}
		});

		dispatch({type: types.AUTH_INIT_OBSERVER, authObserver})
	},

	showAuthLoader: loader => ({type: types.AUTH_SHOW_LOADING, loader}),

	checkIfClaimsValid: (matchParams = {}, userClaims = {}, customClaims = {}) => dispatch => {
		const {portalId: matchPortalId = "", tagId: matchTagId = ""} = matchParams;
		const {portalId = "", tagId = ""} = userClaims;
		const {profileId = "", languages = {}, portal = {}, bundle = {}, sticker = {}, tag = {}} = customClaims;

		// allow sticker login also for portal page
		if ((!!matchPortalId && (matchPortalId !== portalId)) || (!!matchTagId && (matchTagId !== tagId))) {
			dispatch({type: types.AUTH_USER_CLAIMS_INVALID});
		} else {
			dispatch({type: types.AUTH_UPDATE_LOADING, addRel: 0.3});

			if (!!matchPortalId && !matchTagId) {
				// valid portal
				dispatch({type: types.AUTH_USER_CLAIMS_VALID, profileId, languages, portal});
				dispatch({type: gtm.GTM_EVENT_PORTAL_LOADED, portalId, portal})
			}
			if (!!matchPortalId && !!matchTagId) {
				// valid sticker
				dispatch({type: types.AUTH_USER_CLAIMS_VALID, profileId, languages, portal, bundle, sticker, tag});
				dispatch({type: gtm.GTM_EVENT_STICKER_LOADED, portalId, portal, bundle, sticker, tag})
			}
		}
	},

	signIn: matchParams => dispatch => {
		auth.signIn(matchParams).catch(error => dispatch({type: types.AUTH_USER_CLAIMS_ERROR, error}))
	},

	resetAuthHandler: () => ({type: types.AUTH_RESET_STATE})
};