import React from 'react'

import {useFormState} from 'components/hooks';
import FormContainer from 'components/forms/FormContainer';

import FormCollisionCar from './Car/FormCollision'
import FormCollisionPerson from './Person/FormCollision'
import FormCollisionAnimal from './Animal/FormCollision'
import FormCollisionObject from './Object/FormCollision'

const PageCollision = ({substep, cancel, onSubmit, actions, item, images, onClearForm}) => {
	const [{subcategory}] = useFormState();
	const formProps = {substep, cancel, actions, item, images, onClearForm};

	return (
		<>
			{subcategory === "car" && <FormContainer onSubmit={onSubmit} FormComponent={FormCollisionCar(formProps)}/>}
			{subcategory === "person" && <FormContainer onSubmit={onSubmit} FormComponent={FormCollisionPerson(formProps)}/>}
			{subcategory === "animal" && <FormContainer onSubmit={onSubmit} FormComponent={FormCollisionAnimal(formProps)}/>}
			{subcategory === "object" && <FormContainer onSubmit={onSubmit} FormComponent={FormCollisionObject(formProps)}/>}
		</>
	);
};

export default PageCollision;