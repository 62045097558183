/* imports */
import filesize from 'filesize'
import _ from 'lib/lodash'

import {db} from 'services'

/* action creators */
export const actions = {

	createFileNode: (payload, nodeType, file, properties, cb) => {
		const constraintValidationError = () => {
			/* check for valid file type */
			const typeConstraints = _.get(payload, `constraints[${nodeType}]`);

			if (!typeConstraints || !typeConstraints.contentTypes || typeConstraints.contentTypes.length === 0) return null;
			if (!typeConstraints.contentTypes.includes(file.type)) {
				return new Error(`Upload nicht möglich. Erlaubte Formate: ${typeConstraints.contentTypes.join(', ')}`);
			}

			/* check for storage size constraint */
			const maxStorageSize = _.get(payload, 'constraints.maxStorageSize');
			if (!maxStorageSize) return null;

			const payloadStorageSize = _.get(payload, 'statistics.storageSize') || 0;
			const totalSizeAvailable = maxStorageSize - payloadStorageSize;
			if (file.size > totalSizeAvailable) {
				return totalSizeAvailable > 0
					? new Error(`Die Datei ist leider zu groß. Ihnen stehen auf diesem Sticker noch ${filesize(totalSizeAvailable)} zur Verfügung.`)
					: new Error(`Die Datei kann nicht hochgeladen werden, da kein Speicherplatz mehr verfügbar ist.`);
			}
			return null;
		};

		return dispatch => {
			const constraintError = constraintValidationError();
			if (!constraintError) {
				const tempNode = {
					$type: nodeType,
					$path: `${payload.$path}/nodes/${nodeType}`,
					contentType: file.type,
					creationDate: (new Date()).toISOString(),
					properties
				};
				dispatch(actions.createFileNodeSuccess(tempNode));
				dispatch(actions.postNodeAndFile(payload, tempNode, file, cb));
			} else {
				dispatch(actions.createFileNodeError(constraintError));
			}
		}
	},

	postNode: (payload, nodeType, node, cb) => dispatch => {
		const tempNode = {
			...node,
			$path: `${payload.$path}/nodes/${nodeType}`,
			creationDate: (new Date()).toISOString(),
		};
		return db.postNode(payload, tempNode)
			.then((newNode) => {
				dispatch(actions.postNodeSuccess(newNode));
				cb(newNode);
			})
			.catch(error => dispatch(actions.postNodeError(error)));
	},
};
