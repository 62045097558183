import React, {Component} from 'react';
import _ from 'lib/lodash'

import icons from 'components/nodes/icons'
import {Skeleton, ImagePlaceholder} from 'components/utils'
import {withIntl} from 'components/hocs'

const C = "ThumbnailLoader";

class ThumbnailLoader extends Component {
	state = {
		isLoading: true,
		loadingSuccess: false,
		loadingError: null,
	};

	constructor() {
		super();
		this.image = new Image();
	}

	componentDidMount() {
		this.loadImage();
	}

	shouldComponentUpdate(nextProps, nextState) {
		// check for whole item, not just item.storage since PannellumViewer changes are not triggered otherwise
		return _.anyDiff(nextProps, this.props, ['item']) || _.anyDiff(nextState, this.state, ['loadingSuccess', 'loadingError']);
	}

	componentDidUpdate() {
		const {loadingSuccess} = this.state;
		const {thumbUrl} = this.props.item.storage;

		if (!loadingSuccess && !!thumbUrl) this.loadImage();
	}

	componentWillUnmount() {
		const {image} = this;
		// reset handlers, otherwise get triggered again once image is unmounted
		image.onload = null;
		image.onerror = null;
	}

	loadImage = () => {
		const {item} = this.props;
		const {thumbUrl} = item.storage;
		const {image} = this;

		if (!!image.src) return; // image loading process already started

		if (!!thumbUrl) {
			image.onload = () => this.setState({isLoading: false, loadingSuccess: true, loadingError: null});
			image.onerror = () => this.setState({isLoading: false, loadingSuccess: false, loadingError: "noPreviewAvailable"});
			image.src = thumbUrl;
		} else {
			//this.setState({isLoading: false, loadingSuccess: false, loadingError: new Error(T(C, "noPreviewAvailable"))})
		}
	};


	render() {
		const {T, item, height = 180, children} = this.props;
		const {storage = {}} = item;
		const {status, error: statusError} = storage;
		const {isLoading, loadingSuccess, loadingError} = this.state;

		const icon = icons[item.type];

		// no storage section, therefore no thumbnail
		if (!_.has(storage, 'thumbUrl')) return <ImagePlaceholder icon={icon} title={T(C, "THUMBNAIL_ERROR")}/>;

		// when resource gets added
		// TODO: unfortunately also called when thumbnail is never produced or thumbnail creator is not executed
		//if (storage.thumbUrl === "") return <Skeleton height={height} text={{section: C, id: "THUMBNAIL_INIT"}}/>;

		// when thumbnail is available and needs to be loaded
		if (isLoading) return <Skeleton height={height} text={{section: C, id: status || "THUMBNAIL_LOADING"}}/>;

		// error during loading, e.g. URL not valid anymore or server unavailable
		if (!!loadingError) return <ImagePlaceholder icon={icon} title={T(C, loadingError)}/>;

		// conversion error or error during thumbnail generation
		if (!!statusError) return <ImagePlaceholder icon={icon} title={T(C, status)}/>;

		// in case of success, render children
		if (loadingSuccess) return children;

		return null;
	}
}

export default withIntl(ThumbnailLoader);
