import AudioCollectionFactory from './audio-collection/AudioCollectionFactory'
import ButtonCollectionFactory from './button-collection/ButtonCollectionFactory'
import ContactCollectionFactory from './contact-collection/ContactCollectionFactory'
import DocumentCollectionFactory from './document-collection/DocumentCollectionFactory'
import FeedbackCollectionFactory from './feedback-collection/FeedbackCollectionFactory'
import ImageCollectionFactory from './image-collection/ImageCollectionFactory'
import Image360CollectionFactory from './image360-collection/Image360CollectionFactory'
import LinkCollectionFactory from './link-collection/LinkCollectionFactory'
import PostCollectionFactory from './post-collection/PostCollectionFactory'
import TextCollectionFactory from './text-collection/TextCollectionFactory'
import VideoCollectionFactory from './video-collection/VideoCollectionFactory'
import Video360CollectionFactory from './video360-collection/Video360CollectionFactory'
import CustomCollectionFactory from './custom-collection/CustomCollectionFactory'

import AudioFactory from './audio/AudioFactory'
import ButtonFactory from './button/ButtonFactory'
import ContactFactory from './contact/ContactFactory'
import DocumentFactory from './document/DocumentFactory'
import FeedbackFactory from './feedback/FeedbackFactory'
import ImageFactory from './image/ImageFactory'
import Image360Factory from './image360/Image360Factory'
import LinkFactory from './link/LinkFactory'
import PostFactory from './post/PostFactory'
import TextFactory from './text/TextFactory'
import VideoFactory from './video/VideoFactory'
import Video360Factory from './video360/Video360Factory'
import CustomFactory from './custom/CustomFactory'

// custom factories
// HVCH
import CustomHVCHButtonAssistanceFactory
	from 'profiles/helvetia-ch/nodes/custom-hvch-button-assistance/CustomHVCHButtonAssistanceFactory'
import CustomHVCHButtonAssistanceCollectionFactory
	from 'profiles/helvetia-ch/nodes/custom-hvch-button-assistance-collection/CustomHVCHButtonAssistanceCollectionFactory'
import CustomHVCHClaimFactory
	from 'profiles/helvetia-ch/nodes/custom-hvch-claim/CustomHVCHClaimFactory'
import CustomHVCHClaimCollectionFactory
	from 'profiles/helvetia-ch/nodes/custom-hvch-claim-collection/CustomHVCHClaimCollectionFactory'
// AIDA
import CustomAIDACallbackFormFactory
	from 'profiles/aida/nodes/custom-aida-callback-form/CustomAIDACallbackFormFactory'
import CustomAIDACallbackFormCollectionFactory
	from 'profiles/aida/nodes/custom-aida-callback-form-collection/CustomAIDACallbackFormCollectionFactory'

export {
	AudioCollectionFactory,
	ButtonCollectionFactory,
	ContactCollectionFactory,
	DocumentCollectionFactory,
	FeedbackCollectionFactory,
	ImageCollectionFactory,
	Image360CollectionFactory,
	LinkCollectionFactory,
	PostCollectionFactory,
	TextCollectionFactory,
	VideoCollectionFactory,
	Video360CollectionFactory,
	CustomCollectionFactory,

	AudioFactory,
	ButtonFactory,
	ContactFactory,
	DocumentFactory,
	FeedbackFactory,
	ImageFactory,
	Image360Factory,
	LinkFactory,
	PostFactory,
	TextFactory,
	VideoFactory,
	Video360Factory,
	CustomFactory,

	CustomHVCHButtonAssistanceFactory,
	CustomHVCHButtonAssistanceCollectionFactory,
	CustomHVCHClaimFactory,
	CustomHVCHClaimCollectionFactory,

	CustomAIDACallbackFormFactory,
	CustomAIDACallbackFormCollectionFactory
}