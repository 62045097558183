import React from 'react';

import {withIntl} from 'components/hocs'

import css from './SlaveFooter.module.scss'

//const C = "profiles.default.SlaveFooter";

const SlaveFooter = ({T}) => {
	return (
		<div className={css.container}>
		</div>
	);
};

export default withIntl(SlaveFooter);