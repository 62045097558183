import React from 'react'
import {Field, Form} from 'formik';
import {StyledFormListbox, StyledFormTextField, StyledImagesUploader} from '../../../../controls';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import _ from 'lib/lodash'

const C = "profiles.folder.PageService";

const PageWrapper = styled.div`
	padding: 16px; 
`;

export const ServiceForm = ({actions, item, images, onChanged}) => ({T, formProps: {values, submitForm, setFieldValue}}) => {
	const {devices} = actions.getStickerProperties();
	const items = _.map(devices, device => ({label: device, value: device}));

	return (
		<PageWrapper>
			<Form>
				<Field
					type="text"
					name="details"
					label={T(C, "claim.details.label")}
					placeholder={T(C, "placeholder")}
					component={StyledFormListbox}
					menuItems={items}
					fullWidth
					sameWidth
					stripActiveItem
					onChanged={value => onChanged({...values, ...value})}
				/>
				<Field
					type="text"
					name="comments"
					label={T(C, "claim.comments.label")}
					placeholder={T(C, "placeholder")}
					component={StyledFormTextField}
					rows={3}
					onChanged={value => onChanged({...values, ...value})}
				/>
				<Field
					name="images_damage"
					label={T(C, "claim.images.label")}
					actions={actions}
					item={item}
					images={images}
					tag="damage"
					component={StyledImagesUploader}
				/>
			</Form>
		</PageWrapper>
	);
};

export const validationSchema = Yup.object().shape({
	details: Yup.string().required("Bitte wählen Sie die passende Kategorie."),
});