import PageService from './PageService/PageService'
import PageContact from './PageContact/PageContact'
import PageInactive from './PageInactive/PageInactive'
import PageMain from './PageMain/PageMain'

export {
	PageService,
	PageContact,
	PageInactive,
	PageMain
}