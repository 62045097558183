import React from 'react'

import FormContainer from 'components/forms/FormContainer';
import FormGlass from './FormGlass'

const PageGlass = ({substep, cancel, onSubmit, actions, item, images, onClearForm}) => {
	const FormComponent = FormGlass({substep, cancel, actions, item, images, onClearForm});

	return (
		<FormContainer
			onSubmit={onSubmit}
			FormComponent={FormComponent}/>
	);
};

export default PageGlass;