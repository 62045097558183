import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import _ from 'lib/lodash'

class Iframe extends PureComponent {
	render() {
		const props = {
			id: this.props.id ? this.props.id : "",
			className: this.props.className ? this.props.className : "",
			ref: "iframe",
			frameBorder: "0",
			src: this.props.url,
			target: "_parent",
			allowFullScreen: this.props.allowFullScreen || false,
			name: this.props.name || "",
			height: this.props.height || "100%",
			width: this.props.width || "100%",
			position: this.props.position || "absolute",
			display: this.props.display || "block",
			onLoad: this.props.onLoad || _.noop
		};

		return React.createElement("iframe", props)
	}
}

Iframe.propTypes = {
	url: PropTypes.string.isRequired,
	id: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
	position: PropTypes.string,
	display: PropTypes.string,
	name: PropTypes.string,
	height: PropTypes.string,
	onLoad: PropTypes.func,
	styles: PropTypes.object,
	allowFullScreen: PropTypes.bool
};

export default Iframe