import React, {Component} from 'react';
import {connect} from 'react-redux'
import _ from 'lib/lodash'

import {layoutActions} from 'components/handlers/actions'
import DrawersContainer from './DrawersContainer'
import css from './LayoutContainer.module.scss'

class LayoutContainer extends Component {

	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['visibles', 'children']);
	}

	render() {
		const {theme, drawers, visibles, onVisibilityChange, children} = this.props;

		return (
			<div className={theme} style={{height: "100%"}}>
				<DrawersContainer
					drawers={drawers}
					visibles={visibles}
					onVisibilityChange={onVisibilityChange}
				/>
				<div className={css.container}>
					{children}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({layoutHandler}) => layoutHandler;

export default connect(mapStateToProps, layoutActions)(LayoutContainer);