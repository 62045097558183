import React, {PureComponent} from 'react';
import vCard from 'vcards-js'

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Avatar, Button, Card, CardActions, CardTitle, CardText, FontIcon} from 'lib/react-md'
import {TextFieldEdit} from 'components/controls'

import css from './ContactSlave.module.scss'

const createVCard = properties => {
	const {
		namePrefix = "",
		firstName = "",
		lastName = "",
		organization = "",
		role = "",
		workPhone = "",
		workEmail = "",
		workUrl = "",
		workAddress_street = "",
		workAddress_city = "",
		workAddress_countryRegion = "",
		workAddress_postalCode = "",
	} = properties;

	const vc = vCard();

	vc.namePrefix = namePrefix;
	vc.firstName = firstName;
	vc.lastName = lastName;
	vc.organization = organization;

	vc.workAddress.street = workAddress_street;
	vc.workAddress.city = workAddress_city;
	vc.workAddress.postalCode = workAddress_postalCode;
	vc.workAddress.countryRegion = workAddress_countryRegion;

	vc.workPhone = workPhone;
	vc.role = role;
	vc.workEmail = workEmail;
	vc.workUrl = workUrl;

	return vc.getFormattedString();
};

const addToContacts = properties => () => {
	const {firstName, lastName} = properties;
	const vcardbase64 = btoa(unescape(encodeURIComponent(createVCard(properties))));

	const link = document.createElement('a');
	const uri = `data:text/vcard;base64,${vcardbase64}`;
	const filename = `${firstName} ${lastName}.vcf`;

	if (typeof link.download === 'string') {
		link.href = uri;
		link.download = filename;

		document.body.appendChild(link); // Firefox requires the link to be in the body
		link.click(); // simulate click
		document.body.removeChild(link); // remove the link when done
	} else {
		window.open(uri, "_self");
	}
};

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent, allowEdit} = this.props;
		const editAllowed = allowEdit(item);

		const {properties = {}} = item;
		var {
			namePrefix = "",
			firstName = "",
			lastName = "",
			organization = "",
			role = "",
			workPhone = "",
			workEmail = "",
			workUrl = "",
			workAddress_street = "",
			workAddress_city = "",
			workAddress_countryRegion = "",
			workAddress_postalCode = "",
		} = properties;

		const label = (namePrefix + " " + firstName + " " + lastName).trim();
		const address = (workAddress_countryRegion + " " + workAddress_postalCode + " " + workAddress_city).trim();

		const callPhone = () => window.location.href = `tel:${workPhone.replace(/\s/g, '')}`;
		const sendEmail = () => window.location.href = `mailto:${workEmail}`;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<Card className={css.card}>
						<CardTitle
							avatar={<Avatar icon={<FontIcon>person</FontIcon>} suffix="amber" role="presentation" />}
							title={label}
							subtitle={role}
						>
						</CardTitle>
						<CardText>
							{organization && <div>{organization}</div>}
							{workAddress_street && <div>{workAddress_street}</div>}
							{address && <div>{address}</div>}
							{workUrl && <br/>}
							{workUrl && <a href={workUrl}>{workUrl}</a>}
						</CardText>
						<CardActions>
							{editAllowed && <div className={css.editButton}><EditComponent/></div>}
							{workPhone && <Button icon onClick={callPhone}>phone</Button>}
							{workEmail && <Button icon onClick={sendEmail}>email</Button>}
							{label && <Button icon onClick={addToContacts(properties)}>get_app</Button>}
						</CardActions>
					</Card>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}


class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties = {}} = item;
		const {
			namePrefix = "",
			firstName = "",
			lastName = "",
			organization = "",
			role = "",
			workPhone = "",
			workEmail = "",
			workUrl = "",
			note = "",
			workAddress_street = "",
			workAddress_city = "",
			workAddress_countryRegion = "",
			workAddress_postalCode = "",
		} = properties;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit id="namePrefix" label="contact.namePrefix" value={{namePrefix}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="firstName" label="contact.firstName" value={{firstName}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="lastName" label="contact.lastName" value={{lastName}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="organization" label="contact.organization" value={{organization}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="role" label="contact.role" value={{role}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workPhone" label="contact.workPhone" value={{workPhone}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workEmail" label="contact.workEmail" value={{workEmail}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workUrl" label="contact.workUrl" value={{workUrl}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_street" label="contact.workAddress_street" value={{workAddress_street}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_countryRegion" label="contact.workAddress_countryRegion" value={{workAddress_countryRegion}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_postalCode" label="contact.workAddress_postalCode" value={{workAddress_postalCode}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_city" label="contact.workAddress_city" value={{workAddress_city}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="note" label="contact.note" value={{note}} onSubmit={this.updateProperty}/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}