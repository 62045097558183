import React from 'react'
import Dropzone from 'react-dropzone'
import {FontIcon} from 'lib/react-md'

export default ({accept, onDrop}) => (
	<Dropzone
		accept={accept}
		onDrop={onDrop}
		multiple={false}
	>
		{({getRootProps, getInputProps}) =>
			<div
				{...getRootProps()}
				className="styles-dropzone"
			>
				<input {...getInputProps()}/>
				<FontIcon>add</FontIcon>
			</div>
		}
	</Dropzone>
)