export default ({stickerId, order = 1000}) => ({
	type: "contact",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		label: "",
		firstName: "",
		lastName: "",
		namePrefix: "",
		note: "",
		organization: "",
		role: "",
		version: "3.0",
		workAddress_street: "",
		workAddress_city: "",
		workAddress_countryRegion: "",
		workAddress_postalCode: "",
		workEmail: "",
		workPhone: "",
		workUrl: "",
	}
})