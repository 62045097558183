import styled from 'styled-components/macro'

import Label from 'components/controls/wizard/Labels/SimpleLabel'

const ThemedSimpleLabel = styled(Label).attrs(({theme}) => ({
	color: theme.primaryTextColor,
	backgroundColor: theme.primaryColor,
}))`
	padding: 0 16px 8px 16px;
	justify-content: flex-start;
	color: ${({color}) => color};
	background-color: ${({backgroundColor}) => backgroundColor};
	font-size: 16px;
	
	@media (max-height: 400px) {
		padding-top: 8px;
	}
`;

export default ThemedSimpleLabel;