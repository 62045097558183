import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl, withEditDrawer} from 'components/hocs'
import {AddItemDropzone, FooterInfo} from 'components/utils'
import {TextFieldEdit} from 'components/controls'
import {DocumentFactory} from 'components/nodes/factories'

import css from './DocumentCollectionMaster.module.scss'

class ViewComponent extends PureComponent {
	onDrop = acceptedFiles => {
		const {item, actions} = this.props;

		_.forEach(acceptedFiles, file => actions.postSubnodeAndFile(item, "master", DocumentFactory, file));
	};

	render() {
		const {T, item, children, allowAdd} = this.props;
		const {constraints = {}, $subnodeCount} = item;
		const {count = 0, mimeTypes} = constraints;

		const accept = mimeTypes || ["application/pdf"];
		const uploadLimitReached = !!count && $subnodeCount >= count;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContent}>
					{children}
				</div>
				<div className={css.viewFooter}>
					{allowAdd && !uploadLimitReached && <AddItemDropzone accept={accept} onDrop={this.onDrop}/>}
					{allowAdd && uploadLimitReached && <FooterInfo T={T} type="error" info="uploadLimitReached"/>}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {constraints = {}} = item;
		const {count = 0, size = 0, maxDownloads = 0} = constraints;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="constraints.count"
					label="document.constraints.count"
					placeholder="document.constraints.count.placeholder"
					value={{count}}
					type="number"
					min={0}
					step={1}
					onSubmit={this.updateConstraint}
				/>
				<TextFieldEdit
					id="constraints.size"
					label="document.constraints.size"
					placeholder="document.constraints.size.placeholder"
					value={{size}}
					type="number"
					min={0}
					step={1}
					onSubmit={this.updateConstraint}
				/>
				<TextFieldEdit
					id="constraints.maxDownloads"
					label="document.constraints.maxDownloads"
					placeholder="document.constraints.maxDownloads.placeholder"
					value={{maxDownloads}}
					type="number"
					min={0}
					step={1}
					onSubmit={this.updateConstraint}
				/>
			</div>
		);
	}
}

export default {
	ContainerComponent: Wrappers.ContainerComponentWrapper,
	HeaderComponent: Wrappers.HeaderComponentWrapper,
	ViewComponent: withIntl(ViewComponent),
	EditComponent: props => withEditDrawer(EditComponent, props)
}