import styled from 'styled-components/macro';

const Container = styled.div`
	margin: auto;
	max-width: 750px;
`;

const Picture = styled.div`
	img {
		width: 100%;
	}
`;

const Header = styled.div`
	background: ${({theme}) => theme.primaryColor};
	padding: 16px 24px 0 24px;
	
	h1 {
		color: ${({theme}) => theme.primaryTextColor};
		font-size: 24px;
	}
`;

const Content = styled.div`
	padding: 24px;
	
	p {
		font-size: 16px;
		line-height: 24px;
		padding-bottom: 24px;
	}
	
	ul, li {
		font-size: 16px;
		font-weight: 700;
	}
`;

export {
	Container,
	Header,
	Picture,
	Content
}