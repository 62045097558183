import React from 'react'
import {WizardStep} from 'react-wizard-primitive';
import styled from 'styled-components/macro'

const PagesWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
`;

const Pages = ({children, moveToStep, activeStepIndex, ...wizardProps}) => {
	return (
		<PagesWrapper>
			{children.map((child, key) => (
				<WizardStep key={key}>
					{wizardStepProps =>
						wizardStepProps.isActive && React.cloneElement(child, {activeStepIndex, ...wizardStepProps, wizardProps})
					}
				</WizardStep>
			))}
		</PagesWrapper>
	);
};

export default Pages;