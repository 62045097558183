import React from 'react'

import DefaultMaster from 'components/templates/sticker/master/DefaultMaster'
import {withIntlCookieInfo} from 'components/hocs'

import StickerMenu from '../../common/StickerMenu'
import ActionsMenu from 'profiles/default/stickers/default/roles/master/ActionsMenu'

import Slave from '../slave/Slave';

const Master = props => (
	<DefaultMaster
		bundle={props.bundle}
		sticker={props.sticker}
		ActionsMenu={ActionsMenu}
		StickerMenu={() => <StickerMenu {...props}/>}
		SlaveComponent={Slave}
		/*styles={styles}*/
		{...props}
	/>
);

export default withIntlCookieInfo(Master);