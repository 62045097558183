import React from 'react'
import styled from 'styled-components/macro';
import {Form} from 'formik';
import {Subheader, ListItem, FontIcon} from 'lib/react-md';
import _ from 'lib/lodash'

import FormContainer from 'components/forms/FormContainer';
import PageToolbar from '../PageToolbar'
import {StyledButton, StyledList} from '../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedSimpleLabel from '../ThemedSimpleLabel';

const C = "profiles.helvetia-at.PageCategory";

const PageWrapper = styled.div`
	padding: 16px; 
`;

const CategoryForm = ({formProps: {submitForm, setFieldValue}}) => {
		const categories = {
			"collision": {
				label: "Kollisionsschaden",
				subheader: "Es gab eine Kollision",
				items: {
					"car": "mit einem anderen Fahrzeug",
					"person": "mit einer Person",
					"animal": "mit einem Tier (zB Wild)",
					"object": "mit einem Objekt/Gegenstand"
				},
				icon: "collision"
			},
			"glass": {
				label: "Glasbruch",
				icon: "windshield-broken"
			},
			"parking": {
				label: "Parkschaden",
				icon: "park-damage"
			},
			"event": {
				label: "Naturereignis",
				icon: "storm-damage"
			},
			"other": {
				label: "Andere Schäden",
				icon: "accident"
			}
		};

		const confirmChoice = (category, subcategory) => () => {
			setFieldValue("category", category, false);
			setFieldValue("subcategory", subcategory, false);
			submitForm();
		};

		return (
			<PageWrapper>
				<Form>
					<StyledList styled="secondary">
						{_.map(categories, ({label, subheader, icon, items = {}}, categoryId) => (
								<ListItem
									key={categoryId}
									primaryText={label}
									expanderIcon={_.isSet(items) ? <FontIcon>keyboard_arrow_down</FontIcon> : <div/>}
									animateNestedItems={false}
									nestedItems={[
										<StyledList styled="nested" key="subcategories">
											{subheader && <Subheader primaryText={subheader}/>}
											{_.map(items, (subcategory, subcategoryId) => (
												<ListItem
													key={subcategoryId}
													primaryText={subcategory}
													onClick={confirmChoice(categoryId, subcategoryId)}
												/>
											))}
										</StyledList>
									]}
									onClick={_.isSet(items) ? () => null : confirmChoice(categoryId, "")}
								/>
							)
						)}
					</StyledList>
				</Form>
			</PageWrapper>
		);
	}
;

const PageCategory = ({T, actions, previousStep, nextStep, userInfo, setUserInfo}) => {
	const [, dispatch] = useFormState();

	const onPreviousStep = () => {
		dispatch({type: "FORM_GOTO_PREVIOUS_STEP"});
		previousStep();
	};

	const onSubmit = ({category, subcategory}, {setSubmitting}) => {
		dispatch({type: "FORM_SUBMIT_STEP_CATEGORY", category, subcategory});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		nextStep();
	};

	const initialValues = {
		category: "",
		subcategory: "",
	};

	return (
		<FormContainer
			initialValues={initialValues}
			onSubmit={onSubmit}
			FormComponent={CategoryForm}
			HeaderComponent={PageToolbar({title: T(C, "title")})}
			LabelComponent={ThemedSimpleLabel}
			label="Ich melde folgenden Schaden:"
			actionButtons={() => [
				<StyledButton styled="secondary" key="cancel" flat onClick={onPreviousStep}>Zurück</StyledButton>,
			]}
		/>
	);
};

export default PageCategory;