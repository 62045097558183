import React from 'react';
import {Button} from 'lib/react-md'
//import {isAndroid, isIOS} from 'lib/utils';

import {Link, SensotixLink} from 'components/utils'
import {withIntl} from 'components/hocs'
import {GTMVisibilityObserver} from 'components/observers'

import css from './StickerFooter.module.scss'

const C = "profiles.aida.SlaveFooter";

const StickerFooter = ({T}) => {
	const subscribe = () => window.location.href = 'https://www.aida.de/newsletter.19263.html';

	return (
		<div className={css.container}>
			<div className={css.newsletter}>
				<span className={css.header}>AIDA NEWSLETTER</span>
				<span className={css.subheader}>Aktuelle Informationen immer zuerst erhalten</span>
				<Button
					flat
					className={css.button}
					onClick={subscribe}
					data-gtm-action={`button:${C}`}
					data-gtm-label="Newsletter abonnieren"
				>
					Newsletter abonnieren
				</Button>
				<br/>
				<span style={{display: "none"}} className={css.subheader}>Folgen Sie uns auf</span>
			</div>
			<div style={{display: "None"}} className={css.socialMediaButtons}>
				<a className={css.socialMediaLink} href="https://www.facebook.com/AIDA" title="Facebook"
				   data-gtm-action={`${C}:Facebook`}
				>
					<img src={require('../../../img/facebook.svg')} alt="facebook"/>
				</a>
				<a className={css.socialMediaLink} href="http://www.youtube.com/channel/UChTjH9geklen_Ynu0XRuq2g?sub_confirmation=1"
				   title="YouTube" data-gtm-action={`${C}:Youtube`}
				>
					<img src={require('../../../img/youtube-play.svg')} alt="youtube"/>
				</a>
				<a className={css.socialMediaLink} href="https://twitter.com/aida_de" title="Twitter"
				   data-gtm-action={`${C}:Twitter`}
				>
					<img src={require('../../../img/twitter.svg')} alt="twitter"/>
				</a>
				<a className={css.socialMediaLink} href="https://www.xing.com/companies/aidacruises" title="Xing"
				   data-gtm-action={`${C}:Xing`}
				>
					<img src={require('../../../img/xing.svg')} alt="xing"/>
				</a>
				<a className={css.socialMediaLink} href="https://www.pinterest.com/aidakreuzfahrt/" title="Pinterest"
				   data-gtm-action={`${C}:Pinterest`}
				>
					<img src={require('../../../img/pinterest.svg')} alt="pinterest"/>
				</a>
				<a className={css.socialMediaLink} href="https://www.instagram.com/aida_cruises/" title="Instagram"
				   data-gtm-action={`${C}:Instagram`}
				>
					<img src={require('../../../img/instagram.svg')} alt="instagram"/>
				</a>
			</div>
			{/*<div className={css.appstoreButtons}>
				{isIOS && (
					<a className={css.apple} href="https://itunes.apple.com/de/app/aida-cruises/id510342362?mt=8">
						<img src={require(`../../img/ios-appstore-badge.svg`)} alt="App Store"/>
					</a>
				)}
				{isAndroid && (
					<a className={css.android} href="https://play.google.com/store/apps/details?id=com.markveys.aida.app.phone&amp;hl=de">
						<img src={require(`../../img/google-play-badge.svg`)} alt="Google Play Store"/>
					</a>
				)}
			</div>*/}

			<div className={css.disclaimer}>
				<div className={css.link}>
					<Link title="Zur Webseite" href="https://www.aida.de" data-gtm-action={`${C}:Website`}/>
				</div>
				<p/>
				<div>{T(C, "subtitle")}</div>
				<p/>
				<div>
					<Link title={T(C, "link.imprint.title")} href={T(C, "link.imprint.href")} data-gtm-action={`${C}:Imprint`}/>
					&nbsp;·&nbsp;
					<Link title={T(C, "link.legal.title")} href={T(C, "link.legal.href")} data-gtm-action={`${C}:Legal`}/>
					&nbsp;·&nbsp;
					<Link title={T(C, "link.privacy.title")} href={T(C, "link.privacy.href")} data-gtm-action={`${C}:Privacy`}/>
				</div>
				<p/>
				<small>
					<strong><SensotixLink title="Smart Stickers by Sensotix." data-gtm-action={`${C}:SensotixFooter`}/></strong>
				</small>
				<GTMVisibilityObserver eventAction={C} eventLabel="Bis zum Ende" eventValue={100}/>
			</div>
		</div>
	);
};

export default withIntl(StickerFooter);