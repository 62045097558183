import React, {useState} from 'react';
import Geosuggest from 'react-geosuggest';
import Geolocation from 'react-geolocation'
import {GoogleMap, Marker} from "@react-google-maps/api"
//import GoogleMap from '@react-google-maps/api/lib/GoogleMap'
//import Marker from '@react-google-maps/api/lib/components/drawing/Marker'

import styled from 'styled-components/macro'

import {Button} from 'lib/react-md'
import _ from 'lib/lodash'
import {withIntl} from 'components/hocs'
import {convertToDMSString} from 'lib/utils';

const C = "LocationPicker";

const Wrapper = styled.div`
  .geosuggest {
    width: 100%;
    font-size: 16px;
    position: relative;
    margin: 1em auto;
    text-align: left;
  }
  .geosuggest__input {
    font-size: 16px;
    background: inherit;
    width: 100%;
    border: none; //2px solid transparent;
    box-shadow: none; //0 0 1px #3d464d;
    padding: 0; //.5em 1em;
    transition: border 0.2s, box-shadow 0.2s;
    outline: none;
  }
  .geosuggest__input:focus {
    border-color: #ddd;
    box-shadow: 0 0 0 transparent;
  }
  .geosuggest__input::placeholder {
    font-size: 16px;
  }
  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    height: 200px;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #ddd;
    border-top-width: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    transition: max-height 0.2s, border 0.2s;
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  /**
   * A geosuggest item
   */
  .geosuggest__item {
    font-size: 16px;
    padding: .5em .65em;
    cursor: pointer;
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: #f5f5f5;
  }
  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: #ccc;
  }
  .geosuggest__item__matched-text {
    font-weight: bold;
  }
`;


const LocationMap = ({mapLocation, markerLabel, onClick}) => (
	<GoogleMap
		id="google-maps"
		mapContainerStyle={{
			height: "250px"
		}}
		zoom={14}
		center={mapLocation}
		onClick={onClick}
		options={{streetViewControl: false}}
	>
		<Marker position={mapLocation} label={markerLabel}/>
	</GoogleMap>
);

const LocationPicker = ({T, className, id, label, markerLabel, onChange, value, children, ...otherProps}) => {
	const [state, setState] = useState({
		mapLocation: !!value ? {lng: value.lng, lat: value.lat} : {lng: 0, lat: 0},
		mapLabel: !!value ? value.label : ""
	});

	const {mapLocation, mapLabel} = state;

	const getAddressFromLocation = ({lat, lng}) => {
		const location = new window.google.maps.LatLng({lat, lng});
		const geocoder = new window.google.maps.Geocoder();

		return new Promise(resolve => {
			geocoder.geocode({location}, results => resolve(_.get(results, '[0].formatted_address', "")))
		});
	};

	const onSuccess = async ({coords: {longitude: lng, latitude: lat}}) => {
		const address = await getAddressFromLocation({lng, lat});

		onChangeValue(lng, lat, `${address} (${convertToDMSString({lng, lat})})`);
	};

	const onClick = async ({latLng}) => {
		const lng = latLng.lng();
		const lat = latLng.lat();
		const address = await getAddressFromLocation({lng, lat});

		onChangeValue(lng, lat, `${address} (${convertToDMSString({lng, lat})})`);
	};

	const onSuggestSelect = (position = {location: {lng: 0, lat: 0}}) => {
		onChangeValue(position.location.lng, position.location.lat, position.label || "");
	};

	const onChangeSuggestion = label => onChangeValue(0, 0, label);
	const clearSuggestion = () => onChangeSuggestion("");

	const onChangeValue = (lng, lat, label) => {
		setState({mapLabel: label, mapLocation: {lng, lat}})
		onChange({label, lng, lat});
	};

	return (
		<Wrapper id="container">
			{<label id="label">{label ? label : undefined}</label>}
				<Geolocation
					loadingElement={<div style={{height: "100%"}}/>}
					lazy
					onSuccess={onSuccess}
					render={({fetchingPosition, error, getCurrentPosition}) => {
						const mapLocationValid = !!mapLocation.lng && !!mapLocation.lat;
						const location = mapLocationValid
							? new window.google.maps.LatLng({lat: mapLocation.lat, lng: mapLocation.lng}) : undefined;
						const placeholder = fetchingPosition ? T(C, "placeholder.fetching") : T(C, "placeholder.empty");
						const radius = location && 1000; // 1km

						return (
							<div id="wrapper">
								<div id="suggestion">
									<Button id="suggestion_button_clear" icon onClick={clearSuggestion}>clear</Button>
									<Geosuggest
										id="geosuggest"
										initialValue={mapLabel}
										location={location}
										placeholder={placeholder}
										radius={radius}
										onChange={onChangeSuggestion}
										onSuggestSelect={onSuggestSelect}
										placeDetailFields={[]}
										autoComplete="off"
									/>
									<Button
										id="suggestion_button_determine"
										icon
										iconEl={children}
										onClick={getCurrentPosition}
									/>
								</div>
								{fetchingPosition && mapLabel !== "" && <div id="placeholder">{placeholder}</div>}
								{error && <div id="error">{error.message}</div>}
								{mapLocationValid && (
									<LocationMap
										markerLabel={!!markerLabel ? T("label", markerLabel) : ""}
										mapLocation={mapLocation}
										onClick={onClick}
									/>
								)}
							</div>
						);
					}}/>
		</Wrapper>
	);
};

export default withIntl(LocationPicker);