import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lib/lodash'
import {LS} from 'lib/constants'

import {dataActions} from 'components/handlers/actions'
import {DataProvider} from 'components/contexts'

class DataContainer extends Component {
	componentDidMount() {
		const {bundle, sticker, payloadIds} = this.props;

		this.props.subscribeToPublicPayloadChange(bundle.id, sticker.id, payloadIds);
	}

	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps.dataHandler, this.props.dataHandler, ['dataLS', 'subnodes', 'nodes', 'userInfo'])
			|| _.anyDiff(nextProps, this.props, ['children']);
	}

	componentDidUpdate() {
		if (this.props.dataHandler.dataLS !== LS.LOADED) {
			const {nodes, subnodes} = this.props.dataHandler;

			const subnodesLength = _.reduce(subnodes, (length, subnode) => length + _.size(subnode), 0);

			// TODO: inaccurate since feedback can have public subnode AND a private subnode
			if (_.size(nodes.items) === subnodesLength) {
				this.props.dataLoadingFinished();
			}
		}
	}

	render() {
		if (this.props.dataHandler.dataLS !== LS.LOADED) return null;

		const {profileId, languages, portal, bundle, sticker, dataHandler, children, userInfo, setUserInfo} = this.props;
		const {payloads, nodes, subnodes} = dataHandler;

		return (
			<DataProvider
				profileId={profileId}
				languages={languages}
				portal={portal}
				bundle={bundle}
				sticker={sticker}
				payloads={payloads}
				nodes={nodes}
				subnodes={subnodes}
				userInfo={userInfo}
				setUserInfo={setUserInfo}
			>
				{children}
			</DataProvider>
		);
	}
}

const mapStateToProps = ({dataHandler}) => ({dataHandler}); // important, otherwise bundle and sticker get overwritten

export default connect(mapStateToProps, dataActions)(DataContainer);