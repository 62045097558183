import React, {PureComponent} from 'react';
import {api} from 'services'
import {Button} from 'lib/react-md'

import {ConfirmationDialog} from 'components/controls'

import css from './CustomHVCHButtonAssistanceSlave.module.scss'

const C = "profiles.helvetia-ch.CustomHVCHButtonAssistanceSlave";

class CustomHVCHButtonAssistanceSlave extends PureComponent {
	state = {
		confirmVisible: false
	};

	showConfirmationDialog = () => this.setState({confirmVisible: true});
	hideConfirmationDialog = (result, callback) => () => {
		const {actions} = this.props;

		this.setState({confirmVisible: false}, () => {
			callback && callback();

			const labelCancel = "Abbrechen";
			const labelOK = "Anrufen";

			actions.gtmClickEvent({
				eventAction: `button:${C}:confirmationDialog:${result ? labelOK : labelCancel}`,
				eventLabel: result ? labelOK : labelCancel
			});
		});
	};

	onCall = () => {
		const {T, actions, dataObj} = this.props;
		const {telno = "", slackText = "", slackChannel = ""} = dataObj;

		window.location.href = `tel:${telno}`;

		api.auth().post("postSlack",
			{channel: slackChannel, text: slackText}
		).then(() => actions.showCustomInfo(T(C, "sent")));
	};

	render() {
		const {confirmVisible} = this.state;
		const {T, actions, dataObj} = this.props;
		const {label = ""} = dataObj;

		const stickerProps = actions.getStickerProperties();
		const {polno = "", plate = ""} = stickerProps;

		const dialogActions = [
			{id: "cancel", secondary: false, children: T(C, "confirmDialog.Cancel"), onClick: this.hideConfirmationDialog(false)},
			{id: "ok", secondary: false, children: T(C, "confirmDialog.OK"), onClick: this.hideConfirmationDialog(true, this.onCall)},
		];

		return (
				<div className={css.viewContainer}>
					<Button
						flat
						secondary
						swapTheming
						className={css.button}
						onClick={this.showConfirmationDialog}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Assistance anrufen"
					>
						{label}
					</Button>
					<ConfirmationDialog
						id="confirmation-dialog"
						visible={confirmVisible}
						title={T(C, "confirmDialog.title")}
						actions={dialogActions}
						initialFocus="ok"
					>
						{T(C, "confirmDialog.text", {polno, plate})}
					</ConfirmationDialog>
				</div>
		);
	}
}

export default CustomHVCHButtonAssistanceSlave;