import React, {PureComponent} from 'react';

import {Iframe} from 'components/containers'

import css from './SlavePanorama.module.scss'

class SlavePanorama extends PureComponent {
	state = {
		loaded: false
	};

	onLoad = () => this.setState({loaded: true});

	render() {
		const {loaded} = this.state;

		const Loading = () => (
			<div className={css.loading}>
				Bitte haben Sie einen Augenblick Geduld, während das 360° Erlebnis geladen wird...
			</div>
		);

		return (
			<div className={css.container}>
				{!loaded && <Loading/>}
				<div className={css.panoramaContainer}>
					<Iframe
						id="panorama"
						url="https://www.aida.de/fileadmin/user_upload_aws/360/_/index.php?options=projectPath%3A..%2Fde_00326%2F%2Cfile%3Aperla_3080%2Clanguage%3Ade"
						height="400px"
						display="block"
						position="relative"
						allowFullScreen
						onLoad={this.onLoad}
					/>
				</div>
			</div>
		);
	}
}

export default SlavePanorama;