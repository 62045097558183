import React from 'react';

import css from './SlaveHeader.module.scss'

const SlaveHeader = () => (
	<div className={css.container}>
		<div className={css.main}>
			Mit 120 km/h auf dem Meer. Erleben Sie AIDA Kreuzfahrten aus einer neuen Perspektive!
		</div>
	</div>
);

export default SlaveHeader;