import React, {PureComponent} from 'react';
import {TextField} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'

class TextFieldEdit extends PureComponent {
	static defaultProps = {
		id: "",
		label: "",
		value: {value: ""},
		onSubmit: _.noop,
		rows: undefined,
		maxRows: 1
	};

	state = {
		key: "",
		val: ""
	};

	componentDidMount() {
		const {value} = this.props;

		this.setState({
			key: Object.keys(value)[0],
			val: value[Object.keys(value)[0]]
		});
	}

	componentDidUpdate(prevProps) {
		const {value} = this.props;

		if (!_.isEqual(value, prevProps.value)) {
			this.setState({
				key: Object.keys(value)[0],
				val: value[Object.keys(value)[0]]
			});
		}
	}

	onKeyPress = key => e => {
		if (e.key === 'Enter' && this.props.rows === 1 && this.props.maxRows === 1) {
			e.preventDefault();
			this.onBlur(key)(e);
		}
	};

	onBlur = e => {
		const {key} = this.state;
		const val = this.props.type === "number" ? parseInt(e.target.value) || 0 : e.target.value;
		this.props.onSubmit({[key]: val});
	};

	onChange = val => this.setState({val});

	render() {
		const {
			T, FormattedMessage, containerClassName, label, floatingLabel = true,
			rawLabel, value, placeholder, onSubmit, ...otherProps
		} = this.props;
		const {key, val} = this.state;

		return (
			<div className={containerClassName}>
				{!floatingLabel && <label>{label ? T("label", label) : rawLabel}</label>}
				<TextField
					label={floatingLabel ? (label ? T("label", label) : rawLabel) : undefined}
					placeholder={placeholder && T("label", placeholder)}
					//placeholder={label}
					value={String(val)}
					//onClick={this.handleOnFocus}
					onBlur={this.onBlur}
					onChange={this.onChange}
					onKeyPress={this.onKeyPress(key)}
					{...otherProps}
				/>
			</div>
		)
	}
}

export default withIntl(TextFieldEdit);