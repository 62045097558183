import React from 'react';
import {FontIcon} from 'lib/react-md'

import {withIntl} from 'components/hocs'
import LocationPicker from './LocationPicker'

const C = "FormLocationPicker";

export default withIntl(({
	                         T,
	                         className,
	                         field: {name, value, onBlur}, // {name, value, onChange, onBlur}
	                         form: {touched, errors, setFieldValue}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                         label,
	                         ...otherProps
                         }) => {

	const onChange = value => setFieldValue(name, value);

	return (
		<div className={className}>
			{<label>{label ? label : undefined}</label>}
			<LocationPicker
				{...otherProps}
				id={name}
				name={name}
				value={value}
				onChange={onChange}
			>
				<FontIcon data-gtm-action={`button:${C}`} data-gtm-label="Aktuelle Position">location_on</FontIcon>
			</LocationPicker>
			{errors[name] && <div id="errorText">{errors[name].label}</div>}
		</div>
	);
});