import styled from 'styled-components/macro';

export default styled.div`
  padding: 12px;
  text-align: center;
  font-size: 16px;
  color: ${({styled, theme}) => styled === "primary" && theme.primaryColor};
  color: ${({styled, theme}) => styled === "secondary" && theme.secondaryColor};
  border: 2px solid ${({styled, theme}) => styled === "primary" && theme.primaryColor};
  border: 2px solid ${({styled, theme}) => styled === "secondary" && theme.secondaryColor};
`;