import React, {PureComponent} from 'react'

const initTweenMax = ({canvas, scene, image}) => {
	const TweenMax = window.TweenMax;
	const Sine = window.Sine;
	const Linear = window.Linear;

///////////
// Snowman
	TweenMax.to("#head", 1.5, {
		startAt: {
			rotation: -10
		},
		rotation: 10,
		yoyo: true,
		transformOrigin: "50% 50%",
		repeat: -1,
		ease: Sine.easeInOut
	});
	TweenMax.to("#nose", 1.5, {
		startAt: {
			rotation: -10
		},
		rotation: 10,
		scaleX: .8,
		yoyo: true,
		transformOrigin: "5% 95%",
		repeat: -1,
		ease: Sine.easeInOut
	});
	TweenMax.to("#scarf", 1.5, {
		startAt: {
			rotation: 20
		},
		rotation: -20,
		yoyo: true,
		transformOrigin: "50% 10%",
		repeat: -1,
		ease: Sine.easeInOut
	});
	TweenMax.to("#shadow", 1.5, {
		startAt: {
			x: -5
		},
		x: 0,
		yoyo: true,
		repeat: -1,
		ease: Sine.easeInOut
	});
	TweenMax.to("#snowman", 1.5, {
		startAt: {
			rotation: -5
		},
		rotation: 7,
		yoyo: true,
		transformOrigin: "50% 90%",
		repeat: -1,
		ease: Sine.easeInOut
	});
	TweenMax.to("#snowman", .3, {
		scaleX: .95,
		scaleY: 1.05,
		yoyo: true,
		transformOrigin: "50% 95%",
		repeat: -1,
		ease: Sine.easeInOut
	});

	blink();

	function blink() {
		TweenMax.to(["#eye1,#eye2"], .1, {
			delay: 5 * Math.random(),
			scaleY: .2,
			yoyo: true,
			repeat: 1,
			transformOrigin: "50% 50%",
			onComplete: blink
		});
	}

////////////////
// Snow
	var numFlakes = 100;
	var minScale = .15;
	var maxScale = 1;
	var minDuration = 2;
	var maxDuration = 4;
	var wind = 300;
	var wiggle = 60;
	//var canvas = document.querySelector("canvas");
	var context = canvas.getContext("2d");
	//var snowflakeImage = new Image();
	//snowflakeImage.src = "./snowflake.svg";

	var snowflakes = [];
	const snowflakeImage = image;
	snowflakeImage.onload = init;
	onResize();
	window.onresize = onResize;

// wait until the snowflake image loads
	function init() {
		TweenMax.to(scene, 0.3, {
			opacity: 1
		});
		TweenMax.to(canvas, 7, {
			delay: .3,
			opacity: 1
		});
		for (var i = 0; i < numFlakes; i++) {
			// create a snowflake object and its properties
			var snowflake = {
				initX: (canvas.width * 2 * Math.random()) - (canvas.width * .5),
				y: -16,
				scale: (Math.random() * (maxScale - minScale)) + minScale,
				angle: Math.random() * Math.PI * 2,
				drift: 0,
				radius: (Math.random() * wiggle) - (wiggle * 0.5)
			};
			snowflakes.push(snowflake);
			// create a tween that animates the angle and y properties - call update each frame
			const tween = TweenMax.to(snowflake, (Math.random() * (maxDuration - minDuration)) + minDuration, {
				repeat: -1,
				angle: Math.random() * 20,
				drift: (Math.random() * wind),
				y: window.innerHeight,
				ease: Linear.easeNone,
				onUpdate: update,
				onUpdateParams: [snowflake]
			});
			tween.progress(Math.random());
		}
	}

	function update(snowflake) {
		// modify the x value based on cos of the angle
		snowflake.x = (snowflake.initX + Math.cos(snowflake.angle) * snowflake.radius) + snowflake.drift;
	}

// create a render loop that fires approximately every 16ms
	TweenMax.ticker.addEventListener("tick", render);

	function render() {
		// clear old canvas
		context.clearRect(0, 0, window.innerWidth * 2, window.innerHeight - 50);
		for (var i = 0; i < numFlakes; i++) {
			var snowflake = snowflakes[i];
			if (snowflake) {
				// draw snowflakes to canvas
				context.drawImage(snowflakeImage, snowflake.x, snowflake.y, 16 * snowflake.scale, 16 * snowflake.scale);
			}
		}
	}

	function onResize(e) {
		//readjust size of canvas
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight - 50;
		kill();
		init();
	}

	function kill() {
		// shut down all currently running tweens
		for (var i = 0; i < numFlakes; i++) {
			var snowflake = snowflakes[i];
			TweenMax.killTweensOf(snowflake);
		}
		snowflakes = [];
	}
};

const loadDynamicScript = ({id, src, callback}) => {
	const existingScript = document.getElementById(id);

	if (!existingScript) {
		const script = document.createElement('script');
		script.src = src; // URL for the third-party library being loaded.
		script.id = id; // e.g., googleMaps or stripe
		document.body.appendChild(script);

		script.onload = () => callback && callback();
	}

	if (existingScript && callback) callback();
};

class Snowman extends PureComponent {
	componentDidMount() {
		const canvas = this.refs.canvas;
		const scene = this.refs.scene;
		const image = this.refs.image;

		loadDynamicScript({
			id: "TweenMax",
			src: `${window.location.origin}/js/TweenMax.min.js`,
			callback: () => {
				initTweenMax({canvas, scene, image})
			}
		});
	}

	render() {
		return (
			<div>
				<canvas ref="canvas" id="canvas" width="1000" height="600">
					Canvas is not supported.
				</canvas>
				<img hidden ref="image" src={require('./snowflake.svg')} alt="snowflake"/>

				<svg ref="scene" id="scene" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
				     viewBox="0 0 250 100"
				     opacity="0">
					<path id="drift" fill="#EAF5F9" d="M222.6,94.4c-19.6-1.8-38.8,0.7-52.6,0.8c-19.8,0.1-23.9-5.6-45-6.4c-15.7-0.6-27.5,5-47.3,2.6
	C61.2,89.5,34.5,89.2,0,93.5v6.5h250C250,100,242.4,96.2,222.6,94.4z"/>
					<path id="shadow" fill="#D9E2E8" d="M42,99c-39.8,0.1-25.5-9.4,0-8.1C89.8,93.4,66.5,98.9,42,99z"/>
					<g id="snowman" cursor="pointer">
						<path id="body" fill="#EAF5F9" d="M35.6,53.2c23-2.6,42.2,41.9,3.4,44.3C0.2,99.9,6.7,56.4,35.6,53.2z"/>
						<path id="neck" fill="#FF0000" d="M36,63c-12.8,0-18.4-2.9-16.8-6.2c1.4-2.8,12.6-1,17.1-1c8.4,0,14.5-4.3,17,0
	C55.5,59.7,47,63,36,63z"/>
						<path id="scarf" fill="#FF0000" d="M49.4,76.9c-0.7-4.2-3.2-18.3-7.1-17.3c-3.5,0.9-1.7,10.8-0.7,15.4c0.3,1.5,1.5,3.1,3,3.4
	c1.3,0.3,2.6,0.3,3.9,0.2C49.2,78.5,49.6,77.5,49.4,76.9z"/>
						<g id="head">
							<path id="top" fill="#EAF5F9" d="M33.5,26.5c17.7-0.2,30.2,33.8,0.9,32.2C6,57.3,15.7,26.7,33.5,26.5z"/>
							<path id="eye1" fill="#000000" d="M41.9,38c0.2,2-3.4,3.9-3.6,0.6C38.1,35.3,41.7,36,41.9,38z"/>
							<path id="eye2" fill="#000000" d="M27.8,40.6c0.8,1.8,5.2,1.4,3.6-1.5C30,36.2,27,38.7,27.8,40.6z"/>
							<path id="mouth" fill="none" stroke="#000000" strokeMiterlimit="10" d="M27.4,49c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.3,0.8,0.6,1.1,1
	c0.3,0.4,0.6,0.8,1.1,0.9c0.5,0.1,0.9-0.3,1.4-0.4c0.8-0.2,1.7,0.2,2.6,0.4c1.3,0.3,2.6-0.1,3.9,0.1c0.4,0.1,0.9,0.2,1.3,0.1
	c0.4-0.1,0.7-0.5,1.1-0.6c0.3-0.1,0.6-0.1,0.9-0.1c0.5-0.1,1-0.4,1.4-0.7c0.3-0.3,1.3-1.2,1.6-1.5"/>
							<path id="nose" fill="#FF0000" d="M35.4,43c-0.6,0.6-0.9,1.2-0.8,1.9c0.1,1,1,1.9,2,2c1.7,0.2,3.3,0.3,4.7,0.4
	c2.9,0.3,5.5-0.9,8.3-0.9c0.7,0,0.9-0.8,0.3-1.2c-1.6-1-3.3-1.6-5.3-1.8c-1.5-0.2-2.5,0.2-4.2-0.1c-1-0.2-1.9-0.8-2.8-1
	C36.7,42.2,35.9,42.4,35.4,43z"/>
						</g>
					</g>
				</svg>
			</div>
		);
	}
}

export default Snowman;