import React from 'react';
import {FontIcon, SelectionControlGroup} from 'lib/react-md'
import {ErrorMessage} from 'formik';
import _ from 'lib/lodash'

const defaultControls = [{label: "Option 1", value: "option1"}, {label: "Option 2", value: "option2"}];
const defaultCheckedRadioIcon = <FontIcon>radio_button_checked</FontIcon>;
const defaultUncheckedRadioIcon = <FontIcon>radio_button_unchecked</FontIcon>;

export default ({
	                className,
	                field: {name, value = "", onBlur}, // {name, value, onChange, onBlur}
	                form: {touched, errors, setFieldValue}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                label,
	                controls = defaultControls,
	                checkedRadioIcon = defaultCheckedRadioIcon,
	                uncheckedRadioIcon = defaultUncheckedRadioIcon,
	                onChanged = _.noop,
	                ...otherProps
                }) => {

	const onChange = (value, e) => {
		setFieldValue(name, value);
		onChanged({[name]: value});
	};

	return (
		<div className={className}>
			{<label>{label ? label : undefined}</label>}
			<SelectionControlGroup
				{...otherProps}
				id={name}
				name={name}
				type="checkbox"
				controls={controls}
				value={value}
				checkedRadioIcon={checkedRadioIcon}
				uncheckedRadioIcon={uncheckedRadioIcon}
				onChange={onChange}
			/>
			<div id="errorText"><ErrorMessage name={name}/></div>
		</div>
	);
}