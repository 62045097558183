import React from 'react';
import styled from 'styled-components/macro';
import {List} from 'lib/react-md'

const ListWrapper = ({className, styled, children, ...otherProps}) =>
	<List className={className} {...otherProps}>{children}</List>;

export default styled(ListWrapper)`
	&& { // increase specificity
		background: none;
		font-weight: bold;
		
		li.md-list-item .md-list-tile {
			padding-left: 40px;
		}
		
		> li {
			background: ${({styled, theme}) => styled === "primary" && theme.primaryColor};
			background: ${({styled, theme}) => styled === "secondary" && theme.secondaryColor};
			background: ${({styled, theme}) => styled === "nested" && theme.neutralBackgroundColor};
			margin: ${({styled, theme}) => styled !== "nested" && "24px 0"};
			
	    @media (max-height: 400px) {
	      margin: 10px;	// to fit all items on screen in landscape view
	    }	

		
			> div {
				.md-text, i {
					color: ${({styled, theme}) => styled === "primary" && theme.primaryTextColor};
					color: ${({styled, theme}) => styled === "secondary" && theme.primaryTextColor};
					color: ${({styled, theme}) => styled === "nested" && theme.textColor};
				}
			}
			
			ul.md-list--nested-3 {
				background: ${({styled, theme}) => theme.primaryTextColor};
				
				li.md-list-item {
					.md-list-tile {
						padding-left: 40px;
						
						.md-tile-content {
							padding: 8px;
							font-size: 16px;
							font-weight: 500;
							background: #46a9b4;
					    border-radius: 8px;
					    text-align: center;
					    
					    > div {
					    	color: ${({styled, theme}) => theme.primaryTextColor};
					    }		
						}
					}
		    }
			}
		}
	}
`;