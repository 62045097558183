const requireComponent = (component, role) => require(`./${component}/${role}`).default;
const requireCustomComponent = (component, role) => require(`../../${component}/${role}`).default;

export const master = {
	"button-collection": requireComponent("button-collection", "master"),
	"contact-collection": requireComponent("contact-collection", "master"),
	"document-collection": requireComponent("document-collection", "master"),
	"feedback-collection": requireComponent("feedback-collection", "master"),
	"image-collection": requireComponent("image-collection", "master"),
	"image360-collection": requireComponent("image360-collection", "master"),
	"link-collection": requireComponent("link-collection", "master"),
	"post-collection": requireComponent("post-collection", "master"),
	"text-collection": requireComponent("text-collection", "master"),
	"video-collection": requireComponent("video-collection", "master"),
	"video360-collection": requireComponent("video360-collection", "master"),
	"custom-collection": requireComponent("custom-collection", "master"),

	"custom-hvch-button-assistance-collection":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-button-assistance-collection", "master"),
	"custom-hvch-claim-collection":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-claim-collection", "master"),

	"custom-aida-callback-form-collection":
		requireCustomComponent("profiles/aida/nodes/custom-aida-callback-form-collection", "master"),
};

export const slave = {
	"button-collection": requireComponent("button-collection", "slave"),
	"contact-collection": requireComponent("contact-collection", "slave"),
	"document-collection": requireComponent("document-collection", "slave"),
	"feedback-collection": requireComponent("feedback-collection", "slave"),
	"image-collection": requireComponent("image-collection", "slave"),
	"image360-collection": requireComponent("image360-collection", "slave"),
	"link-collection": requireComponent("link-collection", "slave"),
	"post-collection": requireComponent("post-collection", "slave"),
	"text-collection": requireComponent("text-collection", "slave"),
	"video-collection": requireComponent("video-collection", "slave"),
	"video360-collection": requireComponent("video360-collection", "slave"),
	"custom-collection": requireComponent("custom-collection", "slave"),

	"custom-hvch-button-assistance-collection":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-button-assistance-collection", "slave"),
	"custom-hvch-claim-collection":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-claim-collection", "slave"),

	"custom-aida-callback-form-collection":
		requireCustomComponent("profiles/aida/nodes/custom-aida-callback-form-collection", "slave"),
};
