import React from 'react'
import styled from 'styled-components/macro'

const LabelWrapper = styled.div`
	display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = ({className, pageProps, activeStepIndex}) => (
	<LabelWrapper className={className}>
		{pageProps[activeStepIndex].label}
	</LabelWrapper>
);

export default Label;