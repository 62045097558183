import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormDatetimePicker, StyledFormLocationPicker} from '../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../ThemedPageWrapper'

const C = "profiles.helvetia-at.PageClaim.PageCollision.Common.PageDatetimeLocation";

const PageDatetimeLocationForm = () => (
	<ThemedPageWrapper>
		<Form>
			<Field
				type="datetime-local"
				name="datetime"
				label="Zeitpunkt des Unfalls"
				placeholder="Bitte ausfüllen..."
				component={StyledFormDatetimePicker}
				max={new Date()}
			/>
			<Field
				type="text"
				name="location"
				label="Ort des Unfalls"
				placeholder="Bitte ausfüllen..."
				component={StyledFormLocationPicker}
			/>
		</Form>
	</ThemedPageWrapper>
);

const validationSchema = Yup.object().shape({
	datetime: Yup.date().required("Bitte geben Sie den Unfallzeitpunkt an."),
	location: Yup.object().shape({
		label: Yup.string().required("Bitte geben Sie den Unfallort an.")
	})
});

const PageDatetimeLocation = ({wizardProps: {T, actions}, cancel, ...otherProps}) => {
	const [formState, dispatch] = useFormState();
	const {datetime, location} = formState;
	const initialValues = {datetime: datetime || new Date(), location};

	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COMMON_DATETIME_LOCATION", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={PageDatetimeLocationForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={cancel}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageDatetimeLocation;