import _ from 'lib/lodash'

import {gtm} from 'components/events'

/**
 * Middleware to handle Google Tag Manager requests and Google Analytics events by modifying the dataLayer
 * @param store => next => action
 */

export const gtmEventMiddleware = store => next => action => {
	next(action);

	if (!action.type.startsWith('GTM_EVENT')) return;

	const {authHandler, localeHandler} = store.getState();
	const {profileId = "", portal = {}, bundle = {}, sticker = {}, tag = {}} = authHandler;

	const eventData = _.get(action, 'eventData', {});
	const {id = "", item = {}} = eventData;
	const filename = _.get(item, "storage.fileLoc", "unknown");

	const {eventAction = "", eventLabel = "", eventValue} = action;

	switch (action.type) {
		case gtm.GTM_EVENT_PORTAL_LOADED:
			window.dataLayer.push({
				event: "portal-loaded",
				profileId: profileId,
				portalId: portal.id,
				locale: localeHandler.locale,
				bundleId: bundle.id,
				stickerId: undefined,
				role: undefined,
				tagId: undefined,
				tagType: undefined,
			});
			break;

		case gtm.GTM_EVENT_STICKER_LOADED:
			window.dataLayer.push({
				event: "sticker-loaded",
				profileId: profileId,
				portalId: portal.id,
				locale: localeHandler.locale,
				bundleId: bundle.id,
				stickerId: sticker.id,
				role: sticker.role,
				tagId: tag.id,
				tagType: tag.type,
			});
			break;

		case gtm.GTM_EVENT_ONBOARDING_ACKNOWLEDGED:
			window.dataLayer.push({
				event: "onboarding-acknowledged",
				profileId: profileId,
				bundleId: bundle.id,
				stickerId: sticker.id,
			});
			break;

		case gtm.GTM_EVENT_LOCALE_CHANGE:
			window.dataLayer.push({
				event: "locale-change",
				eventAction
			});
			break;

		case gtm.GTM_EVENT_VIDEO_READY:
			window.dataLayer.push({
				event: "video-ready",
				eventAction: id,
				eventLabel: filename
			});
			break;

		case gtm.GTM_EVENT_SCROLLDEPTH_VERTICAL:
			window.dataLayer.push({
				event: "scrolldepth-vertical",
				eventAction,
				eventLabel,
				eventValue
			});
			break;

		case gtm.GTM_EVENT_CLICK:
			window.dataLayer.push({
				event: "click-event",
				eventAction,
				eventLabel,
				eventValue
			});
			break;

		case gtm.GTM_EVENT_CUSTOM:
			window.dataLayer.push({
				event: "custom-event",
				eventAction,
				eventLabel,
				eventValue
			});
			break;

		case gtm.GTM_EVENT_VALIDATION_ERROR:
			window.dataLayer.push({
				event: "validation-error",
				eventAction,
				eventLabel,
				eventValue
			});
			break;

		default:
			break;
	}
};


export const gtmErrorMiddleware = store => next => action => {
	next(action);

	if (action.type.startsWith('NOTIFICATION')) return; // ignore notifications
	if (!action.type.endsWith('_ERROR')) return; // focus on errors

	const {code = "", message, details = {}, number, response} = action.error || {};

	window.dataLayer.push({
		event: "custom-error",
		eventAction: `${action.type}/${code}`,
		eventLabel: JSON.stringify({number, message, response, ...details}),
	});
};