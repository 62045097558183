import React from 'react'
import {Form, Field} from 'formik';
import _ from 'lib/lodash'

import {FormContainer} from 'components/forms';
import {StyledButton, StyledTable, StyledFormImageList} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Animal.PageOverview";
const CC = "profiles.folder-f.PageClaim.PageCollision.PageOverview";

const PageOverviewForm = ({
	                          claimId,
	                          categoryText,
	                          subcategoryText,
	                          polno, owner,
	                          driverName, driverPlate, driverTelno, driverEmail,
	                          datetime,
	                          location,
	                          damagesText,
	                          comments,
	                          partyHurt, partyCause, partyReason, partyName, partyAddress, partyTelno, partyEmail,
	                          images
                          }) => ({T}) => {

	const table = _.compact([
		[T(CC, "owner"), owner],
		["", polno],
		["", ""],
		!!driverPlate && [T(CC, "driverPlate"), driverPlate],
		!!driverName && ["", driverName],
		!!driverTelno && ["", driverTelno],
		!!driverEmail && ["", driverEmail],
		["", ""],
		[T(CC, "categoryText"), categoryText + " " + subcategoryText],
		[T(CC, "date"), new Date(datetime).toLocaleDateString()],
		[T(CC, "time"), new Date(datetime).toLocaleTimeString()],
		[T(CC, "location"), location.label],
		["", ""],
		!!damagesText && [T(CC, "damagesText"), damagesText],
		!!damagesText && ["", ""],
		!!comments && [T(CC, "comments"), comments],
		!!comments && ["", ""],
		["", ""],
	]);

	const hasImages = {
		"damage": _.filter(images, {properties: {tag: "damage"}}).length > 0,
	};

	return (
		<ThemedPageWrapper>
			<Form>
				<div>{T(CC, "title")}<strong>{claimId}</strong></div>
				<StyledTable>
					<tbody>
					{table.map((row, idx) => (
						<tr key={idx}>
							<td>{row[0]}</td>
							<td>{row[1]}</td>
						</tr>
					))}
					</tbody>
				</StyledTable>
				{hasImages["damage"] && (
					<Field
						label={T(CC, "damage")}
						images={images}
						tag="damage"
						component={StyledFormImageList}
					/>
				)}
			</Form>
		</ThemedPageWrapper>
	);
};

const PageOverview = ({wizardProps: {T}, submitForm, isSubmitting, images, ...otherProps}) => {
	const [formState, dispatch] = useFormState();
	const FormComponent = PageOverviewForm({...formState, images});

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});

	return (
		<FormContainer
			onSubmit={submitForm}
			FormComponent={FormComponent}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" isLoading={isSubmitting} onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageOverview;