export default ({stickerId, order = 1000}) => ({
	type: "custom",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		component: "",
		data: ""
	}
})