import React, {PureComponent} from 'react';
import {FontIcon, Button, Toolbar} from 'lib/react-md'

import {withIntlDrawer} from 'components/hocs'

import css from './withEditDrawer.module.scss'

const withEditDrawer = (WrappedComponent, {keyPrefix, item, actions, hideSave = false}) => {

	const C = `${keyPrefix}-NodeEditContainer-${item.$id}`;

	//const isNewItem = !_.has(item, 'modified');

	class NodeEditContainer extends PureComponent {
		state = {
			confirmDelete: false,
		};

		constructor(props) {
			super(props);
			this.wrappedComponent = React.createRef();
		}

		componentDidMount() {
			this.props.initDrawer({container: this.getContainer(), position: "right"});
		}

		componentDidUpdate() {
			this.props.updateDrawer({container: this.getContainer(), position: "right"});
		}

		onClose = () => {
			this.onDeleteCancel();
			this.props.closeDrawer();
		};

		onDeleteConfirm = () => this.setState({confirmDelete: true});

		onDeleteCancel = () => this.setState({confirmDelete: false});

		onSubmit = () => {
			const item = this.wrappedComponent.current.getItem();

			actions.updateDocument(item, true);
			actions.showCustomInfo({id: "node.saved"});

			this.onClose();
		};

		onDelete = () => {
			const item = this.wrappedComponent.current.getItem();

			actions.deleteDocument(item);
			actions.showCustomInfo({id: "node.deleted"});

			this.onClose();
		};

		getContainer = () => {
			const {
				T, FormattedMessage, intl,
				initDrawer, openDrawer, closeDrawer, removeDrawer, updateDrawer,
				...otherProps
			} = this.props;
			const {confirmDelete} = this.state;

			const title = T("Media", item.type);

			const actionsLeft = (
				<>
					{/*!isNewItem && */<Button icon onClick={this.onClose}>close</Button>}
				</>
			);

			const actionsRight = (
				<>
					{!confirmDelete && <Button icon onClick={this.onDeleteConfirm}>delete</Button>}
					{confirmDelete && <Button flat onClick={this.onDelete}>{T("Actions", "deleteProceed")}</Button>}
					{confirmDelete && <Button flat onClick={this.onDeleteCancel}>{T("Actions", "deleteCancel")}</Button>}
					{!hideSave && <Button icon onClick={this.onSubmit}>done</Button>}
				</>
			);

			return (
				<div key={item.$id}>
					<Toolbar
						colored
						title={title}
						prominent
						prominentTitle
						zDepth={0}
						nav={actionsLeft}
						actions={actionsRight}
					/>
					<div className={css.container}>
						<WrappedComponent
							T={T}
							ref={this.wrappedComponent}
							item={item}
							actions={actions}
							onSubmit={this.onSubmit}
							{...otherProps}
						/>
					</div>
				</div>
			);
		};

		render() {
			return <FontIcon onClick={this.props.openDrawer}>edit</FontIcon>
		}
	}

	return withIntlDrawer(NodeEditContainer, {name: C/*, isNewItem*/});
};

export default withEditDrawer