import history from '../../history'

import {scannerTypes} from 'components/handlers/types'

export const scannerMiddleware = () => next => action => {
	next(action);

	if (action.type === scannerTypes.WEBCODE_SCAN_SUCCESS || action.type === scannerTypes.QR_SCAN_SUCCESS) {
		history.push(action.url);
	}
};