/* Loading status of data to fetch */
export const LS = Object.freeze({
	INIT: 'INIT',
	REQUESTED: 'REQUESTED',
	LOADED: 'LOADED',
	ERROR: 'ERROR'
});

/* Claim status of user */
export const CS = Object.freeze({
	INIT: 'INIT',
	RECEIVED: 'RECEIVED',
	VALID: 'VALID',
	INVALID: 'INVALID',
	FAILURE: 'FAILURE',
});

export const MB = 2**20;