import React, {PureComponent} from "react";
import VisibilitySensor from "react-visibility-sensor";
import _ from "lib/lodash"

import css from "./VideoViewer.module.scss"

class VideoViewer extends PureComponent {
	static defaultProps = {
		width: "100%",
		height: "100%"
	};

	state = {
		videojs: null,
		id: `video-${this.props.id}-${_.uniqueId()}`, // generate unique id for each instance
	};

	constructor(props) {
		super(props);
		this.videojsNode = React.createRef();
	}

	initPlayer = () => {
		const {imgPath, thumbPath, onReady, ...otherProps} = this.props;
		const {id, videojs} = this.state;

		this.videoPlayer = videojs(this.videojsNode.current, {
			plugins: {},
			poster: thumbPath,
			sources: [{
				src: imgPath,
				type: "video/mp4"
			}],
			loop: true,
			controls: true,
			...otherProps
		});

		if (!!onReady) onReady({id: `${id}_html5_api`}); // name given by video.js

		// hook up user interaction handlers
		//const controlBar = this.videojsContainerNode.querySelectorAll(".vjs-control-bar");
		//const controlElements = _.get(controlBar, "[0].childNodes");
		//_.forEach(controlElements, el => el.addEventListener("click", this.onUserInteraction));
	};

	onVisibilityChange = isVisible => {
		const player = this.videoPlayer;
		if (!player) return;
		if (!this.props.stopOnScroll) return;

		if (!isVisible && !player.paused()) {
			player.pause();
		} else if (isVisible && this.props.autoplay) {
			// TODO: Remember last action taken in state (play/pause) and decide whether to continue playing once visible
			player.play();
		}
	};

	componentDidMount() {
		// dynamically import video.js library, otherwise it increases bundle size by 337KB
		//import("video.js").then(module => {
		// see https://github.com/videojs/video.js/issues/6166 to reduce bundle size
		import ("video.js/dist/alt/video.core.novtt.js").then(module => {
			this.setState({videojs: module.default || module});
		});
	}

// eslint-disable-next-line
	componentDidUpdate(prevProps, prevState) {
		if (!prevState.videojs && !!this.state.videojs) {
			this.initPlayer();
		}
	}

	componentWillUnmount() {
		if (this.videoPlayer) {
			this.videoPlayer.dispose();
		}
	}

	render() {
		const {width, height, thumbPath} = this.props;
		const {id} = this.state;

		// wrap the player in a div with a `data-vjs-player` attribute
		// so videojs won"t create additional wrapper in the DOM
		// see https://github.com/videojs/video.js/pull/3856

		return (
			<VisibilitySensor onChange={this.onVisibilityChange}>
				<div className={css.container} style={{width, height}}>
					<div data-vjs-player>
						<video
							id={id}
							ref={this.videojsNode}
							className="video-js vjs-default-skin vjs-big-play-centered"
							controls
							preload="none"
							style={{width, height}}
							crossOrigin="anonymous"
							poster={thumbPath}
							playsInline
						/>
					</div>
				</div>
			</VisibilitySensor>
		);
	}
}

export default VideoViewer;
