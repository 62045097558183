import React from 'react';

import {withIntl} from 'components/hocs'
import DefaultPortal from 'components/templates/portal/DefaultPortal'
import PortalMenu from './PortalMenu';

const C = "profiles.default.portals.default";

const Portal = ({T, languages, profileId}) => (
	<DefaultPortal
		profileId={profileId}
		logoPath="components/templates/portal/img/logo.svg"
		logoWidth={160}
		helpImagePath="components/templates/portal/img/help.png"
		length={9}
		options={{blocks: [3, 3, 3], delimiter: ' ', uppercase: true}}
		title={T(C, "title")}
		textCamera={T(C, "textCamera")}
		textNoCamera={T(C, "textNoCamera")}
		Menu={<PortalMenu languages={languages}/>}
		theme=""
	/>
);

export default withIntl(Portal);