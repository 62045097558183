import React, {PureComponent} from 'react';
import {AccessibleFakeButton} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl, withDraggableList} from 'components/hocs'
import {FaqButton} from 'components/faq'
import {DataConsumer} from 'components/contexts'

import MasterNodeContainer from './MasterNodeContainer'
import css from './MasterNodesList.module.scss'

const ListTitle = withIntl(({T, nodes, actions}) => {
	const allExpanded = _.reduce(nodes.items, (expanded, item) => expanded && item.expanded, true);
	const allCollapsed = _.reduce(nodes.items, (expanded, item) => expanded && !item.expanded, true);

	return (
		<div className={css.actions}>
			<FaqButton />
			<div className={css.buttons}>
				{!allExpanded && (
					<AccessibleFakeButton
						className={css.button}
						onClick={actions.updateNodesExpanded(nodes.items)}>{T("Actions", "expand")}
					</AccessibleFakeButton>
				)}
				{!allCollapsed && (
					<AccessibleFakeButton
						className={css.button}
						onClick={actions.updateNodesCollapsed(nodes.items)}>{T("Actions", "collapse")}
					</AccessibleFakeButton>
				)}
			</div>
		</div>
	);
});

const DraggableNodesList = withDraggableList({ListItemComponent: MasterNodeContainer});

class MasterNodesList extends PureComponent {
	render() {
		const {keyPrefix} = this.props;

		return (
			<DataConsumer>
				{({nodes, subnodes, actions, isUploading}) => (
					<div className={css.list}>
						<ListTitle nodes={nodes} actions={actions}/>
						<DraggableNodesList
							items={nodes.items}
							actions={actions}
							subnodes={subnodes}
							onDragEnd={actions.updateNodesOrder}
							keyPrefix={keyPrefix}
							isUploading={isUploading}
						/>
					</div>
				)}
			</DataConsumer>
		)
	}
}

export default MasterNodesList;