import {fs, serverTimestamp, storage, setStorageBucket} from './firebase'
import _ from 'lib/lodash'

export const setBundle = bundle => setStorageBucket(_.get(bundle, 'storageBucket'));

// omit all properties starting with $-sign, therefore used only internally
// eslint-disable-next-line
const omitInternals = obj => _.pickBy(obj, (v, k) => !k.startsWith('$')); // more performant than omit()

const colSnapshotToObject = (snapshot, targetRef) => {
	// add key and additional $-prefixed variables
	// do not store ref object itself since it changes after every update; therefore, keep only ref path string
	const items = _.map(snapshot.docs,
		doc => {
			const data = doc.data();
			return ({
				$id: doc.id,
				$ref: doc.ref.path,
				$targetRefs: {
					master: !!targetRef && !!_.get(data, 'targets.master') ? targetRef(data.targets.master, doc.id) : undefined,
					slave: !!targetRef && !!_.get(data, 'targets.slave') ? targetRef(data.targets.slave, doc.id) : undefined,
				},
				...data
			})
		}
	);
	return {items};
};

/*
	master methods
 */
/*export const getDocumentsByRole = (bundleId, role) => {
	const $collection = `bundles/${bundleId}/stickers`;
	return fs.collection($collection).where("profileDoc.role", "==", role).get();
};*/

/*
	payload methods
 */

export let listeners = {}; // collect onSnapshot listeners to later detach them

const getPayloadId = (payloadId, stickerId) => payloadId === "private" ? stickerId : payloadId;

export const getPayload = (bundleId, stickerId, payloadId) => {
	return {$ref: `bundles/${bundleId}/payloads/${getPayloadId(payloadId, stickerId)}`};
};

// bundles/<bundleId>/payloads/<payloadId>/nodes
export const subscribeToNodesChange = (bundleId, stickerId, payloadId, callback) => {
	const $collection = `bundles/${bundleId}/payloads/${payloadId}/nodes`;
	// public payload requires order field to be available
	const col = payloadId === "public" ? fs.collection($collection).orderBy("order") : fs.collection($collection);
	// calculate target reference for new subnodes
	const targetRef = (target, nodeId) => {
		return `bundles/${bundleId}/payloads/${getPayloadId(target, stickerId)}/nodes/${nodeId}/subnodes`;
	};

	listeners[`payload-${payloadId}-nodes`] = col
		.onSnapshot(nodesSnap => callback(colSnapshotToObject(nodesSnap, targetRef)));
};

export const subscribeToSubnodesChange = (bundleId, stickerId, payloadId, nodeId, callback) => {
	const $collection = `bundles/${bundleId}/payloads/${getPayloadId(payloadId, stickerId)}/nodes/${nodeId}/subnodes`;

	listeners[`payload-${payloadId}-nodes-${nodeId}-subnodes`] = fs.collection($collection)
		.orderBy('order') // requires field to be available
		.onSnapshot(subnodesSnap => callback(colSnapshotToObject(subnodesSnap)));
};

export const unsubscribeListeners = () => {
	_.forEach(listeners, listener => listener());
	listeners = {};
};

/*
	node methods
 */

export const postDocument = (col, doc) => fs.collection(col).add({...omitInternals(doc), created: serverTimestamp()});

export const updateDocument = (doc, modified = false) => {
	return fs.doc(doc.$ref).update(modified ? {...omitInternals(doc), modified: serverTimestamp()} : {...omitInternals(doc)});
};

export const deleteDocument = doc => fs.doc(doc.$ref).delete(); // subnodes and GCS items are handled by backend

/**
 *  node operations
 **/
export const postNode = (payload, node, fields) => postDocument(`${payload.$ref}/nodes`, {...node, ...fields});

export const updateNodes = (col, properties) => {
	const batch = fs.batch();
	_.forEach(col, doc => batch.update(fs.doc(doc.$ref), properties));
	return batch.commit();
};

// update order of all nodes in collection at the same time
export const updateNodesOrder = col => {
	const batch = fs.batch();
	_.forEach(col, doc => batch.update(fs.doc(doc.$ref), {order: doc.order}));
	return batch.commit();
};

export const updateNodeProperties = (node, properties) => {
	const doc = {...node, properties: {...node.properties, ...properties}};
	updateDocument(doc, true);
};

export const updateNodeFilters = (node, filters) => {
	const doc = {...node, filters: {...node.filters, ...filters}};
	updateDocument(doc, true);
};

export const updateNodeStats = (node, properties) => {
	const doc = {...node, stats: {...node.stats, ...properties}};
	updateDocument(doc, true);
};


/**
 *  subnode operations
 **/
export const postSubnode = (targetRef, subnode) => postDocument(targetRef, subnode);

export const postSubnodeAndFile = (targetRef, subnode, file, fileName, stickerId, onTick, onComplete, onError) => {
	return postSubnode(targetRef, subnode).then(nodeRef => {
		const storagePath = `${nodeRef.path}/${fileName}`;
		const uploadTask = storage.child(storagePath).put(file, {
			customMetadata: {
				nodePath: nodeRef.path,
				stickerId // keep a reference to the owner
			}
		});

		uploadTask.on('state_changed', onTick, onError, () => {
			nodeRef.update({
				storage: {
					fileLoc: storagePath,
					fileUrl: "",
					thumbLoc: "",
					thumbUrl: "",
					totalSize: 0, //uploadTask.snapshot.totalBytes
				}
			}).then(() => onComplete(uploadTask.snapshot))
		});

		return nodeRef;
	});
};