import React from 'react';
import {Formik} from 'formik';

import {withIntl} from 'components/hocs'
import {Wizard} from 'components/controls'
import ActionFooter from 'components/controls/wizard/Footers/ActionFooter'
import SimplePages from 'components/controls/wizard/Pages/SimplePages'

const FormContainer = ({
	                       T,
	                       initialValues = {},
	                       validationSchema, onSubmit, actionButtons, FormComponent,
	                       HeaderComponent, StepperComponent, LabelComponent,
	                       PagesComponent = SimplePages,
	                       FooterComponent = ActionFooter,
	                       formProps = {},
	                       ...otherProps
                       }) => (
	<Formik
		initialValues={initialValues}
		onSubmit={onSubmit}
		validationSchema={validationSchema}
		validateOnBlur={false}
		validateOnChange={false}
		{...formProps}
	>
		{formProps => (
			<Wizard
				HeaderComponent={HeaderComponent}
				StepperComponent={StepperComponent}
				LabelComponent={LabelComponent}
				PagesComponent={PagesComponent}
				FooterComponent={FooterComponent}
				T={T}
			>
				<FormComponent
					{...otherProps}
					formProps={formProps}
					actionButtons={() => actionButtons && actionButtons({formProps, ...otherProps})}
					T={T}
				/>
			</Wizard>
		)}
	</Formik>
);

export default withIntl(FormContainer);