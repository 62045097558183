export default {
	GTM_EVENT_PORTAL_LOADED: "GTM_EVENT/PORTAL_LOADED",
	GTM_EVENT_STICKER_LOADED: "GTM_EVENT/STICKER_LOADED",
	GTM_EVENT_ONBOARDING_ACKNOWLEDGED: "GTM_EVENT/ONBOARDING_ACKNOWLEDGED",
	GTM_EVENT_SCROLLDEPTH_VERTICAL: "GTM_EVENT/SCROLLDEPTH_VERTICAL",
	GTM_EVENT_CUSTOM: "GTM_EVENT/CUSTOM",
	GTM_EVENT_CLICK: "GTM_EVENT/CLICK",
	GTM_EVENT_VIDEO_READY: "GTM_EVENT/VIDEO_READY",
	GTM_EVENT_VALIDATION_ERROR: "GTM_EVENT/VALIDATION_ERROR",
	GTM_EVENT_LOCALE_CHANGE: "GTM_EVENT/LOCALE_CHANGE"
};
