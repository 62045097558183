export default ({stickerId, order = 1000}) => ({
	type: "custom-hvch-button-assistance",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		telno: "",
		slackChannel: "https://hooks.slack.com/services/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",
		slackText: "Anruf von *{{stickerProps.owner}}*: KFZ-Vertragsnummer: `{{stickerProps.polno}}`",
	}
})