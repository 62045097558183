import React, {Component} from 'react';
import Cleave from 'cleave.js/react'
import {connect} from 'react-redux'
import _ from 'lib/lodash'

import {scannerActions} from 'components/handlers/actions'

class WebCodeScanner extends Component {
	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['placeholder', 'options', 'data', 'error']);
	}

	componentWillUnmount() {
		this.props.resetScannerHandler();
	}

	onChange = event => {
		const {length, encodePrefix} = this.props;
		const {rawValue} = event.target;

		if (rawValue.length === length) this.props.handleWebCodeData(encodePrefix, rawValue);
		if (rawValue === "#ENV" || rawValue === "#VERSION") {
			const {name = "", version = "", description = ""} = window.CONFIG;
			window.alert(`Name: ${name}\nVersion: ${version}\nDescription: ${description}`);
		}
	};

	render() {
		const {placeholder, options} = this.props;

		return (
			<Cleave
				placeholder={placeholder}
				options={options}
				onChange={this.onChange}
				autoFocus
			/>
		);

		/*return (
			<ReactCodeInput
				type="text"
				fields={9}
				autoFocus
				inputStyle={{
					fontFamily: 'monospace',
					MozAppearance: 'textfield',
					margin: '2px',
					paddingLeft: '4px',
					width: '24px',
					height: '42px',
					fontSize: '24px',
					boxSizing: 'border-box',
					textTransform: 'uppercase',
					backgroundColor: 'lightgray',
					border: 'none',
					fontWeight: '700'
				}}
			/>
		)*/
	}
}

const mapStateToProps = ({scannerHandler}) => scannerHandler;

export default connect(mapStateToProps, scannerActions)(WebCodeScanner);