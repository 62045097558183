import React from 'react'
import {ListItem} from 'lib/react-md'

import * as factories from 'components/nodes/factories'
import icons from 'components/nodes/icons'

const addNode = (actions, {targets, itemFactory}) => () => {
	actions.postNode(targets, itemFactory);
	actions.closeDrawer();
};

const C = "CustomNodeActions";

const CustomNodeActions = ({T, actions}) => ({
	customAIDACallbackFormCollectionPrivate:
		<ListItem
			key="custom-aida-callback-form-collection-private"
			primaryText={T(C, 'customAIDACallbackFormCollectionPrivate.primary')}
			leftIcon={icons['custom-aida-callback-form-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "private"}, itemFactory: factories.CustomAIDACallbackFormCollectionFactory})}
		/>,
});

export default CustomNodeActions;