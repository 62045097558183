import React from 'react'
import {ExpansionList, ExpansionPanel} from 'lib/react-md'

import css from './InfoPanel.module.scss'

const InfoPanel = ({label, children}) => (
	<ExpansionList className={css.container}>
		<ExpansionPanel
			label={label}
			footer={null}
			zDepth={0}
			animateContent={false}
		>
			{children}
		</ExpansionPanel>
	</ExpansionList>
);

export default InfoPanel