/* polyfills */
//import '@firebase/polyfill'
//import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import WebFontLoader from 'webfontloader'
//import {whyDidYouUpdate} from 'why-did-you-update';

import {disableConsole} from 'lib/logger'
import {disableReactDevTools} from 'lib/utils';
//import registerServiceWorker from 'services/registerServiceWorker'
import App from 'components/App'
import './index.scss'

window.CONFIG = require('../package.json'); // application configuration settings

if (process.env.NODE_ENV !== 'production') {
	//whyDidYouUpdate(React, { exclude: /^(Button|Toolbar|InkContainer|withInk|Portal|Paper|withTooltip|Tab|FontIcon|AccessibleFakeButton|Motion)/ });
} else {
	// disable React Dev Tools for production environment
	disableReactDevTools();
	disableConsole();
}

console.log(`Running app in ${process.env.NODE_ENV} build / ${process.env.REACT_APP_ENV} mode`);

// use custom (local) fonts to prevent glitches during load
WebFontLoader.load({
	custom: {
		families: ['Roboto:400,500,700', 'Material Icons']
	},
	google: {
		families: ['Caveat:700']
	}
});

ReactDOM.render(<App/>, document.getElementById('root'));

//registerServiceWorker();