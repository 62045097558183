import React from 'react';
import styled from 'styled-components/macro';
import {FormErrors} from 'components/forms'

const FormErrorsWrapper = ({className, styled, children, ...otherProps}) =>
	<FormErrors className={className} {...otherProps}>{children}</FormErrors>;

export default styled(FormErrorsWrapper)`
  #errorText {
  	font-size: 16px;
  	color: ${({styled, theme}) => theme.alertTextColor};
  }
`;