import React, {PureComponent} from 'react';
import {connect} from 'react-redux'
import VisibilitySensor from "react-visibility-sensor";

import {gtm} from 'components/events'

import "./GTMVisibilityObserver.scss";

class GTMVisibilityObserver extends PureComponent {
	state = {
		fired: false
	};

	onVisibilityChange = isVisible => {
		const {dispatch, ...eventProps} = this.props;

		if (isVisible) {
			this.setState({fired: true}, () => dispatch({type: gtm.GTM_EVENT_SCROLLDEPTH_VERTICAL, ...eventProps}));
		}
	};

	render() {
		if (this.state.fired) return null;

		return (
			<VisibilitySensor onChange={this.onVisibilityChange}>
				<div className="visibility-observer-scrolldepth-vertical">&hairsp;</div>
			</VisibilitySensor>
		);
	}
}

export default connect()(GTMVisibilityObserver);