import React from 'react'
import {FontIcon} from 'lib/react-md'

export default {
	"button-collection": <FontIcon>call</FontIcon>,
	"contact-collection": <FontIcon>contact_mail</FontIcon>,
	"document-collection": <FontIcon>picture_as_pdf</FontIcon>,
	"feedback-collection": <FontIcon>feedback</FontIcon>,
	"image-collection": <FontIcon>photo_camera</FontIcon>,
	"image360-collection": <FontIcon>3d_rotation</FontIcon>,
	"link-collection": <FontIcon>link</FontIcon>,
	"post-collection": <FontIcon>question_answer</FontIcon>,
	"text-collection": <FontIcon>text_format</FontIcon>,
	"video-collection": <FontIcon>videocam</FontIcon>,
	"video360-collection": <FontIcon>3d_rotation</FontIcon>,
	"custom-collection": <FontIcon>code</FontIcon>,

	"custom-hvch-button-assistance-collection": <FontIcon>touch_app</FontIcon>,
	"custom-hvch-claim-collection": <FontIcon>assignment</FontIcon>,

	//"custom-aida-callback-form-collection": <FontIcon>assignment</FontIcon>,
};