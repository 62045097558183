import {loadingMiddleware} from './loading-middleware'
import {infoNotificationMiddleware, errorNotificationMiddleware} from './notification-middleware'
import {scannerMiddleware} from './scanner-middleware'
import {importComponentMiddleware, importTranslationsMiddleware} from './import-middleware'
import {gtmEventMiddleware, gtmErrorMiddleware} from './gtm-middleware';

export default [
	loadingMiddleware,
	infoNotificationMiddleware,
	errorNotificationMiddleware,
	importComponentMiddleware,
	importTranslationsMiddleware,
	scannerMiddleware,
	gtmEventMiddleware,
	gtmErrorMiddleware
]; // must be an array as order matters