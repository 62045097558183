import {notificationActions} from 'components/handlers/actions'
import {authTypes, scannerTypes} from 'components/handlers/types'

export const infoNotificationMiddleware = store => next => action => {
	next(action);

	if (!action.type.endsWith("_INFO")) return; // only focus on info messages
	if (action.type.startsWith("NOTIFICATION")) return; // ignore notifications

	const {addInfoToast} = notificationActions;
	const {id = "", params = {}} = action.info;

	store.dispatch(addInfoToast(!!id ? {section: "info", id, params} : action.info));
};

export const errorNotificationMiddleware = store => next => action => {
	next(action);

	if (!action.type.endsWith('_ERROR')) return; // only focus on errors
	if (action.type.startsWith('NOTIFICATION')) return; // ignore notifications
	if (action.type.startsWith('GTM_EVENT')) return; // ignore GTM event errors

	const {addErrorToast} = notificationActions;
	const {code = "", message = "", details = {}} = action.error || {};
	const {portalId, stickerId, status} = details;

	if (action.type === authTypes.AUTH_USER_CLAIMS_ERROR) {
		switch (code) {
			case "INVALID_PORTAL_ID":
			case "PORTAL_NOT_FOUND":
				return store.dispatch(addErrorToast({section: "error", id: code, params: {portalId}}));

			case "INVALID_PORTAL_STATUS":
			case "INVALID_STICKER_STATUS":
				return status !== "inactive" ? store.dispatch(addErrorToast({section: "error", id: code, params: {status}})) : null;

			case "INVALID_STICKER_ID":
				return store.dispatch(addErrorToast({section: "error", id: code, params: {stickerId}}));

			case "TAG_NOT_FOUND":
			case "auth/invalid-custom-token":
				return store.dispatch(addErrorToast({section: "error", id: code}));

			default:
				return store.dispatch(addErrorToast({section: "error", id: code}));
		}
	}

	if (action.type === scannerTypes.QR_SCAN_ERROR) {
		return store.dispatch(addErrorToast({section: "error", id: message}));
	}

	switch (message) {
		case "":
			return store.dispatch(addErrorToast("No error message given."));

		case "FILE_TOO_LARGE_ERROR":
			return store.dispatch(addErrorToast({section: "error", id: "fileTooLarge", params: {size: action.size}}));

		default:
			return store.dispatch(addErrorToast(message));
	}
};