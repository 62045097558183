import React, {PureComponent} from 'react';
import {Button} from 'lib/react-md'

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {CustomFactory} from 'components/nodes/factories'
import {TextFieldEdit} from 'components/controls'

import css from './CustomCollectionMaster.module.scss'

class ViewComponent extends PureComponent {
	addButton = () => this.props.actions.postSubnode(this.props.item, "master", CustomFactory);

	render() {
		const {children, allowAdd} = this.props;

		const AddItem = () => (
			<div className="styles-dropzone">
				<Button icon onClick={this.addButton}>add</Button>
			</div>
		);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.container}>
					<div className={css.content}>
						{children}
					</div>
					<div className={css.footer}>
						{allowAdd && <AddItem/>}
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties = {}} = item;
		const {component = "", data = ""} = properties;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="component"
					label="component"
					rows={2}
					maxRows={3}
					value={{component}}
					onSubmit={this.updateProperty}
				/>
				<TextFieldEdit
					id="data"
					label="data"
					value={{data}}
					rows={5}
					maxRows={5}
					onSubmit={this.updateProperty}
				/>
			</div>
		);
	}
}

export default {
	ContainerComponent: Wrappers.ContainerComponentWrapper,
	HeaderComponent: Wrappers.HeaderComponentWrapper,
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, {...props, hideSave: false})
}