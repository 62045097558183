import React from 'react'

import FormContainer from 'components/forms/FormContainer';
import FormOther from './FormOther'

const PageOther = ({substep, cancel, onSubmit, actions, item, images, onClearForm}) => {
	const FormComponent = FormOther({substep, cancel, actions, item, images, onClearForm});

	return (
		<FormContainer
			onSubmit={onSubmit}
			FormComponent={FormComponent}/>
	);
};

export default PageOther;