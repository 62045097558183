import React, {Component} from 'react'
import {Button} from 'lib/react-md'

import css from './OnboardingActions.module.scss'

class OnboardingActions extends Component {

	render() {
		const {leftAction, rightAction, isLoading} = this.props;

		return (
			<div className={css.container}>
				{leftAction && <Button flat primary onClick={leftAction.action}>{leftAction.label}</Button>}
				{!isLoading && rightAction && <Button flat primary onClick={rightAction.action}>{rightAction.label}</Button>}
				{isLoading && <Button flat primary disabled>Bitte warten...</Button>}
			</div>
		);
	}
}

export default OnboardingActions