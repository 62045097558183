import React from 'react';
import PropTypes from 'prop-types'
import imageCompression from 'browser-image-compression';

export default function ImageUploaderCompressorViewer({options, onStart, onComplete, onError}) {
	const onChange = async event => {
		let imageFile = event.target.files[0];

		onStart();

		try {
			const compressedFile = await imageCompression(imageFile, options);
			onComplete({imageFile, compressedFile});

		} catch (error) {
			onError(error);
		}
	};

	return (
		<>
			<input type="file" name="file" onChange={onChange}/>
		</>
	);
}

ImageUploaderCompressorViewer.propTypes = {
	options: PropTypes.shape({
		maxSizeMB: PropTypes.number,
		maxWidthOrHeight: PropTypes.number,
		useWebWorker: PropTypes.bool,
		maxIteration: PropTypes.number,
		fileType: PropTypes.string,
		onProgress: PropTypes.func
	}),
	onStart: PropTypes.func,
	onComplete: PropTypes.func,
};

ImageUploaderCompressorViewer.defaultProps = {
	options: {
		maxSizeMB: Number.POSITIVE_INFINITY,
		maxWidthOrHeight: undefined,
		useWebWorker: true,
		maxIteration: 10,
		fileType: 'image/jpeg',
		onProgress: () => null
	},
	onStart: () => null,
	onComplete: () => null
};