import React from 'react';

import {SlaveNodesList} from 'components/containers'
import {withIntl} from 'components/hocs'

import css from './SlaveMain.module.scss'

const SlaveMain = ({sticker, locale}) => (
	<div className={css.container}>
		<SlaveNodesList
			keyPrefix={`slave-${locale}`}
			subnodeFilters={{
				language: locale,
				stickerId: sticker.id
			}}
		/>
	</div>
);

export default withIntl(SlaveMain);