import {db} from 'services'
import {LS} from 'lib/constants'
import _ from 'lib/lodash'

/* action types */
export const types = {
	DATA_OBJECTS_INIT: 'DATA/OBJECTS_INIT',
	DATA_PAYLOADS_INIT: 'DATA/PAYLOADS_INIT',

	DATA_NODES_CHANGE: 'DATA/NODES_CHANGE',
	DATA_SUBNODES_CHANGE: 'DATA/SUBNODES_CHANGE',

	DATA_LOAD_SUCCESS: 'DATA/LOAD_SUCCESS',
	DATA_UPDATE_LOADING: 'DATA/UPDATE_LOADING',
	DATA_HIDE_LOADING: 'DATA/HIDE_LOADING',

	DATA_RESET_STATE: 'DATA/RESET_STATE'
};

/* initial state */
const initialState = {
	profileId: "",
	languages: {},
	portal: {},
	bundle: {},
	sticker: {},
	payloads: {},
	nodes: {},
	subnodes: {},

	error: null,
	dataLS: LS.INIT
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.DATA_OBJECTS_INIT:
			return {
				...state,
				profileId: action.profileId,
				languages: action.languages,
				portal: action.portal,
				bundle: action.bundle,
				sticker: action.sticker
			};

		case types.DATA_PAYLOADS_INIT:
			return {
				...state,
				payloads: _.transform(action.payloadIds, (res, id) => res[id] = db.getPayload(action.bundleId, action.stickerId, id), {})
			};

		case types.DATA_NODES_CHANGE:
			return {...state, nodes: action.nodes};

		case types.DATA_SUBNODES_CHANGE:
			const subnodes = state.subnodes[action.payloadId];
			return {...state, subnodes: {...state.subnodes, [action.payloadId]: {...subnodes, [action.nodeId]: action.subnodes}}};

		case types.DATA_LOAD_SUCCESS:
			return {...state, dataLS: LS.LOADED};

		case types.DATA_RESET_STATE:
			return initialState;

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	setDataObjects: objects => dispatch => {
		db.setBundle(objects.bundle); // set storage bucket
		dispatch({type: types.DATA_OBJECTS_INIT, ...objects});
	},

	subscribeToPublicPayloadChange: (bundleId, stickerId, payloadIds) => dispatch => {
		dispatch({type: types.DATA_PAYLOADS_INIT, bundleId, stickerId, payloadIds});

		if (_.has(db.listeners, `payload-public-nodes`)) return;

		if (_.isEmpty(payloadIds)) { // e.g. in case of sticker.status !== "active"
			dispatch({type: types.DATA_NODES_CHANGE, payloadId: "public", nodes: []});
			return;
		}

		let i = 0;
		db.subscribeToNodesChange(bundleId, stickerId, "public", nodes => {
			const {items} = nodes;

			_.forEach(items, ({$id: nodeId, targets}) => {
				_.forEach(targets, payloadId => {
					if (_.has(db.listeners, `payload-${payloadId}-nodes-${nodeId}-subnodes`)) return;

					db.subscribeToSubnodesChange(bundleId, stickerId, payloadId, nodeId,
						subnodes => dispatch({type: types.DATA_SUBNODES_CHANGE, payloadId, nodeId, subnodes})
					);

					if (++i <= items.length) { // while 100% not reached update progress bar on loader
						dispatch({type: types.DATA_UPDATE_LOADING, addRel: i / (items.length || 1), main: "sticker.main.nodes"});
					}
				});
			});

			dispatch({type: types.DATA_NODES_CHANGE, payloadId: "public", nodes});
		});
	},

	dataLoadingFinished: () => dispatch => {
		dispatch({type: types.DATA_LOAD_SUCCESS});
		dispatch({type: types.DATA_HIDE_LOADING});
	},

	resetDataHandler: () => dispatch => {
		db.unsubscribeListeners();
		dispatch({type: types.DATA_RESET_STATE});
	}
};