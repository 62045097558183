import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer, StickerPickerContainer} from 'components/containers'
import {TextFieldEdit} from 'components/controls'

import css from './CustomMaster.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent} = this.props;
		const {properties = {}, filters = {}} = item;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					{filters.language && <Flag className={css.flag} code={filters.language}/>}
					{properties.component}
					<div className={css.icon}>
						<EditComponent/>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties = {}, filters = {}} = item;
		const {component = "", data = ""} = properties;
		const {language, stickers} = filters;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="component"
					label="component"
					rows={2}
					maxRows={3}
					value={{component}}
					onSubmit={this.updateProperty}
				/>
				<TextFieldEdit
					id="data"
					label="data"
					value={{data}}
					rows={5}
					maxRows={5}
					onSubmit={this.updateProperty}
				/>
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
				<StickerPickerContainer
					defaultValue={{stickers}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}