import React from 'react';

import css from './SlaveHeader.module.scss'

//const C = "profiles.default.SlaveHeader";

const SlaveHeader = () => (
	<div className={css.container}>
	</div>
);

export default SlaveHeader;