/* action types */
export const types = {
	NOTIFICATION_ADD_INFO_TOAST: 'NOTIFICATION/ADD_INFO_TOAST',
	NOTIFICATION_DISMISS_INFO_TOAST: 'NOTIFICATION/DISMISS_INFO_TOAST',
	NOTIFICATION_ADD_ERROR_TOAST: 'NOTIFICATION/ADD_ERROR_TOAST',
	NOTIFICATION_DISMISS_ERROR_TOAST: 'NOTIFICATION/DISMISS_ERROR_TOAST',
};

/* initial state */
const initialState = {
	infoToasts: [],     // shape: [ {text, action}, ... ], action shape: node | { onClick:fct, children:node, label:node }
	errorToasts: [],     // shape: [ {text, action}, ... ], action shape: node | { onClick:fct, children:node, label:node }
	autohideToasts: true
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.NOTIFICATION_ADD_INFO_TOAST:
			const infoToast = {text: action.text, action: action.action};
			return {...state, infoToasts: [...state.infoToasts, infoToast]};

		case types.NOTIFICATION_DISMISS_INFO_TOAST:
			const [, ...infoToasts] = state.infoToasts; // dismiss first element from array
			return {...state, infoToasts};

		case types.NOTIFICATION_ADD_ERROR_TOAST:
			const errorToast = {text: action.text, action: action.action};
			return {...state, errorToasts: [...state.errorToasts, errorToast]};

		case types.NOTIFICATION_DISMISS_ERROR_TOAST:
			const [, ...errorToasts] = state.errorToasts; // dismiss first element from array
			return {...state, errorToasts};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	addInfoToast: (text, action) => ({type: types.NOTIFICATION_ADD_INFO_TOAST, text, action}),
	dismissInfoToast: () => ({type: types.NOTIFICATION_DISMISS_INFO_TOAST}),

	addErrorToast: (text, action) => ({type: types.NOTIFICATION_ADD_ERROR_TOAST, text, action}),
	dismissErrorToast: () => ({type: types.NOTIFICATION_DISMISS_ERROR_TOAST}),
};