import React, {PureComponent} from 'react';
import {Tab, Tabs, TabsContainer, Toolbar} from 'lib/react-md'

import SlaveMenu from './SlaveMenu';
import SlaveHeader from './SlaveHeader'
import SlaveMain from './SlaveMain'
import SlaveFooter from './SlaveFooter'

import css from './Slave.module.scss';

class Slave extends PureComponent {
	state = {
		activeTabIndex: 0,
	};

	changeTabIndex = activeTabIndex => this.setState({activeTabIndex});

	render() {
		const {locale, bundle, sticker, languages} = this.props;
		const {activeTabIndex} = this.state;
		const {properties = {}} = sticker;
		const {title = ""} = properties;

		const Header = (
			<Toolbar
				className={css.toolbar}
				title={title}
				nav={<SlaveMenu languages={languages} bundle={bundle} sticker={sticker}/>}
				zDepth={0}
			/>
		);

		return (
			<TabsContainer
				className={css.container}
				colored
				toolbar={Header}
				swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
				activeTabIndex={activeTabIndex}
				onTabChange={this.changeTabIndex}
			>
				<Tabs tabId={`tabs-${locale}`}>
					<Tab key={`tab-${locale}`} label="" className={css.tab}>
						<main>
							<SlaveHeader/>
							<SlaveMain sticker={sticker} locale={locale}/>
							<SlaveFooter/>
						</main>
					</Tab>
				</Tabs>
			</TabsContainer>
		);
	}
}

export default Slave