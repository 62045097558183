import React, {PureComponent} from 'react'

import {BasisContainer, DataContainer, LayoutContainer} from 'components/containers'
import {withIntl} from 'components/hocs'

import Slave from './Slave'

class SlaveContainer extends PureComponent {
	render() {
		const {intl, T, profileId, languages, portal, bundle, sticker} = this.props;
		const {locale} = intl;

		const data = {profileId, languages, portal, bundle, sticker};

		return (
			<BasisContainer {...data}>
				<LayoutContainer theme="">
					<DataContainer {...data} payloadIds={['public', 'shared', 'private']}>
						<Slave T={T} locale={locale} {...data}/>
					</DataContainer>
				</LayoutContainer>
			</BasisContainer>
		);
	}
}

export default withIntl(SlaveContainer);