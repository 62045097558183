export default ({stickerId, order = 1000}) => ({
	type: "image360",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	storage: {
		fileLoc: "",
		fileUrl: "",
		thumbLoc: "",
		thumbUrl: "",
		status: "",
		metadata: {},
		totalSize: 0
	},
	properties: {
		label: "",
		description: "",
		author: "",
		hotspots: [],
		likes: 0,
		dislikes: 0,
		ranking: 0
	}
})