import React from 'react'
import {TextFieldEdit} from 'components/controls'
import {Button} from 'lib/react-md'

import css from './SlaveHeader.module.scss';

const SlaveHeader = ({T, sticker, changeTabIndex}) => {
	const {properties = {}} = sticker;
	const {owner = "", polno = ""} = properties;

	return (
		<div className={css.container}>
			<div className={css.fields}>
				<TextFieldEdit
					id="owner"
					label="overview.owner"
					value={{owner}}
					className={css.field}
					readOnly
				/>
				<TextFieldEdit
					id="polno"
					label="overview.polno"
					value={{polno}}
					readOnly
				/>
			</div>
			<div className={css.buttonContainer}>
				<Button
					flat
					primary
					onClick={() => changeTabIndex(1)}
					className={css.button}
				>
					{T("profiles.demo-insurance.Slave", "tabClaims")}
				</Button>
			</div>
		</div>
	);
};

export default SlaveHeader;