import {RichText} from 'prismic-reactjs';
import {api} from 'services'

const linkResolver = doc => {};

const hrefResolver = doc => {};

const getUserPage = ({uid, lang}) => api.auth().post("cms/cmsGetByUid", {type: "user", uid, lang});

export {
	RichText,

	linkResolver,
	hrefResolver,

	getUserPage
};
