import React, {Component} from 'react';
import nodeIcons from 'components/nodes/icons'
import icons from 'lib/icons'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'

import css from './HeaderComponentWrapper.module.scss'

class HeaderComponentWrapper extends Component {
	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['item']);
	}

	onHeaderClick = () => {
		const {item, actions, allowEdit, allowSubnodeAdd} = this.props;
		const {$subnodeCount} = item;
		const allowHeaderClick = allowEdit && (allowSubnodeAdd || $subnodeCount > 0);

		// TODO: item is out of sync sometimes when dragged around!
		if (allowHeaderClick) actions.updateDocument({$ref: item.$ref, expanded: !item.expanded});
	};

	render() {
		const {T, item, EditComponent, allowEdit, target} = this.props;
		const {type, $subnodeCount, targets, properties = {}} = item;
		const {component} = properties; // in case of custom-collection

		const itemTarget = _.get(targets, target, "");

		return (
			<div className={css.header}>
				<div className={css[`target-${itemTarget}`]}>{nodeIcons[type]}</div>
				<div className={css.title} onClick={this.onHeaderClick}>
					<div className={css.main}>{component || T("Media", type)}</div>
					<div className={css.sub}>
						<h6>{icons[itemTarget]} {T("Targets", itemTarget)}</h6>
						{$subnodeCount > 0 && <h6>{icons["count"]} {$subnodeCount}</h6>}
					</div>
				</div>
				<div className={css.actions}>
					{allowEdit && EditComponent && <EditComponent/>}
				</div>
			</div>
		)
	};
}

export default withIntl(HeaderComponentWrapper);