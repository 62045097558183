import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'
import css from './ButtonCollectionSlave.module.scss'

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	render() {
		const {children} = this.props;

		return (
			<Wrappers.ViewComponentWrapper>
				{children}
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent
}