import React, {PureComponent} from 'react'

export default class FileSelector extends PureComponent {
	constructor(props) {
		super(props);
		this.fileUploadControl = React.createRef();
	}

	onClick = () => this.fileUploadControl.current.click();

	onChange = e => {
		if (e.target.files.length > 0) {
			this.props.onFileSelected(e.target.files[0]);
			e.target.value = "";	// important, otherwise uploading same file again would not work
		}
	};

	/* PureComponent: re-rendered only if props change */
	render() {
		const accept = this.props.accept || "*";
		const capture = this.props.capture || false;

		return (
			<input
				ref={this.fileUploadControl}
				type="file"
				accept={accept}
				capture={capture}
				onChange={this.onChange}
				//style={{display: "none"}}
			/>
		)
	}
}