export default ({stickerId, order = 1000}) => ({
	type: "text",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		label: "",
		purpose: "title"
	},
	style: {
		fontSize: "24px",
		fontWeight: 500,
		marginTop: "0",
		marginBottom: "0"
	}
})