import React from 'react';

import Snowman from './Snowman'
import {Container} from './CustomXMASSlave.styles'

const CustomXMASSlave = props => {
	const {actions} = props;
	const stickerProps = actions.getStickerProperties();
	const {title, body, footer} = stickerProps;

	return (
		<Container>
			<Snowman/>

			<div id="content">
				<div id="container">
					<div>{title}</div>
					<div>{body}</div>
					<div>{footer}</div>
				</div>
			</div>
		</Container>
	);
};

export default CustomXMASSlave;