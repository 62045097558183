const requireComponent = (component, role) => require(`./${component}/${role}`).default;
const requireCustomComponent = (component, role) => require(`../../${component}/${role}`).default;

export const master = {
	"button": requireComponent("button", "master"),
	"contact": requireComponent("contact", "master"),
	"document": requireComponent("document", "master"),
	"feedback": requireComponent("feedback", "master"),
	"image": requireComponent("image", "master"),
	"image360": requireComponent("image360", "master"),
	"link": requireComponent("link", "master"),
	"post": requireComponent("post", "master"),
	"text": requireComponent("text", "master"),
	"video": requireComponent("video", "master"),
	"video360": requireComponent("video360", "master"),
	"custom": requireComponent("custom", "master"),

	"custom-hvch-button-assistance":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-button-assistance", "master"),
	"custom-hvch-claim":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-claim", "master"),

	//"custom-aida-callback-form":
	//	requireCustomComponent("profiles/aida/nodes/custom-aida-callback-form", "master"),
};

export const slave = {
	"button": requireComponent("button", "slave"),
	"contact": requireComponent("contact", "slave"),
	"document": requireComponent("document", "slave"),
	"feedback": requireComponent("feedback", "slave"),
	"image": requireComponent("image", "slave"),
	"image360": requireComponent("image360", "slave"),
	"link": requireComponent("link", "slave"),
	"post": requireComponent("post", "slave"),
	"text": requireComponent("text", "slave"),
	"video": requireComponent("video", "slave"),
	"video360": requireComponent("video360", "slave"),
	"custom": requireComponent("custom", "slave"),

	"custom-hvch-button-assistance":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-button-assistance", "slave"),
	"custom-hvch-claim":
		requireCustomComponent("profiles/helvetia-ch/nodes/custom-hvch-claim", "slave"),

	//"custom-aida-callback-form":
	//	requireCustomComponent("profiles/aida/nodes/custom-aida-callback-form", "slave"),
};
