import React from 'react'
import _ from 'lib/lodash'

import FormContainer from 'components/forms/FormContainer';
import {Wizard} from 'components/controls'
import ActionFooter from 'components/controls/wizard/Footers/ActionFooter'
import {useFormState} from 'components/hooks';
import ThemedSimpleLabel from '../ThemedSimpleLabel';
import IconStepper from '../PageClaim/ThemedIconStepper';
import PageDriverInfo from './PageDriverInfo';
import PageToolbar from '../PageToolbar';

const C = "profiles.folder-f.PageDriver";

const PageDriver = ({T, actions, previousStep, nextStep, userInfo, setUserInfo, sticker}) => {
	const [formState, dispatch] = useFormState();
	const {substep} = formState;

	const onPreviousStep = () => {
		dispatch({type: "FORM_GOTO_PREVIOUS_STEP"});
		previousStep();
	};

	const onSubmit = ({driver}, {setSubmitting}) => {
		if (driver.driverLicenseAttached === "image") {
			driver.driverLicenseId = "";
			driver.driverLicenseIssuer = "";
			driver.driverLicenseClasses = "";
		} else if (driver.driverLicenseAttached === "manual") {
			driver.driverLicenseImage = "";
		}

		driver.driverPlate = _.get(sticker, 'properties.plate');

		const updatedUserInfo = _.merge({}, userInfo, {driver});

		dispatch({type: "FORM_SUBMIT_STEP_DRIVER", ...driver});
		setUserInfo(updatedUserInfo);
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		nextStep();
	};

	const FormDriver = () => (
		<Wizard
			HeaderComponent={PageToolbar({title: T(C, "title")})}
			LabelComponent={ThemedSimpleLabel}
			StepperComponent={IconStepper}
			FooterComponent={ActionFooter}
			labelPosition="above"
			initialStepIndex={substep}
			T={T}
			actions={actions}
		>
			<PageDriverInfo
				icon="person"
				label={T(C, "PageDriverInfo.label")}
				cancel={onPreviousStep}
				userInfo={userInfo}
				setUserInfo={setUserInfo}
				submitForm={onSubmit}
			/>
		</Wizard>
	);

	return (
		<FormContainer
			FormComponent={FormDriver}
		/>
	);
};

export default PageDriver;