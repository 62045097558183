import React from 'react'
import {connect} from 'react-redux';

import {layoutActions} from 'components/handlers/actions'
import {withIntl} from 'components/hocs'

import css from './FaqLink.module.scss'

const C = "FaqLink";

const FaqLink = ({T, openDrawer}) => {
	const openFAQDrawer = () => openDrawer("FAQ");
	const label = T(C, 'label');

	return <p className={css.container} onClick={openFAQDrawer}>{label}</p>;
};

export default withIntl(connect(null, layoutActions)(FaqLink));
