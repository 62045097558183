import React, {PureComponent} from 'react'
import {Button} from 'lib/react-md'

import {withIntlDrawer} from 'components/hocs'
import history from '../../../../../history';

class StickerMenu extends PureComponent {
	navigateToPortal = () => history.push('./');

	render() {
		return (
			<Button
				icon
				onClick={this.navigateToPortal}
			>
				home
			</Button>
		);
	}
}

export default withIntlDrawer(StickerMenu, {name: "StickerMenu"});