import React from 'react'
import {SelectionControlGroup} from 'lib/react-md'
import icons from 'lib/icons'

const CheckboxGroup = ({id, label, controls, defaultValue, onChange}) => (
	<div className="md-text-field-container md-full-width md-text-field-container--multiline">
		<label htmlFor={id} className="md-floating-label md-floating-label--floating md-text--secondary">{label}</label>
		<div className="md-text-field-multiline-container md-text-field--floating-margin">
			<SelectionControlGroup
				id={id}
				name={id}
				type="checkbox"
				inline
				controls={controls}
				defaultValue={defaultValue}
				checkedCheckboxIcon={icons.check}
				uncheckedCheckboxIcon={icons.uncheck}
				onChange={onChange}
			/>
		</div>
	</div>
);

export default CheckboxGroup;