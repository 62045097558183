import {Component} from 'react';
import _ from 'lib/lodash'

class EditComponentWrapper extends Component {
	state = {
		item: this.props.item
	};

	shouldComponentUpdate(nextProps, nextState) {
		return _.anyDiff(nextProps, this.props, ['item', 'subitems']) || !_.isEqual(nextState, this.state);
	}

	componentDidUpdate(prevProps) {
		if (_.anyDiff(this.props, prevProps, ['item'])) {
			this.setState({item: this.props.item});
		}
	}

	updateDocument = () => {
		const modified = _.anyDiff(this.props, this.state, ['item']); // check whether item has actually been modified at all

		this.props.actions.updateDocument(this.state.item, modified);
	};

	updateField = (key, value, updateImmediately) => {
		this.setState(
			state => ({item: {...state.item, [key]: {...state.item[key], ...value}}}),
			() => updateImmediately && this.updateDocument() // required for feedback items
		);
	};

	updateProperty = (property, updateImmediately = false) => this.updateField('properties', property, updateImmediately);
	updateConstraint = (constraint, updateImmediately = false) => this.updateField('constraints', constraint, updateImmediately);
	updateFilter = (filter, updateImmediately = false) => this.updateField('filters', filter, updateImmediately);
	updateStyle = (style, updateImmediately = false) => this.updateField('style', style, updateImmediately);

	getItem = () => this.state.item; // required by withEditDrawer(...) to access wrappedComponent.item
}

export default EditComponentWrapper