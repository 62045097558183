import React, {PureComponent} from 'react';
import {connect} from 'react-redux'
import {IntlProvider} from 'react-intl'

import {LS} from 'lib/constants'

import {localeActions} from 'components/handlers/actions'

export const LocaleContext = React.createContext();

class RawLocaleProvider extends PureComponent {
	componentDidMount() {
		const {localeLS, setDefaultLocale} = this.props;

		if (localeLS === LS.INIT) setDefaultLocale();
	}

	render() {
		const {locale, formats, messages, changeLocale, children, localeLS} = this.props;

		const renderIntlProvider = () => (
			<IntlProvider locale={locale} key={locale} formats={formats} messages={messages}>
				<>
					{children}
				</>
			</IntlProvider>
		);

		return (
			<LocaleContext.Provider value={{
				locale,
				changeLocale
			}}>
				{localeLS === LS.LOADED && renderIntlProvider()}
			</LocaleContext.Provider>
		)
	}
}

const mapStateToProps = ({localeHandler}) => localeHandler;

export const LocaleProvider = connect(mapStateToProps, localeActions)(RawLocaleProvider);