import React, {PureComponent} from 'react'
import {connect} from 'react-redux'

import ConfirmationDialog from 'components/controls/ConfirmationDialog'
import {withIntl} from 'components/hocs'

import {onboardingActions} from 'components/handlers/actions';

const APIVERSION = process.env.REACT_APP_FIREBASE_APIVERSION;
const APISUBVERSION = process.env.REACT_APP_FIREBASE_APISUBVERSION;

const withIntlCookieInfo = (WrappedComponent, options = {}) => {

	const {C = "CookieInfo"} = options;

	class CookieContainer extends PureComponent {
		state = {
			confirmVisible: true
		};

		getStorageItem = () => {
			const {bundleId: optBundleId = false, stickerId: optStickerId = false} = options;
			const {profileId, bundle = {}, sticker = {}} = this.props;
			const {id: bundleId = ""} = bundle;
			const {id: stickerId = ""} = sticker;

			var storageItem = `stx-${APIVERSION}-${APISUBVERSION}-cookieaccept-${profileId}`;
			storageItem += optBundleId && !!bundleId ? "-" + bundleId : "";
			storageItem += optStickerId && !!stickerId ? "-" + stickerId : "";

			return storageItem;
		};

		componentDidMount() {
			const storageItem = this.getStorageItem();

			// check if dialog has already been accepted once
			const accepted = localStorage.getItem(storageItem) === "true" || false;

			this.setState({confirmVisible: !accepted});
		}

		onAccept = () => {
			const storageItem = this.getStorageItem();

			// store acceptance information
			localStorage.setItem(storageItem, "true");
			this.props.onboardingAcknowledged();

			this.setState({confirmVisible: false});
		};


		renderConfirmationDialog = () => {
			const {T} = this.props;

			return (
				<ConfirmationDialog
					id="cookie-info-confirmation"
					visible
					title={T(C, "title")}
					actions={[{secondary: false, children: T(C, "buttonAcceptLabel"), onClick: this.onAccept}]}
				>
					<div>
						<p id="cookieMainText">{T(C, "mainText")}</p>
						<p id="cookieSubText">{T(C, "subText")} <a href={T(C, "linkHref")} data-gtm-action="CookieInfo:CookieInfo">{T(C, "linkTitle")}</a></p>
					</div>
				</ConfirmationDialog>
			);
		};

		render() {
			const {confirmVisible} = this.state;

			return confirmVisible ? this.renderConfirmationDialog() : <WrappedComponent {...this.props}/>;
		}
	}

	return connect(null, onboardingActions)(withIntl(CookieContainer));
};

export default withIntlCookieInfo;