import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'

import css from './FeedbackCollectionSlave.module.scss'

class ContainerComponent extends PureComponent {
	render() {
		const {subitems} = this.props;

		if (_.isEmpty(subitems)) return null;

		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave"/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	render() {
		const {children} = this.props;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.content}>
					{children}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent
}