import React from 'react';
import {Divider, Subheader} from 'lib/react-md'

import {ActionContainer} from 'components/containers'
import {DataConsumer} from 'components/contexts'
import CustomNodeActions from 'profiles/aida/nodes/customActions'

const actionsList = {
	public: ({A}) => [
		A.infoPanelPublic,
		A.textCollectionPublic,

		A.subheaderMediaPublic,
		A.imageCollectionPublic,
		A.image360CollectionPublic,
		A.videoCollectionPublic,
		A.video360CollectionPublic,
		A.documentCollectionPublic,
		<Divider key="divider2"/>,
		A.linkCollectionPublic,
		A.contactCollectionPublic,
		A.buttonCollectionPublic
	],
	shared: ({A}) => [
		A.infoPanelShared,
		A.postCollectionShared,
		A.imageCollectionShared,
		A.videoCollectionShared,
		A.contactCollectionShared
	],
	private: ({T, A, CA}) => [
		A.infoPanelPrivate,
		A.feedbackCollectionPublic,
		A.imageCollectionPrivate,
		A.videoCollectionPrivate,
		A.documentCollectionPrivate,
		<Subheader
			key="subheader-custom-elements-private"
			primary
			primaryText={T('profiles.aida.NodeActions', 'subheaderCustomFormsPrivate.primary')}
		/>,
		CA.customAIDACallbackFormCollectionPrivate,
	]
};

const ActionsMenu = () => (
	<DataConsumer>
		{({isUploading, actions}) => !isUploading &&
			<ActionContainer actions={actions} actionsList={actionsList} CustomNodeActions={CustomNodeActions} icon="add"/>
		}
	</DataConsumer>
);

export default ActionsMenu;
