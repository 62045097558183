import React from 'react';

import {SlaveNodesList} from 'components/containers'
import {withIntl} from 'components/hocs'
import {GTMVisibilityObserver} from 'components/observers'
//import {Tooltip} from 'components/controls'

import css from './SlaveMain.module.scss'

/*const Tooltip1 = () => (
	<Tooltip
		title="Bitte beachten Sie, dass Drohnen an Bord unserer Schiffe nicht benutzt werden dürfen."
		position="top"
		trigger="mouseenter click"
		theme="light"
		size="big"
	>
		<span className={css.tooltipText}>Drohne</span>
	</Tooltip>
);*/

const C = "profiles.aida.SlaveMain";

const SlaveMain = ({sticker, locale}) => (
	<div className={css.container}>
		<SlaveNodesList
			keyPrefix={`slave-${locale}-video`}
			nodeFilters={{section: "video"}}
			subnodeFilters={{
				language: locale,
				stickerId: sticker.id
			}}
		/>
		<div id="contentStart" className={css.main}>
			Lassen Sie sich jetzt von AIDA inspirieren und nutzen Sie die Chance, eine Drohne im Wert von 250€ zu gewinnen!
		</div>
		<div style={{height: "16px"}}/>
		<div className={css.subBold}>Wie Sie teilnehmen können:</div>
		<div className={css.sub}>1. Formular ausfüllen</div>
		<div className={css.sub}>2. Termin für persönliche Reiseberatung vereinbaren</div>
		<div className={css.sub}>3. Mit etwas Glück eine Drohne gewinnen!</div>
		<div className={css.form}>
			<SlaveNodesList
				keyPrefix={`slave-${locale}-form`}
				nodeFilters={{section: "form"}}
				subnodeFilters={{
					language: locale,
					stickerId: sticker.id
				}}
			/>
		</div>
		<GTMVisibilityObserver eventAction={C} eventLabel="Bis zum Gewinnspiel" eventValue={50}/>
	</div>
);

export default withIntl(SlaveMain);