import React from 'react';
import styled from 'styled-components/macro';
import {FormImageList} from 'components/forms'

const FormImageListWrapper = ({className, ...otherProps}) => (
	<FormImageList className={className} {...otherProps}/>
);

export default styled(FormImageListWrapper)`
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	> div {
		margin-top: 8px;
		
		> div {
			padding: 8px;
		}
	}
`;