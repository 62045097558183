import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl} from 'components/hocs'

import css from './CustomCollectionSlave.module.scss'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g; // string template variables are stored in form {{var}}

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends Wrappers.EditComponentWrapper {
	state = {
		...this.state, // important
		Component: () => <div>Custom Collection not available</div>
	};

	componentDidMount() {
		const {item, actions} = this.props;
		const {properties = {}} = item;
		const {component = ""} = properties;

		if (component === "") {
			this.setState({Component: null});
			return null;
		}

		try {
			const Component = React.lazy(() => import(`custom-nodes/${component}`));
			this.setState({Component});
		} catch (e) {
			actions.raiseCustomError(new Error("Custom Collection could be loaded."));
		}
	}

	render() {
		const {item, actions, children} = this.props;
		const {properties = {}} = item;
		const {data = ""} = properties;
		let dataObj = {};

		const {Component} = this.state;

		const bundleId = actions.getBundleId();
		const stickerId = actions.getStickerId();
		const bundleProps = actions.getBundleProperties();
		const stickerProps = actions.getStickerProperties();
		const obj = {bundleId, bundleProps, stickerId, stickerProps, ...properties};

		try {
			const interpolatedText = _.template(data)(obj); // use {{obj.<variableName>}} to access its values
			dataObj = JSON.parse(interpolatedText || "{}");
		} catch (e) {
			actions.raiseCustomError(new Error("Custom Collection data object not valid."));
		}

		return (
			<React.Suspense fallback={<div>Loading custom collection...</div>}>
				<Wrappers.ViewComponentWrapper>
					{!!Component && <Component {...this.props} dataObj={dataObj} updateProperty={this.updateProperty}/>}
					{!Component && children}
				</Wrappers.ViewComponentWrapper>
			</React.Suspense>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent: withIntl(ViewComponent),
}