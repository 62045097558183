import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import {List, ListItem} from 'lib/react-md'
import _ from 'lib/lodash'

const withDraggableList = ({ListComponent = List, ListItemComponent = ListItem}) => {

	const SortableItem = SortableElement(ListItemComponent); // TODO: possible performance bug in react-sortable-hoc?

	const SortableList = SortableContainer(({items, keyPrefix, ...otherProps}) => (
		<ListComponent className="md-list-unstyled">
			{_.map(items, (item, index) => (
				<SortableItem
					key={`${keyPrefix}-item-${item.$id}`}
					index={index}
					item={item}
					keyPrefix={keyPrefix}
					{...otherProps}
				/>))
			}
		</ListComponent>
	));

	class DraggableList extends Component {
		static propTypes = {
			items: PropTypes.arrayOf(PropTypes.shape({
				$id: PropTypes.string.isRequired
			})).isRequired,
			onDragEnd: PropTypes.func,
		};

		static defaultProps = {
			onDragEnd: _.noop,
			useDragHandle: false
		};

		shouldComponentUpdate(nextProps) {
			return _.anyDiff(nextProps, this.props, ['items', 'subnodes']);
		}

		onSortEnd = ({oldIndex, newIndex}) => {
			const {items, onDragEnd} = this.props;

			const sortedItems = arrayMove(items, oldIndex, newIndex); // arrayMove does not mutate source
			_.forEach(sortedItems, (item, index) => item.order = index); // set order property to be persisted in db

			onDragEnd(sortedItems);
		};

		render() {
			const {items, onDragEnd, keyPrefix, useDragHandle, ...otherProps} = this.props;

			if (_.isEmpty(items)) return null;

			return (
				<SortableList
					items={items}
					keyPrefix={keyPrefix}
					lockToContainerEdges
					lockAxis="y"
					useDragHandle={useDragHandle}
					pressDelay={200}
					onSortEnd={this.onSortEnd}
					{...otherProps}
				/>
			);
		}
	}

	return DraggableList;
};

export default withDraggableList;