import React from 'react'
import {TextFieldEdit} from 'components/controls'
import {Button} from 'lib/react-md'

import css from './SlaveHeader.module.scss';

const C = "profiles.helvetia-ch.SlaveHeader";

const SlaveHeader = ({T, sticker, changeTabIndex}) => {
	const {properties = {}} = sticker;
	const {owner = "", polno = ""} = properties;

	const polnoCount = polno.split('/').length;
	const polnoDisplay = polno.split('/').join('\n');

	return (
		<div className={css.container}>
			<div className={css.fields}>
				<TextFieldEdit
					id="owner"
					label="overview.owner"
					value={{owner}}
					rows={owner.length > 16 ? 2 : 1}
					className={css.field}
					readOnly
				/>
				<TextFieldEdit
					id="polno"
					label="overview.polno"
					value={{polno: polnoDisplay}}
					rows={polnoCount}
					readOnly
				/>
			</div>
			<div className={css.buttonContainer}>
				<Button
					flat
					primary
					onClick={() => changeTabIndex(1)}
					className={css.button}
					data-gtm-action={`button:${C}`}
					data-gtm-label="Schadenfall melden"
				>
					{T("profiles.helvetia-ch.Slave", "tabClaims")}
				</Button>
			</div>
		</div>
	);
};

export default SlaveHeader;