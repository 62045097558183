import React from "react";
import styled, {keyframes} from "styled-components/macro";

const BounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

const DotWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
	border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  
  animation: ${BounceAnimation} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

export default ({className}) => (
	<DotWrapper className={className}>
		<Dot delay="-0.32s"/>
		<Dot delay="-0.16s"/>
		<Dot/>
	</DotWrapper>
);