import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {TextFieldEdit} from 'components/controls'

import css from './PostSlave.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent, allowEdit} = this.props;
		const {properties = {}, filters = {}, stickerId = ""} = item;
		const editAllowed = allowEdit(item);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					{filters.language && <Flag className={css.flag} code={filters.language}/>}
					<blockquote
						className={css.label}
					>
						{properties.label}
						<cite>{stickerId}</cite>
					</blockquote>
					<div className={css.icon}>
						{editAllowed && <EditComponent/>}
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties = {}} = item;
		const {label} = properties;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit
					id="title"
					label="title"
					value={{label}}
					rows={1}
					maxRows={5}
					onSubmit={this.updateProperty}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}