import React from 'react';

import css from './SlaveHeader.module.scss'

//const C = "profiles.default.SlaveHeader";

const SlaveHeader = () => (
	<div className={css.container}>
		<div className={css.demoContainer}>
			<span className={css.gold}>Gold Premium</span>
			<span className={css.after}>Event Sticker</span>
		</div>
	</div>
);

export default SlaveHeader;