export default ({stickerId, order = 1000}) => ({
	type: "feedback",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		inputType: "",
		label: "",
		value: "",
		options: [],
	}
})