import React from 'react';

export default ({
	                className,
	                field: {name, onChange, value = "", onBlur}, // {name, value, onChange, onBlur}
	                form: {touched, errors}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                showOnlyFirst = false,
	                ...otherProps
                }) => {
	const errorItems = Object.values(errors).map((error, idx) => <div key={idx} id="errorText">{error}</div>);

	return (
		<div className={className}>
			{showOnlyFirst && errorItems.length > 0 ? errorItems[0] : errorItems}
		</div>
	);
}