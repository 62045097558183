export default ({stickerId, order = 1000}) => ({
	type: "custom-hvch-claim",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: []
	},
	properties: {
		category: "",
		location: {
			label: "",
			position: {
				lat: 0,
				lng: 0
			}
		},
		owner: "",
		partyStatus: "",
		partyName: "",
		partyAddress: "",
		partyZip: "",
		partyTel: "",
		partyEmail: "",
		plate: "",
		polno: "",
		subcategory: "",
		comments: "",
		driverName: "",
		driverZip: "",
		driverTelno: "",
		driverEmail: "",
		policeInvolved: ""
	}
})