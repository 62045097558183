export default ({stickerId, order = 1000}) => ({
	type: "link",
	order,
	stickerId,
	filters: {
		language: "",
		stickers: [],
	},
	properties: {
		label: "",
		value: "",
	}
})