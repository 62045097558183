import React from 'react'
import styled from 'styled-components/macro'
import YouTube from 'react-youtube-embed'

import {Wizard} from 'components/controls'
import {StickerFooter} from '../../../../common';
import PageMainToolbar from './PageMainToolbar'
import {StyledButton, StyledInfoBox} from '../../../../controls';

const C = "profiles.folder.Slave";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px;
`;

const MainWrapper = styled.div`
	flex: 1;
	padding-top: 16px;
	
  button {
  	height: 60px;
	  width: 100%;
	  font-size: x-large !important;
  }
`;

const VideoWrapper = styled.div`
  margin: auto;
	max-width: 600px;
`;

const FooterWrapper = styled.div`
	flex: none;
`;

const PageMain = ({T, languages, bundle, sticker}) => {
	const onWebsite = () => window.location.href = T(C, "button.website.href");

	return (
		<Wizard HeaderComponent={PageMainToolbar({T, languages, bundle, sticker})}>
			<Container>
				<MainWrapper>
					<StyledInfoBox styled="primary">
						<div>{T(C, "welcome.label")}</div>
						<br/>
						<div>{T(C, "instruction.label")}</div>
					</StyledInfoBox>

					<VideoWrapper>
						<br/>
						<YouTube
							id={T(C, "youtubeVideoId")}
							appendSrc="?cc_load_policy=1&rel=0"
						/>
					</VideoWrapper>

					<div style={{height: "8px"}}/>
					<StyledButton
						styled="secondary"
						flat
						primary
						onClick={onWebsite}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Zu unserer Website"
					>
						{T(C, "button.website")}
					</StyledButton>
				</MainWrapper>

				<FooterWrapper>
					<StickerFooter/>
				</FooterWrapper>
			</Container>
		</Wizard>
	);
};

export default PageMain;