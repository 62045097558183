import React from 'react';
import styled from 'styled-components/macro';

const LinkWrapper = ({className, styled, children, ...otherProps}) => (
	<a className={className} {...otherProps}>{children}</a>
);

export default styled(LinkWrapper)`
	text-decoration: underline;
  margin: 0 4px;
  font-weight: 500;
  
  color: ${({styled, theme}) => styled === "primary" && theme.tertiaryColor};
`;