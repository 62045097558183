import React, {PureComponent} from 'react'
import {FontIcon, Toolbar, Button} from 'lib/react-md'

import {withIntlDrawer} from 'components/hocs'

import Conditions from './Conditions'
import css from './SlaveMenu.module.scss'
import history from '../../../../../../history';

const C = "profiles.aida.SlaveMenu";

class SlaveMenu extends PureComponent {
	navigateToPortal = () => history.push('./');

	componentDidMount() {
		const {/*intl, T,*/ initDrawer, closeDrawer, bundle, sticker} = this.props;
		const id = bundle.id + '/' + sticker.id;

		const container = (
			<div className={css.container}>
				<Toolbar
					colored
					fixed
					title={id}
					zDepth={0}
					nav={<Button icon onClick={closeDrawer}>close</Button>}
					actions={<Button icon onClick={this.navigateToPortal}>home</Button>}
				/>
				<section>
					<Conditions/>
				</section>
			</div>
		);

		initDrawer({container, position: "left", memoized: true});
	}

	render() {
		const {openDrawer} = this.props;

		return (
			<Button
				icon
				onClick={openDrawer}
				iconEl={<FontIcon data-gtm-action={`button:${C}`} data-gtm-label="Sticker Menü">info_outline</FontIcon>}
			>
			</Button>
		);
	}
}

export default withIntlDrawer(SlaveMenu, {name: "SlaveMenu"});