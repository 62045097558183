import React from 'react';
import {Provider} from 'react-redux';
import {Router, Route, Redirect, Switch} from 'react-router-dom'
import {LoadScript} from '@react-google-maps/api'
//import LoadScript from '@react-google-maps/api/dist/LoadScript'

import {store} from '../redux';
import history from '../history';
import {LocaleProvider} from 'components/contexts'
import {PortalRoute, StickerRoute, ErrorRoute, NotFoundRoute, HVCHFlyerRoute} from 'components/routes'
import {AuthContainer, FaqsContainer, LoadingContainer, NotificationContainer} from 'components/containers'

/* Routing: https://<subdomain>.stx.world/<tagId> */

const ResetApp = () => {
	localStorage.clear();
	console.log('localStorage reset successfully.');

	return <Redirect to="/"/>;
};

const App = () => (
	<Provider store={store}>
		<LocaleProvider>
			<AuthContainer/>
			<Router history={history}>
				<Switch>
					<Route path="/" exact component={PortalRoute}/>
					<Route path="/:tagId(\w{24})" exact component={StickerRoute}/>
					<Route path="/portals/:portalId" exact component={PortalRoute}/>
					<Route path="/portals/:portalId/:tagId(\w{24})" exact component={StickerRoute}/>
					<Route path="/error" exact component={ErrorRoute}/>
					<Route path="/reset" exact component={ResetApp}/>
					<Route path="/helvetia-smartsticker-portal" exact component={HVCHFlyerRoute}/>
					<Route path="/180901/Vw234dfhjsRslkEwndjf" exact component={HVCHFlyerRoute}/>
					<Route path="/portals/:portalId/helvetia-smartsticker-portal" exact component={HVCHFlyerRoute}/>
					<Route path="/portals/:portalId/180901/Vw234dfhjsRslkEwndjf" exact component={HVCHFlyerRoute}/>
					<Route path="*" component={NotFoundRoute}/>
				</Switch>
			</Router>
			<FaqsContainer/>
			<NotificationContainer/>
			<LoadingContainer/>
			<LoadScript
				id="script-loader"
				libraries={["places"]}
				preventGoogleFonts
				googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_APIKEY}
				version={"weekly"}
				region="EU"
				loadingElement={<div>Loading...</div>}
			/>
		</LocaleProvider>
	</Provider>
);

export default App;