import React from 'react'
import {FontIcon, SVGIcon} from 'lib/react-md'

import css from './icons.module.scss'

export default {
	"drag": <FontIcon>drag_handle</FontIcon>,
	"count": <FontIcon>view_module</FontIcon>,

	"public": <FontIcon>public</FontIcon>,
	"shared": <FontIcon>group</FontIcon>,
	"private": <FontIcon>person</FontIcon>,

	"check": <FontIcon>check</FontIcon>,
	"uncheck": <FontIcon style={{color: "#D2D2D2"}}>radio_button_unchecked</FontIcon>,
	"uncheckbox": <FontIcon style={{color: "#D2D2D2"}}>check_box_outline_blank</FontIcon>,
	"checkStateful": <FontIcon className={css.stateful}>check</FontIcon>,

	"toggle-on": props => (
		<SVGIcon {...props} size={24}>
			<path
				d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"/>
			<path fill="none" d="M0 0h24v24H0z"/>
		</SVGIcon>
	),

	"toggle-off": props => (
		<SVGIcon {...props} size={24}>
			<path
				d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zM7 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"/>
			<path fill="none" d="M0 0h24v24H0z"/>
		</SVGIcon>
	),
};