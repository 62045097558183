import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {
	StyledButton, StyledFormCheckboxGroup, StyledErrors
} from '../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../ThemedPageWrapper'

const C = "profiles.helvetia-at.PageClaim.PageGlass.PageDetails";

const PagePartyForm = () => () => {
	const controlsDamages = [
		{label: "Frontscheibe", value: "front"},
		{label: "Heck-/Seitenscheibe", value: "rearside"},
		{label: "Übrige Glasteile", value: "other"}
	];

	const controlsDetails = [
		{label: "im Sichtfeld", value: "view"},
		{label: "kleiner als eine Zwei-Euro-Münze", value: "small"},
		{label: "größer als eine Zwei-Euro-Münze", value: "large"},
		{label: "ein Riss", value: "crack"},
	];

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					name="errors"
					showOnlyFirst
					component={StyledErrors}
				/>
				<Field
					type="text"
					name="damages"
					label="Wo befinden sich die Schäden?"
					component={StyledFormCheckboxGroup}
					controls={controlsDamages}
					inline
				/>
				<Field
					type="text"
					name="details"
					label="Der Schaden ist"
					component={StyledFormCheckboxGroup}
					controls={controlsDetails}
					inline
				/>
			</Form>
		</ThemedPageWrapper>
	);
};

const validationSchema = Yup.object().shape({
	damages: Yup.string().required("Bitte geben Sie an, welche Fahrzeugteile betroffen sind."),
	details: Yup.string().required("Bitte geben Sie Details zum Schaden an."),
});

const PageSpecifics = ({wizardProps: {T, actions}, item, images}) => {
	const [formState, dispatch] = useFormState();
	const {damages, details} = formState;
	const initialValues = {damages, details};

	const FormComponent = PagePartyForm();

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_GLASS_DETAILS", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageSpecifics;