import React, {PureComponent} from 'react';
import {connect} from 'react-redux'
import {Tab, Tabs, TabsContainer} from 'lib/react-md'
import _ from 'lib/lodash'

import {onboardingActions} from 'components/handlers/actions'
import {withIntl} from 'components/hocs'

import css from './OnboardingContainer.module.scss'

class OnboardingContainer extends PureComponent {
	state = {
		activePageNo: 1,
		noPages: _.size(this.props.PageComponents),
		isLoading: false
	};

	componentDidMount() {
		const {disabled} = this.props;

		if (!disabled) this.props.onboardingInitialize();
	}

	onSpecPage = pageNo => () => {
		this.setState(({activePageNo, noPages}) => ({
			activePageNo: pageNo >= 1 && pageNo <= noPages ? pageNo : activePageNo
		}));
	};

	onPrevPage = () => {
		this.setState(({activePageNo}) => ({
			activePageNo: activePageNo > 1 ? activePageNo - 1 : activePageNo
		}));
	};

	onNextPage = () => {
		this.setState(({activePageNo, noPages}) => ({
			activePageNo: activePageNo >= 1 && activePageNo < noPages ? activePageNo + 1 : activePageNo
		}));
	};

	onFinish = () => {
		this.setState({isLoading: true}, () => this.props.onFinish());
		this.props.onboardingAcknowledged();
	};

	changeTabIndex = activeTabIndex => this.setState({activePageNo: activeTabIndex + 1});

	renderPages = () => {
		const {T, PageComponents} = this.props;
		const {activePageNo, noPages, isLoading} = this.state;

		return (
			<>
				<TabsContainer
					colored
					headerZDepth={0}
					swipeableViewsProps={{disabled: false, id: "swipeView"}}
					//slideStyle={{height: "600px"}}
					activeTabIndex={activePageNo - 1}
					onTabChange={this.changeTabIndex}
					className={css.tabsContainer}
				>
					<Tabs tabId="onboarding-pages">
						{_.map(PageComponents, (Page, idx) => (
							<Tab key={`onboarding-page-${idx + 1}`} label={`onboarding-page-${idx + 1}`} className={css.tab}>
								<Page
									T={T}
									isLoading={isLoading}
									onFirstPage={this.onSpecPage(1)}
									onPrevPage={this.onPrevPage}
									onNextPage={this.onNextPage}
									onLastPage={this.onSpecPage(noPages)}
									onSpecPage={this.onSpecPage}
									onFinish={this.onFinish}
								/>
							</Tab>
						))}
					</Tabs>
				</TabsContainer>
			</>
		)
	};

	render() {
		return !this.props.disabled ? this.renderPages() : this.props.children;
	}
}

export default connect(null, onboardingActions)(withIntl(OnboardingContainer));
