import React from 'react'

import {FormContainer} from 'components/forms';
import {StyledButton, StyledLink, StyledInfoBox} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Person.PageInfo";

const PageInfoForm = ({T}) => (
	<ThemedPageWrapper>
		<StyledInfoBox styled="primary">
			<div>{T(C, "info.line1")}</div>
			<StyledLink styled="primary" href="tel:112">{T(C, "info.link1")}</StyledLink>
			{T(C, "info.line2")}
			<StyledLink styled="primary" href="tel:112">{T(C, "info.link2")}</StyledLink>
			<div>{T(C, "info.line3")}</div>
		</StyledInfoBox>
	</ThemedPageWrapper>
);

const PageParty = ({wizardProps: {T, actions}}) => {
	const [, dispatch] = useFormState();

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_INFO", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			onSubmit={onSubmit}
			FormComponent={PageInfoForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageParty;