import React, {PureComponent} from 'react';

import {Tab, Tabs, TabsContainer, Toolbar} from 'lib/react-md'

import SlaveMenu from './StickerMenu';
import SlaveFooter from './StickerFooter'

import css from './StickerInactive.module.scss';
import "../../../styles.scss" // profile-wide styles

const C = "profiles.demo-insurance.Slave";

class StickerInactive extends PureComponent {
	render() {
		const {T, locale, bundle, sticker, languages} = this.props;
		const id = bundle.id + '/' + sticker.id;

		const Logo = (
			<div className={css.logo}>
				<img src={require('../../../img/insurance_logo.svg')} alt="logo"/>
			</div>
		);

		const Header = (
			<Toolbar
				className={css.toolbar}
				title=""
				nav={<SlaveMenu languages={languages} bundle={bundle} sticker={sticker}/>}
				actions={Logo}
				zDepth={0}
			/>
		);

		return (
			<TabsContainer
				className={css.container}
				colored
				toolbar={Header}
				swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
				swipeableViewsClassName={css.swipeableViewsContainer}
			>
				<Tabs tabId={`tabs-${locale}`}>
					<Tab key={`tab-overview-${locale}`} label="" className={css.tab}>
						<main>
							<div className={css.infoBoxContainer}>
								<div className="infoBoxPrimary">{T(C, `stickerStatus.${sticker.status}`, {id})}</div>
							</div>
							<SlaveFooter/>
						</main>
					</Tab>
				</Tabs>
			</TabsContainer>
		);
	}
}

export default StickerInactive