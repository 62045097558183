import React from 'react'
import styled from 'styled-components/macro'

const FooterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: orange;
	
	button {
		flex: 1;
		margin: 0;
		
		:before {
			all: unset; // important to fix button margins
		}
		
		:after {
			all: unset; // important to fix button margins
		}
	}
`;

const Footer = ({className, pageProps, activeStepIndex, ...wizardProps}) => {
	const activePageProps = pageProps[activeStepIndex];

	return (
		<FooterWrapper className={className}>
			{activePageProps.actionButtons && activePageProps.actionButtons({...wizardProps, pageProps: activePageProps})}
		</FooterWrapper>
	);
};

export default Footer;