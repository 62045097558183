import React, {Component} from 'react';
import Geosuggest from 'react-geosuggest';
import Geolocation from 'react-geolocation'
import {GoogleMap, Marker} from "@react-google-maps/api"
//import GoogleMap from '@react-google-maps/api/lib/GoogleMap'
//import Marker from '@react-google-maps/api/lib/components/drawing/Marker'

import {Button, FontIcon} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'
import {convertToDMSString} from 'lib/utils';

import css from './LocationPicker.module.scss'

const C = "LocationPicker";

const LocationMap = ({mapLocation, markerLabel, onClick}) => (
	<GoogleMap
		id="google-maps"
		mapContainerStyle={{
			height: "250px"
		}}
		zoom={14}
		center={mapLocation}
		onClick={onClick}
		options={{streetViewControl: false}}
	>
		<Marker position={mapLocation} label={markerLabel}/>
	</GoogleMap>
);

class LocationPicker extends Component {
	state = {
		key: "",
		currentLocation: {lng: 0, lat: 0},
		mapLocation: {lng: 0, lat: 0},
		mapLabel: ""
	};

	componentDidMount() {
		const value = this.props.value;
		const key = Object.keys(value)[0];

		this.setState({
			key,
			mapLabel: _.get(value[key], "label", ""),
			mapLocation: _.get(value[key], "position", {lng: 0, lat: 0})
		});
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(prevProps.value, this.props.value)) {
			const value = this.props.value;
			const key = Object.keys(value)[0];

			this.setState({
				key,
				mapLabel: _.get(value[key], "label", ""),
				mapLocation: _.get(value[key], "position", {lng: 0, lat: 0})
			});
		}
	}

	onChange = key => () => {
		const {mapLabel, mapLocation} = this.state;

		const value = {
			label: mapLabel,
			position: mapLocation
		};

		this.props.onSubmit({[key]: value});
	};

	getAddressFromLocation = ({lat, lng}) => {
		const location = new window.google.maps.LatLng({lat, lng});
		const geocoder = new window.google.maps.Geocoder();

		return new Promise(resolve => {
			geocoder.geocode({location}, results => {
				resolve(_.get(results, '[0].formatted_address', ""));
			});
		});
	};

	render() {
		const {T, id, label, markerLabel, className, suggestionClassName, suggestionButtonClassName} = this.props;
		const {currentLocation, mapLocation, mapLabel} = this.state;

		const onSuccess = async ({coords}) => {
			const {longitude: lng, latitude: lat} = coords;
			const address = await this.getAddressFromLocation({lng, lat});

			this.setState({
				currentLocation: {lng, lat},
				mapLocation: {lng, lat},
				mapLabel: `${address} (${convertToDMSString({lng, lat})})`
			}, this.onChange(this.state.key));
		};

		const onClick = async ({latLng}) => {
			const lng = latLng.lng();
			const lat = latLng.lat();
			const address = await this.getAddressFromLocation({lng, lat});

			this.setState({
				mapLocation: {lng, lat},
				mapLabel: `${address} (${convertToDMSString({lng, lat})})`
			}, this.onChange(this.state.key));
		};

		const onSuggestSelect = position => {
			const label = _.get(position, "label", ""); // cannot be destructured if position = null
			const location = _.get(position, "location", {lng: 0, lat: 0}); // cannot be destructured if position = null
			const lng = _.get(location, 'lng', 0);
			const lat = _.get(location, 'lat', 0);

			this.setState({
				mapLocation: {lng, lat},
				mapLabel: label
			}, this.onChange(this.state.key));
		};

		const onChange = label => {
			this.setState({
				mapLocation: {lng: 0, lat: 0},
				mapLabel: label,
			}, this.onChange(this.state.key));
		};

		const clearSuggestion = () => onChange("");

		return (
			<div className={css.container}>
				<div className={className}>
					<div className="md-text-field-container md-full-width md-text-field-container--input">
						<label
							htmlFor={id}
							className="md-floating-label md-floating-label--floating md-text--secondary"
						>
							{label && T("label", label)}
						</label>
					</div>
					<Geolocation
						loadingElement={<div style={{height: "100%"}}/>}
						lazy
						onSuccess={onSuccess}
						render={({fetchingPosition, error, getCurrentPosition}) => {
							const currentLocationValid = !!currentLocation.lng && !!currentLocation.lat;
							const mapLocationValid = !!mapLocation.lng && !!mapLocation.lat;

							const location = currentLocationValid
								? new window.google.maps.LatLng({lat: currentLocation.lat, lng: currentLocation.lng}) : undefined;
							const placeholder = fetchingPosition ? T(C, "placeholder.fetching") : T(C, "placeholder.empty");
							const radius = location && 1000; // 1km

							return (
								<div className={css.wrapper}>
									<div className={`${css.suggestion} ${suggestionClassName}`}>
										<Button className={css.button} icon onClick={clearSuggestion}>clear</Button>
										<Geosuggest
											className={css.geosuggestWrapper}
											initialValue={mapLabel}
											location={location}
											placeholder={placeholder}
											radius={radius}
											onChange={onChange}
											onSuggestSelect={onSuggestSelect}
											placeDetailFields={[]}
										/>
										<Button
											icon
											className={`${css.button} ${suggestionButtonClassName}`}
											onClick={getCurrentPosition}
											iconEl={<FontIcon data-gtm-action={`button:${C}`} data-gtm-label="Aktuelle Position">location_on</FontIcon>}
										/>
									</div>
									{fetchingPosition && mapLabel !== "" && <div className={css.placeholder}>{placeholder}</div>}
									{error && <div className={css.placeholder}>{error.message}</div>}
									{mapLocationValid && (
										<LocationMap
											markerLabel={!!markerLabel ? T("label", markerLabel) : ""}
											mapLocation={mapLocation}
											onClick={onClick}
										/>
									)}
								</div>
							);
						}}/>
				</div>
			</div>
		);
	}
}

export default withIntl(LocationPicker)