import React, {PureComponent} from 'react';
import {Button} from 'lib/react-md'

import * as Wrappers from 'components/wrappers'
import css from './ContactCollectionSlave.module.scss'
import {ContactFactory} from 'components/nodes/factories'

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	addContact = () => this.props.actions.postSubnode(this.props.item, "slave", ContactFactory);

	render() {
		const {children, allowAdd} = this.props;

		const AddItem = () => (
			<div className="styles-dropzone">
				<Button icon onClick={this.addContact}>add</Button>
			</div>
		);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.content}>
					{children}
				</div>
				<div className={css.footer}>
					{allowAdd && <AddItem/>}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent
}