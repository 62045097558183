import React from 'react'

import css from './LoadingDots.module.scss'

const LoadingDots = ({styleClassName}) => (
	<span className={css.spinner}>
		<div className={styleClassName}>
			<span className={css.bounce1}/>
			<span className={css.bounce2}/>
			<span className={css.bounce3}/>
		</div>
	</span>
);

export default LoadingDots;