import React from 'react'
import PropTypes from 'prop-types'

import {Card, CardText, FontIcon} from 'lib/react-md'
import {QRScanner} from 'components/scanners'

import css from './TabQRReader.module.scss'

const C = "templates.portal.TabQRReader";

const TabQRReader = ({cameraInfoText, onNextTab}) => (
	<Card className={css.card}>
		<CardText className={css.cardText}>
			<div className={css.info}>
				<FontIcon onClick={onNextTab} data-gtm-action={`button:${C}`} data-gtm-label="Webcode">cancel</FontIcon>
				{cameraInfoText}
			</div>
			<div className={css.qrscanner}>
				<QRScanner/>
			</div>
		</CardText>
	</Card>
);

TabQRReader.propTypes = {
	cameraInfoText: PropTypes.string.isRequired,
	onNextTab: PropTypes.func.isRequired,
};

export default TabQRReader