import React from 'react'
import {ListItem, Subheader} from 'lib/react-md'

import {InfoPanel} from 'components/utils'
import {FaqLink} from 'components/faq'
import * as factories from 'components/nodes/factories'
import icons from 'components/nodes/icons'

const addNode = (actions, {targets, itemFactory}) => () => {
	actions.postNode(targets, itemFactory);
	actions.closeDrawer();
};

const C = "NodeActions";

const NodeActions = ({T, actions}) => ({
	infoPanelPublic:
		<InfoPanel
			key="infopanel-public"
			label={T(C, 'infoPanelPublic.label')}
		>
			{T(C, 'infoPanelPublic.text')}
			<FaqLink/>
		</InfoPanel>,
	infoPanelShared:
		<InfoPanel
			key="infopanel-shared"
			label={T(C, 'infoPanelShared.label')}
		>
			{T(C, 'infoPanelShared.text')}
			<FaqLink/>
		</InfoPanel>,
	infoPanelPrivate:
		<InfoPanel
			key="infopanel-private"
			label={T(C, 'infoPanelPrivate.label')}
		>
			{T(C, 'infoPanelPrivate.text')}
			<FaqLink/>
		</InfoPanel>,

	subheaderMediaPublic:
		<Subheader
			key="subheader-media-public"
			primary
			primaryText={T(C, 'subheaderMediaPublic.primary')}
		/>,

	textCollectionPublic:
		<ListItem
			key="text-collection-public"
			primaryText={T(C, 'textCollectionPublic.primary')}
			leftIcon={icons['text-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.TextCollectionFactory})}
		/>,

	imageCollectionPublic:
		<ListItem
			key="image-collection-public"
			primaryText={T(C, 'imageCollectionPublic.primary')}
			leftIcon={icons['image-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.ImageCollectionFactory})}
		/>,
	imageCollectionShared:
		<ListItem
			key="image-collection-shared"
			primaryText={T(C, 'imageCollectionShared.primary')}
			secondaryText={T(C, 'imageCollectionShared.secondary')}
			leftIcon={icons['image-collection']}
			onClick={addNode(actions, {targets: {master: "shared", slave: "shared"}, itemFactory: factories.ImageCollectionFactory})}
		/>,
	imageCollectionPrivate:
		<ListItem
			key="image-collection-private"
			primaryText={T(C, 'imageCollectionPrivate.primary')}
			leftIcon={icons['image-collection']}
			onClick={addNode(actions, {targets: {master: "private", slave: "private"}, itemFactory: factories.ImageCollectionFactory})}
		/>,

	image360CollectionPublic:
		<ListItem
			key="image360-collection-public"
			primaryText={T(C, 'image360CollectionPublic.primary')}
			leftIcon={icons['image360-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.Image360CollectionFactory})}
		/>,

	videoCollectionPublic:
		<ListItem
			key="video-collection-public"
			primaryText={T(C, 'videoCollectionPublic.primary')}
			leftIcon={icons['video-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.VideoCollectionFactory})}
		/>,
	videoCollectionShared:
		<ListItem
			key="video-collection-shared"
			primaryText={T(C, 'videoCollectionShared.primary')}
			secondaryText={T(C, 'videoCollectionShared.secondary')}
			leftIcon={icons['video-collection']}
			onClick={addNode(actions, {targets: {master: "shared", slave: "shared"}, itemFactory: factories.VideoCollectionFactory})}
		/>,
	videoCollectionPrivate:
		<ListItem
			key="video-collection-private"
			primaryText={T(C, 'videoCollectionPrivate.primary')}
			leftIcon={icons['video-collection']}
			onClick={addNode(actions, {targets: {master: "private", slave: "private"}, itemFactory: factories.VideoCollectionFactory})}
		/>,

	video360CollectionPublic:
		<ListItem
			key="video360-collection-public"
			primaryText={T(C, 'video360CollectionPublic.primary')}
			leftIcon={icons['video360-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.Video360CollectionFactory})}
		/>,

	customCollectionPublic:
		<ListItem
			key="custom-collection-public"
			primaryText={T(C, 'customCollectionPublic.primary')}
			leftIcon={icons['custom-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.CustomCollectionFactory})}
		/>,
	customCollectionShared:
		<ListItem
			key="custom-collection-shared"
			primaryText={T(C, 'customCollectionShared.primary')}
			leftIcon={icons['custom-collection']}
			onClick={addNode(actions, {targets: {master: "shared", slave: "shared"}, itemFactory: factories.CustomCollectionFactory})}
		/>,
	customCollectionPrivate:
		<ListItem
			key="custom-collection-private"
			primaryText={T(C, 'customCollectionPrivate.primary')}
			leftIcon={icons['custom-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "private"}, itemFactory: factories.CustomCollectionFactory})}
		/>,

	documentCollectionPublic:
		<ListItem
			key="document-collection-public"
			primaryText={T(C, 'documentCollectionPublic.primary')}
			leftIcon={icons['document-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.DocumentCollectionFactory})}
		/>,
	documentCollectionPrivate:
		<ListItem
			key="document-collection-private"
			primaryText={T(C, 'documentCollectionPrivate.primary')}
			leftIcon={icons['document-collection']}
			onClick={addNode(actions, {targets: {master: "private", slave: "private"}, itemFactory: factories.DocumentCollectionFactory})}
		/>,

	feedbackCollectionPublic:
		<ListItem
			key="feedback-collection-public"
			primaryText={T(C, 'feedbackCollectionPublic.primary')}
			secondaryText={T(C, 'feedbackCollectionPublic.secondary')}
			leftIcon={icons['feedback-collection']}
			onClick={addNode(actions, {
				targets: {master: "public", slave: "private"},
				itemFactory: factories.FeedbackCollectionFactory
			})}
		/>,
	postCollectionShared:
		<ListItem
			key="post-collection-shared"
			primaryText={T(C, 'postCollectionShared.primary')}
			secondaryText={T(C, 'postCollectionShared.secondary')}
			leftIcon={icons['post-collection']}
			onClick={addNode(actions, {targets: {master: "shared", slave: "shared"}, itemFactory: factories.PostCollectionFactory})}
		/>,
	contactCollectionPublic:
		<ListItem
			key="contact-collection-public"
			primaryText={T(C, 'contactCollectionPublic.primary')}
			leftIcon={icons['contact-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.ContactCollectionFactory})}
		/>,
	contactCollectionShared:
		<ListItem
			key="contact-collection-shared"
			primaryText={T(C, 'contactCollectionShared.primary')}
			secondaryText={T(C, 'contactCollectionShared.secondary')}
			leftIcon={icons['contact-collection']}
			onClick={addNode(actions, {targets: {master: "shared", slave: "shared"}, itemFactory: factories.ContactCollectionFactory})}
		/>,
	linkCollectionPublic:
		<ListItem
			key="link-collection-public"
			primaryText={T(C, 'linkCollectionPublic.primary')}
			leftIcon={icons['link-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.LinkCollectionFactory})}
		/>,
	buttonCollectionPublic:
		<ListItem
			key="button-collection-public"
			primaryText={T(C, 'buttonCollectionPublic.primary')}
			leftIcon={icons['button-collection']}
			onClick={addNode(actions, {targets: {master: "public", slave: "public"}, itemFactory: factories.ButtonCollectionFactory})}
		/>,
});

export default NodeActions;