import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {LinearProgress} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'

import css from './LoadingContainer.module.scss'

const C = "LoadingContainer";

class LoadingContainer extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		main: PropTypes.string.isRequired,
		sub: PropTypes.string.isRequired,
		imgPath: PropTypes.string.isRequired,
		addAbs: PropTypes.number,
		addRel: PropTypes.number
	};

	constructor(props) {
		super(props);

		this.containerElement = document.createElement('div');
		document.body.appendChild(this.containerElement);
	}

	componentWillUnmount() {
		document.body.removeChild(this.containerElement);
	}

	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ["show", "value", "main", "sub", "title"]);
	}

	render() {
		const {T, title, main, sub, imgPath, show, value} = this.props;

		if (!show) return null;

		const Title = () => <h2>{T(C, title)}</h2>;
		const Main = () => <span>{T(C, main)}</span>;
		const Sub = () => <span>{T(C, sub)}</span>;

		const container = (
			<div className={css.container}>
				<div className={css.top}>
					{imgPath && <img src={require(`img/${imgPath}`)} alt="Sensotix Smart Sticker World" height="60%"/>}
				</div>
				<LinearProgress className={css.progressbar} id="loading-container-linear-progress" value={value}/>
				<div className={css.bottom}>
					<Title/>
					<Main/>
					<Sub/>
				</div>
			</div>
		);

		return ReactDOM.createPortal(container, this.containerElement);
	}
}

const mapStateToProps = ({loadingHandler}) => loadingHandler;

export default withIntl(connect(mapStateToProps)(LoadingContainer));