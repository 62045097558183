import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lib/lodash'

import {dataActions} from 'components/handlers/actions'
import {BasisProvider} from 'components/contexts'

class BasisContainer extends Component {
	componentDidMount() {
		const {profileId, languages, portal, bundle, sticker} = this.props;

		this.props.setDataObjects({profileId, languages, portal, bundle, sticker});
	}

	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps.dataHandler, this.props.dataHandler, ['profileId', 'languages', 'portal', 'bundle', 'sticker'])
			|| _.anyDiff(nextProps, this.props, ['children']);
	}

	componentWillUnmount() {
		this.props.resetDataHandler();
	}

	render() {
		const {dataHandler, children} = this.props;
		const {profileId, languages, portal, bundle, sticker} = dataHandler;

		if (_.isEmpty(bundle) || _.isEmpty(sticker)) return null;

		return (
			<BasisProvider
				profileId={profileId}
				languages={languages}
				portal={portal}
				bundle={bundle}
				sticker={sticker}
			>
				{children}
			</BasisProvider>
		);
	}
}

const mapStateToProps = ({dataHandler}) => ({dataHandler}); // important, otherwise bundle and sticker get overwritten

export default connect(mapStateToProps, dataActions)(BasisContainer);