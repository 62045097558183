import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormTextField} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Animal.PageComments";

const PageCommentsForm = ({T}) => (
	<ThemedPageWrapper>
		<Form>
			<Field
				type="text"
				name="damages"
				label={T(C, "damages.label")}
				component={StyledFormTextField}
				rows={3}
			/>
			<Field
				type="text"
				name="comments"
				label={T(C, "comments.label")}
				component={StyledFormTextField}
				rows={3}
			/>
		</Form>
	</ThemedPageWrapper>
);

const validationSchema = Yup.object().shape({
});

const PageComments = ({wizardProps: {T, actions}, ...otherProps}) => {
	const [formState, dispatch] = useFormState();
	const {damages, comments} = formState;
	const initialValues = {damages, comments};

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_ANIMAL_COMMENTS", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={PageCommentsForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageComments;