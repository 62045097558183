import React from 'react'

import css from './Conditions.module.scss'

const Conditions = () => (
	<div className={css.container}>
		<div className={css.header}>AIDA Gewinnspiel</div>
		<div className={css.header}>Teilnahmebedingungen</div>
		<div className={css.list}>
			<p className={css.subheader}>1. Allgemeines</p>
			<p className={css.textblock}>Mit der Teilnahme an dem gegenständlichen Gewinnspiel (nachfolgend <strong>"Gewinnspiel"</strong> genannt) bestätigen Sie, dass Sie sich mit den nachstehenden Teilnahmeverbindungen einverstanden erklären.</p>
			<p className={css.textblock}>Veranstalter des Gewinnspiels ist AIDA Cruises - German Branch of Costa Crociere S.p.A., Am Strande 3d, 18055 Rostock (im Folgenden <strong>"AIDA&nbsp;Cruises"</strong> genannt).</p>
			<p className={css.subheader}>2. Preise und Gewinnspielzeitraum</p>
			<p className={css.textblock}>Der Gewinnspielzeitraum dauert vom 1.&nbsp;Jänner bis 30.&nbsp;Jänner&nbsp;2019&nbsp;23:59 Uhr. Innerhalb dieses Zeitraums erhalten Teilnahmeberechtigte die Möglichkeit, am Gewinnspiel teilzunehmen. Die Teilnahme ist nur innerhalb des Teilnahmezeitraums möglich. Nach Teilnahmeschluss eingehende Rückruf-Formulare werden bei der Auslosung nicht berücksichtigt. Die Auslosung der Gewinnerin oder des Gewinners wird einmalig am 31.&nbsp;Jänner&nbsp;2019 durchgeführt. Die Gewinnbenachrichtigung erfolgt unmittelbar im Anschluss.</p>
			<p className={css.subheader}>3. Teilnahme, Teilnahmeberechtigte</p>
			<p className={css.textblock}>Die Teilnahme am Gewinnspiel ist kostenlos und setzt keine Reisebuchung voraus.
Um am Gewinnspiel teilzunehmen, ist ein vollständiges Ausfüllen und Absenden des angezeigten Kontaktformulars nötig. Mehrfachteilnahmen sind nicht möglich.
Ein Verstoß gegen die Teilnahmebedingungen, insbesondere durch falsche Angaben, berechtigt AIDA Cruises, den Teilnehmer vom Gewinnspiel auszuschließen. Gleiches gilt für den Fall, wenn zu befürchten ist, dass durch das Verhalten eines Teilnehmers oder durch in der Person des Teilnehmers liegende Gründe das Image oder der gute Ruf von AIDA Cruises, den verbundenen oder beauftragten Unternehmen oder von diesem Gewinnspiel Schaden nehmen könnten.</p>
			<p className={css.subheader}>4. Ermittlung des Gewinners</p>
			<p className={css.textblock}>Während des Gewinnspielzeitraums wird unter allen Teilnahmeberechtigten eine ferngesteuerte Drohne im Wert von €&nbsp;250,00 verlost. Die Ermittlung der Gewinner erfolgt nach Teilnahmeschluss im Rahmen einer auf dem Zufallsprinzip beruhenden Verlosung unter allen Teilnahmeberechtigten.
Der Gewinner der Verlosung wird zeitnah über einen gesonderten Anruf über den Gewinn informiert. Ist der Gewinner nach zweifachem Anrufversuch bis zum 08.02.2019 nicht erreichbar oder meldet sich der Gewinner bis zum 08.02.2019 nicht bei AIDA Cruises unter der kostenlosen Hotline +43&nbsp;1&nbsp;22&nbsp;709&nbsp;050, kann der Gewinn auf einen anderen Teilnehmer unter sinngemäßer Anwendung der Bestimmungen über die Ermittlung des Gewinners von AIDA Cruises übertragen werden.</p>
			<p className={css.subheader}>5. Übergabe des Gewinns</p>
			<p className={css.textblock}>Der Gewinn wird von AIDA Cruises längstens binnen 14&nbsp;Tagen ab dem mit dem Gewinner geführten Telefonat an die vom Gewinner bekanntgegebene Adresse per Post versendet.</p>
			<p className={css.subheader}>6. Nutzung von Drohnen an Bord</p>
			<p className={css.textblock}>Drohnen dürfen an Bord der Schiffe von AIDA Cruises nicht benutzt werden.</p>
			<p className={css.subheader}>7. Anwendbares Recht</p>
			<p className={css.textblock}>Das Gewinnspiel unterliegt ausschließlich österreichischem Recht. Der Rechtsweg ist ausgeschlossen. Sollte eine Bestimmung der Teilnahmebedingungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt.</p>
			<p className={css.footer}>Rostock, am 01.01.2019, AIDA Cruises - German Branch of Costa Crociere S.p.A.</p>
		</div>
	</div>
);

export default Conditions