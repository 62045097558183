import React from 'react';
import styled from 'styled-components/macro';
import {FormCheckboxGroup} from 'components/forms'

const FormCheckboxGroupWrapper = ({className, styled, children, ...otherProps}) =>
	<FormCheckboxGroup className={className} {...otherProps}>{children}</FormCheckboxGroup>;

export default styled(FormCheckboxGroupWrapper)`
	margin: 0 0 16px 0;
	
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
  #errorText {
    font-size: 16px;
  	color: ${({theme}) => theme.alertTextColor};
  }
`;