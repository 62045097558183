import React from 'react';

import {LayoutContainer} from 'components/containers'

import Portal from './Portal'
import PortalInactive from './PortalInactive'

const PortalContainer = props => props.portal.status === "active"
	? <Portal {...props}/>
	: <LayoutContainer theme="theme-aida"><PortalInactive {...props}/></LayoutContainer>;

export default PortalContainer