import React from 'react'
import {DialogContainer} from 'lib/react-md'
import _ from 'lib/lodash'

import css from './ConfirmationDialog.module.scss'

// disableScrollLocking requires fix to width: 0 in .md-no-scroll.md-no-scroll object when shown in tab pane

const ConfirmationDialog = ({id, title, actions, visible, children, ...otherProps}) => (
	<DialogContainer
		id={id}
		dialogClassName={css.dialog}
		visible={visible}
		title={title}
		actions={actions}
		onHide={_.noop}
		disableScrollLocking
		portal
		lastChild
		{...otherProps}
	>
		{children}
	</DialogContainer>
);

export default ConfirmationDialog;