import React, {Component} from 'react';
import _ from 'lib/lodash'

import './lib/pannellum.css';
import css from './PannellumVideo360Viewer.module.scss'

class PannellumImage360Viewer extends Component {
	static defaultProps = {
		width: "100%",
		height: "100%"
	};

	state = {
		videojs: null,
		id: `pano-video360-${_.uniqueId()}` // generate unique id for each instance
	};

	constructor(props) {
		super(props);
		this.videojsNode = React.createRef();
	}


	initPlayer = () => {
		const {width, height, imgPath, thumbPath, showFullscreenCtrl, ...otherProps} = this.props;
		const {videojs} = this.state;

		if (!window.pannellum) return;

		// register plugin only if not registered already
		if (!videojs.getPlugin("pannellum")) {
			videojs.registerPlugin('pannellum', function (config) { // function(...) important since it references 'this'
				// Create Pannellum instance
				var player = this;
				var container = player.el();
				var vid = container.getElementsByTagName('video')[0];
				var pnlmContainer = document.createElement('div');
				config = config || {};
				config.type = 'equirectangular';
				config.dynamic = true;
				config.showZoomCtrl = false;
				config.showFullscreenCtrl = false;
				config.autoLoad = true;
				config.panorama = vid;
				pnlmContainer.style.visibility = 'hidden';
				player.pnlmViewer = window.pannellum.viewer(pnlmContainer, config);
				container.insertBefore(pnlmContainer, container.firstChild);
				vid.style.display = 'none';

				// Handle update settings
				player.on('play', function () {
					if (vid.readyState > 1) player.pnlmViewer.setUpdate(true);
				});
				player.on('canplay', function () {
					if (!player.paused()) player.pnlmViewer.setUpdate(true);
				});
				player.on('pause', function () {
					player.pnlmViewer.setUpdate(false);
				});
				player.on('loadeddata', function () {
					pnlmContainer.style.visibility = 'visible';
				});
				player.on('seeking', function () {
					if (player.paused()) player.pnlmViewer.setUpdate(true);
				});
				player.on('seeked', function () {
					if (player.paused()) player.pnlmViewer.setUpdate(false);
				});
			});
		}

		this.videoPlayer = videojs(this.videojsNode.current, {
			plugins: {
				pannellum: {
					...otherProps
				}
			},
			controlBar: {
				fullscreenToggle: showFullscreenCtrl
			},
			loop: true,
			autoplay: false,
			controls: true,
			poster: thumbPath,
			sources: [{
				src: imgPath,
				type: 'video/mp4'
			}]
		});
	};

	componentDidMount() {
		// dynamically import video.js library, otherwise it increases bundle size by 337KB
		//import('video.js').then(module => this.setState({videojs: module.default || module}));
		// see https://github.com/videojs/video.js/issues/6166 to reduce bundle size
		import ("video.js/dist/alt/video.core.novtt.js").then(module => {
			this.setState({videojs: module.default || module});
		});
	}

	// eslint-disable-next-line
	componentDidUpdate(prevProps, prevState) {
		if (!prevState.videojs && !!this.state.videojs) {
			this.initPlayer();
		}
	}

	componentWillUnmount() {
		if (this.videoPlayer) {
			this.videoPlayer.dispose();
		}
	}

	render() {
		const {width, height, thumbPath} = this.props;
		const {id} = this.state;

		// wrap the player in a div with a `data-vjs-player` attribute
		// so videojs won't create additional wrapper in the DOM
		// see https://github.com/videojs/video.js/pull/3856

		// TODO: Issue with iOS (both Safari and Chrome): Plugin does not work in Fullscreen
		return (
			<div className={css.container} style={{width, height}}>
				<div data-vjs-player>
					<video
						id={id}
						ref={this.videojsNode}
						className="video-js vjs-default-skin vjs-big-play-centered"
						controls
						preload="none"
						style={{width, height}}
						crossOrigin="anonymous"
						poster={thumbPath}
						playsInline
					/>
				</div>
			</div>
		);
	}
}

export default PannellumImage360Viewer;
