import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'

import css from './LinkSlave.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item} = this.props;
		const {properties = {}} = item;
		const {label = "", value = ""} = properties;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<a href={value}>{label}</a>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: () => null
}