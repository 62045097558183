import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lib/lodash'

const ImagesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ImageWrapper = styled.div`	
	
`;

const ImageList = ({images, tag, children}) => {
	const imgs = _.map(images, image => ({
		id: image.$id,
		alt: image.$id,
		thumbnail: _.get(image, 'storage.thumbUrl'),
		thumbnailWidth: _.get(image, 'storage.metadata.width'),
		thumbnailHeight: _.get(image, 'storage.metadata.height'),
		tag: _.get(image, 'properties.tag', "")
	})).filter(image => !!tag ? image.tag === tag : true);

	if (imgs.length === 0) return null;

	return (
		<ImagesWrapper>
			{imgs.map(({id, alt, thumbnail, thumbnailWidth, thumbnailHeight}) => (
				<ImageWrapper key={id}>
					<img id={id} alt={alt} src={thumbnail} width={thumbnailWidth} height={thumbnailHeight}/>
				</ImageWrapper>
			))}
		</ImagesWrapper>
	);
};

export default ImageList;