import React from 'react'

import FormContainer from 'components/forms/FormContainer';
import FormParking from './FormParking'

const PageParking = ({substep, cancel, onSubmit, actions, item, images, onClearForm}) => {
	const FormComponent = FormParking({substep, cancel, actions, item, images, onClearForm});

	return (
		<FormContainer
			onSubmit={onSubmit}
			FormComponent={FormComponent}/>
	);
};

export default PageParking;