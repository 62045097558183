import React, {Component} from 'react';
import {connect} from 'react-redux'
import {db} from 'services'
import _ from 'lib/lodash'

import {nodeActions} from 'components/handlers/actions'
import {UploadProgress} from 'components/utils'
import {gtm} from 'components/events'

export const DataContext = React.createContext();

class RawDataProvider extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return _.anyDiff(nextProps, this.props, ['subnodes', 'nodes', 'children', 'uploadProgress']);
	}

	render() {
		const {profileId, languages, portal, bundle, sticker, payloads, nodes, subnodes, children, dispatch} = this.props;
		const {isUploading, uploadProgress, error} = this.props;
		const {postNode, postSubnode, postSubnodeAndFile, showCustomInfo, raiseCustomError} = this.props;

		// TODO: calculate stickerTotalSize and bundleTotalSize to compare with constraints

		// append subnode count to each node for each target
		_.forEach(nodes.items, node => {
			/*node.$subnodeCounts = _.transform(_.get(node, 'targets', {}), (res, v, k) => {
				res[k] = _.size(_.get(subnodes, `[${v}][${node.$id}].items`, []));
			}, {});*/
			const target = _.get(node, 'targets.master');
			node.$subnodeCount = _.size(_.get(subnodes, `[${target}][${node.$id}].items`, []));
		});

		const $nodeCount = _.get(nodes, 'items', []).length;

		return (
			<DataContext.Provider value={{
				profileId,
				languages,
				portal,
				bundle,
				sticker,
				payloads,
				nodes,
				subnodes,
				isUploading,
				actions: {
					postNode: postNode(_.get(payloads, 'public'), nodes, {order: $nodeCount}),
					updateNodeProperties: db.updateNodeProperties,
					updateNodeFilters: db.updateNodeFilters,
					updateNodeStats: db.updateNodeStats,

					postSubnode: postSubnode(sticker.id, subnodes),
					postSubnodeAndFile: postSubnodeAndFile(sticker.id, subnodes),

					updateNodesOrder: db.updateNodesOrder,
					updateNodesExpanded: col => () => db.updateNodes(col, {expanded: true}),
					updateNodesCollapsed: col => () => db.updateNodes(col, {expanded: false}),

					updateDocument: db.updateDocument,
					deleteDocument: db.deleteDocument,

					dispatch,

					showCustomInfo,
					raiseCustomError,

					getBundleId: () => bundle.id,
					getStickerId: () => sticker.id,
					getId: () => `${bundle.id}/${sticker.id}`,
					getProfileId: () => profileId,
					getPortalProperties: () => _.get(portal, 'properties', {}),
					getBundleProperties: () => _.get(bundle, 'properties', {}),
					getStickerProperties: () => _.get(sticker, 'properties', {}),

					gtmClickEvent: eventParams => dispatch({type: gtm.GTM_EVENT_CLICK, ...eventParams}),
					gtmCustomEvent: eventParams => dispatch({type: gtm.GTM_EVENT_CUSTOM, ...eventParams}),
					gtmValidationError: eventParams => dispatch({type: gtm.GTM_EVENT_VALIDATION_ERROR, ...eventParams}),
				}
			}}>
				{children}
				{isUploading && !error && <UploadProgress progress={uploadProgress}/>}
			</DataContext.Provider>
		)
	}
}

const mapStateToProps = ({nodeHandler}) => nodeHandler;

export const DataProvider = connect(mapStateToProps, nodeActions)(RawDataProvider);