import React from 'react';

import {Link, SensotixLink} from 'components/utils'
import {withIntl} from 'components/hocs'

import css from './StickerFooter.module.scss'

const C = "profiles.helvetia-at.StickerFooter";

const StickerFooter = ({T}) => (
	<div className={css.container}>
		<div className={css.footerTop}>
			<div className={css.footerMain}>
				<strong>{T(C, "title")}</strong>
				<div>{T(C, "subtitle1")}</div>
			</div>
			<div className={css.footerContact}>
				<div>{T(C, "subtitle2")}</div>
				<div>{T(C, "subtitle3")}</div>
				<div>{T(C, "subtitle4")}</div>
			</div>
		</div>
		<div className={css.footerBottom}>
			<div>
				<Link
					title={T(C, "link.imprint.title")}
					href={T(C, "link.imprint.href")}
					data-gtm-action={`${C}:Impressum`}
				/>
				&nbsp;·&nbsp;
				<Link
					title={T(C, "link.legal.title")}
					href={T(C, "link.legal.href")}
					data-gtm-action={`${C}:Rechtliches`}
				/>
				&nbsp;·&nbsp;
				<Link
					title={T(C, "link.privacy.title")}
					href={T(C, "link.privacy.href")}
					data-gtm-action={`${C}:Datenschutz`}
				/>
			</div>
			<p/>
			<div className={css.footerLogo}>
				<SensotixLink title="Smart Stickers by Sensotix." data-gtm-action={`${C}:Smart Stickers by Sensotix`}/>
			</div>
		</div>
	</div>
);

export default withIntl(StickerFooter);