import React, {useEffect} from 'react'
import styled from 'styled-components/macro';
import {Form, Field} from 'formik';
import * as Yup from 'yup';
import _ from 'lib/lodash'

import {api} from 'services'
import FormContainer from 'components/forms/FormContainer';
import PageToolbar from '../PageToolbar'
import {StyledButton, StyledFormTextField} from '../../../../controls';
import ThemedSimpleLabel from '../ThemedSimpleLabel'
import {useFormState} from 'components/hooks';

const C = "profiles.demo-sc.PageContact";

const PageWrapper = styled.div`
	padding: 24px 16px;
`;

const ContactForm = () => (
	<PageWrapper>
		<Form>
			<Field
				type="text"
				name="contactName"
				label="Name"
				placeholder="Bitte ausfüllen..."
				component={StyledFormTextField}
			/>
			<Field
				type="text"
				name="contactEmail"
				label="Email"
				placeholder="Bitte ausfüllen..."
				component={StyledFormTextField}
			/>
		</Form>
	</PageWrapper>
);

const validationSchema = Yup.object().shape({
	contactName: Yup.string().required("Bitte geben Sie den Namen an."),
	contactEmail: Yup.string().trim().email().required("Bitte geben Sie eine gültige Emailadresse an."),
});

const onPostEmail = ({formState, actions, doc, images}) => {
	const bundleProps = actions.getBundleProperties();
	const stickerProps = actions.getStickerProperties();

	const {email: emailBundle = {}} = bundleProps; // from: {name, address}, to, cc, subject, templateId
	const {email: emailSticker = {}} = stickerProps; // from: {name, address}, to, cc, subject, templateId

	// merge email properties from bundleProps and stickerProps
	const customizer = (objValue, srcValue, key) => {
		if (objValue && ['to', 'cc', 'bcc'].indexOf(key) > -1) {
			return objValue.concat(`,${[srcValue].filter(Boolean).join(',')}`);
		}
	};
	const email = {};
	_.mergeWith(email, emailBundle, customizer);
	_.mergeWith(email, emailSticker, customizer);

	const {
		id,
		owner,
		service,
		claimId,
		datetime, location,
		datetimeTo,
		contactName, contactEmail,
		details, comments,
		serviceText, isMassage, isClaim, isResource
	} = formState;

	const location_label = location.label;
	const lat = (location.lat || 0).toFixed(4);
	const lng = (location.lng || 0).toFixed(4);

	const date = new Date(datetime).toLocaleDateString();
	const time = new Date(datetime).toLocaleTimeString();

	const dateTo = !!datetimeTo ? new Date(datetimeTo).toLocaleDateString() : "";
	const timeTo = !!datetimeTo ? new Date(datetimeTo).toLocaleTimeString() : "";

	const url = window.location.href;

	const attachments = _.map(images, image => {
		const filename = _.get(image, "properties.label", `file_${doc.id}`);
		const extension = _.get(image, "storage.metadata.format", "jpg").replace("jpeg", "jpg");
		const path = _.get(image, "storage.fileUrl", "");
		const filesize = _.get(image, "storage.totalSize", 0);

		return {filename: filename + "." + extension, path, filesize};
	});

	if (!!contactEmail) _.mergeWith(email, {cc: contactEmail}, customizer);

	const params = {
		dynamic_template_data: {
			id,
			claimId,
			owner,
			service,
			date,
			time,
			dateTo,
			timeTo,
			location_label,
			lat,
			lng,
			contactName,
			contactEmail,
			url,
			details,
			comments,
			serviceText,
			isMassage,
			isClaim,
			isResource,
		},
		...email,
		attachments: isClaim ? attachments : [],
	};

	return api.auth().post("postEmail", params);
};

const PageContact = ({T, actions, subitems, previousStep, userInfo, setUserInfo, wizardProps: {resetToStep}}) => {
	const [formState, dispatch] = useFormState();
	const {contactName = "", contactEmail = "", datetime} = _.get(userInfo, 'contact', {});
	const initialValues = {contactName, contactEmail};

	const doc = _.get(_.filter(subitems, {properties: {component: "demo/demo-smartconcierge19/form"}}), 0, {});
	const images = _.filter(subitems, {type: "image"});

	/*eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (doc) {
			const id = actions.getId();
			const claimNo = _.get(doc.properties, 'claimNo', 0) + 1;
			const claimId = id + "/" + claimNo;
			const {component = "", data = ""} = _.get(doc, 'properties', {});

			dispatch({type: "FORM_INIT_CLAIM", component, data, id, claimNo, claimId});
		}
	}, []); // if inputs are given, endless re-rendering occurs due to formState change

	const onPreviousStep = () => {
		dispatch({type: "FORM_GOTO_PREVIOUS_STEP"});
		previousStep();
	};

	const onClearForm = () => {
		dispatch({type: "RESET_FORM"});
		resetToStep(0);
	};

	const onSubmit = async (values, {setSubmitting}) => {
		try {
			const formProperties = {
				..._.omit(formState, ["step", "substep", "showFeedback"]),
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				datetime: new Date(datetime || new Date())
			};

			setUserInfo(_.merge({}, userInfo, {contact: {contactName: values.contactName, contactEmail: values.contactEmail}}));

			const fields = _.omitNullAndUndefined({...doc, properties: _.omitNullAndUndefined(formProperties)}); // remove null and undefined variables
			await actions.updateDocument(fields, true);

			await onPostEmail({formState: formProperties, actions, doc, images});
			dispatch({type: "FORM_SUBMIT_STEP_CONTACT", ...values});
			onClearForm();
			setSubmitting(false);
			actions.showCustomInfo(T(C, "successMessage"));
			actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		} catch (e) {
			const msg = _.get(e, 'response.data.error.response.body.errors[0].message', e);
			actions.raiseCustomError(new Error(msg));
			setSubmitting(false);
		}
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={ContactForm}
			HeaderComponent={PageToolbar({title: T(C, "title")})}
			LabelComponent={ThemedSimpleLabel}
			label={T(C, "subtitle")}
			actionButtons={({formProps: {submitForm, isSubmitting}}) => [
				<StyledButton styled="secondary" key="cancel" flat onClick={onPreviousStep}>Zurück</StyledButton>,
				<StyledButton styled="primary" key="next" isLoading={isSubmitting} onClick={submitForm}>Absenden</StyledButton>
			]}
		/>
	);
};

export default PageContact;