import React, {PureComponent} from 'react';

import * as Wrappers from 'components/wrappers'
import {withEditDrawer} from 'components/hocs'
import {Flag} from 'components/utils'
import {LanguagePickerContainer, StickerPickerContainer} from 'components/containers'
import {TextFieldEdit} from 'components/controls'

import css from './ContactMaster.module.scss'

class ViewComponent extends PureComponent {
	render() {
		const {item, EditComponent} = this.props;
		const {properties = {}, filters = {}} = item;
		const {namePrefix = "", firstName = "", lastName = ""} = properties;
		const label = (namePrefix + " " + firstName + " " + lastName).trim();

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					{filters.language && <Flag className={css.flag} code={filters.language}/>}
					<div className={css.label}>{label}</div>
					<div className={css.icon}>
						<EditComponent/>
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		const {item} = this.state;
		const {properties = {}, filters = {}} = item;
		const {
			namePrefix = "",
			firstName = "",
			lastName = "",
			organization = "",
			role = "",
			workPhone = "",
			workEmail = "",
			workUrl = "",
			note = "",
			workAddress_street = "",
			workAddress_city = "",
			workAddress_countryRegion = "",
			workAddress_postalCode = "",
		} = properties;
		const {language, stickers} = filters;

		return (
			<div className={css.editContainer}>
				<TextFieldEdit id="namePrefix" label="contact.namePrefix" value={{namePrefix}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="firstName" label="contact.firstName" value={{firstName}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="lastName" label="contact.lastName" value={{lastName}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="organization" label="contact.organization" value={{organization}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="role" label="contact.role" value={{role}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workPhone" label="contact.workPhone" value={{workPhone}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workEmail" label="contact.workEmail" value={{workEmail}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workUrl" label="contact.workUrl" value={{workUrl}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_street" label="contact.workAddress_street" value={{workAddress_street}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_countryRegion" label="contact.workAddress_countryRegion" value={{workAddress_countryRegion}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_postalCode" label="contact.workAddress_postalCode" value={{workAddress_postalCode}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="workAddress_city" label="contact.workAddress_city" value={{workAddress_city}} onSubmit={this.updateProperty}/>
				<TextFieldEdit id="note" label="contact.note" value={{note}} onSubmit={this.updateProperty}/>
				<LanguagePickerContainer
					defaultValue={{language}}
					onChange={this.updateFilter}
				/>
				<StickerPickerContainer
					defaultValue={{stickers}}
					onChange={this.updateFilter}
				/>
			</div>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, props)
}