import React, {useState, useRef} from 'react';
import {Button, List} from 'lib/react-md'
import {StyledButton} from '../../../stickers/pilot-18/controls'
import {ConfirmationDialog} from "../../../../../components/controls";
import AudioRecorder from 'lib/AudioRecorder'
import AudioRecorderPolyfill from 'audio-recorder-polyfill'
import {useTimer} from 'use-timer';
import styled from 'styled-components/macro'
import {Skeleton} from 'components/utils'
//import LoadingDots from 'components/utils/LoadingDots';

import _ from 'lib/lodash'
//import css from "./CustomHVCHAudioRecordings.module.scss";

const C = "profiles.helvetia-ch.CustomHVCHClaimCollectionSlave.CustomHVCHAudioRecordings";

window.MediaRecorder = AudioRecorderPolyfill;

const CustomHVCHAudioRecordings = ({T, actions, audios = [], maxDurationSec = 30, onNewRecording = _.noop, onDeleteRecording = _.noop}) => {
	const [recordingState, setRecordingState] = useState("initialized");
	const mediaRecorderRef = useRef(new AudioRecorder());
	const {time: timerValue, start: timerStart, pause: timerPause, reset: timerReset} = useTimer({interval: 1000});

	const onStart = () => {
		setRecordingState("recording");
		timerReset();
		timerStart();
	};

	const onStop = (data, url) => {
		timerPause();
		setRecordingState("stopped");
		onNewRecording(data);
	};

	const onError = e => {
		setRecordingState("error");
		console.error(e);
	};

	const mediaRecorder = mediaRecorderRef.current;
	const record = () => mediaRecorder.record(onStart, onStop, onError, maxDurationSec);
	const stop = () => mediaRecorder.stop();

	if (MediaRecorder.notSupported) {
		return <div>Audio recording is not supported in this browser</div>;
	}

	const deleteRecording = audio => () => onDeleteRecording(audio);

	return (
		<>
			<RecordingDialog T={T} onOk={record} onStop={stop} isRecording={recordingState === "recording"}
											 timerValue={timerValue}/>
			<List>
				{audios.map((audio, idx) => (
					<Recording key={`audio-${idx}`} url={_.get(audio, 'storage.fileUrl')} onDelete={deleteRecording(audio)}/>
				))}
			</List>
		</>
	);
}

const TimerDisplay = ({value}) =>
	<span>{String(parseInt(value / 60)).padStart(2, '0')}:{String(value % 60).padStart(2, '0')}</span>;

const RecordingContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px;
`;

const ActionButton = styled(Button)`
	background: none;
	color: ${({theme}) => theme.secondaryColor};
`;

const Recording = ({url, onDelete}) => {
	const [isPreview, setIsPreview] = useState(true);
	const togglePreview = () => setIsPreview(!isPreview);

	return (
		<>
			{!!url && (
				<RecordingContainer>
					<audio controls crossOrigin="anonymous" controlsList="nodownload" style={{width: "100%", maxHeight: "40px"}}>
						<source src={url} type="audio/mp3"/>
						<a href={url}>Download</a>
					</audio>
					{isPreview && <ActionButton icon onClick={togglePreview}>delete</ActionButton>}
					{!isPreview && <ActionButton icon onClick={onDelete}>delete_forever</ActionButton>}
					{!isPreview && <ActionButton icon onClick={togglePreview}>clear</ActionButton>}
				</RecordingContainer>
			)}
			{!url && <Skeleton height="50px" text={{section: C, id: "AUDIO_LOADING"}}/>}
		</>
	);
}

const RecordingDialog = ({T, onOk = _.noop, onStop = _.noop, isRecording = false, timerValue}) => {
	const [confirmDlgVisible, setConfirmDlgVisible] = useState(false);

	const showConfirmationDialog = () => setConfirmDlgVisible(true);
	const hideConfirmationDialog = (result, callback) => () => {
		setConfirmDlgVisible(false);
		if (!!callback) callback();
	};

	const dialogActions = [
		{
			id: "cancel",
			secondary: false,
			children: T(C, "RecordingDialog.buttonCancel"),
			onClick: hideConfirmationDialog(false)
		},
		{
			id: "ok",
			secondary: false,
			children: T(C, "RecordingDialog.buttonOk"),
			onClick: hideConfirmationDialog(true, onOk)
		},
	];

	return (
		<div>
			{!isRecording && (
				<StyledButton
					styled="tertiary"
					fullWidth
					iconChildren="mic"
					onClick={showConfirmationDialog}
					data-gtm-action={`button:${C}`}
					data-gtm-label="Audio aufnehmen"
				>
					{T(C, "RecordingDialog.buttonRecord.record")}
				</StyledButton>
			)}
			{isRecording && (
				<StyledButton
					styled="secondary"
					fullWidth
					iconChildren="stop"
					onClick={onStop}
					data-gtm-action={`button:${C}`}
					data-gtm-label="Audio stoppen"
				>
					{T(C, "RecordingDialog.buttonRecord.stop")} (<TimerDisplay value={timerValue}/>)
				</StyledButton>
			)}
			<ConfirmationDialog
				id="confirmation-dialog-records"
				visible={confirmDlgVisible}
				title={T(C, "RecordingDialog.title")}
				actions={dialogActions}
				initialFocus="ok"
			>
				{T(C, "RecordingDialog.content")}
			</ConfirmationDialog>
		</div>
	)
}

export default CustomHVCHAudioRecordings;