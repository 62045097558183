import React, {useState, useEffect, useRef} from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import {withIntl} from 'components/hocs'
import {ErrorMessage} from 'formik';

export default withIntl(({
	                         intl,
	                         className,
	                         field: {name, value, onChange, onBlur}, // {name, value, onChange, onBlur}
	                         form: {touched, errors, setFieldValue, values}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                         label,
	                         open,
	                         readOnly = true,
	                         ...otherProps
                         }) => {

	const ref = useRef(null);
	const [state, setState] = useState({open: false});

	const onClick = () => setState({open: !state.open ? "date" : false});
	const onToggle = open => setState({open});

	useEffect(() => {
		document.getElementById(ref.current.inner.inputId).readOnly = readOnly;
	}, [readOnly]);

	const dateValue = value instanceof Date || typeof value === "string" || value instanceof String ? new Date(value) : new Date();

	return (
		<div className={className}>
			{<label>{label ? label : undefined}</label>}
			<DateTimePicker
				ref={ref}
				{...otherProps}
				id={name}
				name={name}
				value={dateValue}
				initialValue={dateValue}
				onChange={v => setFieldValue(name, v)}
				inputProps={{onClick}}
				onToggle={onToggle}
				culture={intl.locale}
				timeFormat="HH:mm"
				open={state.open}
			/>
			<div id="errorText"><ErrorMessage name={name}/></div>
		</div>
	);
});