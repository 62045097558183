import React, {Component} from 'react';
import _ from 'lib/lodash'

import {slave as NodeComponents} from 'components/nodes/subnodes'

class SlaveSubnodeContainer extends Component {
	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['item']);
	}

	render() {
		const Components = _.get(NodeComponents, this.props.item.type);
		if (!Components) {
			console.error(`ERROR: Subnode component '${this.props.item.type}' is not available.`);
			return <div/>;
		}

		const {ViewComponent, EditComponent} = Components;
		//const {ViewComponent, EditComponent} = _.get(NodeComponents, this.props.item.type, {ViewComponent: null, EditComponent: null});
		if (!ViewComponent && !EditComponent) return <div/>;

		const {keyPrefix, item, actions, allowEdit, ...otherProps} = this.props;
		const {$id} = item;

		return (
			<ViewComponent
				key={$id}
				item={item}
				actions={actions}
				allowEdit={allowEdit}
				EditComponent={EditComponent({keyPrefix, item, actions, ...otherProps})}
			/>
		);
	}
}

export default SlaveSubnodeContainer