import React from 'react';
import {ThemeProvider} from 'styled-components';

import * as Wrappers from 'components/wrappers'
import {UserInfoConsumer} from 'components/contexts'
import {Wizard} from 'components/controls'
import * as Pages from './pages';
import theme from '../../../../theme';
import {FormStateProvider} from 'components/contexts';
import {reducer, initialState} from './formclaim'

class Slave extends Wrappers.EditComponentWrapper {
	render() {
		const {profileId, bundle, sticker} = this.props;
		const {status} = sticker;

		const isActive = status === "active";

		return (
			<UserInfoConsumer>
				{userInfoProps => (
					<ThemeProvider theme={theme}>
						<FormStateProvider
							name="claimform"
							profileId={profileId}
							bundleId={bundle.id}
							stickerId={sticker.id}
							reducer={reducer}
							initialState={initialState}
						>
							{({formState: {step}}) => (
								<Wizard initialStepIndex={step}>
									{isActive && <Pages.PageMain {...this.props}/>}
									{isActive && <Pages.PageDriver {...this.props} {...userInfoProps}/>}
									{isActive && <Pages.PageCategory {...this.props} {...userInfoProps}/>}
									{isActive && <Pages.PageClaim {...this.props} {...userInfoProps}/>}
									{!isActive && <Pages.PageInactive {...this.props}/>}
								</Wizard>
							)}
						</FormStateProvider>
					</ThemeProvider>
				)}
			</UserInfoConsumer>
		);
	}
}

export default Slave;