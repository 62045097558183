import React, {PureComponent} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {Snackbar} from 'lib/react-md';
import _ from 'lib/lodash'

import {notificationActions} from 'components/handlers/actions'
import {withIntl} from 'components/hocs'

import css from './NotificationContainer.module.scss'

class NotificationContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.containerElement = document.createElement('div');
		document.body.appendChild(this.containerElement);
	}

	componentWillUnmount() {
		document.body.removeChild(this.containerElement);
	}

	translateToasts = (T, toasts) => _.map(toasts, toast => {
		const {text} = toast;
		const section = _.get(text, 'section');
		const id = _.get(text, 'id');
		const params = _.get(text, 'params', {});
		return !!section && !!id ? {...toast, text: T(section, id, params)} : toast;
	});

	render() {
		const {T, infoToasts, errorToasts, autohideToasts, dismissInfoToast, dismissErrorToast} = this.props;

		const container = (
			<div className={css['container']}>
				{_.isSet(infoToasts) && (
					<Snackbar
						className={css['info-toasts']}
						id="info-toasts"
						toasts={this.translateToasts(T, infoToasts)}
						autohide={autohideToasts}
						onDismiss={dismissInfoToast}
					/>
				)}
				{_.isSet(errorToasts) && (
					<Snackbar
						className={css['error-toasts']}
						id="error-toasts"
						toasts={this.translateToasts(T, errorToasts)}
						autohide={autohideToasts}
						onDismiss={dismissErrorToast}
					/>
				)}
			</div>
		);

		return ReactDOM.createPortal(container, this.containerElement);
	}
}

const mapStateToProps = ({notificationHandler}) => notificationHandler;

export default connect(mapStateToProps, notificationActions)(withIntl(NotificationContainer));