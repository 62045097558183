import React, {PureComponent} from 'react';
import {Button} from 'lib/react-md'
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl} from 'components/hocs'
import {ImageFactory} from 'components/nodes/factories'
import {AddItemDropzone, FooterInfo} from 'components/utils'
import {ResponsiveImageGallery} from 'components/controls'

import css from './ImageCollectionSlave.module.scss'

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	onDrop = acceptedFiles => {
		const {item, actions} = this.props;

		_.forEach(acceptedFiles, file => actions.postSubnodeAndFile(item, "slave", ImageFactory, file));
	};

	render() {
		const {T, item, subitems, allowAdd, allowEdit, actions} = this.props;
		const {constraints = {}, $subnodeCount} = item;
		const {count = 0, mimeTypes} = constraints;

		const accept = mimeTypes || ["image/*"];
		const uploadLimitReached = !!count && $subnodeCount >= count;

		const images = subitems;
		const items = _.map(images, subnode => ({
			original: _.get(subnode, 'storage.fileUrl'),
			//description: _.get(subnode, 'properties.label'),
			thumbnail: _.get(subnode, 'storage.thumbUrl'),
			thumbnailWidth: _.get(subnode, 'storage.metadata.width'),
			thumbnailHeight: _.get(subnode, 'storage.metadata.height')
		}));

		// eslint-disable-next-line
		const voteImage = (idx, getCurrentIndex, vote = 0) => () => {
			const index = idx !== null ? idx : getCurrentIndex(); //null-checking mandatory since idx=0 is a valid index!
			const currentNode = _.get(images, index, {});

			if (!_.isEmpty(currentNode)) {
				//actions.deleteDocument(currentNode);
				//actions.showCustomInfo({id: "node.deleted"});
			}
		};

		const deleteImage = (idx, getCurrentIndex) => () => {
			const index = idx !== null ? idx : getCurrentIndex(); //null-checking mandatory since idx=0 is a valid index!

			const currentNode = _.get(images, index, {});
			const deleteAllowed = allowEdit(currentNode);

			if (!_.isEmpty(currentNode)) {
				if (deleteAllowed) {
					actions.deleteDocument(currentNode);
					actions.showCustomInfo({id: "node.deleted"});
				} else {
					actions.raiseCustomError(new Error(T("error", "deleteNotAllowed")));
				}
			}
		};

		const customControls = (idx, getCurrentIndex) => () => (
			<div>
				<Button icon key="like" onClick={voteImage(idx, getCurrentIndex, 1)}>thumb_up</Button>
				<Button icon key="dislike" onClick={voteImage(idx, getCurrentIndex, -1)}>thumb_down</Button>
				<Button icon key="delete" onClick={deleteImage(idx, getCurrentIndex)}>delete</Button>
			</div>
		);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContent}>
					<ResponsiveImageGallery
						items={items}
						customControls={customControls}
						showCustomControlsInLightbox={true}
						showCustomControlsInView={false}
					/>
				</div>
				<div className={css.viewFooter}>
					{allowAdd && !uploadLimitReached && <AddItemDropzone accept={accept} onDrop={this.onDrop}/>}
					{allowAdd && uploadLimitReached && <FooterInfo T={T} type="error" info="uploadLimitReached"/>}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent: withIntl(ViewComponent)
}