/* imports */
import {gtm} from 'components/events'

/* action types */
export const types = {
	ONBOARDING_HIDE_LOADING: 'ONBOARDING/HIDE_LOADING'
};

/* initial state */
const initialState = {
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	onboardingInitialize: () => ({type: types.ONBOARDING_HIDE_LOADING}),

	onboardingAcknowledged: () => ({type: gtm.GTM_EVENT_ONBOARDING_ACKNOWLEDGED})
};