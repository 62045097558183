import React, {PureComponent} from 'react'

import {BasisContainer, DataContainer, LayoutContainer} from 'components/containers'
import {withIntlCookieInfo} from 'components/hocs'

import Slave from './Slave'
import StickerInactive from '../../common/StickerInactive'

import '../../../../theme-helvetia.scss'
import PortalContainer from "../../../../portals/default";

class SlaveContainer extends PureComponent {
	render() {
		const {intl, T, profileId, languages, portal, bundle, sticker} = this.props;
		const {locale} = intl;

		const data = {profileId, languages, portal, bundle, sticker};
		const payloadIds = sticker.status === "active" ? ['public', 'shared', 'private'] : [];

		return (
			<BasisContainer {...data}>
				<LayoutContainer theme="theme-helvetia">
					<DataContainer {...data} payloadIds={payloadIds}>
						{portal.status === "inactive" && <PortalContainer T={T} locale={locale} {...data}/>}
						{sticker.status === "active" && <Slave T={T} locale={locale} {...data}/>}
						{sticker.status !== "active" && <StickerInactive T={T} locale={locale} {...data}/>}
					</DataContainer>
				</LayoutContainer>
			</BasisContainer>
		);
	}
}

export default withIntlCookieInfo(SlaveContainer, {C: "CookieInfo.Portal", profileId: true/*, bundleId: true, stickerId: true*/});