import React, {PureComponent} from 'react'
import {Toolbar, TabsContainer, Tabs, Tab, List, Button} from 'lib/react-md'
import icons from 'lib/icons'

import {withIntlDrawer} from 'components/hocs'
import {FaqButton} from 'components/faq'
import NodeActions from 'components/nodes/actions'

import css from './ActionContainer.module.scss'

const C = "ActionContainer";

class ActionContainer extends PureComponent {
	static defaultProps = {
		CustomNodeActions: () => {}
	};

	componentDidMount() {
		const {T, initDrawer, closeDrawer, actions, actionsList, CustomNodeActions} = this.props;

		const toolbar = (
			<Toolbar
				title={T(C, "title")}
				zDepth={0}
				nav={<FaqButton/>}
				actions={<Button icon onClick={closeDrawer}>close</Button>}
			/>
		);

		const container = (
			<TabsContainer
				className={css.tabsContainer}
				colored
				toolbar={toolbar}
				headerZDepth={0}
				labelAndIcon
			>
				<Tabs tabId="action-tabs">
					<Tab key="tab-public" label={T("Targets", "public")} icon={icons.public}>
						<div className={css.tab}>
							<List>{
								actionsList.public({
									T,
									A: NodeActions({T, actions: {...actions, closeDrawer}}),
									CA: CustomNodeActions({T, actions: {...actions, closeDrawer}})
								})}
							</List>
						</div>
					</Tab>
					<Tab key="tab-shared" label={T("Targets", "shared")} icon={icons.shared}>
						<div className={css.tab}>
							<List>{
								actionsList.shared({
									T,
									A: NodeActions({T, actions: {...actions, closeDrawer}}),
									CA: CustomNodeActions({T, actions: {...actions, closeDrawer}})
								})}
							</List>

						</div>
					</Tab>
					<Tab key="tab-private" label={T("Targets", "private")} icon={icons.private}>
						<div className={css.tab}>
							<List>{
								actionsList.private({
									T,
									A: NodeActions({T, actions: {...actions, closeDrawer}}),
									CA: CustomNodeActions({T, actions: {...actions, closeDrawer}})
								})}
							</List>

						</div>
					</Tab>
				</Tabs>
			</TabsContainer>
		);

		initDrawer({container, position: "right", memoized: true});
	}

	render() {
		const {icon, openDrawer} = this.props;

		return (
			<Button
				id="add-button"
				icon
				onClick={openDrawer}
			>
				{icon}
			</Button>
		);
	}
}

export default withIntlDrawer(ActionContainer, {name: C});