import React, {PureComponent} from 'react';

import {Tab, Tabs, TabsContainer, Toolbar} from 'lib/react-md'
import {withIntl} from 'components/hocs'

import PortalMenu from './PortalMenu';

import css from './PortalInactive.module.scss';
import "../../styles.scss" // profile-wide styles

const C = "profiles.aida.portals.default";

class PortalInactive extends PureComponent {
	render() {
		const {T, locale, languages, portal} = this.props;

		const Logo = (
			<div className={css.logo}>
				<img src={require('../../img/AIDA-Logo.svg')} alt="logo"/>
			</div>
		);

		const Header = (
			<Toolbar
				className={css.toolbar}
				title=""
				nav={<PortalMenu languages={languages}/>}
				actions={Logo}
				zDepth={0}
			/>
		);

		return (
			<TabsContainer
				className={css.container}
				colored
				toolbar={Header}
				swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
				swipeableViewsClassName={css.swipeableViewsContainer}
			>
				<Tabs tabId={`tabs-${locale}`}>
					<Tab key={`tab-overview-${locale}`} label="" className={css.tab}>
						<main>
							<div className={css.infoBoxContainer}>
								<div className="infoBoxPrimary">{T(C, `portalStatus.${portal.status}`)}</div>
							</div>
						</main>
					</Tab>
				</Tabs>
			</TabsContainer>
		);
	}
}

export default withIntl(PortalInactive)