import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import {withIntl} from 'components/hocs'
import {VideoFactory} from 'components/nodes/factories'
import {AddItemDropzone, FooterInfo} from 'components/utils'

import css from './VideoCollectionSlave.module.scss'

class ContainerComponent extends PureComponent {
	render() {
		return <Wrappers.ContainerComponentWrapper {...this.props} target="slave" className={css.card}/>;
	}
}

class HeaderComponent extends PureComponent {
	render() {
		return null;
	}
}

class ViewComponent extends PureComponent {
	onDrop = acceptedFiles => {
		const {item, actions} = this.props;

		_.forEach(acceptedFiles, file => actions.postSubnodeAndFile(item, "slave", VideoFactory, file));
	};

	render() {
		const {T, item, allowAdd, children} = this.props;
		const {constraints = {}, $subnodeCount} = item;
		const {count = 0, mimeTypes} = constraints;

		const accept = mimeTypes || ["video/mp4,video/x-m4v,video/*"];
		const uploadLimitReached = !!count && $subnodeCount >= count;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={$subnodeCount > 1 ? css.viewContentMultiple : css.viewContentSingle}>
					{children}
				</div>
				<div className={css.viewFooter}>
					{allowAdd && !uploadLimitReached && <AddItemDropzone accept={accept} onDrop={this.onDrop}/>}
					{allowAdd && uploadLimitReached && <FooterInfo T={T} type="error" info="uploadLimitReached"/>}
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}


export default {
	ContainerComponent,
	HeaderComponent,
	ViewComponent: withIntl(ViewComponent)
}