import styled from 'styled-components/macro';

const IconWrapper = ({className, icon, width = "32", height = "24", ...otherProps}) => (
	{/*<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox='0 0 16 16'
		width={width}
		height={height}
		className={className}
		{...otherProps}
	>
		<use xlinkHref={`${process.env.PUBLIC_URL}/img/ux-a-icons.svg#ux-a-icon-${icon}`} />
	</svg>*/}
);

export default styled(IconWrapper)`
	color: ${({theme}) => theme.primaryTextColor};
`;