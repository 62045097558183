/* inspired by https://github.com/dvtng/react-loading-skeleton/blob/master/src/index.js */
import React from 'react';
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'
import css from './Skeleton.module.scss';

const Skeleton = ({T, count = 1, lineHeight = 1, height = null, width = null, text = {}}) => (
	_.times(count, i => (
		<div key={`skeleton-${i}`}>
			<span
				className={css.skeleton}
				style={{
					//animation: "progress " + String(duration) + "s ease-in-out infinite", // TODO: calc() in keyframes not supported by MS EDGE!!
					height,
					width,
					lineHeight
				}}
			>
				{_.isSet(text) && <p>{T(text.section, text.id, text.params)}</p>}
			</span>
		</div>
	))
);

export default withIntl(Skeleton);