import React, {PureComponent} from 'react'
import {Button, Tab, Tabs, TabsContainer, Toolbar} from 'lib/react-md'
import icons from 'lib/icons'
import _ from 'lib/lodash'

import {BasisContainer, DataContainer, LayoutContainer, MasterNodesList} from 'components/containers'
import {FaqButton} from 'components/faq'
import {withIntl} from 'components/hocs'

import css from './DefaultMaster.module.scss';

const ToggleOnIcon = icons['toggle-on'];
const ToggleOffIcon = icons['toggle-off'];

const C = "DefaultMaster";

class DefaultMaster extends PureComponent {
	state = {
		activeTabIndex: 0
	};

	static defaultProps = {
		SlaveComponent: () => <div/>,
		StickerMenu: () => <div/>,
		OnboardingComponent: props => <>{props.children}</>
	};

	componentDidMount() {
		const {styles} = this.props;

		const element = document.documentElement;
		_.forEach(styles, (v, k) => element.style.setProperty(k, v));
	}

	changeTabIndex = activeTabIndex => this.setState({activeTabIndex});
	setTabIndex = activeTabIndex => () => this.changeTabIndex(activeTabIndex);

	render() {
		const {
			T, profileId, languages, portal, bundle, sticker, ActionsMenu,
			StickerMenu, theme, OnboardingComponent, SlaveComponent
		} = this.props;
		const {activeTabIndex} = this.state;

		const stickerTitle = _.get(sticker, 'title');

		const data = {profileId, languages, portal, bundle, sticker};

		const actions = activeTabIndex === 0 ? [
			<Button onClick={this.setTabIndex(1)} icon svg><ToggleOffIcon/></Button>,
			ActionsMenu && <ActionsMenu/>,
		] : [
			<Button onClick={this.setTabIndex(0)} icon svg><ToggleOnIcon/></Button>,
			<FaqButton/>,
		];

		const Header = (
			<Toolbar
				className={css.toolbar}
				title={activeTabIndex === 1 ? T(C, "tabPreview") : stickerTitle}
				nav={<StickerMenu languages={languages}/>}
				actions={actions}
				zDepth={0}
			/>
		);

		const renderPreview = () => (
			<TabsContainer
				colored
				headerZDepth={0}
				swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
				className={css.previewContainer}
			>
				<Tabs tabId="preview-tabs">
					{_.map(languages, ({code}) => (
						<Tab key={code} label={code}>
							<SlaveComponent T={T} locale={code} toolbar={null} {...data}/>
						</Tab>
					))}
				</Tabs>
			</TabsContainer>
		);

		return (
			<BasisContainer {...data}>
				<LayoutContainer theme={theme}>
					<OnboardingComponent {...data}>
						<DataContainer {...data} payloadIds={['public', 'shared', 'private', 'shadow']}>
							<TabsContainer
								className={css.container}
								colored
								toolbar={Header}
								headerZDepth={0}
								activeTabIndex={activeTabIndex}
								onTabChange={this.changeTabIndex}
								swipeableViewsProps={{disabled: true, springConfig: {duration: '0s', easeFunction: '...', delay: '0s'}}}
							>
								<Tabs tabId="sticker-tabs">
									<Tab label="Design" className={css.tab}>
										<main>
											<MasterNodesList keyPrefix="master"/>
										</main>
									</Tab>
									<Tab label="Vorschau" className={css.tab}>
										<main>
											{renderPreview()}
										</main>
									</Tab>
								</Tabs>
							</TabsContainer>
						</DataContainer>
					</OnboardingComponent>
				</LayoutContainer>
			</BasisContainer>
		);
	}
}

export default withIntl(DefaultMaster);