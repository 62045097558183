import React from 'react'

import css from './Link.module.scss'

const Link = ({href, title, ...otherProps}) => (
	<span className={css.link}>
		<a href={href} {...otherProps} /*target="_blank" rel="noopener noreferrer"*/>{title}</a>
	</span>
);

export default Link;