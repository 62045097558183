import React from 'react';
import {TextField} from 'lib/react-md'
import _ from 'lib/lodash'

export default ({
	                className,
	                field: {name, value = ""}, // {name, value, onChange, onBlur}
	                form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXX, handleXXX, dirty, isValid, ...
	                label,
	                onChanged = _.noop,
	                ...otherProps
                }) => {

	const onChange = (value, e) => setFieldValue(name, value);

	return (
		<div className={className}>
			{<label>{label ? label : undefined}</label>}
			<TextField
				{...otherProps}
				id={name}
				name={name}
				value={value}
				error={touched && !!errors[name]}
				errorText={errors[name]}
				onChange={onChange}
				onBlur={() => {
					setFieldTouched(name, true);
					onChanged({[name]: value})
				}}
			/>
		</div>
	)
}