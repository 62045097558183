import React from 'react';

import {StyledToolbar} from '../../../controls';

const PageToolbar = ({title, ...otherProps}) => props => (
	<StyledToolbar
		{...otherProps}
		title={title}
		zDepth={0}
	/>
);

export default PageToolbar;