import styled from 'styled-components/macro';

export default styled.table`
	font-size: 16px;
	
	> tbody > tr {
		padding-bottom: 8px;
		
		> td {
			height: 20px;
		}

		> td:nth-child(1) {
			white-space: nowrap;
			vertical-align: top;
			font-weight: 500;
		}
		
		> td:nth-child(2) {
			padding-left: 8px;
		}
	}
`;