/* eslint no-eval: 0 */
import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lib/lodash'

import {TextFieldEdit, Wizard} from 'components/controls'
import {StickerFooter} from '../../../../common';
import PageMainToolbar from './PageMainToolbar'
import {StyledButton} from '../../../../controls';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import {useFormState} from 'components/hooks'
import ButtonAssistance from './ButtonAssistance';

import css from './PageMain.module.scss';

const C = "profiles.helvetia-at.Slave";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px;
`;

const HeaderWrapper = styled.div`
	flex: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	@media (min-width: 380px) {
		flex-direction: row;
	}
`;

const MainWrapper = styled.div`
	flex: 1;
	padding-top: 16px;
	
  button {
  	height: 60px;
	  width: 100%;
	  font-size: x-large !important;
  }
`;

const FooterWrapper = styled.div`
	flex: none;
`;

const PageMain = ({T, intl: {locale} = {}, actions, languages, bundle, sticker, nextStep, dataObj}) => {
	const [{claimId, showFeedback}, dispatch] = useFormState();
	const {owner = "", polno = ""} = _.get(sticker, 'properties', {});
	const buttonAssistanceProps = _.get(dataObj, "button-assistance", {});
	const showButtonAssistance = eval(buttonAssistanceProps.filter); // check if filter condition is true

	const onSubmit = () => {
		dispatch({type: "FORM_SUBMIT_STEP_MAIN", owner, polno});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		nextStep();
	};

	const onSubmitFeedback = feedback => {
		dispatch({type: "FORM_SUBMIT_FEEDBACK", feedback});

		actions.gtmCustomEvent({eventAction: `journey:${C}:feedback`});
		actions.gtmCustomEvent({eventAction: `feedback:${C}:feedbackForm.1`, eventLabel: feedback.rating1});
	};

	return (
		<>
			<Wizard HeaderComponent={PageMainToolbar({languages, bundle, sticker})}>
				<Container>
					<HeaderWrapper>
						<TextFieldEdit
							id="owner"
							className={css.textfield}
							label="overview.owner"
							value={{owner}}
							rows={owner.length > 16 ? 2 : 1}
							readOnly
						/>
						<TextFieldEdit
							id="polno"
							className={css.textfield}
							label="overview.polno"
							value={{polno: !!polno ? polno.split("/").join("\n") : polno}}
							rows={2}
							readOnly
						/>
					</HeaderWrapper>
					<MainWrapper>
						<StyledButton
							styled="primary"
							flat
							primary
							onClick={onSubmit}
							data-gtm-action={`button:${C}`}
							data-gtm-label="Schadenfall melden"
						>
							{T(C, "tabClaims")}
						</StyledButton>
						{showButtonAssistance && <ButtonAssistance T={T} actions={actions} locale={locale} {...buttonAssistanceProps}/>}
					</MainWrapper>
					<FooterWrapper>
						<StickerFooter/>
					</FooterWrapper>
				</Container>
			</Wizard>
			<FeedbackForm
				T={T}
				visible={showFeedback}
				onHide={onSubmitFeedback}
				claimId={claimId}
			/>
		</>
	);
};

export default PageMain;