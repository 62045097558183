import React from 'react';

import {Link, SensotixLink} from 'components/utils'
import {withIntl} from 'components/hocs'

import css from './StickerFooter.module.scss'

const C = "profiles.demo-insurance.StickerFooter";

const StickerFooter = ({T}) => (
	<div className={css.container}>
		<div className={css.footer}>
			<strong>{T(C, "title")}</strong>
			<div>{T(C, "subtitle")}</div>
			<p/>
			<div>
				<Link title={T(C, "link.imprint.title")} href={T(C, "link.imprint.href")}/>
				&nbsp;|&nbsp;
				<Link title={T(C, "link.legal.title")} href={T(C, "link.legal.href")}/>
				&nbsp;|&nbsp;
				<Link title={T(C, "link.privacy.title")} href={T(C, "link.privacy.href")}/>
			</div>
			<p/>
			<small>
				<strong><SensotixLink title="Smart Stickers by Sensotix."/></strong>
			</small>
		</div>
	</div>
);

export default withIntl(StickerFooter);