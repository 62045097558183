import React, {Component} from 'react';
import _ from 'lib/lodash'

import './lib/pannellum.css';
import css from './PannellumImage360Viewer.module.scss'

class PannellumImage360Viewer extends Component {
	state = {
		id: `pano-image360-${_.uniqueId()}` // generate unique id for each instance
	};

	static defaultProps = {
		width: "100%",
		height: "100%"
	};

	initializeViewer = () => {
		const {width, height, imgPath, thumbPath, customStrings, ...otherProps} = this.props;
		const {id} = this.state;

		const config = {
			type: "equirectangular",
			panorama: imgPath,
			preview: thumbPath,
			strings: {
				// Labels
				loadButtonLabel: 'Click here<br>to load<br>the panorama',
				loadingLabel: 'Loading...',
				bylineLabel: 'by %s',    // One substitution: author

				// Errors
				noPanoramaError: 'No panorama image was specified.',
				fileAccessError: 'The file %s could not be accessed.',  // One substitution: file URL
				malformedURLError: 'There is something wrong with the panorama URL.',
				iOS8WebGLError: "Due to iOS 8's broken WebGL implementation, only " +
					"progressive encoded JPEGs work for your device (this " +
					"panorama uses standard encoding).",
				genericWebGLError: 'Your browser does not have the necessary WebGL support to display this panorama.',
				textureSizeError: 'This panorama is too big for your device! It\'s ' +
					'%spx wide, but your device only supports images up to ' +
					'%spx wide. Try another device.' +
					' (If you\'re the author, try scaling down the image.)',    // Two substitutions: image width, max image width
				unknownError: 'Unknown error. Check developer console.',
				...customStrings
			},
			...otherProps,
		};

		/*
     * use hotSpotDebug in config object print the coordinates of mouse clicks
     * to the browser's developer console, which makes it much easier
     * to figure out where to place hot spots. Always remove it when
     * finished, though.
     */
		//"hotSpotDebug": true,

		// remove previous instances in case of updates
		const viewer = document.getElementById(id);
		while (viewer.lastChild) viewer.removeChild(viewer.lastChild);

		if (!!window.pannellum) window.pannellum.viewer(id, config);
	};

	componentDidMount() {
		this.initializeViewer();
	}

	componentDidUpdate() {
		this.initializeViewer();
	}

	render() {
		const {width, height} = this.props;
		const {id} = this.state;

		return (
			<div
				id={id}
				className={css.container}
				style={{width, height}}
			/>
		);
	}
}

export default PannellumImage360Viewer;
