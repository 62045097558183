import React, {useState} from 'react';

import {Button} from 'lib/react-md';

const FormConfirmButton = ({
	                           className, styled,
	                           children,
	                           icon, cancelIcon = "clear", confirmIcon = undefined,
	                           noConfirmButton = false, noCancelButton = false, confirmText,
	                           action,
	                           ...otherProps
                           }) => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	return (
		<div className={className}>
			{noConfirmButton && <Button icon onClick={action}>{icon}</Button>}
			{!showConfirmation && !noConfirmButton && <Button icon onClick={() => setShowConfirmation(true)}>{icon}</Button>}
			{showConfirmation && !confirmIcon && <Button flat primary onClick={action}>{confirmText}</Button>}
			{showConfirmation && confirmIcon && <Button icon onClick={action}>{confirmIcon}</Button>}
			{showConfirmation && !noCancelButton && <Button icon onClick={() => setShowConfirmation(false)}>{cancelIcon}</Button>}
		</div>
	);
};

export default FormConfirmButton;