import React from 'react';
import styled from 'styled-components/macro';
import {FormDatetimePicker} from 'components/forms'

const FormDatetimePickerWrapper = ({className, ...otherProps}) => <FormDatetimePicker className={className} {...otherProps}/>;

export default styled(FormDatetimePickerWrapper)`
	label {
		color: ${({theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	> div {
		padding-top: 8px;
		font-size: 16px;
	}
	
  #errorText {
    font-size: 16px;
  	color: ${({theme}) => theme.alertTextColor};
  }
`;