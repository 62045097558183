import React from 'react'
import {Field, Form} from 'formik';
import {StyledFormListbox, StyledFormLocationPicker} from '../../../../controls';
import {StyledFormDatetimePicker} from '../../../../../../../helvetia-at/stickers/pilot-19/controls';
import * as Yup from 'yup';
import styled from 'styled-components/macro';

const C = "profiles.demo-sc.PageService";

const PageWrapper = styled.div`
	padding: 16px; 
`;

export const ServiceForm = () => ({T, formProps: {submitForm, setFieldValue}}) => {
	const items = [{
		label: T(C, "massage.label.1"),
		value: "Klassische Massage bei Mira M.",
	}, {
		label: T(C, "massage.label.2"),
		value: "Shiatsu bei Susanne F.",
	}, {
		label: T(C, "massage.label.3"),
		value: "Hot Stone bei Franz R.",
	}];

	return (
		<PageWrapper>
			<Form>
				<Field
					type="text"
					name="details"
					label={T(C, "massage.details.label")}
					placeholder={T(C, "placeholder")}
					component={StyledFormListbox}
					menuItems={items}
					fullWidth
					sameWidth
					stripActiveItem
				/>
				<Field
					type="datetime-local"
					name="datetime"
					label={T(C, "massage.datetime.label")}
					placeholder={T(C, "placeholder")}
					component={StyledFormDatetimePicker}
					min={new Date()}
				/>
				<Field
					type="text"
					name="location"
					label={T(C, "massage.location.label")}
					placeholder={T(C, "placeholder")}
					component={StyledFormLocationPicker}
				/>
			</Form>
		</PageWrapper>
	);
};

export const validationSchema = Yup.object().shape({
	details: Yup.string().required("Bitte wählen Sie eine Massage."),
	location: Yup.object().shape({
		label: Yup.string().required("Bitte geben Sie den Standort an.")
	})
});

