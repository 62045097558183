import React from 'react'

import DefaultMaster from 'components/templates/sticker/master/DefaultMaster'
import ActionsMenu from './ActionsMenu'
import MasterMenu from './MasterMenu'
import Slave from '../slave/Slave';

const Master = props => (
	<DefaultMaster
		bundle={props.bundle}
		sticker={props.sticker}
		ActionsMenu={ActionsMenu}
		StickerMenu={() => <MasterMenu {...props}/>}
		SlaveComponent={Slave}
		{...props}
	/>
);

export default Master;