import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormTextField, StyledFormRadioGroup, StyledImagesUploader} from '../../../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedPageWrapper from '../../ThemedPageWrapper'

const C = "profiles.folder-f.PageClaim.PageCollision.Car.PageParty";
const CC = "profiles.folder-f.PageClaim.PageCollision";

const PagePartyForm = ({T, actions, item, images, onChanged}) => ({formProps: {values}}) => {
	const controls = [
		{label: T(C, 'partyKnown.options.manual'), value: "manual"},
		{label: T(C, 'partyKnown.options.unknown'), value: "unknown"}
	];

	const partyIsKnown = values.partyKnown === "manual";

	return (
		<ThemedPageWrapper>
			<Form>
				<Field
					type="text"
					name="partyPlate"
					label={T(C, "partyPlate.label")}
					placeholder={T(C, "partyPlate.placeholder")}
					component={StyledFormTextField}
				/>
				<Field
					type="text"
					name="partyKnown"
					label={T(C, "partyKnown.label")}
					component={StyledFormRadioGroup}
					controls={controls}
					inline
					onChanged={value => onChanged({...values, ...value})}
				/>
				{partyIsKnown && (
					<>
						<Field
							name="images_license"
							label={T(C, "images_license.label")}
							actions={actions}
							item={item}
							images={images}
							tag="license"
							height="80px"
							component={StyledImagesUploader}
						/>
						<Field
							type="text"
							name="partyName"
							label={T(C, "partyName.label")}
							placeholder=""
							component={StyledFormTextField}
						/>
						<Field
							type="text"
							name="partyAddress"
							label={T(C, "partyAddress.label")}
							placeholder=""
							component={StyledFormTextField}
						/>
						<Field
							type="text"
							name="partyTelno"
							label={T(C, "partyTelno.label")}
							placeholder=""
							component={StyledFormTextField}
						/>
						<Field
							type="text"
							name="partyEmail"
							label={T(C, "partyEmail.label")}
							placeholder=""
							component={StyledFormTextField}
						/>
						<Field
							type="text"
							name="partyInsurer"
							label={T(C, "partyInsurer.label")}
							placeholder=""
							component={StyledFormTextField}
						/>
					</>
				)}
			</Form>
		</ThemedPageWrapper>
	);
};

const validationSchema = ({T}) => Yup.object().shape({
	partyKnown: Yup.string().required(T(CC, "yup.partyKnown")),
	partyPlate: Yup.string(),
	partyName: Yup.string(),
	partyAddress: Yup.string(),
	partyTelno: Yup.string(),
	partyEmail: Yup.string().trim().email(T(CC, "yup.partyEmail")),
	partyInsurer: Yup.string()
});

const PageParty = ({wizardProps: {T, actions}, item, images}) => {
	const [formState, dispatch] = useFormState();
	const {partyKnown, partyPlate, partyName, partyAddress, partyTelno, partyEmail, partyInsurer} = formState;
	const initialValues = {partyKnown, partyPlate, partyName, partyAddress, partyTelno, partyEmail, partyInsurer};

	const onChanged = values => dispatch({type: "FORM_CHANGE_VALUES", ...values});
	const FormComponent = PagePartyForm({T, actions, item, images, onChanged});

	const onPrevious = () => dispatch({type: "FORM_GOTO_PREVIOUS_SUBSTEP"});
	const onSubmit = values => {
		dispatch({type: "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_PARTY", ...values});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema({T})}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageParty;