import React from 'react'
import _ from 'lib/lodash'

import FormContainer from 'components/forms/FormContainer';
import PageToolbar from '../PageToolbar'
import {StyledButton} from '../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedSimpleLabel from '../ThemedSimpleLabel';

const C = "profiles.demo-sc.PageService";

const PageService = ({T, actions, item, subitems, previousStep, nextStep, userInfo, setUserInfo, ...otherProps}) => {
	const [{service, details, comments, location, datetime}, dispatch] = useFormState();
	const initialValues = {details, comments, location, datetime: datetime || new Date()};

	//const doc = _.get(_.filter(subitems, {properties: {component: "demo/demo-smartconcierge19/form"}}), 0, {});
	const images = _.filter(subitems, {type: "image"});

	const { ServiceForm, validationSchema } = {
		"claim": require('./ServiceFormClaim'),
		"massage": require('./ServiceFormMassage'),
		"resource": require('./ServiceFormResource')
	}[service];

	const FormComponent = ServiceForm({actions, item, images, initialValues});

	const onPreviousStep = () => {
		dispatch({type: "FORM_GOTO_PREVIOUS_STEP"});
		previousStep();
	};

	const onSubmit = ({details, comments, location, datetime, datetimeTo}, {setSubmitting}) => {
		dispatch({type: "FORM_SUBMIT_STEP_SERVICE", details, comments, location, datetime, datetimeTo});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		nextStep();
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			HeaderComponent={PageToolbar({title: T(C, `${service}.title`)})}
			LabelComponent={ThemedSimpleLabel}
			label={T(C, `${service}.label`)}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" flat onClick={onPreviousStep}>{T(C, "button.cancel")}</StyledButton>,
				<StyledButton styled="primary" key="submit" flat onClick={submitForm}>{T(C, "button.submit")}</StyledButton>,
			]}
		/>
	);
};

export default PageService;