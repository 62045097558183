import React, {Component} from 'react';
import {Button, List, Subheader} from 'lib/react-md'
import Dropzone from 'react-dropzone'
import {format} from 'date-fns'
import {api} from 'services'
import _ from 'lib/lodash'

import {ImageFactory, AudioFactory} from 'components/nodes/factories'
import {ResponsiveImageGallery, ConfirmationDialog} from 'components/controls'
import {withIntl, withDialog} from 'components/hocs'
import {LoadingDots} from 'components/utils'
import {validateEmail} from 'lib/utils'

import CustomHVCHAudioRecordings from "./CustomHVCHAudioRecordings";
import FeedbackForm from './FeedbackForm'
import css from './Claim.module.scss'

const C = "profiles.helvetia-ch.CustomHVCHClaimCollectionSlave";

class Claim extends Component {
	state = {
		isLoading: false,
		feedbackFormVisible: false
	};

	constructor(props) {
		super(props);
		this.dropZone = React.createRef();
		this.feedbackForm = React.createRef();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return _.anyDiff(nextState, this.state, ['isLoading', 'feedbackFormVisible'])
			|| _.anyDiff(nextProps, this.props, ['item', 'claim', 'images', 'audios']);
	}

	componentDidMount() {
		const {actions, claim, getDriver} = this.props;
		const {properties = {}} = claim;
		const {driverName, driverZip, driverTelno, driverEmail} = getDriver();

		actions.updateDocument({
			...claim,
			properties: {
				...properties,
				datetime: !!properties.datetime ? properties.datetime : new Date(), // set current date/time as default value
				driverName,
				driverZip,
				driverTelno,
				driverEmail
			}
		}, true);
	}

	onDrop = acceptedFiles => {
		const {item, actions} = this.props;

		_.forEach(acceptedFiles, file => actions.postSubnodeAndFile(item, "slave", ImageFactory, file));
	};

	onNewAudioRecording = blob => {
		const {item, actions} = this.props;
		const now = format(new Date(), 'yyyyMMddkkmmss');
		const id = `${actions.getBundleId()}-${actions.getStickerId()}-${now}`;

		const filename = `Audio message ${id}`;
		actions.postSubnodeAndFile(item, "slave", AudioFactory, blob, undefined, filename);
	}

	filterChildrenByType = (type, extraActions) => {
		return React.Children.map(this.props.children, child => {
			const items = child.props.items.filter(item => item.type === type);
			const actions = {...child.props.actions, ...extraActions};

			return React.cloneElement(child, {items, actions});
		});
	};

	validateForm = () => {
		const {T, actions, claim, images} = this.props;

		const noImages = _.size(images);
		const {properties = {}} = claim;
		const {
			owner = "", polno = "", plate = "", location = {label: ""},
			category = "", subcategory = "", partyEmail = "",
			driverName, driverZip, driverTelno,
			policeInvolved
		} = properties;
		const subcategoryList = subcategory.split(',');

		const message = (() => {
			if (!category || category === "0") return T(C, "validation.category");
			if ((category === "2" || category === "3" || category === "5") && !subcategory) return T(C, "validation.subcategory");
			if (category === "2" && ["2", "4", "5"].some(sc => subcategoryList.includes(sc))) {
				/*if (!partyName) message = T(C, "validation.party.name");
				else if (!partyTelno) message = T(C, "validation.party.telno");
				else if (!partyEmail || !validateEmail(partyEmail)) message = T(C, "validation.party.email");*/
				if (!!partyEmail && !validateEmail(partyEmail)) return T(C, "validation.party.email")
			}
			if ((category === "1" || category === "2" || category === "5") && policeInvolved === "") {
				return T(C, "validation.policeInvolved");
			}
			if (!location.label) return T(C, "validation.location");
			if (!owner) return T(C, "validation.owner");
			if (!polno) return T(C, "validation.polno");
			if (!plate) return T(C, "validation.plate");
			if (category === "1" && !noImages) return T(C, "validation.pictures");
			if (!driverName) return T(C, "validation.driverName");
			if (!driverZip) return T(C, "validation.driverZip");
			if (!driverTelno) return T(C, "validation.driverTelno");
		})();

		if (message) {
			actions.showCustomInfo(message);
			actions.gtmValidationError({eventAction: `${C}:validateForm`, eventLabel: message});
		}

		return !message;
	};

	clearForm = () => {
		const {actions, getDriver, claim, images, audios, stickerProps} = this.props;
		const {driverName, driverZip, driverTelno, driverEmail} = getDriver();

		claim.properties = {
			category: "",
			subcategory: "",
			location: {
				label: "",
				lng: 0,
				lat: 0
			},
			owner: stickerProps.owner || "",
			polno: stickerProps.polno || "",
			plate: "",
			partyStatus: "",
			partyName: "",
			partyAddress: "",
			partyZip: "",
			partyTelno: "",
			partyEmail: "",
			datetime: new Date(),
			comments: "",
			driverName,
			driverZip,
			driverTelno,
			driverEmail,
			policeInvolved: ""
		};

		actions.updateDocument(claim, true);

		if (!!_.size(images)) _.forEach(images, image => actions.updateNodeFilters(image, {exclude: true}));
		if (!!_.size(audios)) _.forEach(audios, audio => actions.updateNodeFilters(audio, {exclude: true}));
	};

	onPostEmail = () => {
		const {T, actions, intl, claim, images, audios, stickerProps, bundleProps, setDriver} = this.props;
		const {email: emailBundle = {}} = bundleProps; // from: {name, address}, to, cc, subject, templateId
		const {email: emailSticker = {}} = stickerProps; // from: {name, address}, to, cc, subject, templateId

		// merge email properties from bundleProps and stickerProps
		const customizer = (objValue, srcValue, key) => {
			if (objValue && ['to', 'cc', 'bcc'].indexOf(key) > -1) {
				return objValue.concat(`,${[srcValue].filter(Boolean).join(',')}`);
			}
		};
		const email = {};
		_.mergeWith(email, emailBundle, customizer);
		_.mergeWith(email, emailSticker, customizer);

		const id = actions.getId();

		const {properties = {}} = claim;
		const {
			owner = "", polno = "", plate = "", datetime: raw_datetime, location = {},
			category = "", subcategory = "",
			partyName = "", partyTelno = "", partyAddress = "", partyZip = "", partyEmail = "", comments = "",
			driverName, driverZip, driverTelno, driverEmail,
			policeInvolved = "0"
		} = properties;
		const {label: location_label = "", position = {}} = location;
		const {lat = 0, lng = 0} = position;
		const date = raw_datetime.toDate().toLocaleDateString();
		const time = raw_datetime.toDate().toLocaleTimeString();
		const {locale} = intl;
		const url = window.location.href;

		const categoryText = !!category ? T("menuItems", "claim.category.labels").split(';')[parseInt(category)] : "";
		var subcategoryText = "";

		const valueListToTextList = (valueList, textList) => _.map(valueList, value => textList[parseInt(value) - 1]).join(', ');

		switch (category) {
			case "2":
				subcategoryText = !!subcategory
					? valueListToTextList(subcategory.split(','), T("menuItems", "claim.category.2.labels").split(';'))
					: "";
				break;
			case "3":
				subcategoryText = !!subcategory
					? valueListToTextList(subcategory.split(','), T("menuItems", "claim.category.3.labels").split(';'))
					: "";
				break;
			default:
				subcategoryText = subcategory;
		}

		const policeInvolvedText = policeInvolved === "1" ? "Ja" : "Nein";

		const files = [...images, ...audios];
		const attachments = _.map(files, image => {
			const filename = _.get(image, "properties.label", `file_${claim.id}`);
			const extension = _.get(image, "storage.metadata.format", "unknown").replace("jpeg", "jpg");
			const path = _.get(image, "storage.fileUrl", "");
			const filesize = _.get(image, "storage.totalSize", 0);

			return {
				filename: filename + "." + extension,
				extension,
				path,
				filesize,
				isAudio: extension === "mp3",
				isVideo: extension === "mp4",
				isImage: ["jpg", "png", "gif"].includes(extension)
			};
		});

		const attachmentLocs = _.map(files, file => _.get(file, "storage.fileLoc", "")).join(",");
		const attachmentUrls = _.map(files, file => _.get(file, "storage.fileUrl", "")).join(",");

		if (!!partyEmail) _.mergeWith(email, {cc: partyEmail}, customizer);
		if (!!driverEmail) _.mergeWith(email, {cc: driverEmail}, customizer);

		const saveOptions = {
			dataset: "helvetia_ch",
			table: "pilot_18_claims",
			formData: {
				timestamp: new Date(),
				id,
				locale,
				polno,
				owner,
				categoryId: category,
				categoryText,
				subcategoryId: subcategory,
				subcategoryText,
				plate,
				partyName,
				partyTelno,
				partyAddress,
				partyZip,
				partyEmail,
				datetime: raw_datetime.toDate(),
				location_label,
				lat: lat.toFixed(4).toString(),
				lng: lng.toFixed(4).toString(),
				comments,
				driverName,
				driverZip,
				driverTelno,
				driverEmail,
				policeInvolved,
				attachmentLocs,
				attachmentUrls
			}
		};

		const params = {
			dynamic_template_data: {
				id,
				owner,
				categoryText,
				subcategoryText,
				partyName,
				partyTelno,
				partyAddress,
				partyZip,
				partyEmail,
				date,
				time,
				polno,
				plate,
				location_label,
				lat: lat.toFixed(4),
				lng: lng.toFixed(4),
				url,
				comments,
				driverName,
				driverZip,
				driverTelno,
				driverEmail,
				policeInvolved,
				policeInvolvedText,
			},
			...email,
			attachments,
			saveOptions
		};

		this.setState({isLoading: true}, () => {
			// store driver info for next time
			setDriver({driverName, driverZip, driverTelno, driverEmail});

			api.auth().post("postEmail", params)
				.then(() => {
					this.setState({isLoading: false, feedbackFormVisible: true}, () => {
						//this.clearForm();  // issue: feedbackform not visible since clearForm triggers a lot of updates

						actions.gtmCustomEvent({
							eventAction: "email:sent",
							eventLabel: (category || "x") + "." + (subcategory || "x")
						});
					});
				})
				.catch(error => {
					this.setState({isLoading: false});
					actions.raiseCustomError(error);
				})
		});
	};

	onUpload = () => this.dropZone.current.open();

	render() {
		const {isLoading, feedbackFormVisible} = this.state;
		const {T, item, actions, images, audios, getDriver, setDriver} = this.props;
		const {constraints = {}, $subnodeCount} = item;
		const {count = 0, mimeTypes} = constraints;
		const accept = mimeTypes || ["image/*"];
		const uploadLimitReached = !!count && $subnodeCount >= count;

		const Claim = _.get(this.filterChildrenByType("custom-hvch-claim", {
			onUpload: this.onUpload,
			onClear: this.clearForm,
			getDriver,
			setDriver
		}), '[0]');

		const items = _.map(images, image => ({
			alt: image.$id,
			original: _.get(image, 'storage.fileUrl'), /*_.get(subnode, 'storage.fileUrl'),*/
			description: _.get(image, 'properties.label'),
			thumbnail: _.get(image, 'storage.thumbUrl'),
			thumbnailWidth: _.get(image, 'storage.metadata.width'),
			thumbnailHeight: _.get(image, 'storage.metadata.height')
		}));

		const deleteImage = (idx, getCurrentIndex) => () => {
			const index = idx !== null ? idx : getCurrentIndex(); //null-checking mandatory since idx=0 is a valid index!
			const currentNode = _.get(images, index, {});

			if (!_.isEmpty(currentNode)) {
				actions.deleteDocument(currentNode);
				actions.showCustomInfo({id: "node.deleted"});
			}
		};

		const deleteAudio = node => {
			if (!_.isEmpty(node)) {
				actions.deleteDocument(node);
				actions.showCustomInfo({id: "node.deleted"});
			}
		};

		const customControls = (idx, getCurrentIndex) => () => (
			<Button
				icon
				key="delete"
				onClick={deleteImage(idx, getCurrentIndex)}
				data-gtm-action={`button:${C}`}
				data-gtm-label="Bild löschen"
			>
				delete
			</Button>
		);

		const ConfirmationDialogContainer = withDialog(({id, visible, show, hide, title, children}) => (
			<>
				<Button
					flat
					primary
					onClick={() => this.validateForm() && show()}
					data-gtm-action={`button:${C}`}
					data-gtm-label="Absenden"
				>
					{T(C, "send")}
				</Button>
				<ConfirmationDialog
					id="confirmation-dialog"
					visible={visible}
					title={title}
					actions={[
						{
							id: "cancel",
							secondary: false,
							children: T(C, "confirmDialog.Cancel"),
							onClick: hide
						},
						{
							id: "ok",
							secondary: false,
							children: T(C, "confirmDialog.OK"),
							onClick: () => hide(null, this.onPostEmail)
						},
					]}
					initialFocus="ok"
				>
					{children}
				</ConfirmationDialog>
			</>
		));

		const confirmationDialogContainer = () => (
			<ConfirmationDialogContainer title={T(C, "confirmDialog.title")}>
				{T(C, "confirmDialog.text")}
			</ConfirmationDialogContainer>
		);

		const onFeedbackFormHide = () => {
			const {containerActions, actions} = this.props;

			this.setState({feedbackFormVisible: false}, () => {
				// add feedback
				const ratings = this.feedbackForm.current.getAndResetRatings();

				_.forEach(ratings, (rating, idx) => {
					if (!!rating) {
						actions.gtmCustomEvent({
							eventAction: `feedback:${C}:feedbackForm.${idx}`,
							eventLabel: rating
						});
					}
				});

				containerActions.changeTabIndex(0); // move to start page
				actions.showCustomInfo({id: "feedback.thanks"});

				this.clearForm(); // TODO: put earlier in the process, but mind the feedbackFormVisible state!
			});
		};

		const onDropzoneClick = () => {
			actions.gtmClickEvent({
				eventAction: `button:${C}`,
				eventLabel: "Foto hochladen"
			});
		};

		return (
			<>
				<List className={css.list}>
					<div className={css.infoBoxContainer}>
						<div className="infoBoxSecondary">{T(C, "title")}</div>
					</div>
					{Claim}
					<Subheader primaryText={T(C, "subheader.primary.audio")}/>
					<CustomHVCHAudioRecordings T={T} actions={actions} audios={audios} onNewRecording={this.onNewAudioRecording}
																		 onDeleteRecording={deleteAudio}/>
					<Subheader primaryText={T(C, "subheader.primary.bottom")}/>
					<div className={css.content}>
						<ResponsiveImageGallery
							items={items}
							customControls={customControls}
							showCustomControlsInLightbox={true}
							showCustomControlsInView={false}
							enableFullscreen={false}
						/>
					</div>
				</List>
				<div className={css.floatingButtons}>
					<div className={css.primaryFloatingButton}>
						<Dropzone
							ref={this.dropZone}
							className={css.dropzone}
							accept={accept}
							multiple={false}
							onDrop={this.onDrop}
						>
							{({getRootProps, getInputProps}) =>
								<div
									{...getRootProps({
										onClick: onDropzoneClick
									})}
									className="md-btn md-btn--flat md-btn--text md-pointer--hover md-text--theme-primary md-ink--primary md-inline-block"
								>
									<input {...getInputProps()}/>
									{!uploadLimitReached ? T(C, "upload") : T(C, "uploadLimitReached")}
								</div>
							}
						</Dropzone>
					</div>
					<div className={css.tertiaryFloatingButton}>
						{!isLoading && confirmationDialogContainer()}
						{isLoading && <LoadingDots styleClassName={css.loadingDots}/>}
					</div>
					<FeedbackForm
						T={T}
						ref={this.feedbackForm}
						visible={feedbackFormVisible}
						onHide={onFeedbackFormHide}
					/>
				</div>
			</>
		);
	}
}

export default withIntl(Claim);