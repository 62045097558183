import React from 'react'
import {connect} from 'react-redux'
import _ from 'lib/lodash'

import {LanguagePicker} from 'components/controls'

const LanguagePickerContainer = ({languages, defaultValue, onChange}) => (
	<LanguagePicker
		languages={languages}
		defaultValue={defaultValue}
		onChange={onChange}
	/>
);

const mapStateToProps = ({dataHandler}) => ({
	languages: _.get(dataHandler, 'languages', {})
});

export default connect(mapStateToProps)(LanguagePickerContainer);