import React from 'react'
import Dropzone from 'react-dropzone'
import {FontIcon} from 'lib/react-md'

export default ({accept, onDrop, multiple = true, icon = "add"}) => (
  <Dropzone
    accept={accept}
    onDrop={onDrop}
    multiple={multiple}
  >
    {({getRootProps, getInputProps}) => (
      <div {...getRootProps()}>
        <input {...getInputProps()}/>
        <FontIcon>{icon}</FontIcon>
      </div>
    )}
  </Dropzone>
);