import React, {Component} from 'react';
import _ from 'lib/lodash'

export const UserInfoContext = React.createContext();

const APIVERSION = process.env.REACT_APP_FIREBASE_APIVERSION;
const APISUBVERSION = process.env.REACT_APP_FIREBASE_APISUBVERSION;

class RawUserInfoProvider extends Component {
	state = {};

	shouldComponentUpdate(nextProps, nextState) {
		return !_.isEqual(nextState, this.state);
	}

	getStorageItemUserInfo = () => {
		const {profileId} = this.props;
		return `stx-${APIVERSION}-${APISUBVERSION}-userinfo-${profileId}`;
	};

	componentDidMount() {
		const storageItem = this.getStorageItemUserInfo();

		try {
			const encodedUserInfo = Buffer.from(localStorage.getItem(storageItem), 'base64').toString('utf8');

			this.setState(JSON.parse(encodedUserInfo));
		} catch (e) {}
	}

	setUserInfo = (userInfo) => {
		const storageItem = this.getStorageItemUserInfo();

		try {
			const encodedUserInfo = Buffer.from(JSON.stringify(userInfo), 'utf8').toString('base64');
			localStorage.setItem(storageItem, encodedUserInfo);

			this.setState(userInfo);
		} catch(e) {
			console.error('Could not set UserInfo.');
		}

		return userInfo;
	};

	render() {
		const {children} = this.props;
		const userInfo = this.state;

		return (
			<UserInfoContext.Provider value={{
				userInfo,
				setUserInfo: this.setUserInfo,
			}}>
				{children}
			</UserInfoContext.Provider>
		)
	}
}

export const UserInfoProvider = RawUserInfoProvider;