import React, {PureComponent} from 'react';
import {Button} from 'lib/react-md'
import _ from 'lib/lodash'

import * as Wrappers from 'components/wrappers'
import css from './ButtonSlave.module.scss'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

class ViewComponent extends PureComponent {

	onClick = () => {
		const {item} = this.props;
		const {properties = {}} = item;
		const {value} = properties;

		window.location.href = `tel:${value}`;
	};

	render() {
		const {item} = this.props;
		const {$id: id, properties = {}} = item;
		const {label = ""} = properties;

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.viewContainer}>
					<Button
						id={id}
						flat
						secondary
						swapTheming
						className={css.button}
						onClick={this.onClick}
						data-gtm-action="node:button"
						data-gtm-label={label}
					>
						{label}
					</Button>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

export default {
	ViewComponent,
	EditComponent: () => null
}