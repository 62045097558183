import React, {PureComponent} from 'react';

class CustomHVCHClaimCollectionSlave extends PureComponent {
	render() {
		const {children, dataObj} = this.props;

		return (
			<div>
				Hello {dataObj.title}
				{children}
			</div>
		);
	}
}

export default CustomHVCHClaimCollectionSlave;