import React from 'react'
import {Wizard as BaseWizard} from 'react-wizard-primitive';
import styled from 'styled-components/macro'

import SimplePages from './Pages/SimplePages'

const WizardWrapper = styled.div`
	display: flex;
	flex-direction: column;
  height: 100%;
`;

const HeaderWrapper = styled.div`
	flex: 0;
`;

const LabelWrapper = styled.div`
	flex: 0;
`;

const StepperWrapper = styled.div`
	flex: 0;
`;

const PagesWrapper = styled.div`
	flex: 1;
	position: relative;
	height: 100%;
	overflow: auto; // required in case no other wrappers are provided
`;

const FooterWrapper = styled.div`
	flex: 0;
`;

const DefaultComponent = styled.div``;

const Wizard = ({
	                HeaderComponent = DefaultComponent,
	                StepperComponent = DefaultComponent,
	                LabelComponent = DefaultComponent,
	                PagesComponent = SimplePages,
	                FooterComponent = DefaultComponent,
	                labelPosition = "above",
	                children,
	                initialStepIndex = 0,
	                onChange = null,
	                ...otherProps
                }) => {

	const childrenArray = React.Children.toArray([].concat(children).filter(React.isValidElement));
	const pageProps = childrenArray.map(({props}) => ({...props}));

	return (
		<BaseWizard initialStepIndex={initialStepIndex} onChange={onChange}>
			{wizardProps => {
				const mergedProps = {pageProps, ...wizardProps, ...otherProps};

				const Label = () => (
					<LabelWrapper>
						<LabelComponent {...mergedProps}/>
					</LabelWrapper>
				);

				return (
					<WizardWrapper>
						<HeaderWrapper>
							<HeaderComponent {...mergedProps}/>
						</HeaderWrapper>
						{labelPosition === "above" && Label()}
						<StepperWrapper>
							<StepperComponent {...mergedProps}/>
						</StepperWrapper>
						{labelPosition === "below" && Label()}
						<PagesWrapper>
							<PagesComponent {...mergedProps}>
								{childrenArray}
							</PagesComponent>
						</PagesWrapper>
						{labelPosition === "bottom" && Label()}
						<FooterWrapper>
							<FooterComponent {...mergedProps}/>
						</FooterWrapper>
					</WizardWrapper>
				)
			}}
		</BaseWizard>
	);
};

export default Wizard;