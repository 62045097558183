import React from 'react'
import {Form, Field} from 'formik';
import * as Yup from 'yup';
import _ from 'lib/lodash'

import {FormContainer} from 'components/forms';
import {StyledButton, StyledFormTextField, StyledInfoBox} from '../../../../controls';
import ThemedPageWrapper from '../PageClaim/ThemedPageWrapper'

const C = "profiles.folder-f.PageDriver.PageDriverInfo";

const PageDriverInfoForm = ({T}) => (
	<ThemedPageWrapper>
		<Form>
			<StyledInfoBox styled="primary">
				<div>{T(C, "info1")}</div>
				<br/>
				<div>{T(C, "info2")}</div>
			</StyledInfoBox>
			<Field
				type="text"
				name="driverEmail"
				label={T(C, "email")}
				placeholder={T(C, "email.placeholder")}
				component={StyledFormTextField}
			/>
		</Form>
	</ThemedPageWrapper>
);

const validationSchema = Yup.object().shape({
}, [""]);

const PageDriverInfo = ({wizardProps: {T, actions}, submitForm, nextStep, userInfo, setUserInfo, cancel, ...otherProps}) => {
	const {driverPlate = "", driverName = "", driverTelno = "", driverEmail = ""} = _.get(userInfo, 'driver', {});
	const initialValues = {driverPlate, driverName, driverTelno, driverEmail};

	const onSubmit = async (values, ...submitProps) => {
		const updatedUserInfo = _.merge({}, userInfo, {driver: values});
		setUserInfo(updatedUserInfo);
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		submitForm(updatedUserInfo, ...submitProps);
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={PageDriverInfoForm}
			actionButtons={({formProps: {submitForm}}) => [
				<StyledButton styled="secondary" key="cancel" onClick={cancel}>{T(C, "previous")}</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>{T(C, "next")}</StyledButton>
			]}
		/>
	);
};

export default PageDriverInfo;