import React, {PureComponent} from 'react';
import {List, Button} from 'lib/react-md'
import _ from 'lib/lodash'

import {withIntl} from 'components/hocs'
import {TextFieldEdit} from 'components/controls'

import css from './Driver.module.scss'

const C = "profiles.helvetia-ch.CustomHVCHClaimCollectionSlave.Driver";

class Driver extends PureComponent {
	state = {
		driverName: _.get(this.props, "driver.driverName", ""),
		driverZip: _.get(this.props, "driver.driverZip", ""),
		driverTelno: _.get(this.props, "driver.driverTelno", ""),
		driverEmail: _.get(this.props, "driver.driverEmail", ""),
	};

	updateDriver = key => value => this.setState(value);

	setDriver = () => this.props.setDriver(this.state);

	validateForm = () => {
		const {T, actions} = this.props;
		const {driverName, driverZip, driverTelno} = this.state;

		const message = (() => {
			if (!driverName) return T(C, "validation.driverName");
			if (!driverZip) return T(C, "validation.driverZip");
			if (!driverTelno) return T(C, "validation.driverTelno");
			//if (!driverEmail) return T(C, "validation.driverEmail");
		})();

		if (message) {
			actions.showCustomInfo(message);
			actions.gtmValidationError({eventAction: `${C}:validateForm`, eventLabel: message});
		}

		return !message;
	};

	render() {
		const {T} = this.props;
		const {driverName, driverZip, driverTelno, driverEmail} = this.state;

		return (
			<>
				<List>
					<div className={css.infoBoxContainer}>
						<div className="infoBoxSecondary">{T(C, "title")}</div>
					</div>
					<TextFieldEdit
						id="driver.name"
						label="Driver.driverName"
						floatingLabel={false}
						className={css.textFieldEdit}
						containerClassName={css.containerTextFieldEdit}
						value={{driverName}}
						onSubmit={this.updateDriver("driverName")}
					/>
					<TextFieldEdit
						id="driver.zip"
						label="Driver.driverZip"
						floatingLabel={false}
						type="tel"
						className={css.textFieldEdit}
						containerClassName={css.containerTextFieldEdit}
						value={{driverZip}}
						onSubmit={this.updateDriver("driverZip")}
					/>
					<TextFieldEdit
						id="driver.telno"
						label="Driver.driverTelno"
						floatingLabel={false}
						type="tel"
						className={css.textFieldEdit}
						containerClassName={css.containerTextFieldEdit}
						value={{driverTelno}}
						onSubmit={this.updateDriver("driverTelno")}
					/>
					<TextFieldEdit
						id="driver.email"
						label="Driver.driverEmail"
						floatingLabel={false}
						className={css.textFieldEdit}
						containerClassName={css.containerTextFieldEdit}
						value={{driverEmail}}
						onSubmit={this.updateDriver("driverEmail")}
					/>
				</List>
				<div className={css.button}>
					<Button
						flat
						primary
						onClick={() => this.validateForm() && this.setDriver()}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Weiter"
					>
						{T("label", "Driver.button.ok")}
					</Button>
				</div>
			</>
		);
	}
}

export default withIntl(Driver);