import React from 'react'
import styled from 'styled-components/macro';
import {Form} from 'formik';
import {Subheader, ListItem, FontIcon} from 'lib/react-md';
import _ from 'lib/lodash'

import FormContainer from 'components/forms/FormContainer';
import PageToolbar from '../PageToolbar'
import {StyledButton, StyledList} from '../../../../controls';
import {useFormState} from 'components/hooks';
import ThemedSimpleLabel from '../ThemedSimpleLabel';

const C = "profiles.folder-f.PageCategory";

const PageWrapper = styled.div`
	padding: 16px; 
`;

const CategoryForm = ({T, formProps: {submitForm, setFieldValue}}) => {
		const categories = {
			"collision": {
				label: T(C, "collision.label"),
				subheader: T(C, "collision.subheader"),
				items: {
					"car": T(C, "collision.items.car"),
					"person": T(C, "collision.items.person"),
					"animal": T(C, "collision.items.animal"),
					"object": T(C, "collision.items.object")
				},
				icon: "collision",
				disabled: false,
			},
			"glass": {
				label: T(C, "glass.label"),
				icon: "windshield-broken",
				disabled: false,
			},
			"parking": {
				label: T(C, "parking.label"),
				icon: "park-damage",
				disabled: true,
			},
			"event": {
				label: T(C, "event.label"),
				icon: "storm-damage",
				disabled: true,
			},
			"other": {
				label: T(C, "other.label"),
				icon: "accident",
				disabled: true,
			}
		};

		const confirmChoice = (category, subcategory) => () => {
			setFieldValue("category", category, false);
			setFieldValue("subcategory", subcategory, false);
			submitForm();
		};

		return (
			<PageWrapper>
				<Form>
					<StyledList styled="secondary">
						{_.map(categories, ({label, subheader, icon, disabled, items = {}}, categoryId) => (
								<ListItem
									key={categoryId}
									primaryText={label}
									expanderIcon={_.isSet(items) ? <FontIcon>keyboard_arrow_down</FontIcon> : <div/>}
									animateNestedItems={false}
									disabled={disabled}
									nestedItems={[
										<StyledList styled="nested" key="subcategories">
											{subheader && <Subheader primaryText={subheader}/>}
											{_.map(items, (subcategory, subcategoryId) => (
												<ListItem
													key={subcategoryId}
													primaryText={subcategory}
													onClick={confirmChoice(categoryId, subcategoryId)}
												/>
											))}
										</StyledList>
									]}
									onClick={_.isSet(items) ? () => null : confirmChoice(categoryId, "")}
								/>
							)
						)}
					</StyledList>
				</Form>
			</PageWrapper>
		);
	}
;

const PageCategory = ({T, actions, previousStep, nextStep, userInfo, setUserInfo}) => {
	const [, dispatch] = useFormState();

	const onPreviousStep = () => {
		dispatch({type: "FORM_GOTO_PREVIOUS_STEP"});
		previousStep();
	};

	const onSubmit = ({category, subcategory}, {setSubmitting}) => {
		dispatch({type: "FORM_SUBMIT_STEP_CATEGORY", category, subcategory});
		actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
		nextStep();
	};

	const initialValues = {
		category: "",
		subcategory: "",
	};

	return (
		<FormContainer
			initialValues={initialValues}
			onSubmit={onSubmit}
			FormComponent={CategoryForm}
			HeaderComponent={PageToolbar({title: T(C, "title")})}
			LabelComponent={ThemedSimpleLabel}
			label={T(C, "label")}
			actionButtons={() => [
				<StyledButton styled="secondary" key="cancel" flat onClick={onPreviousStep}>{T(C, "previous")}</StyledButton>,
			]}
		/>
	);
};

export default PageCategory;