import React from 'react';
import PropTypes from 'prop-types';

import {Card, CardText, FontIcon} from 'lib/react-md'
import {WebCodeScanner} from 'components/scanners'

import css from './TabWebCode.module.scss'

const C = "templates.portal.TabWebCode";

const TabWebCode = ({encodePrefix, length, options, placeholder, onNextTab, showCameraButton, helpImagePath}) => (
	<Card className={css.card}>
		<CardText className={css.cardText}>
			<div className={css.webcode}>
				<WebCodeScanner
					encodePrefix={encodePrefix}
					length={length}
					options={options}
					placeholder={placeholder}
				/>
				{showCameraButton && (
					<FontIcon onClick={onNextTab} data-gtm-action={`button:${C}`} data-gtm-label="QRReader">photo_camera</FontIcon>
				)}
			</div>
			<div className={css.image}>
				<img src={require(`../../../${helpImagePath}`)} alt="Webabrufcode"/>
			</div>
		</CardText>
	</Card>
);

TabWebCode.propTypes = {
	length: PropTypes.number.isRequired,
	options: PropTypes.object.isRequired,
	placeholder: PropTypes.string,
	onNextTab: PropTypes.func.isRequired,
	showCameraButton: PropTypes.bool.isRequired,
	helpImagePath: PropTypes.string.isRequired
};

export default TabWebCode