import React, {PureComponent} from 'react'
import {Toolbar, Button, List, Subheader} from 'lib/react-md'

import {withIntlDrawer} from 'components/hocs'
import {LocaleContainer} from 'components/containers'

import css from './StickerMenu.module.scss'
import history from '../../../../../history';

const C = "profiles.demo-insurance.StickerMenu";

class StickerMenu extends PureComponent {
	navigateToPortal = () => history.push('./');

	componentDidMount() {
		const {T, initDrawer, closeDrawer, languages, bundle, sticker} = this.props;
		const id = bundle.id + '/' + sticker.id;

		const container = (
			<div className={css.container}>
				<Toolbar
					colored
					fixed
					title={id}
					zDepth={0}
					nav={<Button icon onClick={closeDrawer}>close</Button>}
					actions={<Button icon onClick={this.navigateToPortal}>home</Button>}
				/>
				<section>
					<p className={css.title}>{T(C, "title")}</p>
					<p className={css.text}>{T(C, "welcomeMessage")}</p>
					<List>
						<Subheader primaryText={T(C, "subheaderLanguages")}/>
						<LocaleContainer languages={languages}/>
					</List>
				</section>
			</div>
		);

		initDrawer({container, position: "left", memoized: true});
	}

	render() {
		const {openDrawer} = this.props;

		return (
			<Button
				icon
				onClick={openDrawer}
			>
				menu
			</Button>
		);
	}
}

export default withIntlDrawer(StickerMenu, {name: "StickerMenu"});