import styled from 'styled-components/macro';

const Container = styled.div`
	height: calc(100vh - 50px);
  background:#00acc1;
  padding:0;
  margin:0;

  font-family: 'Caveat', cursive;
  font-weight: 700;
  font-style: normal;
  
  color: white;
	
	canvas {
	  opacity:0;
	  position:fixed;
	}
	
	#scene{
	  height: 45%;
	  position: absolute;
	  bottom: 0;
	}
	
	#content {
	  position: absolute;
	  width: 100%;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	}
	
	#container {
	  font-size: 28px;
	  text-align: center;
	
	  padding: 1em;
	}
	
	@media all and (min-width: 960px) {
	  #container {
	    font-size: 50px;
	  }
	}
	
	@media all and (max-width: 959px) and (min-width: 600px) {
	  #container {
	    font-size: 5vw;
	  }
	}
	
	@media all and (max-width: 599px) and (min-width: 50px) {
	  #container {
	    font-size: 6vw;
	  }
	}
	
	@media all and (max-height: 400px) {
	  #container {
	    font-size: 6vh;
	  }
	}
	
	#content div {
	  padding-top: 16px;
	}
`;

export {
	Container
}