import PageClaim from './PageClaim/PageClaim'
import PageCategory from './PageCategory/PageCategory'
import PageDriver from './PageDriver/PageDriver'
import PageInactive from './PageInactive/PageInactive'
import PageMain from './PageMain/PageMain'

export {
	PageClaim,
	PageCategory,
	PageDriver,
	PageInactive,
	PageMain
}