import React from 'react';
import styled from 'styled-components/macro';
import {FormLocationPicker} from 'components/forms'

const FormLocationPickerWrapper = ({className, ...otherProps}) => <FormLocationPicker className={className} {...otherProps}/>;

export default styled(FormLocationPickerWrapper)`
	label {
		color: ${({theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;
	}
	
	> div {
		padding-top: 8px;
		font-size: 16px;
	}
	
	#wrapper {
    #suggestion {
      display: flex;
      flex-direction: row;
      border: #ccc 1px solid;
      border-radius: 4px;
      align-items: center;
      height: 40px;
      font-size: 16px;
      background: white;

      #geosuggest {
        flex: 1 1;
      }
      
      #suggestion_button_determine {
      	color: ${({theme}) => theme.secondaryColor};
      }
    }
    
    #locationmap_container {
    	height: 160px;
    }

    #placeholder {
      font-size: 16px;
      color: ${({theme}) => theme.secondaryColor};
    }
  }
  
  #errorText {
    font-size: 16px;
  	color: ${({theme}) => theme.alertTextColor};
  }
`;