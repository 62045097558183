import _ from 'lib/lodash'

export const isAndroid = /Android/i.test(window.navigator.userAgent);
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// Get ordered list of locales from various sources. window.navigator contains language information
// 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"]) Firefox^32, Chrome^32
// 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11, Safari, Chrome sends Browser UI language
// 3. browserLanguage -> UI Language of IE<11
// 4. userLanguage    -> Language of Windows Regional Options, IE<11
// 5. systemLanguage  -> UI Language of Windows, IE<11
const detectLocales = () => {
	// if locale available in localStorage return value, otherwise detect based on browser settings
	const storedLocale = localStorage.getItem('stx-locale');
	if (!!storedLocale) return [storedLocale];

	if (!!navigator.languages && navigator.languages.length) {
		// remove country suffix and return only unique values
		return [...new Set(_.map(navigator.languages, lang => lang.substr(0, 2)))];
	} else if (!!navigator.language) return [navigator.language.substr(0, 2)];
	else if (!!navigator.userLanguage) return [navigator.userLanguage.substr(0, 2)];
	else if (!!navigator.browserLanguage) return [navigator.browserLanguage.substr(0, 2)];
	else if (!!navigator.systemLanguage) return [navigator.systemLanguage.substr(0, 2)];
	else return [];
};

// determine list of locale preferences and choose the first locale that is supported
export const getLocalePreference = availableLocales => detectLocales().find(locale => _.includes(availableLocales, locale));

// Overrides the following:
// https://github.com/facebook/react-devtools/blob/master/shells/chrome/src/checkForReact.js
export const disableReactDevTools = () => {
	if (
		typeof window === 'object' &&
		typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object'
	) {
		// do not load ReactDevTools
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = _.noop;
	}
};

export const enableReduxDevTools = environments => (
	typeof window === 'object' &&
	typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
	environments.includes(process.env.REACT_APP_ENV)
);

/* https://github.com/jbt/js-crypto/blob/master/hash.js */
/*eslint-disable no-mixed-operators*/
export const hash = str1 => {
	var blockstart = 0,
		i = 0,
		W = [],
		A, B, C, D, F, G,
		H = [A = 0x67452301, B = 0xEFCDAB89, ~A, ~B, 0xC3D2E1F0],
		word_array = [],
		temp2,
		s = unescape(encodeURI(str1)),
		str_len = s.length;

	for (i = 0; i <= str_len; word_array[i >> 2] |= (s.charCodeAt(i) || 128) << (8 * (3 - i++ % 4))) {
	}

	word_array[temp2 = ((str_len + 8) >> 2) | 15] = str_len << 3;

	for (; blockstart <= temp2; blockstart += 16) {
		A = H;
		i = 0;

		for (; i < 80;
		       A = [[
			       (G = ((s = A[0]) << 5 | s >>> 27) + A[4] + (W[i] = (i < 16) ? ~~word_array[blockstart + i] : G << 1 | G >>> 31) + 1518500249) + ((B = A[1]) & (C = A[2]) | ~B & (D = A[3])),
			       F = G + (B ^ C ^ D) + 341275144,
			       G + (B & C | B & D | C & D) + 882459459,
			       F + 1535694389
		       ][0 | ((i++) / 20)] | 0, s, B << 30 | B >>> 2, C, D]
		) {
			G = W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16];
		}

		for (i = 5; i;) H[--i] = H[i] + A[i] | 0;
	}

	for (str1 = ''; i < 40;) str1 += (H[i >> 3] >> (7 - i++ % 8) * 4 & 15).toString(16);
	return str1;
};
/*eslint-enable no-mixed-operators*/

/*
sizeof.js
A function to calculate the approximate memory usage of objects
Created by Kate Morley - http://code.iamkate.com/ - and released under the terms
of the CC0 1.0 Universal legal code:
http://creativecommons.org/publicdomain/zero/1.0/legalcode
*/
export const sizeof = object => {

	// initialise the list of objects and size
	var objects = [object];
	var size = 0;

	// loop over the objects
	for (var index = 0; index < objects.length; index++) {
		// determine the type of the object
		switch (typeof objects[index]) {
			// the object is a boolean
			case 'boolean':
				size += 4;
				break;

			// the object is a number
			case 'number':
				size += 8;
				break;

			// the object is a string
			case 'string':
				size += 2 * objects[index].length;
				break;

			// the object is a generic object
			case 'object':
				var key;
				// if the object is not an array, add the sizes of the keys
				if (Object.prototype.toString.call(objects[index]) !== '[object Array]') {
					for (key in objects[index]) size += 2 * key.length;
				}
				// loop over the keys
				for (key in objects[index]) {
					// determine whether the value has already been processed
					var processed = false;
					for (var search = 0; search < objects.length; search++) {
						if (objects[search] === objects[index][key]) {
							processed = true;
							break;
						}
					}
					// queue the value to be processed if appropriate
					if (!processed) objects.push(objects[index][key]);
				}
				break;

			default:
				break;
		}
	}
	// return the calculated size
	return size;
};

export const convertToDMSString = ({lng, lat}) => {
	const toDMS = n => {
		n = Math.abs(n); // The sign doesn't matter

		const d = Math.floor(n); // Get the degrees

		n = n - d; // Strip off the answer we already have
		n *= 60; // And then put the minutes before the '.'

		const m = Math.floor(n); // Get the minutes
		n = n - m; // Remove them from the answer
		n *= 60; // Put the seconds before the '.'

		const s = Math.floor(n); // Get the seconds

		return `${d}°${m}'${s}"`;
	};

	return `${toDMS(lat)} ${lat > 0 ? 'N' : 'S'}, ${toDMS(lng)} ${lng > 0 ? 'E' : 'W'}`;
};

/* RFC 5322 implementation from http://emailregex.com */
// eslint-disable-next-line
export const validateEmail = email => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

/* round down date to nearest minutes interval, e.g. 30 */
export const getFloorDate = (minutes, d = new Date()) => {
	const ms = 1000 * 60 * minutes; // convert minutes to ms
	return new Date(Math.floor(d.getTime() / ms) * ms);
};

/* round up date to nearest minutes interval, e.g. 30 */
export const getCeilDate = (minutes, d = new Date()) => {
	const ms = 1000 * 60 * minutes; // convert minutes to ms
	return new Date(Math.ceil(d.getTime() / ms) * ms);
};

/*export const getFingerprint = () => {
	const nav = window.navigator;
	const screen = window.screen;

	return nav.userAgent + '-'
		+ nav.mimeTypes.length + '-'
		+ nav.plugins.length + '-'
		+ (screen.height || '') + '-'
		+ (screen.width || '') + '-'
		+ (screen.pixelDepth || '');
};*/

/*
export function copyTextToClipboard(text) {
	const temp = document.createElement("input");	// Create a "hidden" input
	temp.setAttribute("value", text); 					// Assign it the value of the specified element
	document.body.appendChild(temp);						// Append it to the body
	temp.select();												// Highlight its content
	document.execCommand("copy");							// Copy the highlighted text
	document.body.removeChild(temp); 					// Remove it from the body
}

export function copyElementToClipboard(elementId) {
	copyTextToClipboard(document.getElementById(elementId).innerHTML);
}*/