import React from 'react'
import filesize from 'filesize'
import {LinearProgress} from 'lib/react-md';

import {withIntl} from 'components/hocs'
import css from './UploadProgress.module.scss'

const C = "UploadProgress";

const UploadProgress = ({T, progress}) => (
	<div className={css.container}>
		<LinearProgress
			className={css.progressbar}
			id="upload-progress-linear-progress"
			centered
			value={progress.percentage || 0}
		/>
		<span className={css.title}>
			{T(C, "title")}
		</span>
		<span className={css.subtitle}>
			{T(C, "subtitle", {size: filesize(progress.bytesTransferred || 0, {round: 1}), totalSize: filesize(progress.totalBytes || 0, {round: 1})})}
		</span>
		<span className={css.percentage}>
			{`${Math.floor(progress.percentage || 0)} %`}
		</span>
		<span className={css.spacing}/>
	</div>
);

export default withIntl(UploadProgress);