import _ from 'lib/lodash'

/* action types */
export const types = {
	LAYOUT_ADD_DRAWER: 'LAYOUT/ADD_DRAWER',
	LAYOUT_UPDATE_DRAWER: 'LAYOUT/UPDATE_DRAWER',
	LAYOUT_OPEN_DRAWER: 'LAYOUT/OPEN_DRAWER',
	LAYOUT_CLOSE_DRAWER: 'LAYOUT/CLOSE_DRAWER',
	LAYOUT_REMOVE_DRAWER: 'LAYOUT/REMOVE_DRAWER',
};

/* initial state */
const initialState = {
	drawers: {},
	visibles: [] // shape {key, visible} status field can be used to trigger update
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.LAYOUT_ADD_DRAWER:
			return {
				drawers: {...state.drawers, [action.key]: {key: action.key, ...action.drawer}},
				visibles: [...state.visibles, {key: action.key, visible: false}]
			};

		case types.LAYOUT_UPDATE_DRAWER:
			return {
				drawers: {...state.drawers, [action.key]: {key: action.key, ...action.drawer}},
				visibles: _.map(state.visibles, v => v.key === action.key ? {...v, updated: Date.now()} : v)
			};

		case types.LAYOUT_OPEN_DRAWER:
			return {
				drawers: {...state.drawers},
				visibles: [..._.filter(state.visibles, v => v.key !== action.key), {key: action.key, visible: true}]
			};

		case types.LAYOUT_CLOSE_DRAWER:
			const topVisible = _.findLast(state.visibles, v => v.visible); // find top-most drawer
			return {
				drawers: {...state.drawers},
				visibles: _.map(state.visibles, v => v === topVisible ? {...v, visible: false} : v)
			};

		case types.LAYOUT_REMOVE_DRAWER:
			return {
				// eslint-disable-next-line
				drawers: _.pickBy(state.drawers, (v, k) => k !== action.key),
				visibles: _.filter(state.visibles, v => v.key !== action.key)
			};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	addDrawer: (key, drawer) => ({type: types.LAYOUT_ADD_DRAWER, key, drawer}),

	updateDrawer: (key, drawer) => ({type: types.LAYOUT_UPDATE_DRAWER, key, drawer}),

	openDrawer: key => ({type: types.LAYOUT_OPEN_DRAWER, key}),

	closeDrawer: () => ({type: types.LAYOUT_CLOSE_DRAWER}),

	removeDrawer: key => ({type: types.LAYOUT_REMOVE_DRAWER, key}),

	onVisibilityChange: visibles => !visibles && actions.closeDrawer()
};