/* imports */
import {LS} from 'lib/constants'

/* action types */
export const types = {
	STICKER_ROUTE_UPDATE_LOADING: 'STICKER_ROUTE/UPDATE_LOADING',

	STICKER_ROUTE_COMPONENT_LOAD_REQUEST: 'STICKER_ROUTE/COMPONENT_LOAD_REQUEST',
	STICKER_ROUTE_COMPONENT_LOAD_SUCCESS: 'STICKER_ROUTE/COMPONENT_LOAD_SUCCESS',
	STICKER_ROUTE_COMPONENT_LOAD_ERROR: 'STICKER_ROUTE/COMPONENT_LOAD_ERROR',

	STICKER_ROUTE_TRANSLATIONS_LOAD_REQUEST: 'STICKER_ROUTE/TRANSLATIONS_LOAD_REQUEST',
	STICKER_ROUTE_TRANSLATIONS_LOAD_SUCCESS: 'STICKER_ROUTE/TRANSLATIONS_LOAD_SUCCESS',

	STICKER_ROUTE_RESET_STATE: 'STICKER_ROUTE/RESET_STATE',
};

/* initial state */
const initialState = {
	StickerComponent: null,
	componentLS: LS.INIT,
	translationsLS: LS.INIT,
	error: null,
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.STICKER_ROUTE_COMPONENT_LOAD_REQUEST:
			return {...state, componentLS: LS.REQUESTED};

		case types.STICKER_ROUTE_COMPONENT_LOAD_SUCCESS:
			return {...state, componentLS: LS.LOADED, StickerComponent: action.Component};

		case types.STICKER_ROUTE_COMPONENT_LOAD_ERROR:
			return {...state, componentLS: LS.ERROR, StickerComponent: null, error: action.error};

		case types.STICKER_ROUTE_TRANSLATIONS_LOAD_REQUEST:
			return {...state, translationsLS: LS.REQUESTED};

		case types.STICKER_ROUTE_TRANSLATIONS_LOAD_SUCCESS:
			return {...state, translationsLS: LS.LOADED};

		case types.STICKER_ROUTE_RESET_STATE:
			return initialState;

		//case "@@intl/UPDATE": // locale is updated
		//	return {...state, translationsLS: LS.INIT};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	loadStickerComponent: component => dispatch => {
		dispatch({
			type: types.STICKER_ROUTE_COMPONENT_LOAD_REQUEST,
			component,
			onSuccessActions: [
				{type: types.STICKER_ROUTE_COMPONENT_LOAD_SUCCESS},
				{type: types.STICKER_ROUTE_UPDATE_LOADING, addAbs: 10, main: "sticker.main.components"}
			],
			onErrorActions: [{type: types.STICKER_ROUTE_COMPONENT_LOAD_ERROR}]
		});
	},

	loadTranslations: component => dispatch => {
		dispatch({
			type: types.STICKER_ROUTE_TRANSLATIONS_LOAD_REQUEST,
			profile: component.split('/')[1],
			onSuccessActions: [
				{type: types.STICKER_ROUTE_TRANSLATIONS_LOAD_SUCCESS},
				{type: types.STICKER_ROUTE_UPDATE_LOADING, addAbs: 10, main: "sticker.main.translations"}
			]
		});
	},

	resetStickerRouteHandler: () => ({type: types.STICKER_ROUTE_RESET_STATE})
};