import React, {Component} from 'react';
import {Drawer} from 'lib/react-md'
import _ from 'lib/lodash'

import css from './DrawersContainer.module.scss'

class DrawersContainer extends Component {
	shouldComponentUpdate(nextProps) {
		return _.anyDiff(nextProps, this.props, ['visibles']);
	}

	renderDrawer = (drawer, {key, visible}) => {
		const {onVisibilityChange} = this.props;
		const {position, container, navItems, className, style} = drawer;

		return (
			<Drawer
				key={key}
				className={className || css.drawer}
				position={position}
				type={Drawer.DrawerTypes.TEMPORARY}
				header={container}
				navItems={navItems}
				onVisibilityChange={onVisibilityChange}
				style={style}
				overlay
				visible={visible}
				transitionDuration={250}
			/>
		);
	};

	/* memoize function as only visibility flags change true|false */
	// eslint-disable-next-line
	renderDrawerMemoized = _.memoize(this.renderDrawer, (drawer = {}, {key, visible}) => key + visible);

	render() {
		const {drawers} = this.props;
		const {visibles} = this.props;

		return _.map(visibles, visible => {
			const drawer = drawers[visible.key];
			// TODO: transition not visible if not drawn with visibility={false} first
			if (visible.visible) {
				return drawer.memoized ? this.renderDrawerMemoized(drawer, visible) : this.renderDrawer(drawer, visible)
			} else return null;
		});
	}
}

export default DrawersContainer