/* eslint no-eval: 0 */
import React from 'react'
import styled from 'styled-components/macro'
import YouTube from 'react-youtube-embed'
import _ from 'lib/lodash'

import {TextFieldEdit, Wizard} from 'components/controls'
import {StickerFooter} from '../../../../common';
import PageMainToolbar from './PageMainToolbar'
import {StyledButton} from '../../../../controls';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import {useFormState} from 'components/hooks'
import ButtonAssistance from './ButtonAssistance';

import css from './PageMain.module.scss';

const C = "profiles.folder-f.Slave";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px;
`;

const HeaderWrapper = styled.div`
	flex: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	@media (min-width: 600px) {
		flex-direction: row;
	}
`;

const BodyWrapper = styled.div`
	flex: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	
	@media (max-width: 330px) {
		flex-direction: column;
	}
`;

const MainWrapper = styled.div`
	flex: 1;
	padding-top: 16px;
	
  button {
  	height: 60px;
	  width: 100%;
	  font-size: x-large !important;
  }
`;

const VideoWrapper = styled.div`
  margin: auto;
	max-width: 600px;
`;

const FooterWrapper = styled.div`
	flex: none;
`;

const PageMain = ({T, intl: {locale} = {}, actions, languages, bundle, sticker, nextStep, dataObj}) => {
  const [{claimId, showFeedback}, dispatch] = useFormState();
  const {owner = "", polno = "", plate = ""} = _.get(sticker, 'properties', {});
  const buttonAssistanceProps = _.get(dataObj, "button-assistance", {});
  const showButtonAssistance = eval(buttonAssistanceProps.filter); // check if filter condition is true

  const onSubmit = () => {
    dispatch({type: "FORM_SUBMIT_STEP_MAIN", owner, polno});
    actions.gtmCustomEvent({eventAction: `journey:${C}:submit`});
    nextStep();
  };

  const onSubmitFeedback = feedback => {
    dispatch({type: "FORM_SUBMIT_FEEDBACK", feedback});

    actions.gtmCustomEvent({eventAction: `journey:${C}:feedback`});
    actions.gtmCustomEvent({eventAction: `feedback:${C}:feedbackForm.1`, eventLabel: feedback.rating1});
  };

  return (
    <>
      <Wizard HeaderComponent={PageMainToolbar({T, languages, bundle, sticker})}>
        <Container>
          <HeaderWrapper>
            <TextFieldEdit
              id="owner"
              className={css.textfield}
              label="folder-f.overview.owner"
              value={{owner}}
              rows={owner.length > 16 ? 2 : 1}
              readOnly
            />
            <BodyWrapper>
              <TextFieldEdit
                id="polno"
                className={css.textfield}
                label="folder-f.overview.polno"
                value={{polno}}
                rows={1}
                readOnly
              />
              <TextFieldEdit
                id="plate"
                className={css.textfield}
                label="folder-f.overview.plate"
                value={{plate}}
                rows={1}
                readOnly
              />
            </BodyWrapper>
          </HeaderWrapper>
          <MainWrapper>
            <StyledButton
              styled="secondary"
              flat
              primary
              onClick={onSubmit}
              data-gtm-action={`button:${C}`}
              data-gtm-label="Schadenfall melden"
            >
              {T(C, "tabClaims")}
            </StyledButton>
            {showButtonAssistance &&
            <ButtonAssistance T={T} actions={actions} locale={locale} {...buttonAssistanceProps}/>}
            <VideoWrapper>
              <br/>
              <YouTube
                id={T(C, "youtubeVideoId")}
                appendSrc="?cc_load_policy=1&rel=0"
              />
            </VideoWrapper>
          </MainWrapper>
          <FooterWrapper>
            <StickerFooter/>
          </FooterWrapper>
        </Container>
      </Wizard>
      <FeedbackForm
        T={T}
        visible={showFeedback}
        onHide={onSubmitFeedback}
        claimId={claimId}
      />
    </>
  );
};

export default PageMain;