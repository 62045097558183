import axios from 'axios'

/* unauthenticated api */

let apiUnauth = axios.create({
	baseURL: process.env.REACT_APP_FIREBASE_APIURL,
	timeout: 20000
});

export const unauth = () => apiUnauth;

/* authenticated api */

let apiAuth = null;

export const initAuth = idToken => {
	apiAuth = axios.create({
		baseURL: process.env.REACT_APP_FIREBASE_APIURL,
		timeout: 20000,
		headers: {"Authorization": "Bearer " + idToken}
	});
};

export const auth = () => apiAuth;