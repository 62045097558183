import React from 'react'
import {connect} from 'react-redux';
import {Button} from 'lib/react-md'

import {layoutActions} from 'components/handlers/actions'

const FaqButton = ({openDrawer}) => {
	const openFAQDrawer = () => openDrawer("FAQ");

	return <Button icon onClick={openFAQDrawer}>help_outline</Button>;
};

export default connect(null, layoutActions)(FaqButton);
