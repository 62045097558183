/* action types */
export const types = {
	LOADING_SHOW: 'LOADING/SHOW',
	LOADING_UPDATE: 'LOADING/UPDATE',
	LOADING_HIDE: 'LOADING/HIDE',
};

/* initial state */
const initialState = {
	title: '',
	main: '',
	sub: '',
	imgPath: '',
	show: false,
	value: null
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.LOADING_SHOW:
			return {...action.loader, show: true};

		case types.LOADING_UPDATE:
			const value =  (state.value || 0) + (action.addAbs || (action.addRel * (100 - state.value)) || 0);
			return {...state,	title: action.title || state.title, main: action.main || state.main, sub: action.sub || state.sub, value};

		case types.LOADING_HIDE:
			return initialState;
		//return {...state};

		default:
			return state;
	}
}

/* action creators */
export const actions = {
	showLoading: loader => ({type: types.LOADING_SHOW, loader}),
	updateLoading: ({addAbs, addRel, title, main, sub}) => ({type: types.LOADING_UPDATE, addAbs, addRel, title, main, sub}),
	hideLoading: () => ({type: types.LOADING_HIDE}),
};