import React from 'react';
import styled from 'styled-components/macro';

import {SensotixLink} from 'components/utils'
import {withIntl} from 'components/hocs'

import css from './StickerFooter.module.scss'

const C = "profiles.folder.StickerFooter";

const A = styled.a`
	text-decoration: none;
	color: ${({styled, theme}) => theme.primaryColor};
	
	:visited, :hover, :active {
  	color: ${({styled, theme}) => theme.primaryColor};
	}
`;

const StickerFooter = ({T}) => (
	<div className={css.container}>
		<div className={css.footerTop}>
			<div className={css.footerMain}>
				<strong>{T(C, "title")}</strong>
				<div>{T(C, "subtitle1")}</div>
			</div>
			<div className={css.footerContact}>
				<div><strong>SENSOTIX GmbH</strong></div>
				<div>{<A href="tel:+436505907369">+43 650 590 7369</A>}</div>
				<div>{<A href="mailto:office@sensotix.com">office@sensotix.com</A>}</div>
				<div>{<A href="https://www.sensotix.com">www.sensotix.com</A>}</div>
			</div>
		</div>
		<div className={css.footerBottom}>
			<div className={css.footerLogo}>
				<SensotixLink title="SMARTSTICKER by Sensotix." data-gtm-action={`${C}:SMARTSTICKER by Sensotix`}/>
			</div>
		</div>
	</div>
);

export default withIntl(StickerFooter);