import React, {PureComponent} from 'react';
import {Button} from 'lib/react-md'

import {withEditDrawer} from 'components/hocs'
import * as Wrappers from 'components/wrappers'
import {TextFactory} from 'components/nodes/factories'

import css from './TextCollectionMaster.module.scss'

class ViewComponent extends PureComponent {
	addTitle = () => this.props.actions.postSubnode(this.props.item, "master", TextFactory);

	render() {
		const {children, allowAdd} = this.props;

		const AddItem = () => (
			<div className="styles-dropzone">
				<Button icon onClick={this.addTitle}>add</Button>
			</div>
		);

		return (
			<Wrappers.ViewComponentWrapper>
				<div className={css.container}>
					<div className={css.content}>
						{children}
					</div>
					<div className={css.footer}>
						{allowAdd && <AddItem/>}
					</div>
				</div>
			</Wrappers.ViewComponentWrapper>
		);
	}
}

class EditComponent extends Wrappers.EditComponentWrapper {
	render() {
		return null;
	}
}

export default {
	ContainerComponent: Wrappers.ContainerComponentWrapper,
	HeaderComponent: Wrappers.HeaderComponentWrapper,
	ViewComponent,
	EditComponent: props => withEditDrawer(EditComponent, {...props, hideSave: true})
}