import React from 'react';

import {Link, SensotixLink} from 'components/utils'
import {withIntl} from 'components/hocs'

import css from './StickerFooter.module.scss'

const C = "profiles.helvetia-ch.StickerFooter";

const StickerFooter = ({T}) => (
	<div className={css.container}>
		<div className={css.footer}>
			<strong>{T(C, "title")}</strong>
			<div>{T(C, "subtitle")}</div>
			<p/>
			<div>
				<Link
					title={T(C, "link.imprint.title")}
					href={T(C, "link.imprint.href")}
					data-gtm-action={`${C}:Impressum`}
				/>
				&nbsp;·&nbsp;
				<Link
					title={T(C, "link.legal.title")}
					href={T(C, "link.legal.href")}
					data-gtm-action={`${C}:Rechtliches`}
				/>
				&nbsp;·&nbsp;
				<Link
					title={T(C, "link.privacy.title")}
					href={T(C, "link.privacy.href")}
					data-gtm-action={`${C}:Datenschutz`}
				/>
			</div>
			<p/>
			<small>
				<strong>
					<SensotixLink title="Smart Stickers by Sensotix." data-gtm-action={`${C}:Smart Stickers by Sensotix`}/>
					</strong>
			</small>
		</div>
	</div>
);

export default withIntl(StickerFooter);