import React from 'react';
import {Divider} from 'lib/react-md'

import {ActionContainer} from 'components/containers'
import {DataConsumer} from 'components/contexts'

const actionsList = {
	public: ({A}) => [
		A.infoPanelPublic,
		A.textCollectionPublic,

		A.subheaderMediaPublic,
		A.imageCollectionPublic,
		A.image360CollectionPublic,
		A.videoCollectionPublic,
		A.video360CollectionPublic,
		A.documentCollectionPublic,
		<Divider key="divider2"/>,
		A.linkCollectionPublic,
		A.contactCollectionPublic,
		A.buttonCollectionPublic,
		A.customCollectionPublic,
	],
	shared: ({A}) => [
		A.infoPanelShared,
		A.postCollectionShared,
		A.imageCollectionShared,
		A.videoCollectionShared,
		A.contactCollectionShared,
		A.customCollectionShared,
	],
	private: ({T, A}) => [
		A.infoPanelPrivate,
		A.feedbackCollectionPublic,
		A.imageCollectionPrivate,
		A.videoCollectionPrivate,
		A.documentCollectionPrivate,
		A.customCollectionPrivate,
	]
};

const ActionsMenu = () => (
	<DataConsumer>
		{({isUploading, actions}) => !isUploading &&
			<ActionContainer actions={actions} actionsList={actionsList} icon="add"/>
		}
	</DataConsumer>
);

export default ActionsMenu;
